/*@font-face {
    font-family: 'SegoeUI MDL2 Assets';
    src: url(/fonts/SEGMDL2.eot), url(/fonts/SEGMDL2.ttf) format("truetype"), url(/fonts/SEGMDL2.svg) format("svg"), url(/fonts/SEGMDL2.woff) format("woff");
    font-weight: 100;
    font-style: normal
}*/
.icon-container {
    outline: 0;
    border: 0;
    width: auto;
    display: block;
    float: left;
    min-width: 0;
    min-height: 0;
    font-size: 1.25em;
    padding: 0 14px;
    background-color: transparent
}
.icon-container span {
    margin-left: 10px;
    position: relative;
    top: -3px
}
.icon-container:active,
.icon-container:hover {
    background: rgba(0, 0, 0, .1);
    cursor: pointer
}
.icon-container:active {
    background: rgba(0, 0, 0, .2);
    transform: scale(.98, .98)
}
.icon-container.disabled {
    opacity: .6;
    cursor: none
}
.icon-container.disabled:active,
.icon-container.disabled:hover {
    background: 0 0;
    cursor: none
}
.icon-container.disabled:active {
    transform: none
}
.icon {
    font-family: "SegoeUI MDL2 Assets";
    font-weight: 400;
    font-style: normal;
    position: relative;
    top: 1px;
    display: inline-block;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}
.icon.icon-notification:before {
    content: "\E91C"
}
.icon.icon-back:before {
    content: "\E112"
}
.icon.icon-close:before {
    content: "\E10A"
}
.icon.icon-hamburger:before {
    content: "\E700"
}
.icon.icon-search:before {
    content: "\E71E"
}
.icon.icon-user:before {
    content: "\E2AF"
}
.icon.icon-settings:before {
    content: "\E713"
}
.icon.icon-copy:before {
    content: "\E8C8"
}
.icon.icon-chevron-up:before {
    content: "\E09C"
}
.icon.icon-chevron-down:before {
    content: "\E09D"
}
.icon.icon-chevron-right:before {
    content: "\E00F"
}
.icon.icon-star:before {
    content: "\E1CE"
}
.icon.icon-star-filled:before {
    content: "\E1CF"
}
.icon.icon-basket:before {
    content: "\E821"
}
.icon.icon-pipe:before {
    content: "\E17B"
}
.icon.icon-accept:before {
    content: "\E001"
}
.icon.icon-pipe-health:before {
    content: "\E17A"
}
.icon.icon-bulb:before {
    content: "\E82F"
}
.icon.icon-alert:before {
    content: "\E1DE"
}
.icon.icon-eye:before {
    content: "\E052"
}
.icon.icon-square:before {
    content: "\E25E"
}
.icon.icon-circle:before {
    content: "\EC14"
}
.icon.icon-home:before {
    content: "\E10F"
}
.icon.icon-apps:before {
    content: "\E74C"
}
.icon.icon-pointer:before {
    content: "\E1E3"
}
.icon.icon-next:before {
    content: "\E0AB"
}
.icon.icon-home2:before {
    content: "\E80F"
}
.icon.icon-devices:before {
    content: "\E70C"
}
.icon.icon-clock:before {
    content: "\E823"
}
.icon.icon-collapse:before {
    content: "\E92C"
}
.icon.icon-book:before {
    content: "\E82D"
}
.icon.icon-calender:before {
    content: "\E163"
}
.icon.icon-resize:before {
    content: "\E73F"
}
.icon.icon-favoriteStar:before {
    content: "\E734"
}
.icon.icon-emoji2:before {
    content: "\E76E"
}
.icon.icon-delete:before {
    content: "\E74D"
}
.icon.icon-fullscreen:before {
    content: "\E740"
}
.icon.icon-glyph-733:before {
    content: "\EA6C"
}
.icon.icon-world-wire:before {
    content: "\E12B"
}
.icon.icon-glyph-533:before {
    content: "\E8AB"
}
.icon.icon-glyph-378:before,
.icon.icon-other-user:before {
    content: "\E7EE"
}
.icon.icon-check-outline-check:before {
    content: "\E0A2"
}
.icon.icon-plus:before {
    content: "\E948"
}
.icon.icon-glyph-81e:before {
    content: "\E81E"
}
.icon.icon-glyph-7c4:before {
    content: "\E7C4"
}
.icon.icon-contact-info:before {
    content: "\E779"
}
.icon.icon-theme:before {
    content: "\E771"
}
.icon.icon-users:before {
    content: "\E125"
}
.icon.icon-glyph-957:before {
    content: "\E957"
}
.icon.icon-glyph-1a6:before {
    content: "\E1A6"
}
.icon.icon-glyph-91f:before {
    content: "\E91F"
}
.icon.icon-tick:before {
    content: "\E73E"
}
.icon.icon-account-outline:before {
    content: "\E13D"
}
.icon.icon-edit:before {
    content: "\E104"
}
.icon.icon-stop:before {
    content: "\E71A"
}
.icon.icon-save:before {
    content: "\E74E"
}
.icon.icon-publish:before {
    content: "\E930"
}
.icon.icon-arrowHTMLMirroredLegacy:before {
    content: "\E0AE"
}
.icon.icon-arrowHTMLLegacy:before {
    content: "\E0D5"
}
.icon.icon-account-plus:before {
    content: "\E1E2"
}
.icon.icon-add-friend:before {
    content: "\E8FA"
}
.icon.icon-glyph-8cc:before {
    content: "\E8CC"
}
.icon.icon-glyph-152:before {
    content: "\E152"
}
.icon.icon-transcribe:before {
    content: "\E929"
}
.icon.icon-caption:before {
    content: "\E8BA"
}
.icon.icon-details:before {
    content: "\E12A"
}
.icon.icon-accept2:before {
    content: "\E8FB"
}
.icon.icon-disconnect-drive:before {
    content: "\E8CD"
}
.icon.icon-lock:before {
    content: "\E1F6"
}
.icon.icon-refresh:before {
    content: "\E1CD"
}
.icon.icon-new-window:before {
    content: "\E2B4"
}
.icon.icon-forward:before {
    content: "\E72A"
}
.icon.icon-back2:before {
    content: "\E72B"
}
.icon.icon-glyph-411:before {
    content: "\E81C"
}
.icon.icon-folder-move:before {
    content: "\E8DE"
}
.icon.icon-info:before {
    content: "\E946"
}
.icon.icon-arrow-left:before {
    content: "\E0A6"
}
.icon.icon-arrow-right:before {
    content: "\E0AD"
}
.icon.icon-email-opened:before {
    content: "\E166"
}
.icon.icon-send:before {
    content: "\E122"
}
.icon.icon-compare:before {
    content: "\E11E"
}
.icon-arrowdown:before {
        content: "\E74B";
	}

.icon-warning:before {
content: "\E7BA";
}
 
.icon-security-alert:before {
content: "\E1DE";
}
.icon-info-alert:before {
content: "\E946";
}
.icon-sucsess-alert:before {
content: "\E930";
}  
.icon-people:before {
        content: "\E716";
}
.icon-tag:before {
        content: "\E8EC";
}
.icon-contact:before {
        content: "\E77B";
}
.icon-dots-vertical:before {
    content: "\E796";
}
.icon-bulk:before {
    content: "\E2B2";
}
.icon-upload:before {
    content: "\E8B7";
}
.icon-zoom:before {
    content: "\E71E";
}
.icon-dots-horizontal:before {
    content: "\E10C";
}

.icon-alert-outline:before {
    content: "\E7BA";    
} 
.icon.icon-copy:before {
    content: "\E8C8"
} 

#content-area,
body,
body .row {
    height: 100%
}
.ng-cloak,
.ng-hide:not(.ng-hide-animate),
.x-ng-cloak,
[data-ng-cloak],
[ng-cloak],
[ng\:cloak],
[x-ng-cloak] {
    display: none!important
}
ng\:form {
    display: block
}
.ng-animate-shim {
    visibility: hidden
}
.ng-anchor {
    position: absolute
}
#header,
#header .icon-container.user-photo {
    position: relative
}
body {
    width: 100%;
    border: 0;
    padding: 0;
    margin: 0;
    font-family: "Segoe UI";
    font-size: 16px;
    background: #e9eef1 !important;
    color: #222
}
#header {
    background-color: #0275d1;
    height: 48px;
    overflow: hidden
}
/*#header .title {
    text-transform: uppercase;
    font-size: 1.0625em;
    line-height: 48px;
    font-weight: 500;
    color: #fff;
    display: block;
    float: left;
    margin-left: 14px
}*/
#header .title.adminMode {
    color: #fff
}
#header .icon-container {
    color: #d3f1e1;
    line-height: 48px;
    width: 48px;
    text-align: center
}
#header .icon-container.adminMode {
    color: #fff
}
#header .icon-container.margin-fix {
    margin-right: 20px
}
#header .icon-container .icon {
    font-size: 1em;
    text-align: center
}
#header .icon-container .user-photo-blank {
    background: rgba(255, 255, 255, .75);
    color: #25b768;
    border-radius: 50%;
    width: 36px;
    line-height: 36px;
    position: relative;
    top: 6px;
    left: 6px
}
#header .icon-container.user-photo .user-status-notifier {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background: #fff;
    border: 2px solid #E0E0E0;
    position: absolute;
    bottom: 5px;
    right: 3px
}
#header .icon-container.user-photo .user-status-notifier .status {
    width: 14px;
    height: 14px;
    position: absolute;
    top: -1px;
    left: -1px;
    border-radius: 50%;
    color: #fff;
    background-color: #FFF
}
#header .icon-container.user-photo .user-status-notifier .status .icon {
    font-size: x-small;
    position: absolute;
    height: 15px;
    left: 0;
    width: 15px
}
#header .icon-container.user-photo .user-status-notifier .status.available {
    background-color: #25b768
}
#header .icon-container.user-photo .user-status-notifier .status.offline {
    background-color: #FFF
}
#content {
    height: calc(100vh - 48px);
    background: url(/images/slide1.png) top left no-repeat;
    background-size: contain;
    background-position-x: -1%
}
#content.pipeline-mode {
    background-image: url(/images/slide4.png)
}
.notification-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    font-size: 10pt;
    z-index: 998;
    background: #151c25;
    color: #dee6ef;
    overflow: hidden;
    min-height: 48px
}
.notification-container .border {
    width: 5px;
    height: 24px;
    margin-left: 10px;
    margin-bottom: 8px;
    display: inline-block;
    background: #9eadc0
}
.notification-container.error .border,
.notification-container.networkError {
    background: #d45555
}
.notification-container.success .border {
    background: #25b768
}
.notification-container .icon-container {
    color: #dee6ef;
    display: inline-block;
    font-size: medium;
    width: 48px;
    line-height: 48px;
    text-align: center
}
.notification-container .notification {
    padding: 15px;
    display: inline-block;
    width: calc(100% - 100px)
}
#hero {
    position: relative;
    height: 225px;
    background: url(/assets/WIN12_Muji_HP_03.jpg) center center no-repeat;
    background-size: cover;
    transition: height .5s ease-in-out 0s
}
#hero.invisible {
    height: 0
}
#hero .overlay {
    position: absolute;
    background: #181931;
    opacity: .45;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}
#hero .action .icon-container.disabled,
#hero .content .icon-container.disabled,
#hero .notification .icon-container.disabled {
    opacity: .6
}
#hero .content {
    display: block;
    color: rgba(255, 255, 255, .8);
    position: absolute;
    bottom: 50px;
    font-size: 14pt
}
#hero .content .icon-container {
    font-size: 13pt;
    padding: 0
}
#hero .content .icon-container span {
    margin-left: 0;
    margin-right: 15px;
    float: left;
    text-transform: uppercase;
    font-weight: 800;
    top: -1px
}
#hero .content .icon-container:active,
#hero .content .icon-container:hover {
    background: 0 0;
    cursor: pointer
}
#hero .content .icon-container:active {
    background: 0 0;
    transform: scale(.98, .98)
}
#hero .content .icon-container.disabled:active,
#hero .content .icon-container.disabled:hover {
    background: 0 0
}
#hero .content .icon-container.disabled:active {
    transform: none
}
#hero .action,
#hero .notification {
    position: absolute;
    width: 225px;
    height: 225px;
    top: 0;
    right: 225px;
    color: rgba(255, 255, 255, .8);
    padding: 15px;
    background-color: rgba(80, 83, 170, .8);
    cursor: pointer
}
#hero .action h1,
#hero .notification h1 {
    text-align: center;
    clear: both;
    font-size: 64pt;
    font-weight: 100
}
#hero .action .icon-container,
#hero .notification .icon-container {
    font-size: 13pt;
    padding: 0
}
#hero .action .icon-container .icon,
#hero .notification .icon-container .icon {
    font-size: 30pt;
    clear: both
}
#hero .action .icon-container p,
#hero .notification .icon-container p {
    display: inline-block;
    text-transform: uppercase;
    font-weight: 800;
    top: -1px;
    text-align: left
}
#hero .action .icon-container:active,
#hero .action .icon-container:hover,
#hero .notification .icon-container:active,
#hero .notification .icon-container:hover {
    background: 0 0;
    cursor: pointer
}
#hero .action .icon-container:active,
#hero .notification .icon-container:active {
    background: 0 0;
    transform: scale(.98, .98)
}
#hero .action .icon-container.disabled:active,
#hero .action .icon-container.disabled:hover,
#hero .notification .icon-container.disabled:active,
#hero .notification .icon-container.disabled:hover {
    background: 0 0
}
#hero .action .icon-container.disabled:active,
#hero .notification .icon-container.disabled:active {
    transform: none
}
#hero .action {
    right: 0;
    background-color: rgba(55, 155, 180, .8)
}
#filters {
    background: #FFF;
    padding: 0;
    height: 48px
}
#filters ul {
    margin: 0;
    padding: 0 15px;
    width: 100%;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
    -ms-overflow-style: -ms-autohiding-scrollbar
}
#filters ul.tileFilters {
    width: calc(100% - 53px);
    display: inline-block
}
#filters ul li {
    display: inline-block;
    color: #222;
    margin-right: 30px;
    text-transform: uppercase;
    font-weight: 500;
    opacity: .4;
    transition: opacity .2s ease;
    font-size: 16px;
    line-height: 48px
}
#filters ul li.active {
    opacity: 1;
    color: #6bbe51
}
#filters ul li:active,
#filters ul li:hover {
    background: 0 0;
    cursor: pointer
}
#filters ul li:active {
    background: 0 0;
    transform: scale(.98, .98)
}
#filters ul li.disabled {
    opacity: .6
}
#filters ul li.disabled:active,
#filters ul li.disabled:hover {
    background: 0 0
}
#filters ul li.disabled:active {
    transform: none
}
#filters .dashboardCollapse {
    display: inline-block;
    width: 48px;
    height: 48px
}
#filters .dashboardCollapse .icon-container {
    color: #30BA6F;
    text-align: center;
    line-height: 48px
}
.notificationArea .actionMenu .icon-container .icon,
.notificationArea.notifications .actionMenu .actionItem .product .name {
    color: #25b768
}
.notificationArea .notification-contents .archive-details .archive-controls .time-details,
.notificationArea .notification-menu .search-header .sort-list {
    text-align: right
}
@media (min-width: 320px) and (max-width: 640px) {
    #hero {
        height: 180px;
        background: url(/assets/WIN12_Muji_HP_03.jpg) center center no-repeat;
        background-size: cover
    }
    #hero.invisible {
        height: 0
    }
    #hero .content {
        position: relative;
        bottom: auto;
        margin-top: 10px
    }
}
#themeRoller {
    width: 100%;
    height: 100%
}
#themeRoller img {
    width: 100%
}
.notificationArea {
    width: 100%;
    display: block;
    position: absolute;
    z-index: 301
}
.notificationArea .collapse {
    top: 15px
}
.notificationArea .overlay {
    position: absolute;
    height: 100vh;
    width: 100%;
    z-index: 30;
    background-color: rgba(0, 0, 0, .8);
    display: block
}
.notificationArea.notifications .actionMenu .actionItem .time .name {
    text-transform: none;
    font-style: italic
}
.notificationArea .actionMenu {
    background-color: #1e2d3b;
    position: absolute;
    top: 0;
    left: 0;
    padding: 15px;
    z-index: 31;
    max-height: 100vh;
    overflow: auto;
    overflow-x: hidden;
    overflow-y: auto;
    width: 100%
}
.notificationArea .actionMenu h4 {
    color: #25b768;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 11pt;
    margin-bottom: 40px
}
.notificationArea .actionMenu .icon-container {
    position: absolute;
    right: 0
}
.notificationArea .actionMenu .actionContainer {
    padding-bottom: 30px;
    overflow: auto;
    overflow-x: hidden;
    overflow-y: auto
}
.notificationArea .actionMenu .actionItem {
    max-width: 300px;
    color: #e5e6e7;
    font-size: 10pt;
    display: inline-block;
    margin-right: 50px;
    margin-bottom: 50px;
    vertical-align: top
}
.notificationArea .actionMenu .actionItem a {
    text-decoration: none
}
.notificationArea .actionMenu .actionItem p {
    color: #fff;
    text-decoration: none
}
.notificationArea .actionMenu .actionItem .product {
    display: inline-block
}
.notificationArea .actionMenu .actionItem .product .image {
    width: 20px;
    height: 20px;
    border-radius: 100%;
    background: center center no-repeat;
    background-size: contain;
    display: inline-block;
    margin-right: 10px;
    position: relative;
    top: 5px
}
.notificationArea .actionMenu .actionItem .product .name {
    display: inline-block;
    font-size: 10pt;
    text-transform: uppercase;
    color: #fff;
    text-decoration: none
}
.notificationArea .actionMenu .actionItem .time {
    position: relative;
    display: inline-block
}
.notificationArea .actionMenu .actionItem .time .icon-container {
    position: relative;
    font-size: 12pt
}
.notificationArea .actionMenu .actionItem .time .icon-container .icon {
    color: #25b768
}
.notificationArea .actionMenu .actionItem .time .name {
    display: inline-block;
    font-size: 10pt;
    text-transform: uppercase;
    color: #fff;
    text-decoration: none
}
.notificationArea .actionMenu .actionItem .actions a,
.notificationArea .actionMenu .actionItem .actions button {
    outline: 0;
    text-decoration: none;
    color: #e5e6e7;
    border: none;
    margin-top: 15px;
    margin-right: 10px;
    text-transform: uppercase;
    font-weight: 500;
    font-size: 8pt;
    padding: 4px 7px;
    background-color: #1e2d3b
}
.notificationArea .actionMenu .actionItem .actions a.action-primary,
.notificationArea .actionMenu .actionItem .actions button.action-primary {
    background-color: #25b768;
    cursor: pointer
}
.notificationArea .actionMenu .actionItem .actions a:hover,
.notificationArea .actionMenu .actionItem .actions button:hover {
    background-color: #189752
}
.notificationArea .actionMenu .actionItem .actions a:hover.action-secondary,
.notificationArea .actionMenu .actionItem .actions button:hover.action-secondary {
    background-color: #172430
}
.notificationArea .actionMenu .actionItem .actions a:active,
.notificationArea .actionMenu .actionItem .actions button:active {
    background-color: #111c26;
    transform: scale(.98, .98)
}
@media only screen and (min-width: 320px) and (max-width: 768px) {
    .notificationArea .actionMenu .actionItem {
        max-width: none;
        width: auto;
        margin-right: 0
    }
}
.notificationArea .notification-menu {
    background-color: #1e2d3b;
    top: 0;
    left: 0;
    z-index: 31;
    height: 100vh;
    overflow: auto;
    overflow-x: hidden;
    overflow-y: auto
}
.notificationArea .notification-menu .row {
    height: auto
}
.notificationArea .notification-menu .notification-header {
    height: 50px;
    background: #3D4C5B;
    font-size: 12px;
    padding: 15px
}
.notificationArea .notification-menu .notification-header .title {
    color: #fff;
    padding-right: 15px;
    margin-right: 15px;
    border-right: 1px solid #ccc
}
.notificationArea .notification-menu .notification-header .view-archive {
    color: #30BA6F
}
.notificationArea .notification-menu .notification-header .close-icn {
    float: right;
    font-size: 18px;
    color: #fff
}
.notificationArea .notification-menu .search-header {
    height: 48px;
    padding: 15px;
    color: #a2a2a2;
    margin-bottom: 10px;
    font-size: 14px
}
.notificationArea .notification-menu .search-header .icon {
    font-size: 15px;
    color: #fff;
    padding-left: 5px
}
.notificationArea .notification-menu .search-header .search-list {
    float: left
}
.notificationArea .notification-menu .search-header .sort-list .sort-by {
    margin-left: 20px;
    color: #fff;
    margin-right: 5px
}
.notificationArea .notification-menu .content-rows {
    padding: 20px;
    font-size: 13px;
    color: #bfbfbf
}
.notificationArea .notification-menu .content-rows.active {
    background: #3D4C5B
}
.notificationArea .notification-menu .content-rows .left-icon .icon {
    color: #E62737;
    font-size: 20px
}
.notificationArea .notification-menu .content-rows .time-details {
    margin-bottom: 20px
}
.notificationArea .notification-menu .content-rows .time-details .opportunity {
    border-right: 1px solid #bfbfbf;
    padding-right: 15px;
    margin-right: 15px
}
.notificationArea .notification-menu .content-rows .time-details .time-info {
    font-size: 11px;
    font-style: italic
}
.notificationArea .notification-menu .content-rows .time-details .time-info .icon {
    padding-right: 10px
}
.notificationArea .notification-menu .content-rows .ctrl-btns {
    float: left;
    color: #fff;
    width: 100%
}
.notificationArea .notification-menu .content-rows .ctrl-btns span {
    padding-right: 25px;
    font-size: 20px
}
.notificationArea .notification-menu .content-rows .ctrl-btns span.mail-icon {
    padding-right: 0;
    float: right
}
.notificationArea .notification-menu .content-rows .action .icon {
    font-size: 20px
}
.notificationArea .notification-menu .content-rows .action .user-alias {
    border-radius: 0;
    box-shadow: none;
    -webkit-box-shadow: none;
    padding: 5px;
    height: 25px;
    width: 100%;
    border-color: transparent;
    background: #3D4C5B
}
.notificationArea .notification-contents {
    background: #fff;
    height: 100vh;
    display: none
}
.notificationArea .notification-contents.open {
    display: block
}
.notificationArea .notification-contents .archive-details .navigation-header {
    height: 50px;
    background: #E9EEF1;
    padding: 10px 30px 0 20px;
    margin-bottom: 15px;
    font-size: 14px
}
.notificationArea .notification-contents .archive-details .navigation-header .notification-title {
    font-size: 20px;
    float: left
}
.notificationArea .notification-contents .archive-details .navigation-header .notification-title .icon {
    color: #E62737;
    vertical-align: -2px;
    margin-right: 15px
}
.notificationArea .notification-contents .archive-details .navigation-header .right-navigation {
    text-align: right;
    color: #a2a2a2;
    margin-top: 4px
}
.notificationArea .notification-contents .archive-details .navigation-header .right-navigation .icon {
    margin-left: 10px;
    vertical-align: -1px
}
.notificationArea .notification-contents .archive-details .navigation-header .right-navigation .previous {
    margin-right: 40px
}
.notificationArea .notification-contents .archive-details .navigation-header .right-navigation .next {
    padding-right: 30px;
    border-right: 1px solid #d9d9d9
}
.notificationArea .notification-contents .archive-details .navigation-header .right-navigation .archives {
    margin-left: 20px
}
.notificationArea .notification-contents .archive-details .archive-controls {
    padding-right: 30px;
    margin-bottom: 25px
}
.notificationArea .notification-contents .archive-details .archive-controls .left-btns {
    float: left
}
.notificationArea .notification-contents .archive-details .archive-controls .left-btns span {
    padding-right: 25px;
    font-size: 20px
}
.notificationArea .notification-contents .archive-details .archive-controls .left-btns span.separator {
    border-right: 1px solid #d9d9d9;
    margin-right: 35px
}
.notificationArea .notification-contents .archive-details .archive-controls .time-details .opportunity {
    font-weight: 500;
    color: #30BA6F;
    font-size: 15px;
    border-right: 1px solid #d9d9d9;
    padding-right: 15px;
    margin-right: 15px
}
.notificationArea .notification-contents .archive-details .archive-controls .time-details .time-info {
    font-size: 11px;
    font-style: italic
}
.notificationArea .notification-contents .archive-details .archive-controls .time-details .time-info .icon {
    padding-right: 10px
}
.notificationArea .notification-contents .archive-details .archive-contents {
    font-size: 14px;
    color: #888;
    padding-right: 30px
}
.miniEdgeContainer {
    padding: 0 15px
}
.miniEdgeContainer .edgeActionBar {
    height: 48px;
    line-height: 48px;
    width: 100%;
    border-bottom: 1px solid #e0e0e0
}
.miniEdgeContainer .edgeActionBar .icon-container {
    width: 48px;
    line-height: 48px;
    padding: 0;
    margin: 0;
    text-align: center;
    display: inline-block
}
.miniEdgeContainer .edgeActionBar .icon-container.disbaled {
    opacity: .25;
    pointer-events: none
}
.miniEdgeContainer .edgeActionBar .addressInput {
    width: calc(100% - 196px);
    display: inline-block;
    line-height: 36px;
    border-bottom: none;
    border-top: none;
    padding: 6px 10px
}
.miniEdgeContainer .edgeActionBar .addressInput .edgeAddressInput {
    height: 36px;
    line-height: 36px;
    padding: 0 10px;
    margin: 0;
    width: 100%;
    color: gray;
    font-family: 'Segoe UI';
    font-weight: 400;
    font-size: small;
    border-bottom: 1px solid #fff;
    border-top: 1px solid #fff;
    border-left: 1px solid #e0e0e0;
    border-right: 1px solid #e0e0e0
}
.miniEdgeContainer .edgeActionBar .addressInput .edgeAddressInput:active,
.miniEdgeContainer .edgeActionBar .addressInput .edgeAddressInput:focus,
.miniEdgeContainer .edgeActionBar .addressInput .edgeAddressInput:hover {
    color: #333;
    border-bottom: 1px solid #e0e0e0;
    border-top: 1px solid #e0e0e0;
    background: #f8f8f8
}
.miniEdgeContainer .miniEdge {
    width: 100%;
    height: calc(100vh - 48px)
}
.white-bg {
    background: #fff
}
.ht-80 {
    height: 80%
}
.mar-top-30 {
    margin-top: 30px
}
.mar-bot-30 {
    margin-bottom: 30px
}
.pad-lr-0,
.pad-lt-0 {
    padding-left: 0
}
.pad-lr-0,
.pad-rt-0 {
    padding-right: 0
}
.pad-top-20 {
    padding-top: 20px
}
.pad-top-30 {
    padding-top: 30px
}
.pad-top-40 {
    padding-top: 40px
}
.pad-lr-15 {
    padding-left: 15px
}
.pad-rt-20 {
    padding-right: 20px
}
.border-bot {
    border-bottom: 1px solid #C0C5C8
}
.border-lt {
    border-left: 1px solid #C0C5C8
}
.border-top {
    border-top: 1px solid #C0C5C8
}
.border-rt {
    border-right: 1px solid #C0C5C8
}
.width-40 {
    max-width: 40%
}
.width-50 {
    max-width: 50%
}
.width-60 {
    max-width: 60%
}
.invalid {
    color: #E62737
}
button:focus,
input[type=checkbox]:focus,
input[type=checkbox]:hover {
    outline: #222 dotted 1px
}
select::-ms-expand {
    background: 0 0;
    color: #30BA6F
}
.addon-container span.addon {
    display: inline-block;
    position: absolute;
    top: 4px;
    right: -40px
}
.addon-container span.addon button {
    height: 38px;
    color: #fff;
    background: #30BA6F;
    border: 1px solid #30BA6F;
    line-height: 35px;
    text-align: center;
    font-size: 1.5em
}
.addon-container span.addon button:active,
.addon-container span.addon button:focus,
.addon-container span.addon button:hover {
    background: #4cd189;
    border-color: #4cd189
}
.win-splitview-contentwrapper {
    width: 100%
}
.iconDisplayImage {
    width: 36px;
    height: 36px;
    border-radius: 50%;
    vertical-align: top;
    top: 7px
}
.profile,
.profile .details,
.profile .icon-container {
    display: inline-block;
    float: left;
    text-align: right;
    height: 48px
}
.profile {
    border-right: 1px solid rgba(211, 241, 225, .25)
}
.profile .details {
    position: relative;
    padding: 0 15px
}
.profile p,
.profile small {
    color: #d3f1e1;
    margin: 0;
    padding: 0
}
.profile p {
    font-size: 11pt;
    font-weight: 500;
    position: relative
}
.profile small {
    text-transform: uppercase;
    font-size: 9pt;
    opacity: .7
}
.profile .details.active,
.profile .details:active,
.profile .details:hover,
.profile .icon-container.active,
.profile .icon-container:active,
.profile .icon-container:hover {
    background: #21a25c;
    cursor: pointer
}
.profile .details.active,
.profile .details:active,
.profile .icon-container.active,
.profile .icon-container:active {
    -moz-transform: scale(.98, .98);
    -ms-transform: scale(.98, .98);
    -o-transform: scale(.98, .98);
    -webkit-transform: scale(.98, .98);
    transform: scale(.98, .98);
    background: #1c8d50
}
.profile .details.disabled,
.profile .icon-container.disabled {
    opacity: .6
}
.profile .details.disabled.active,
.profile .details.disabled:active,
.profile .details.disabled:hover,
.profile .icon-container.disabled.active,
.profile .icon-container.disabled:active,
.profile .icon-container.disabled:hover {
    background: 0 0
}
.profile .details.disabled.active,
.profile .details.disabled:active,
.profile .icon-container.disabled.active,
.profile .icon-container.disabled:active {
    -moz-transform: none;
    -ms-transform: none;
    -o-transform: none;
    -webkit-transform: none;
    transform: none
}
.icon-user:before {
    content: "\E2AF"
}
.icon-restore:before {
    content: "\E777"
}
.icon-check:before {
    content: "\E001" !important;
}
.win-pivot.settings-container {
    height: calc(100vh - 48px)
}
.win-pivot.settings-container .win-pivot-item {
    height: 100%
}
.win-pivot.settings-container .win-pivot-item-content {
    margin-right: -19px
}
.win-pivot.settings-container .button {
    width: 100%;
    outline: 0;
    text-decoration: none;
    color: #e5e6e7;
    border: none;
    text-transform: uppercase;
    font-weight: 500;
    background-color: #25b768;
    cursor: pointer
}
.win-pivot.settings-container .buttonContainer {
    padding: 15px 0;
    line-height: 48px
}
.win-pivot.settings-container .buttonContainer button {
    color: #FFF
}
.win-pivot.settings-container .personalization,
.win-pivot.settings-container .personalization .selectContainer {
    padding: 15px 0
}
.win-pivot.settings-container .personalization .selectContainer label {
    padding: 5px 15px 5px 0;
    font-family: 'Segoe UI';
    font-size: medium;
    font-weight: 400;
    width: 100%
}
.win-pivot.settings-container .personalization .selectContainer select.selectStyle {
    padding: 5px 15px;
    font-family: 'Segoe UI';
    font-size: medium;
    font-weight: 400;
    width: 100%
}
.win-pivot.settings-container .win-pivot-headers {
    margin: 0 15px!important;
    height: auto;
    border-bottom: 1px solid #9fa1a3
}
.win-pivot.settings-container button.win-pivot-header,
.win-pivot.settings-container button.win-pivot-header:active,
.win-pivot.settings-container button.win-pivot-header:hover {
    color: #9fa1a3;
    font-weight: 400;
    text-transform: uppercase;
    border: none;
    background-color: transparent;
    cursor: pointer;
    line-height: 0!important;
    font-size: 10.75pt!important;
    padding: 15px!important;
    margin: 0!important
}
.win-pivot.settings-container button.win-pivot-header.win-pivot-header-selected,
.win-pivot.settings-container button.win-pivot-header:active.win-pivot-header-selected,
.win-pivot.settings-container button.win-pivot-header:hover.win-pivot-header-selected {
    color: #555;
    font-weight: 500;
    border-bottom: 3px solid #25b768!important
}
.win-pivot.settings-container button.win-pivot-header:active,
.win-pivot.settings-container button.win-pivot-header:active:active,
.win-pivot.settings-container button.win-pivot-header:active:hover,
.win-pivot.settings-container button.win-pivot-header:hover,
.win-pivot.settings-container button.win-pivot-header:hover:active,
.win-pivot.settings-container button.win-pivot-header:hover:hover {
    border-bottom: 3px solid #9fa1a3!important
}
.win-pivot.settings-container .about,
.win-pivot.settings-container .accounts {
    margin-top: 20px;
    padding: 0 15px
}
.win-pivot.settings-container .about .settingAboutMargin {
    margin-left: 4.5px;
    margin-top: 0
}
.win-pivot.settings-container h2 {
    font-weight: 300;
    color: #000;
    margin-bottom: 10px;
    text-transform: none
}
.win-pivot.settings-container h2.small {
    font-size: 13pt;
    font-weight: 400;
    color: #222
}
.win-pivot.settings-container a,
.win-pivot.settings-container p {
    color: #222;
    font-size: 10pt;
    margin-bottom: 0
}
.win-pivot.settings-container a,
.win-pivot.settings-container p.version {
    color: #25b768
}
.win-pivot.settings-container .profile {
    margin-top: 30px
}
.win-pivot.settings-container .profile p {
    font-size: 11pt;
    color: #222
}
.win-pivot.settings-container .profile p.email {
    color: #222
}
.win-pivot.settings-container .icon-container {
    padding: 0;
    font-size: 35px
}
.win-pivot.settings-container .icon-container .icon {
    top: 2px;
    width: 60px;
    height: 60px;
    text-align: center;
    margin-right: 15px
}
.win-pivot.settings-container .btn {
    color: #222;
    background-color: #EFEFEF;
    margin-top: 15px
}
.win-pivot.settings-container .avatar {
    background: center center no-repeat;
    background-size: cover;
    width: 60px;
    height: 60px;
    border-radius: 100%;
    margin-right: 15px
}
.win-pivot.settings-container ul {
    margin: 0;
    list-style-type: none;
    padding: 0
}
.win-pivot.settings-container ul li {
    margin-bottom: 20px
}
.win-pivot.settings-container ul li:first-child {
    margin-top: 15px
}
.win-pivot.settings-container ul.software-list {
    list-style-type: decimal;
    margin-left: 15px
}
.win-pivot.settings-container ul.software-list p {
    margin-bottom: 10px
}
.win-pivot.settings-container ul.software-list a {
    font-weight: 500
}
.win-pivot.settings-container .webView {
    width: 100%;
    height: 100%
}
.settingAboutMargin {
    margin-left: 4.5px
}
.feedbackModal .modal-dialog {
    width: 400px;
    padding: 0;
    float: right;
    margin: 0;
    height: 100%
}
.feedbackModal .modal-dialog .modal-body,
.feedbackModal .modal-dialog .modal-content {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0
}
.descriptionModal .modal-dialog {
    width: 400px;
    padding: 0;
    float: right;
    margin: 0;
    height: 100%;
    overflow: hidden
}
.descriptionModal .modal-dialog .modal-body,
.descriptionModal .modal-dialog .modal-content {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    border-radius: 0;
    overflow: hidden
}
.descriptionModal .modal-dialog .modal-body .descriptionBox,
.descriptionModal .modal-dialog .modal-body .nameBox {
    border-radius: 0;
    padding: 0;
    font-family: 'Segoe UI';
    font-size: medium;
    font-weight: 400;
    box-shadow: none;
    height: auto;
    background: 0 0;
    border: none;
    margin-bottom: 30px
}
.descriptionModal .modal-dialog .modal-body .feedbackFormLabel {
    font-weight: 500;
    margin-bottom: 15px
}
.feedbackPane {
    height: 100%;
    overflow-y: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
    width: 400px;
    background: #f0f0f0;
    z-index: 9999;
    -moz-transition: right .5s ease-in 0s;
    -o-transition: right .5s ease-in 0s;
    -webkit-transition: right .5s ease-in 0s;
    transition: right .5s ease-in 0s
}
.feedbackPane.open {
    right: 0;
    -moz-transition: right .5s ease-out 0s;
    -o-transition: right .5s ease-out 0s;
    -webkit-transition: right .5s ease-out 0s;
    transition: right .5s ease-out 0s
}
.feedbackPane .feedbackContainer {
    width: 100%;
    padding: 0 15px 15px
}
.feedbackPane .feedbackContainer label.title {
    font-family: 'Segoe UI';
    font-size: medium;
    font-weight: 500;
    text-transform: uppercase;
    color: #505050;
    width: calc(100% - 50px);
    display: inline-block;
    line-height: 48px;
    padding-left: 15px
}
.feedbackPane .feedbackContainer .feedbackClose {
    color: #303030;
    width: 48px;
    display: inline-block;
    line-height: 48px
}
.feedbackPane .feedbackContainer form .form-group label.feedbackFormLabel {
    width: 100%;
    font-family: 'Segoe UI';
    font-size: small;
    font-weight: 400;
    text-transform: uppercase;
    color: #505050
}
.feedbackPane .feedbackContainer form .form-group label.feedbackFormLabel.w500 {
    font-weight: 500
}
.feedbackPane .feedbackContainer form {
    margin-top: 15px
}
.feedbackPane .feedbackContainer form .reqdField {
    font-family: 'Segoe UI';
    font-size: small;
    color: #707070;
    font-weight: 400;
    margin-top: 10px;
    margin-bottom: 0
}
.feedbackPane .feedbackContainer form .reqdField .red {
    color: #e74c3c
}
.feedbackPane .feedbackContainer form .form-group input.feedbackTileName,
.feedbackPane .feedbackContainer form .form-group textarea.feedbackComments {
    border-radius: 0;
    padding: 10px 15px;
    font-family: 'Segoe UI';
    font-size: medium;
    font-weight: 400;
    box-shadow: none;
    height: auto;
    border-color: silver
}
.feedbackPane .feedbackContainer form p.bold500 {
    font-weight: 500
}
.feedbackPane .feedbackContainer form .form-group input.feedbackTileName[disabled] {
    background: #fff;
    cursor: default
}
.feedbackPane .feedbackContainer form .win-rating .win-star {
    width: 36px;
    height: 36px;
    padding: 5px
}
.feedbackPane .feedbackContainer form .win-rating .win-star.win-user.win-full,
.feedbackPane .feedbackContainer form .win-rating .win-star.win-user.win-full.win-disabled {
    color: #25b678
}
.feedbackPane .feedbackContainer form .form-group textarea.feedbackComments {
    min-height: 200px
}
.feedbackPane .feedbackContainer form button.btn {
    font-family: 'Segoe UI';
    font-size: small;
    text-transform: uppercase;
    border: 3px solid #303030;
    background: 0 0;
    border-radius: 0;
    padding: 10px 15px;
    margin: 0 15px 0 0;
    min-width: 100px
}
.feedbackPane .feedbackContainer form button.btn.btn-primary {
    background: #303030;
    color: #fff;
    padding: 10px 15px
}
.helpContainer {
    padding: 0;
    margin-top: 15px
}
.helpContainer .title,
.helpContainer a.link {
    padding: 10px 15px;
    color: #25b678;
    font-size: 16px;
    font-family: 'Segoe UI';
    width: 100%
}
.helpContainer a.link {
    text-decoration: underline;
    font-weight: 400
}
.helpContainer .title {
    font-weight: 500
}
.helpContainer label {
    font-size: 16px;
    width: 100%;
    margin-left: 15px;
    font-weight: 500
}
.helpContainer ol.provideFeedbacklist li {
    font-family: 'Segoe UI';
    font-size: 14px;
    margin-left: 15px;
    width: 100%;
    font-weight: 400
}
#sidebar .sidebar-apps #applicationsList .win-listview .win-container:hover,
#sidebar .sidebar-apps #applicationsList .win-listview .win-focusedoutline {
    outline: 0
}
#sidebar .sidebar-apps #applicationsList .rowTemplate.disabled,
#sidebar .sidebar-apps #applicationsList .win-listview .win-groupheader.disabled,
#sidebar .sidebar-apps #applicationsList .zoomed-out .zoom-header.disabled,
#sidebar .sidebar-menu ul li .icon-container.disabled,
#sidebar .sidebar-menu ul li.separator.disabled,
.sidebar-sub-menu .rowTemplate.disabled {
    opacity: .6
}
.win-splitview .win-splitview-pane,
.win-splitview .win-splitview-paneplaceholder,
.win-splitview .win-splitview-panewrapper {
    overflow: visible
}
.win-splitview .win-splitview-pane {
    background-color: #111c26;
    width: 320px
}
.win-splitview-pane-closed .icon-container>span {
    display: none!important
}
.win-splitview-pane-closed .icon-container>span.badge {
    display: inline-block
}
#sidebar,
#sidebar .sidebar-apps {
    height: 100%;
    display: -ms-grid;
    -ms-grid-columns: 1fr
}
span.badge {
    background-color: #25b768;
    color: #2c4053;
    margin-left: 10px;
    border-radius: 100%;
    padding: 4px 7px
}
#sidebar {
    background-color: #2b2b2b;
    color: #e5e6e7;
    -ms-grid-rows: 1fr
}
#sidebar .sidebar-apps {
    -ms-grid-rows: auto auto auto 1fr;
    -ms-grid-row: 1;
    -ms-grid-column: 1
}
#sidebar .sidebar-apps.overlay-mode {
    background-color: #111c26;
    color: #e5e6e7;
    position: relative;
    width: 375px;
    left: 345px;
    padding: 15px
}
#sidebar .sidebar-apps .sidebar-back {
    -ms-grid-row: 1;
    -ms-grid-column: 1;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #9fa1a3
}
#sidebar .sidebar-apps .sidebar-back .icon-container>span {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 10pt
}
#sidebar .sidebar-apps .sidebar-header {
    -ms-grid-row: 2;
    -ms-grid-column: 1;
    background-color: #454d56;
    color: #9fa1a3
}
#sidebar .sidebar-apps .sidebar-header input[type=search] {
    font-size: 12pt;
    height: 30px;
    line-height: 30px;
    padding: 20px 15px;
    outline: 0;
    border: none;
    background-color: transparent;
    color: #9fa1a3;
    width: calc(100% - 25px);
    font-style: italic
}
#sidebar .sidebar-apps .sidebar-header input[type=search].active,
#sidebar .sidebar-apps .sidebar-header input[type=search]:focus {
    color: #e5e6e7;
    font-style: normal
}
#sidebar .sidebar-apps .sidebar-header .icon-search {
    color: #e5e6e7;
    font-size: 12pt;
    right: 10px
}
#sidebar .sidebar-apps .sidebar-tabs {
    -ms-grid-row: 3;
    -ms-grid-column: 1
}
#sidebar .sidebar-apps .sidebar-tabs .nav-tabs.nav-justified {
    margin-top: 20px;
    font-size: 11pt
}
#sidebar .sidebar-apps .sidebar-tabs .nav-tabs.nav-justified li {
    display: table-cell!important;
    width: 1%!important
}
#sidebar .sidebar-apps .sidebar-tabs .nav-tabs.nav-justified .icon-container .icon {
    font-size: .875em
}
#sidebar .sidebar-apps .sidebar-tabs .nav-tabs.nav-justified i.size-fix {
    font-size: 7pt;
    top: -1px;
    left: -3px
}
#sidebar .sidebar-apps .sidebar-tabs .nav-tabs.nav-justified .active a {
    border-bottom: solid 3px #1f8853;
    color: #1f8853
}
#sidebar .sidebar-apps .sidebar-tabs .nav-tabs.nav-justified a {
    border-radius: 0;
    border: none;
    border-bottom: solid 1px #29333d;
    color: #9fa1a3;
    background: 0 0;
    text-transform: uppercase;
    font-weight: 500;
    padding: 15px 0 7px
}
#sidebar .sidebar-apps #applicationsList {
    -ms-grid-row: 4;
    -ms-grid-column: 1
}
#sidebar .sidebar-apps #applicationsList .win-semanticzoom {
    height: calc(100vh - 180px)
}
#sidebar .sidebar-apps #applicationsList .win-semanticzoom .win-listview.win-groups>.win-vertical .win-surface.win-gridlayout,
#sidebar .sidebar-apps #applicationsList .win-semanticzoom .win-listview.win-groups>.win-vertical .win-surface.win-listlayout {
    margin-top: 0
}
#sidebar .sidebar-apps #applicationsList .win-listview .win-itembox {
    background-color: transparent
}
#sidebar .sidebar-apps #applicationsList .win-listview .win-container {
    margin: 0;
    background: #111c26;
    color: #e5e6e7;
    border: none
}
#sidebar .sidebar-apps #applicationsList .win-listview .win-groupheader {
    padding-left: 0;
    font-weight: 500;
    text-transform: uppercase;
    font-size: 20pt;
    margin-bottom: 15px
}
#sidebar .sidebar-apps #applicationsList .win-listview .win-groupheader:active,
#sidebar .sidebar-apps #applicationsList .win-listview .win-groupheader:hover {
    background: 0 0;
    cursor: pointer
}
#sidebar .sidebar-apps #applicationsList .win-listview .win-groupheader:active {
    background: 0 0;
    transform: scale(.98, .98)
}
#sidebar .sidebar-apps #applicationsList .win-listview .win-groupheader.disabled:active,
#sidebar .sidebar-apps #applicationsList .win-listview .win-groupheader.disabled:hover {
    background: 0 0
}
#sidebar .sidebar-apps #applicationsList .win-listview .win-groupheader.disabled:active {
    transform: none
}
#sidebar .sidebar-apps #applicationsList .win-listview .win-listlayout .win-groupleader {
    margin-top: 20px
}
#sidebar .sidebar-apps #applicationsList .zoomed-out .win-container {
    display: inline-block!important
}
#sidebar .sidebar-apps #applicationsList .zoomed-out .zoom-header {
    font-weight: 400;
    text-transform: uppercase;
    font-size: 16pt;
    display: block;
    padding: 20px
}
#sidebar .sidebar-apps #applicationsList .zoomed-out .zoom-header:active,
#sidebar .sidebar-apps #applicationsList .zoomed-out .zoom-header:hover {
    background: 0 0;
    cursor: pointer
}
#sidebar .sidebar-apps #applicationsList .zoomed-out .zoom-header:active {
    background: 0 0;
    transform: scale(.98, .98)
}
#sidebar .sidebar-apps #applicationsList .zoomed-out .zoom-header.disabled:active,
#sidebar .sidebar-apps #applicationsList .zoomed-out .zoom-header.disabled:hover {
    background: 0 0
}
#sidebar .sidebar-apps #applicationsList .zoomed-out .zoom-header.disabled:active {
    transform: none
}
#sidebar .sidebar-apps #applicationsList .rowTemplate {
    margin-bottom: 15px;
    position: relative
}
#sidebar .sidebar-apps #applicationsList .rowTemplate:active,
#sidebar .sidebar-apps #applicationsList .rowTemplate:hover {
    background: 0 0;
    cursor: pointer
}
#sidebar .sidebar-apps #applicationsList .rowTemplate:active {
    background: 0 0;
    transform: scale(.98, .98)
}
#sidebar .sidebar-apps #applicationsList .rowTemplate.disabled:active,
#sidebar .sidebar-apps #applicationsList .rowTemplate.disabled:hover {
    background: 0 0
}
#sidebar .sidebar-apps #applicationsList .rowTemplate.disabled:active {
    transform: none
}
#sidebar .sidebar-apps #applicationsList .rowTemplate .image {
    width: 42px;
    height: 42px;
    border-radius: 100%;
    background: center center no-repeat;
    background-size: contain;
    display: inline-block;
    margin-right: 10px
}
#sidebar .sidebar-apps #applicationsList .rowTemplate .image.widget {
    border-radius: 0
}
#sidebar .sidebar-apps #applicationsList .rowTemplate .meta {
    display: inline-block;
    position: relative;
    top: -3px
}
#sidebar .sidebar-apps #applicationsList .rowTemplate .meta .name {
    font-size: 13pt
}
#sidebar .sidebar-apps #applicationsList .rowTemplate .meta .category {
    color: #9fa1a3;
    font-style: italic;
    font-size: 11pt
}
#sidebar .sidebar-apps #applicationsList .rowTemplate .action {
    display: inline-block;
    position: absolute;
    right: 0;
    top: 5px
}
#sidebar .sidebar-apps #applicationsList .rowTemplate .action .icon-star-filled {
    color: #1f8853
}
#sidebar .sidebar-menu {
    -ms-grid-row: 1;
    -ms-grid-column: 1
}
#sidebar .sidebar-menu ul {
    margin: 0;
    padding: 0
}
#sidebar .sidebar-menu ul li {
    clear: both;
    height: 48px;
    line-height: 48px;
    font-size: 11pt;
    color: #9fa1a3
}
#sidebar .sidebar-menu ul li.separator {
    height: 0;
    border-bottom: solid 1px #454d56;
    margin: 24px 0
}
#sidebar .sidebar-menu ul li.separator:active,
#sidebar .sidebar-menu ul li.separator:hover {
    background: 0 0;
    cursor: pointer
}
#sidebar .sidebar-menu ul li.separator:active {
    background: 0 0;
    transform: scale(.98, .98)
}
#sidebar .sidebar-menu ul li.separator.disabled:active,
#sidebar .sidebar-menu ul li.separator.disabled:hover {
    background: 0 0
}
#sidebar .sidebar-menu ul li.separator.disabled:active {
    transform: none
}
#sidebar .sidebar-menu ul li.active .icon,
#sidebar .sidebar-menu ul li.active span {
    color:#0078d7;
}
#sidebar .sidebar-menu ul li:active,
#sidebar .sidebar-menu ul li:hover {
    background: rgba(0, 0, 0, .1);
    cursor: pointer
}
#sidebar .sidebar-menu ul li:active {
    background: rgba(0, 0, 0, .2);
    transform: scale(.98, .98)
}
#sidebar .sidebar-menu ul li.disabled {
    opacity: .6;
    cursor: none
}
#sidebar .sidebar-menu ul li.disabled:active,
#sidebar .sidebar-menu ul li.disabled:hover {
    background: 0 0;
    cursor: none
}
#sidebar .sidebar-menu ul li.disabled:active {
    transform: none
}
#sidebar .sidebar-menu ul li:hover {
/*    background-color: #243545;
    color: #e5e6e7*/
}
#sidebar .sidebar-menu ul li:active {
    background-color: #2c4053
}
#sidebar .sidebar-menu ul li .icon-container:active,
#sidebar .sidebar-menu ul li .icon-container:hover {
    background: 0 0;
    cursor: pointer
}
#sidebar .sidebar-menu ul li .icon-container:active {
    background: 0 0;
    transform: scale(.98, .98)
}
#sidebar .sidebar-menu ul li .icon-container.disabled:active,
#sidebar .sidebar-menu ul li .icon-container.disabled:hover {
    background: 0 0
}
#sidebar .sidebar-menu ul li .icon-container.disabled:active {
    transform: none
}
#sidebar .sidebar-menu ul li .icon-container span {
    margin-left: 20px
}
.sidebar-sub-menu {
    z-index: 1000;
    display: inline-block;
    background-color: #111c26;
    color: #e5e6e7;
    padding: 30px 15px 15px;
    width: 300px
}
.sidebar-sub-menu .rowTemplate {
    margin-bottom: 15px;
    position: relative
}
.sidebar-sub-menu .rowTemplate .action,
.sidebar-sub-menu .rowTemplate .meta {
    display: inline-block;
    position: absolute;
    top: 5px
}
.sidebar-sub-menu .rowTemplate:active,
.sidebar-sub-menu .rowTemplate:hover {
    background: 0 0;
    cursor: pointer
}
.sidebar-sub-menu .rowTemplate:active {
    background: 0 0;
    transform: scale(.98, .98)
}
.sidebar-sub-menu .rowTemplate.disabled:active,
.sidebar-sub-menu .rowTemplate.disabled:hover {
    background: 0 0
}
.sidebar-sub-menu .rowTemplate.disabled:active {
    transform: none
}
.sidebar-sub-menu .rowTemplate .image {
    width: 42px;
    height: 42px;
    border-radius: 100%;
    background: center center no-repeat;
    background-size: contain;
    display: inline-block;
    margin-right: 10px
}
.sidebar-sub-menu .rowTemplate .image.widget {
    border-radius: 0
}
.sidebar-sub-menu .rowTemplate .meta .name {
    font-size: 13pt
}
.sidebar-sub-menu .rowTemplate .meta .category {
    color: #9fa1a3;
    font-style: italic;
    font-size: 11pt
}
.sidebar-sub-menu .rowTemplate .action {
    right: 0
}
.sidebar-sub-menu .rowTemplate .action .icon-star-filled {
    color: #1f8853
}
.ng-cloak,
.ng-hide:not(.ng-hide-animate),
.x-ng-cloak,
[data-ng-cloak],
[ng-cloak],
[ng\:cloak],
[x-ng-cloak] {
    display: none!important
}
ng\:form {
    display: block
}
.ng-animate-shim {
    visibility: hidden
}
.ng-anchor {
    position: absolute
}
.win-splitview .win-splitview-pane,
.win-splitview .win-splitview-paneplaceholder {
    overflow: auto
}
.win-splitview .win-splitview-pane {
    background-color: #2b2b2b
}
.win-pivot-item .win-pivot-item-content {
    padding-bottom: 70px
}
.win-pivot .win-pivot-surface {
    position: relative!important
}
.win-pivot-item {
    position: static!important
}
.win-pivot .win-pivot-viewport {
    overflow-y: visible!important;
    overflow-x: visible!important
}
