@charset "UTF-8";
.block, .resource .profile-main .skill-inner-right .skill-inner-right-bg .standerdtimezone, .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections {
  display: block;
  overflow: hidden; }

.in-block, .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections ul li {
  display: block;
  overflow: hidden; }

.error i {
  color: #e04d4d;
  margin-right: 3px; }

.error span {
  color: #e04d4d;
  font-size: 13px;
  margin-top: 3px; }

.error input {
  background: #f4d6d6; }

/* sales skill rating variables */
/* sales skill rating variables | end */
body {
  font-family: SegoeUI;
  color: #333333; }

.no-pad {
  padding: 0px; }

.side-pad {
  padding: 0px 15px; }

.no-pad-left {
  padding-left: 0px; }

.no-pad-right {
  padding-right: 0px; }

.no-mar {
  margin: 0px; }

.no-mar-left {
  margin-left: 0px; }

.no-mar-right {
  margin-right: 0px; }

a:hover, a:focus {
  text-decoration: none; }

/*.skill-breadcrumb {
        margin: $mar 0 $mar 0;
        //padding-top:15px;
        li {
            width: auto;
            float: left;
            margin-right: 10px;
            text-transform: $ttup;
            font-weight: normal;
            font-size: medium;
            a {
                color:$primary-color;
                font-size: medium;
                float:left;
            }
            i {
                color: $arrow-color;
                font-size: $fs14;
            }
        }
        .active {
            color:#4a4a4a;
        }
    }*/
.behalf-main {
  position: relative;
  margin: 0px auto;
  width: 500px;
  background: #f7f8b5;
  top: 0px;
  z-index: 9999;
  text-align: center;
  color: red;
  height: 40px;
  line-height: 40px;
  font-size: 14px;
  font-weight: 700; }
  .behalf-main .behalf-close {
    position: absolute;
    right: 10px;
    top: 0px;
    z-index: 99; }
    .behalf-main .behalf-close i {
      color: red;
      font-size: 14px; }
  .behalf-main .behalf-left-arrow {
    width: 10px;
    height: 10px;
    border-style: solid;
    border-width: 40px;
    border-color: #f7f8b5 transparent transparent transparent;
    position: absolute;
    top: 0px;
    left: -38px; }
  .behalf-main .behalf-right-arrow {
    width: 10px;
    height: 10px;
    border-style: solid;
    border-width: 40px;
    border-color: #f7f8b5 transparent transparent transparent;
    position: absolute;
    top: 0px;
    right: -38px;
    z-index: 9; }

/*.win-splitview-content{
     #footer{
         width:100% !important;
     }
  }*/
.bread-topgap {
  padding-top: 15px; }

.oneprofile-pageloader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  background-color: rgba(0, 0, 0, 0.5);
  min-height: 300px; }
  .oneprofile-pageloader .loaderImage {
    position: absolute;
    left: 45%;
    top: 45%;
    margin-left: -32px;
    padding: 20px 30px 0 30px;
    text-align: center; }
    .oneprofile-pageloader .loaderImage p {
      font-size: 13px;
      font-style: italic;
      margin: 10px 0 20px 0; }

.loaderText {
  position: absolute;
  left: 45%;
  top: 60%;
  color: white; }

#sidebar .sidebar-menu ul li .icon-container {
  padding: 0px 13px !important; }

.oneprofile-renderedDefn {
  white-space: normal;
  word-break: break-word; }

@media screen and (-ms-high-contrast: active) {
  .ng-pageslide .fly-out .flyout-block .dropdown-menu .active {
    background-image: url(/images/oneprofile/whbg.png);
    color: #000;
    background-repeat: repeat; }
  .profile-overlay {
    background: none; } }

@media screen and (-ms-high-contrast: black-on-white) {
  .ng-pageslide .fly-out .flyout-block .dropdown-menu .active {
    background-image: url(/images/oneprofile/bhbg.png);
    color: #fff;
    background-repeat: repeat; }
  .profile-overlay {
    background: none; } }

@media screen and (-ms-high-contrast: white-on-black) {
  .ng-pageslide .fly-out .flyout-block .dropdown-menu .active {
    background-image: url(/images/oneprofile/whbg.png);
    color: #000;
    background-repeat: repeat; }
  .profile-overlay {
    background: none; } }

/*fontsize for h2*/
.font20 {
  font-size: 20px !important; }

/*fontsize for h3*/
.font18 {
  font-size: 18px !important; }

/*fontsize for h4*/
.font16 {
  font-size: 16px !important; }

/*fontsize for h5*/
.font14 {
  font-size: 14px !important; }

.mrf {
  color: #fb5f5f;
  font-size: 14px; }

.op-confirm-modal {
  margin-top: calc(50vh - 100px); }
  .op-confirm-modal button.btn {
    min-width: 100px; }

.block, .resource .profile-main .skill-inner-right .skill-inner-right-bg .standerdtimezone, .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections {
  display: block;
  overflow: hidden; }

.in-block, .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections ul li {
  display: block;
  overflow: hidden; }

.error i {
  color: #e04d4d;
  margin-right: 3px; }

.error span {
  color: #e04d4d;
  font-size: 13px;
  margin-top: 3px; }

.error input {
  background: #f4d6d6; }

/* sales skill rating variables */
/* sales skill rating variables | end */
.home-main *:focus {
  outline: 1px dotted #666666; }

.home-main h4 {
  text-transform: uppercase;
  color: #0263B2;
  font-size: 14px;
  font-weight: 700;
  padding: 0px; }

.home-main .white-bg {
  background: #fff; }

.home-main .home-inner-left {
  width: calc(100% - 425px) !important;
  padding-left: 0px;
  padding-right: 0px;
  border-right: 15px solid #e9eef1; }
  .home-main .home-inner-left .home-inner-left-bg {
    background: #fff url(/images/oneprofile/dashimg.png) no-repeat center center;
    background-size: 100% 100%;
    min-height: 746px;
    text-align: center;
    padding: 250px 0px; }
    .home-main .home-inner-left .home-inner-left-bg h2 {
      font-size: medium;
      font-weight: 600;
      color: #595959; }
    .home-main .home-inner-left .home-inner-left-bg h3 {
      font-size: medium;
      color: #595959; }
      .home-main .home-inner-left .home-inner-left-bg h3 .inner-title {
        font-size: xx-large; }

.home-main .home-inner-right {
  padding-right: 0px;
  width: 425px !important;
  padding-left: 0px; }
  .home-main .home-inner-right .home-inner-right-bg {
    min-height: 673px; }
    .home-main .home-inner-right .home-inner-right-bg .skills .knob-bg {
      background: #fafafa url(/images/oneprofile/dashicon.png) no-repeat center center;
      -webkit-border-radius: 50px;
      -moz-border-radius: 50px;
      -ms-border-radius: 50px;
      border-radius: 50px; }
      .home-main .home-inner-right .home-inner-right-bg .skills .knob-bg svg path {
        cursor: pointer !important; }
    .home-main .home-inner-right .home-inner-right-bg .home-inner-right-top-bg {
      min-height: 450px;
      background: #fff;
      border-bottom: #e9eef1 solid 10px;
      padding-top: 10px; }
      .home-main .home-inner-right .home-inner-right-bg .home-inner-right-top-bg h2 {
        font-weight: 500;
        color: #595959;
        font-size: medium;
        text-transform: uppercase;
        margin: 40px 0 0 10px; }
      .home-main .home-inner-right .home-inner-right-bg .home-inner-right-top-bg p {
        font-size: medium; }
      .home-main .home-inner-right .home-inner-right-bg .home-inner-right-top-bg .text-right a {
        color: #0263B2;
        font-weight: 400;
        margin-bottom: 0px;
        font-size: xx-large; }
      .home-main .home-inner-right .home-inner-right-bg .home-inner-right-top-bg .text-right p {
        font-size: medium; }
      .home-main .home-inner-right .home-inner-right-bg .home-inner-right-top-bg .row {
        margin: 10px 10px;
        padding-bottom: 20px;
        border-bottom: 1px solid #eeeeee; }
      .home-main .home-inner-right .home-inner-right-bg .home-inner-right-top-bg .no-row-border {
        border-bottom: 0px solid; }
      .home-main .home-inner-right .home-inner-right-bg .home-inner-right-top-bg .skills h4 {
        margin: 34px 0px 0px 10px;
        font-weight: 500;
        font-size: medium; }
      .home-main .home-inner-right .home-inner-right-bg .home-inner-right-top-bg .functional a {
        color: #0263B2;
        font-weight: 400;
        margin-top: 0px;
        display: inline-block;
        font-size: 28px;
        float: left; }
      .home-main .home-inner-right .home-inner-right-bg .home-inner-right-top-bg .functional span {
        font-weight: 400;
        color: #595959;
        font-size: large;
        margin: 9px 0 0 8px;
        float: left; }
      .home-main .home-inner-right .home-inner-right-bg .home-inner-right-top-bg .functional .link {
        color: #0263B2;
        margin-left: 10px;
        display: inline-block;
        width: 100%;
        font-size: 14px; }
        .home-main .home-inner-right .home-inner-right-bg .home-inner-right-top-bg .functional .link a {
          width: 100% !important;
          font-size: medium; }
          .home-main .home-inner-right .home-inner-right-bg .home-inner-right-top-bg .functional .link a .trimmed {
            width: 80%;
            overflow: hidden;
            text-align: left; }
          .home-main .home-inner-right .home-inner-right-bg .home-inner-right-top-bg .functional .link a .icon-forward {
            font-size: 12px;
            margin: 0 0 0 5px; }
      .home-main .home-inner-right .home-inner-right-bg .home-inner-right-top-bg .functional .bd-right {
        border-right: 1px solid #eeeeee; }
      .home-main .home-inner-right .home-inner-right-bg .home-inner-right-top-bg .functional .bd-left {
        border-left: 1px solid #eeeeee; }
        .home-main .home-inner-right .home-inner-right-bg .home-inner-right-top-bg .functional .bd-left i {
          font-size: 30px;
          color: #a1a1a1; }
    .home-main .home-inner-right .home-inner-right-bg .home-inner-right-bottom-bg {
      background: #fff; }
      .home-main .home-inner-right .home-inner-right-bg .home-inner-right-bottom-bg .data {
        font-weight: 500;
        font-size: medium;
        margin: 0px 0px 15px 0;
        color: #595959;
        text-transform: capitalize; }
      .home-main .home-inner-right .home-inner-right-bg .home-inner-right-bottom-bg .access-profile h2 {
        font-weight: 500;
        margin: 0px 0px 5px 0px;
        font-size: large;
        color: #595959;
        text-transform: capitalize; }
      .home-main .home-inner-right .home-inner-right-bg .home-inner-right-bottom-bg p {
        font-weight: 400;
        color: #595959;
        font-size: medium;
        margin: 0px 0px 3px 0px;
        text-transform: capitalize; }
      .home-main .home-inner-right .home-inner-right-bg .home-inner-right-bottom-bg .row {
        margin: 10px 10px; }
      .home-main .home-inner-right .home-inner-right-bg .home-inner-right-bottom-bg a {
        font-weight: 700;
        color: #0263B2;
        font-size: medium;
        text-decoration: none; }
        .home-main .home-inner-right .home-inner-right-bg .home-inner-right-bottom-bg a i {
          margin: 5px 0 0 5px;
          font-size: 12px; }
    .home-main .home-inner-right .home-inner-right-bg h5 {
      text-transform: uppercase;
      font-weight: 600; }

@media screen and (max-width: 768px) {
  .home-main .home-inner-left {
    width: 100%;
    border-right: 0px;
    margin-bottom: 10px; }
  .home-main .home-inner-right {
    width: 100%;
    padding-left: 0px;
    margin-top: 10px; } }

@media screen and (-ms-high-contrast: active) {
  .home-main .knob-bg {
    background-image: url(/images/oneprofile/dash-icon-white.png); }
  .home-main .home-inner-left-bg {
    background: #fff url(/images/oneprofile/dashimg.png) no-repeat center center; }
  .home-main .knob-bg path#valueArc {
    fill: #fff !important; }
  .home-main .knob-bg path#trackArc {
    fill: #fff !important;
    fill-opacity: .5; } }

@media screen and (-ms-high-contrast: black-on-white) {
  .home-main .knob-bg {
    background-image: url(/images/oneprofile/dash-icon-black.png); }
  .home-main .home-inner-left-bg {
    background: #fff url(/images/oneprofile/dashimg.png) no-repeat center center; }
  .home-main .knob-bg path#valueArc {
    fill: #000 !important; }
  .home-main .knob-bg path#trackArc {
    fill: #000 !important;
    fill-opacity: .5; } }

@media screen and (-ms-high-contrast: white-on-black) {
  .home-main .knob-bg {
    background-image: url(/images/oneprofile/dash-icon-white.png); }
  .home-main .home-inner-left-bg {
    background: #fff url(/images/oneprofile/dashimg.png) no-repeat center center; }
  .home-main .knob-bg path#valueArc {
    fill: #fff !important; }
  .home-main .knob-bg path#trackArc {
    fill: #fff !important;
    fill-opacity: .5; } }

/*@import'modal/modal.scss';*/
/*oneProfile skills page styles*/
.block, .resource .profile-main .skill-inner-right .skill-inner-right-bg .standerdtimezone, .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections {
  display: block;
  overflow: hidden; }

.in-block, .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections ul li {
  display: block;
  overflow: hidden; }

.error i {
  color: #e04d4d;
  margin-right: 3px; }

.error span {
  color: #e04d4d;
  font-size: 13px;
  margin-top: 3px; }

.error input {
  background: #f4d6d6; }

/* sales skill rating variables */
/* sales skill rating variables | end */
.clear {
  clear: both;
  height: 0px; }

html, body {
  height: 100%;
  min-height: 100%;
  max-height: 100%; }

.skillsmain {
  background: #e9eef1 !important;
  min-height: calc(100vh - 96px);
  max-height: calc(100vh - 96px);
  overflow: auto !important;
  /*.skill-breadcrumb {
        margin: $mar 0 0 0;

        li {
            width: auto;
            float: left;
            margin-right: 10px;
            text-transform: $ttup;
            font-size: medium;

            a {
                color:$primary-color;
                font-size: medium;
            }

            i {
                color: $arrow-color;
                font-size: $fs14;
            }
        }

        .active {
            color: #4a4a4a;
        }
    }*/ }
  .skillsmain .skill-filter-radio {
    position: absolute;
    width: 0px;
    height: 0px;
    margin-top: 0; }
  .skillsmain .skill-filter-radio:focus + .filter-circle + span + .skill-filter {
    outline: 1px dashed #000; }
  .skillsmain select::-ms-expand {
    display: none; }
  .skillsmain .text-italic {
    font-style: italic; }
  .skillsmain select {
    background: url(../../images/down_arrow.png) #fff;
    background-repeat: no-repeat;
    background-position: right 10px top 10px;
    background-size: 10px 15px; }
  .skillsmain .category-space {
    margin-top: 5px; }
    .skillsmain .category-space .select-category {
      text-transform: uppercase;
      color: #333333;
      font-weight: bold;
      font-size: medium; }
  .skillsmain .bottom-space {
    margin-bottom: 10px; }
    .skillsmain .bottom-space .ltitle {
      font-size: 14px;
      font-weight: 600;
      float: left;
      width: 90%;
      padding: 0 0 0 15px;
      margin: 0 0 5px 0; }
      .skillsmain .bottom-space .ltitle h2 {
        color: #333333; }
    .skillsmain .bottom-space .skill-filter-main {
      margin-right: 20px;
      margin-top: 15px;
      position: relative; }
    .skillsmain .bottom-space .skill-filter {
      border-radius: 20px;
      border: 2px solid #0078d7;
      padding-left: 35px;
      font-family: 'Segoe UI';
      display: inline-block;
      line-height: 1.9;
      padding-right: 10px;
      font-weight: normal !important;
      cursor: pointer; }
    .skillsmain .bottom-space .skill-filter-active {
      background: #0078d7 !important;
      color: white !important; }
    .skillsmain .bottom-space .filter-circle {
      display: inline;
      border: 2px solid #0078d7;
      position: absolute;
      height: 21px;
      width: 21px;
      border-radius: 12px;
      margin-top: 6px;
      margin-left: 10px; }
    .skillsmain .bottom-space .filter-circle-active {
      border: 2px solid white !important;
      background: #0078d7 !important; }
    .skillsmain .bottom-space .check-mark {
      position: absolute;
      padding-left: 13px;
      padding-top: 8px;
      color: white;
      font-weight: 600;
      font-size: 14px;
      margin-top: 0px; }
    .skillsmain .bottom-space .keep-left {
      float: left; }
      .skillsmain .bottom-space .keep-left .move-right {
        margin-left: 9px; }
      .skillsmain .bottom-space .keep-left .m-indicator {
        float: left;
        border-width: 0px 5px 0px 20px;
        border: #008000 1px solid;
        background: #008000;
        padding: 0px 5px 0px 4px;
        color: white;
        font-size: small;
        font-weight: 500;
        text-align: center; }
      .skillsmain .bottom-space .keep-left .m-text {
        margin-left: 5px;
        font-size: 14px;
        float: left; }
    .skillsmain .bottom-space .snrl {
      float: left;
      margin: 0px 5px 0 15px; }
      .skillsmain .bottom-space .snrl .move-right {
        margin-left: 9px; }
      .skillsmain .bottom-space .snrl .r-indicator {
        float: left;
        border-width: 0px 5px 0px 20px;
        border: #cb3c38 1px solid;
        background: #cb3c38;
        padding: 0px 3px;
        color: white;
        font-size: small;
        font-weight: 500; }
        .skillsmain .bottom-space .snrl .r-indicator i {
          margin: 2px 0 0 1px;
          font-size: 14px;
          font-weight: 500; }
      .skillsmain .bottom-space .snrl .r-text {
        margin-left: 5px;
        font-size: 14px;
        float: left; }
  .skillsmain *:focus {
    outline: 1px solid #666666; }
  .skillsmain .middle-col {
    text-transform: capitalize;
    color: #333333;
    font-size: medium;
    margin-bottom: 15px;
    text-align: left;
    float: left;
    width: 100%; }
  .skillsmain .left-col {
    text-transform: capitalize;
    color: #333333;
    font-size: medium;
    margin-bottom: 15px;
    margin-left: 1px; }
  .skillsmain h6 {
    text-transform: capitalize;
    color: #333333;
    font-weight: bold;
    font-size: medium; }
  .skillsmain .avrm {
    margin-right: 0px; }
    .skillsmain .avrm .opsStepsLink a {
      text-decoration: underline;
      color: #0168ba; }
    .skillsmain .avrm .opsStepsLink a:hover {
      color: #0168ba; }
    .skillsmain .avrm .bottom-space .keep-left {
      margin-left: 15px;
      float: left; }
    .skillsmain .avrm .bottom-space .col-md-6 {
      font-size: medium; }
  .skillsmain .skilltop {
    margin: 0 0 10px 0; }
  .skillsmain .category-label {
    margin: 10px 0 0;
    color: #4a4a4a;
    text-transform: uppercase; }
  .skillsmain .search-main {
    line-height: 35px;
    margin-top: 15px; }
    .skillsmain .search-main .pull-right {
      padding: 0px;
      color: #a9acae; }
    .skillsmain .search-main .search-form {
      height: 40px; }
      .skillsmain .search-main .search-form .serach-icon {
        width: 25px;
        padding: 3px 12px 0 0; }
      .skillsmain .search-main .search-form div:first-child {
        position: relative !important;
        /*float: left !important;
                    width: 90% !important;*/ }
      .skillsmain .search-main .search-form #SkillsSearch {
        margin: 0px 15px 0 0 !important;
        display: table;
        width: calc(100% - 15px); }
        .skillsmain .search-main .search-form #SkillsSearch input.a11ysearch {
          background: #e9eef1 !important;
          margin: 0px auto;
          padding: 0px 1%;
          height: 38px;
          font-style: italic;
          color: #626262;
          font-size: medium;
          border: 0px;
          width: 100%;
          box-sizing: border-box;
          border: #b1b1b3 solid 1px; }
      .skillsmain .search-main .search-form .search-me {
        margin-right: 2px; }
        .skillsmain .search-main .search-form .search-me .icon-search {
          margin-top: 5px;
          font-size: 20px; }
          .skillsmain .search-main .search-form .search-me .icon-search:hover, .skillsmain .search-main .search-form .search-me .icon-search:focus {
            display: none;
            -webkit-transition: all 1s ease;
            -moz-transition: all 1s ease;
            -o-transition: all 1s ease; }
        .skillsmain .search-main .search-form .search-me:hover, .skillsmain .search-main .search-form .search-me:focus {
          -webkit-transition: all 1s ease;
          -moz-transition: all 1s ease;
          -o-transition: all 1s ease; }
          .skillsmain .search-main .search-form .search-me:hover .icon-search, .skillsmain .search-main .search-form .search-me:focus .icon-search {
            display: none; }
    .skillsmain .search-main .loader-bar {
      font-size: small;
      color: #585858;
      position: absolute;
      left: 0px;
      top: 30px;
      z-index: 9999; }
    .skillsmain .search-main ul#SkillsSearch-list {
      display: block;
      height: auto;
      padding: 0px;
      max-height: 378px !important;
      overflow-y: auto;
      background-color: #fafafa;
      right: 0px;
      border: 2px solid #dddfe0;
      /*left: -112px !important;*/
      margin-top: 0px;
      -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
      -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
      -webkit-border-radius: 0px;
      -moz-border-radius: 0px;
      -ms-border-radius: 0px;
      border-radius: 0px;
      text-align: left;
      width: 125%;
      top: 0px;
      z-index: 9999; }
      .skillsmain .search-main ul#SkillsSearch-list li .result-item .skill-head {
        line-height: 1.5; }
        .skillsmain .search-main ul#SkillsSearch-list li .result-item .skill-head a {
          font-weight: 500;
          font-size: medium;
          /*padding: 0px 12px 0px 12px;*/
          color: #0263B2; }
        .skillsmain .search-main ul#SkillsSearch-list li .result-item .skill-head span {
          color: #00a651;
          font-weight: 500;
          font-size: medium;
          color: #0bb066; }
      .skillsmain .search-main ul#SkillsSearch-list li .result-item .skill-path p {
        /*padding: 12px 12px;*/
        font-weight: 400;
        color: #3f3f3f;
        line-height: 20px; }
      .skillsmain .search-main ul#SkillsSearch-list li .result-item .skill-path .icon {
        font-size: 12px; }
      .skillsmain .search-main ul#SkillsSearch-list li .result-item hr {
        margin: 10px 0px 0px 0px; }
      .skillsmain .search-main ul#SkillsSearch-list li:focus, .skillsmain .search-main ul#SkillsSearch-list li:hover, .skillsmain .search-main ul#SkillsSearch-list li.selected {
        background: #EAEAEA;
        border-color: #EAEAEA; }
      @media screen and (-ms-high-contrast: active) {
        .skillsmain .search-main ul#SkillsSearch-list li:focus, .skillsmain .search-main ul#SkillsSearch-list li:hover, .skillsmain .search-main ul#SkillsSearch-list li.selected {
          background-image: url(/images/oneprofile/whbg.png);
          color: #000; } }
      @media screen and (-ms-high-contrast: black-on-white) {
        .skillsmain .search-main ul#SkillsSearch-list li:focus, .skillsmain .search-main ul#SkillsSearch-list li:hover, .skillsmain .search-main ul#SkillsSearch-list li.selected {
          background-image: url(/images/oneprofile/bhbg.png);
          color: #fff; } }
      @media screen and (-ms-high-contrast: white-on-black) {
        .skillsmain .search-main ul#SkillsSearch-list li:focus, .skillsmain .search-main ul#SkillsSearch-list li:hover, .skillsmain .search-main ul#SkillsSearch-list li.selected {
          background-image: url(/images/oneprofile/whbg.png);
          color: #000; } }
    .skillsmain .search-main .tgl-btn {
      margin-top: 5px; }
    .skillsmain .search-main .result-item {
      padding: 2px 12px;
      border-bottom: solid 1px #ccc; }
  .skillsmain .filter-alphabet div {
    margin: 0px 0 20px 0;
    padding: 0px;
    float: left; }
  .skillsmain .filter-alphabet span {
    list-style: none;
    display: inline;
    margin: 0 4px 0 0;
    text-align: center;
    padding: 1px;
    float: left; }
  .skillsmain .filter-alphabet a {
    background: none;
    padding: 1px 8px;
    color: #000;
    font-size: medium;
    font-weight: 500;
    float: left; }
    .skillsmain .filter-alphabet a.selectable {
      font-weight: bold; }
    .skillsmain .filter-alphabet a.non-selectable {
      opacity: 0.4;
      pointer-events: none;
      cursor: default; }
  .skillsmain .filter-alphabet .active {
    background: #0263B2;
    color: #fff;
    border: 1px solid #0263B2; }
  .skillsmain .filter-alphabet .ng-isolate-scope a.active:focus {
    outline: 2px dotted #666666; }
  .skillsmain .skill-inner {
    margin-bottom: 15px;
    display: block;
    float: left;
    width: 100% !important;
    padding: 1px;
    /*in high contrast mode*/ }
    .skillsmain .skill-inner .skill-rep {
      position: relative; }
      .skillsmain .skill-inner .skill-rep.skill-rep-or a {
        padding-right: 45px; }
      .skillsmain .skill-inner .skill-rep .m-indicator {
        position: absolute;
        border-width: 0px 5px 0px 20px;
        background: #008000;
        border: #008000 solid 1px;
        padding: 0px 3px;
        color: white;
        top: 0px;
        right: 0px;
        font-size: 12px;
        font-weight: 500; }
      .skillsmain .skill-inner .skill-rep .r-indicator {
        position: absolute;
        border-width: 0px 5px 0px 20px;
        background: #cb3c38;
        border: #cb3c38 solid 1px;
        padding: 0px 3px;
        color: white;
        top: 0px;
        right: 22px;
        font-size: 12px;
        font-weight: 500;
        height: 19px; }
        .skillsmain .skill-inner .skill-rep .r-indicator i {
          margin: 2px 0 0 1px;
          font-weight: 500;
          font-size: 12px;
          float: left; }
    .skillsmain .skill-inner .skill-rep a {
      background: #fff;
      font-size: medium;
      /*removing line height for tiles*/
      /*line-height: 15px;*/
      color: #333333;
      float: left;
      width: 100%;
      padding: 5px 15px;
      text-transform: capitalize;
      font-weight: 500;
      height: 60px;
      display: table;
      transition: all 0.3s linear 0s;
      border-width: 1px 1px 1px 1px;
      border-style: solid;
      border-color: #e9eef1;
      border-left-color: #fff; }
      .skillsmain .skill-inner .skill-rep a:hover {
        background: #fafafa;
        /*border-color: darken($white-bg-color, 2);*/ }
        .skillsmain .skill-inner .skill-rep a:hover.norating {
          border-left-color: #cb3c38 !important; }
      .skillsmain .skill-inner .skill-rep a span {
        display: table-cell;
        vertical-align: middle; }
    .skillsmain .skill-inner .skill-rep .active {
      background: #0263B2;
      color: #fff;
      border-left-color: #0263B2;
      width: 100%; }
      .skillsmain .skill-inner .skill-rep .active:focus {
        outline: 2px solid #333333; }
    .skillsmain .skill-inner .skill-rep .active:hover {
      background: #0263B2;
      color: #fff;
      border-left: solid 5px #0263B2;
      width: 100%; }
    @media screen and (-ms-high-contrast: active) {
      .skillsmain .skill-inner .skill-rep .active {
        background-image: url(/images/oneprofile/whbg.png);
        color: #000; }
        .skillsmain .skill-inner .skill-rep .active:focus {
          outline: 2px solid #FFFF00; } }
    @media screen and (-ms-high-contrast: black-on-white) {
      .skillsmain .skill-inner .skill-rep .active {
        background-image: url(/images/oneprofile/bhbg.png);
        color: #fff; }
        .skillsmain .skill-inner .skill-rep .active:focus {
          outline: 2px solid #000; } }
    @media screen and (-ms-high-contrast: white-on-black) {
      .skillsmain .skill-inner .skill-rep .active {
        background-image: url(/images/oneprofile/whbg.png);
        color: #000; }
        .skillsmain .skill-inner .skill-rep .active:focus {
          outline: 2px solid #FFFF00; } }
    .skillsmain .skill-inner .rating {
      padding: 0px; }
      .skillsmain .skill-inner .rating a {
        float: left;
        color: #0263B2;
        padding: 10px 0px 0px 0px;
        height: 60px;
        text-align: center;
        width: 100%;
        font-size: 14px;
        transition: background-color 1s ease;
        background: #fafafa; }
        .skillsmain .skill-inner .rating a i {
          width: 100%;
          margin: 5px 0 5px 0;
          float: left;
          font-size: 14px; }
      .skillsmain .skill-inner .rating a:hover {
        color: #fff;
        background: #0263B2; }
        .skillsmain .skill-inner .rating a:hover i {
          color: #fff; }
    .skillsmain .skill-inner .norating {
      border-left-color: #cb3c38 !important; }
    @media screen and (-ms-high-contrast: active) {
      .skillsmain .skill-inner .norating {
        border-left-color: #cb3c38; } }
    @media screen and (-ms-high-contrast: black-on-white) {
      .skillsmain .skill-inner .norating {
        border-left-color: #cb3c38; } }
    @media screen and (-ms-high-contrast: white-on-black) {
      .skillsmain .skill-inner .norating {
        border-left-color: #cb3c38; } }
    .skillsmain .skill-inner .skill-rating {
      height: 60px;
      background: #fafafa;
      position: absolute;
      display: none; }
      .skillsmain .skill-inner .skill-rating h5 {
        text-transform: uppercase;
        margin: 5px 0 5px 0;
        color: #767676;
        font-size: 14px; }
      .skillsmain .skill-inner .skill-rating ul {
        float: left; }
        .skillsmain .skill-inner .skill-rating ul li {
          float: left; }
          .skillsmain .skill-inner .skill-rating ul li img {
            margin: 0px 12px 0px 0px; }
          .skillsmain .skill-inner .skill-rating ul li a {
            border: #d3d3d3 solid 2px;
            height: 25px;
            width: 25px;
            text-align: center;
            margin: 4px 12px 0 0;
            float: left;
            -webkit-border-radius: 25px;
            -moz-border-radius: 25px;
            -ms-border-radius: 25px;
            border-radius: 25px;
            color: #767676;
            font-size: 14px;
            cursor: pointer; }
          .skillsmain .skill-inner .skill-rating ul li a:hover,
          .skillsmain .skill-inner .skill-rating ul li .active {
            text-decoration: none;
            border: #0263B2 solid 2px;
            color: #0263B2; }
          .skillsmain .skill-inner .skill-rating ul li a:hover, .skillsmain .skill-inner .skill-rating ul li .hoveractive {
            text-decoration: none;
            border: #0263B2 solid 2px;
            color: #0263B2; }
          @media screen and (-ms-high-contrast: active) {
            .skillsmain .skill-inner .skill-rating ul li a:hover,
            .skillsmain .skill-inner .skill-rating ul li .active {
              text-decoration: none;
              border: #FFFFFF solid 2px;
              color: #FFFFFF; }
            .skillsmain .skill-inner .skill-rating ul li a:hover, .skillsmain .skill-inner .skill-rating ul li .hoveractive {
              text-decoration: none;
              border: #FFFFFF solid 2px;
              color: #FFFFFF; } }
  .skillsmain .skill-left {
    border-right: solid 1px #ccc; }
    .skillsmain .skill-left h6 {
      margin-bottom: 15px; }
    .skillsmain .skill-left .scroll-purpose {
      height: calc(100vh - 220px);
      overflow-y: auto;
      overflow-x: hidden; }
    .skillsmain .skill-left h4 {
      text-transform: uppercase; }
    .skillsmain .skill-left .skills-hierarchy {
      color: #fff;
      font-size: medium;
      text-transform: capitalize;
      text-align: center;
      width: 100%;
      padding: 1px;
      text-align: left; }
      .skillsmain .skill-left .skills-hierarchy a {
        display: table;
        color: #fff;
        min-height: 55px;
        width: 100%;
        font-size: medium;
        font-weight: 500;
        padding: 5px 10px;
        background: #0263B2;
        border: #e9eef1 solid 1px; }
        .skillsmain .skill-left .skills-hierarchy a:focus {
          outline: 2px solid #333333; }
        .skillsmain .skill-left .skills-hierarchy a span {
          vertical-align: middle;
          display: table-cell; }
      .skillsmain .skill-left .skills-hierarchy .last-block {
        display: table;
        color: #fff;
        min-height: 55px;
        width: 100%;
        font-size: medium;
        font-weight: 500;
        padding: 5px 10px;
        background: #0263B2;
        border: #e9eef1 solid 1px; }
        .skillsmain .skill-left .skills-hierarchy .last-block:focus {
          outline: 2px solid #333333; }
        .skillsmain .skill-left .skills-hierarchy .last-block span {
          vertical-align: middle;
          display: table-cell; }
    .skillsmain .skill-left .sill-arrow {
      color: #ccc;
      font-size: 26px;
      font-weight: 400;
      margin: 5px 0;
      text-align: center; }
  .skillsmain .skill-middle {
    border-right: solid 1px #ccc;
    height: calc(100vh - 175px); }
    .skillsmain .skill-middle h6 {
      margin-bottom: 15px; }
    .skillsmain .skill-middle .skillscroll {
      float: left;
      height: calc(100vh - 265px) !important;
      overflow-y: auto;
      -ms-overflow-style: scrollbar;
      -webkit-overflow-scrolling: touch;
      width: 100%;
      padding-top: 2px; }
    .skillsmain .skill-middle .slimScrollDiv {
      overflow-y: scroll;
      float: left;
      padding-left: 15px;
      height: calc(100vh - 180px) !important; }
  .skillsmain .skill-pad {
    padding-right: 0px; }
  .skillsmain .opsRateHeading {
    width: 400px;
    margin: 0px auto 10px auto; }
    .skillsmain .opsRateHeading .rateText {
      float: left;
      margin: 3px 0 0px 0;
      color: #333333; }
    .skillsmain .opsRateHeading .skillsLevels {
      padding: 0 0 0px 5px;
      font-size: 18px;
      text-decoration: underline;
      font-weight: 500; }
  .skillsmain .help-icon {
    margin-left: 5px;
    font-size: 12px;
    -webkit-border-radius: 80px;
    -moz-border-radius: 80px;
    -ms-border-radius: 80px;
    border-radius: 80px;
    cursor: pointer;
    border: #c9c9c8 solid 2px;
    text-decoration: none;
    color: #585858;
    display: inline-block;
    padding: 0px;
    width: 20px;
    height: 20px;
    text-align: center;
    float: left; }
    .skillsmain .help-icon .icon-help {
      font-size: 12px;
      font-weight: 400;
      line-height: 0px !important; }
  .skillsmain .popover {
    -webkit-border-radius: 0px;
    -moz-border-radius: 0px;
    -ms-border-radius: 0px;
    border-radius: 0px;
    -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
    -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
    border: 2px #c9c9c8 solid;
    max-width: 430px !important; }
  .skillsmain .popover.bottom .arrow:after {
    border-bottom-color: #c9c9c8; }
  .skillsmain .help-icon:hover {
    border: #0263B2 solid 3px;
    color: #0263B2; }
  .skillsmain .meta-rating ul {
    margin-top: 6px;
    margin-bottom: 0px; }
    .skillsmain .meta-rating ul li {
      margin: 0px 5px; }
      .skillsmain .meta-rating ul li div {
        width: 100%;
        display: inline-block;
        min-height: 40px; }
        .skillsmain .meta-rating ul li div .rating {
          min-width: 11%;
          float: left;
          margin-top: 6px; }
          .skillsmain .meta-rating ul li div .rating a {
            font-size: 14px;
            -webkit-border-radius: 80px;
            -moz-border-radius: 80px;
            -ms-border-radius: 80px;
            border-radius: 80px;
            width: 20px !important;
            height: 20px !important;
            border: #0263B2 solid 3px;
            color: #0263B2;
            line-height: 25px;
            text-align: center;
            text-decoration: none;
            padding: 5px 10px;
            margin-top: 7px; }
        .skillsmain .meta-rating ul li div .content {
          margin: 0px 0px 10px 2px;
          display: inline-block;
          width: 85%;
          float: left;
          text-align: justify; }
  .skillsmain .skill-right {
    height: calc(100vh - 170px); }
    .skillsmain .skill-right .skillaccordion {
      float: left;
      height: calc(100vh - 310px); }
      .skillsmain .skill-right .skillaccordion .rating-block {
        overflow-x: hidden;
        overflow-y: hidden; }
        .skillsmain .skill-right .skillaccordion .rating-block .alpha-space {
          margin-top: 5px; }
      .skillsmain .skill-right .skillaccordion .acc-title-rep {
        max-width: calc(100% - 255px);
        float: left;
        background: transparent;
        border: none;
        margin-left: 2px;
        font-weight: 400;
        text-align: left;
        margin-bottom: 30px; }
        .skillsmain .skill-right .skillaccordion .acc-title-rep i {
          float: left;
          width: 25px; }
        .skillsmain .skill-right .skillaccordion .acc-title-rep span {
          float: left;
          width: calc(100% - 25px); }
      .skillsmain .skill-right .skillaccordion .head-rate-section {
        max-width: 215px;
        float: right; }
        .skillsmain .skill-right .skillaccordion .head-rate-section ul {
          float: left;
          max-width: 210px; }
          .skillsmain .skill-right .skillaccordion .head-rate-section ul li {
            float: left;
            display: inline-block;
            margin: 0px 5px;
            /*@media screen and (-ms-high-contrast: active) {
                                     All high contrast styling rules 
                                  
                                    a{
                                         background: url(/images/oneprofile/toprateinnerbg.png);
                                    }
                                  
                                }*/ }
            .skillsmain .skill-right .skillaccordion .head-rate-section ul li a {
              font-size: 18px;
              width: 32px;
              height: 32px;
              line-height: 32px;
              float: left;
              text-align: center;
              text-decoration: none;
              color: #585858;
              background: url(/images/oneprofile/toprateinnerbg.png);
              font-weight: 500; }
            .skillsmain .skill-right .skillaccordion .head-rate-section ul li a:hover,
            .skillsmain .skill-right .skillaccordion .head-rate-section ul li .hoveractive {
              color: #0263B2;
              background-position: left bottom; }
            .skillsmain .skill-right .skillaccordion .head-rate-section ul li a:hover,
            .skillsmain .skill-right .skillaccordion .head-rate-section ul li .active {
              color: #0263B2;
              background-position: left bottom; }
      .skillsmain .skill-right .skillaccordion .scroll-purpose {
        height: calc(100vh - 388px);
        overflow-y: auto;
        overflow-x: hidden;
        padding-top: 2px; }
        .skillsmain .skill-right .skillaccordion .scroll-purpose .acc-body {
          border-left: solid 1px #ccc;
          border-left-style: dashed;
          margin-left: 10px;
          padding-left: 12px; }
          .skillsmain .skill-right .skillaccordion .scroll-purpose .acc-body .accordian-skills {
            margin-bottom: 10px; }
          .skillsmain .skill-right .skillaccordion .scroll-purpose .acc-body ul li {
            margin: 0px 4px !important; }
        .skillsmain .skill-right .skillaccordion .scroll-purpose .acc-repeat {
          padding-bottom: 20px; }
        .skillsmain .skill-right .skillaccordion .scroll-purpose .leverage-scroll {
          width: 48%; }
        .skillsmain .skill-right .skillaccordion .scroll-purpose .vertical-divider {
          border-right: 1px solid black; }
        .skillsmain .skill-right .skillaccordion .scroll-purpose .space-purpose {
          padding-bottom: 15px;
          padding-top: 2px; }
        .skillsmain .skill-right .skillaccordion .scroll-purpose .leftColumn {
          padding-right: 25px; }
        .skillsmain .skill-right .skillaccordion .scroll-purpose .rightColumn {
          padding-left: 25px;
          padding-right: 25px; }
      .skillsmain .skill-right .skillaccordion .non-accordian {
        padding-bottom: 10px; }
        .skillsmain .skill-right .skillaccordion .non-accordian .acc-title-rep {
          padding-left: 41px;
          font-weight: 400;
          margin-bottom: 30px; }
          .skillsmain .skill-right .skillaccordion .non-accordian .acc-title-rep .sub-skill .sub-space {
            width: 95%; }
            .skillsmain .skill-right .skillaccordion .non-accordian .acc-title-rep .sub-skill .sub-space .nameofskill {
              max-width: calc(100% - 255px);
              float: left;
              padding-top: 3px; }
              .skillsmain .skill-right .skillaccordion .non-accordian .acc-title-rep .sub-skill .sub-space .nameofskill span {
                font-size: 16px; }
            .skillsmain .skill-right .skillaccordion .non-accordian .acc-title-rep .sub-skill .sub-space .sub-rate {
              float: right;
              padding-top: 5px; }
      .skillsmain .skill-right .skillaccordion .sub-skill {
        color: #333333; }
        .skillsmain .skill-right .skillaccordion .sub-skill .sub-rate-width {
          max-width: calc(97% - 240px); }
          .skillsmain .skill-right .skillaccordion .sub-skill .sub-rate-width span {
            font-size: 14px !important;
            font-weight: 400 !important; }
        .skillsmain .skill-right .skillaccordion .sub-skill span {
          font-size: 16px;
          padding-left: 5px;
          cursor: pointer;
          /*max-width: calc(100% - 220px);*/
          float: left; }
        .skillsmain .skill-right .skillaccordion .sub-skill .greenm {
          color: #00a651;
          font-weight: 400;
          margin-right: 10px; }
        .skillsmain .skill-right .skillaccordion .sub-skill ul {
          float: left;
          max-width: 210px; }
          .skillsmain .skill-right .skillaccordion .sub-skill ul li {
            float: left;
            margin: 0px 5px;
            cursor: pointer; }
            .skillsmain .skill-right .skillaccordion .sub-skill ul li a {
              font-size: 16px;
              font-weight: 500;
              -webkit-border-radius: 80px;
              -moz-border-radius: 80px;
              -ms-border-radius: 80px;
              border-radius: 80px;
              width: 32px;
              height: 32px;
              line-height: 32px;
              float: left;
              text-align: center;
              text-decoration: none;
              color: #585858;
              background: url(/images/oneprofile/toprateinnerbg.png); }
            .skillsmain .skill-right .skillaccordion .sub-skill ul li .active {
              color: #0263B2;
              background-position: bottom left; }
            .skillsmain .skill-right .skillaccordion .sub-skill ul li .hoveractive {
              color: #0263B2;
              background-position: bottom left; }
      .skillsmain .skill-right .skillaccordion .sub-space .nameofskill {
        max-width: calc(100% - 255px);
        float: left;
        padding-top: 3px; }
      .skillsmain .skill-right .skillaccordion .sub-space .sub-rate {
        max-width: 210px;
        float: right;
        padding-top: 3px; }
    .skillsmain .skill-right .top-space {
      padding: 20px 0px 0px 40px;
      height: calc(100vh - 300px); }
  .skillsmain .skill-right-top .skill-right-top-left {
    width: calc(100% - 200px); }
  .skillsmain .skill-right-top .skill-right-top-right {
    width: 200px; }
  .skillsmain .skill-right-top .skill-label {
    font-size: 18px;
    font-weight: 500; }
  .skillsmain .skill-right-top h5 {
    text-transform: uppercase; }
  .skillsmain .skill-right-top h2 {
    color: #464646;
    font-size: large;
    word-wrap: break-word;
    word-break: keep-all; }
  .skillsmain .skill-right-top h3 {
    font-weight: 400; }
  .skillsmain .skill-right-top ul {
    float: left; }
    .skillsmain .skill-right-top ul li {
      float: left;
      margin: 0 2%;
      text-align: center; }
      .skillsmain .skill-right-top ul li a {
        font-size: 20px;
        width: 35px;
        height: 35px;
        line-height: 32px;
        display: table;
        margin: 0px auto;
        color: #585858;
        text-decoration: none;
        cursor: pointer;
        background: url(/images/oneprofile/topratebg.png); }
      .skillsmain .skill-right-top ul li a:hover,
      .skillsmain .skill-right-top ul li .hoveractive {
        color: #0263B2;
        cursor: pointer;
        background-position: 0px 108px; }
      .skillsmain .skill-right-top ul li a:hover,
      .skillsmain .skill-right-top ul li .active {
        color: #0263B2;
        cursor: pointer; }
      .skillsmain .skill-right-top ul li span {
        float: left;
        width: 100%;
        text-align: center;
        margin-top: 5px;
        font-size: 15px;
        font-weight: normal; }
  .skillsmain .skill-right-top-check {
    margin: 10px 0;
    border-bottom: 1px solid #dddfe0;
    padding-bottom: 10px; }
    .skillsmain .skill-right-top-check .greenm {
      color: #00a651;
      font-weight: 400;
      margin-right: 10px; }
    .skillsmain .skill-right-top-check .expand-collapse {
      margin: 0; }
      .skillsmain .skill-right-top-check .expand-collapse .skill-expand {
        float: left;
        background: transparent;
        color: #0263B2;
        border-right: 0px solid;
        border-left: 1px solid;
        padding-left: 5px;
        border-color: #767676;
        cursor: pointer; }
        .skillsmain .skill-right-top-check .expand-collapse .skill-expand span {
          padding: 2px; }
      .skillsmain .skill-right-top-check .expand-collapse .skill-expand[disabled] {
        float: left;
        background: transparent;
        color: #767676;
        border-left: 1px solid;
        border-right: 0;
        padding-left: 5px;
        opacity: .3;
        pointer-events: none;
        border-color: #767676;
        cursor: default; }
      .skillsmain .skill-right-top-check .expand-collapse .skill-collapse {
        padding-right: 5px;
        background: transparent;
        color: #0263B2;
        cursor: pointer; }
        .skillsmain .skill-right-top-check .expand-collapse .skill-collapse span {
          padding: 2px; }
      .skillsmain .skill-right-top-check .expand-collapse .skill-collapse[disabled] {
        background: transparent;
        color: #767676;
        opacity: .3;
        pointer-events: none;
        cursor: default; }
    .skillsmain .skill-right-top-check .clear-ratings {
      background: transparent;
      color: #0263B2;
      margin-top: 10px;
      width: 110px;
      padding-top: 2px;
      cursor: pointer; }
    .skillsmain .skill-right-top-check .clear-ratings[disabled] {
      background: transparent;
      color: #767676;
      margin-top: 10px;
      pointer-events: none;
      padding-top: 2px;
      opacity: .3;
      cursor: default; }
  .skillsmain .focus-head div:nth-child(2) {
    max-width: 30%;
    min-width: 500px;
    max-height: 95vh; }

/*h4 {
        color: #4d4d4d;
        font-weight: 500;
        margin: -4px 0 15px 0px;
        float: left;
        text-transform: $ttup;
        font-size: large;
    }*/
.skilltracker {
  background: #fff;
  min-height: 310px;
  margin-right: -15px;
  margin-bottom: 15px;
  border: 1px solid #e9eef1; }
  .skilltracker .skill-heading {
    color: #4d4d4d;
    font-weight: 500;
    margin: 2px 0 0 15px;
    float: left;
    text-transform: uppercase;
    font-size: large; }
    .skilltracker .skill-heading h5 {
      font-size: large; }
  .skilltracker .standardTitleNotMapped {
    padding: 0px 0px 15px 10px;
    float: left;
    text-transform: uppercase;
    color: #4d4d4d; }
  .skilltracker .row {
    height: auto !important; }
  .skilltracker .salestrackprogress {
    min-height: 100px !important; }
  .skilltracker .trackprogress {
    float: left;
    margin: 0px 0px 20px 0px;
    /*display: table;*/
    width: 100%;
    min-height: 130px; }
    .skilltracker .trackprogress span {
      /*display: table-cell;
                vertical-align: middle;
                padding-left: 15px;*/
      float: left; }
      .skilltracker .trackprogress span span {
        float: none; }
    .skilltracker .trackprogress .NoStandardTitleMapped {
      color: #0168ba; }
    .skilltracker .trackprogress span:nth-child(2) {
      margin-top: 15px; }
    .skilltracker .trackprogress .primaryskillink {
      /*margin: 0 0 0 20px;
                 padding: 15px 0;*/ }
      .skilltracker .trackprogress .primaryskillink span {
        border-left: 1px solid #ccc;
        padding: 15px;
        float: left; }
      .skilltracker .trackprogress .primaryskillink .priskill-heading {
        margin: 0px;
        padding: 0px;
        border: none;
        font-size: 16px;
        font-weight: 500; }
      .skilltracker .trackprogress .primaryskillink div {
        width: 98%;
        float: left;
        margin-top: 5px 0px; }
        .skilltracker .trackprogress .primaryskillink div .trimmed {
          white-space: nowrap;
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          float: left;
          margin-right: 3px;
          line-height: 24px;
          text-align: left; }
    .skilltracker .trackprogress .skill-ass-bredcrum {
      text-align: left;
      font-size: 12px;
      height: 50px; }
  .skilltracker .persentage-red {
    color: #b61f1a;
    font-size: 40px;
    font-weight: 100;
    padding-right: 15px;
    cursor: pointer !important;
    float: left;
    text-align: right;
    margin-bottom: 15px; }
  .skilltracker .persentage-green {
    color: #1E824C;
    font-size: 40px;
    font-weight: 100;
    cursor: pointer !important;
    padding-right: 15px;
    float: left;
    text-align: right;
    margin-bottom: 15px; }
  .skilltracker p {
    font-size: 14px;
    font-weight: 500;
    padding: 0px 15px;
    text-align: left; }
  .skilltracker .redalert-atag {
    width: 100%;
    position: absolute;
    bottom: 0px;
    left: 0px; }
  .skilltracker .skills-need-review-container {
    position: absolute;
    bottom: 0px;
    width: 100%; }
  .skilltracker .skillassredtile {
    width: 100%;
    left: 0px;
    bottom: 0px;
    float: left; }
  .skilltracker .redalert {
    /*position: absolute;
            bottom: 0px;*/
    border-left: solid 10px #b61f1a;
    background: rgba(182, 31, 26, 0.1);
    height: 120px;
    width: 100%;
    text-align: center;
    display: table; }
    .skilltracker .redalert i {
      font-size: 40px;
      font-weight: bold;
      color: #b61f1a;
      display: table-cell;
      vertical-align: middle;
      padding-right: 2%;
      text-align: right;
      width: 38%; }
    .skilltracker .redalert .alertmsg {
      /*display: table-cell;
                vertical-align: middle;*/
      color: #000;
      font-weight: 500;
      text-align: left;
      float: left;
      /*margin-top: 25px;*/
      height: 70px;
      display: table;
      width: 62%; }
      .skilltracker .redalert .alertmsg span {
        color: #b61f1a;
        display: table-cell;
        vertical-align: middle; }
  .skilltracker .hifi {
    background: none;
    width: 100%;
    padding: 0px 0px 20px 0px;
    float: left;
    position: absolute;
    bottom: 0px;
    left: 0px; }
    .skilltracker .hifi .hifitxt {
      color: #1E824C;
      font-size: 30px;
      font-weight: 500;
      line-height: 22px;
      cursor: pointer !important;
      margin: 0px auto;
      padding: 10px;
      width: 100%; }
      .skilltracker .hifi .hifitxt span {
        font-size: 12px;
        color: #1E824C; }
  .skilltracker .not-part-skilltrack {
    background: none;
    width: 100%;
    height: 70px;
    display: table;
    position: absolute;
    bottom: 0px; }
    .skilltracker .not-part-skilltrack .msgtxt {
      display: table-cell;
      vertical-align: middle;
      color: #000;
      font-size: 14px;
      font-weight: 500;
      line-height: 22px;
      text-align: center;
      font-style: italic; }

.if-nopriskill-selected {
  text-align: center;
  position: relative; }
  .if-nopriskill-selected .pfs-name {
    padding: 0;
    margin: 20px auto;
    font-size: 14px;
    color: #4d4d4d;
    text-transform: uppercase;
    /*img {
                margin: -5px 10px 0px 0px;
            }*/ }
    .if-nopriskill-selected .pfs-name i {
      margin: -5px 10px 0px 0px;
      font-size: 34px;
      color: #a1a1a1; }
  .if-nopriskill-selected .confirmbtn {
    color: #fff;
    padding: 8px 30px;
    background: #0263B2;
    border: solid 1px #fff !important;
    font-size: 14px;
    font-weight: 500;
    text-transform: uppercase;
    border: solid 1px #fafafa;
    margin-bottom: 20px; }
  .if-nopriskill-selected p {
    color: #b61f1a;
    font-style: italic; }
  .if-nopriskill-selected p.opsSkillDesc {
    color: inherit;
    font-style: inherit;
    font-weight: normal; }
  .if-nopriskill-selected p.black-para {
    color: #000;
    padding: 0px 15px;
    font-style: italic; }
  .if-nopriskill-selected .redalert {
    height: 70px !important; }
  .if-nopriskill-selected .red-bdr-only {
    height: 0px !important; }

.pagetourdiv {
  padding-left: 15px; }
  .pagetourdiv .pagetourdesc {
    bottom: 0;
    width: 100%;
    margin-bottom: 0px; }
    .pagetourdiv .pagetourdesc .ops_pagetour {
      background: #0078d7;
      color: #fff; }

.white-tile {
  background: #fff;
  display: table;
  height: 60px;
  padding: 0 15px;
  margin-right: -15px;
  margin-bottom: 15px;
  width: 100%;
  border: 1px solid #fff; }
  .white-tile .skilltitle {
    display: table-cell;
    vertical-align: middle;
    color: #000;
    font-size: 16px;
    text-align: left;
    height: 60px;
    display: table-cell;
    vertical-align: middle; }
  .white-tile .salesdescdiv {
    height: 50px;
    margin-top: 10px; }
  .white-tile .sdptitle {
    display: table-cell;
    vertical-align: middle;
    color: #000;
    font-size: 16px;
    text-align: center;
    height: 70px;
    opacity: 1;
    display: block;
    padding-top: 20px; }
  .white-tile .skillnumber {
    display: table;
    color: #4184d8;
    font-size: 36px;
    text-align: right;
    width: 100%; }
  .white-tile .right-block {
    display: table;
    text-align: right; }
  .white-tile .skillnumlink {
    display: table-cell;
    vertical-align: middle;
    color: #0263B2;
    font-size: 16px;
    line-height: 14px;
    height: 60px;
    word-break: break-word; }
    .white-tile .skillnumlink .trimmed {
      margin-right: 3px;
      line-height: 18px; }
    .white-tile .skillnumlink i {
      float: right;
      padding-left: 3px; }

.myskilltracksummary .no-mar-top {
  margin-top: 0px; }

.myskilltracksummary h4 {
  margin-top: 0px; }

.myskilltracksummary .trackprogress {
  float: left;
  margin: 0px;
  display: table; }
  .myskilltracksummary .trackprogress span {
    /*display: table-cell;
                vertical-align: middle;
                padding-right: 15px;*/
    float: left; }
  .myskilltracksummary .trackprogress .notreviewed {
    margin: 15px 0px 0px 10px; }
  .myskilltracksummary .trackprogress .ofText {
    padding: 20px 5px 0px 5px; }

.myskilltracksummary .notreviewedskills {
  color: #6d6d6d;
  font-size: 40px;
  font-weight: 100; }

.myskilltracksummary .ratethiskill {
  font-size: 14px;
  color: #0168ba;
  margin-bottom: 25px;
  float: left; }
  .myskilltracksummary .ratethiskill:hover {
    color: #000; }

.myskilltracksummary .trackacts {
  margin-bottom: 3px; }

@media (max-width: 1100px) {
  .skillsmain .search-main .search-form #SkillsSearch {
    margin: 0px !important;
    width: 100%; }
  .skillsmain .search-main .search-form {
    width: calc(100% - 30px);
    margin: 0 auto; }
  .skillsmain .search-main ul#SkillsSearch-list {
    width: 100% !important; }
  .skillsmain .skill-right .skillaccordion .scroll-purpose .rightColumn {
    padding-left: 15px; }
  .skillsmain .skill-inner .skill-rep a span {
    font-size: 12px; }
  .leftColumn {
    width: 100% !important; }
  .rightColumn {
    width: 100% !important; }
  .skillsmain .skill-right .skillaccordion .scroll-purpose .space-purpose {
    width: 100% !important; }
  .skillsmain .skill-inner .skill-rep a span {
    font-size: 12px; }
  .zooming-view {
    padding-left: 7px !important; }
  .skillsmain .skill-inner .skill-rep a span {
    font-size: 12px; } }

@media (max-width: 1280px) {
  .skillsmain .skill-pad {
    padding-right: 0px;
    margin-bottom: 15px; }
  .skillsmain .skill-left .skills-hierarchy a {
    font-size: 11px; }
  .skillsmain .skill-inner .skill-rep a span {
    font-size: 12px; } }

@media (max-width: 1366px) {
  .skillsmain .skill-left .skills-hierarchy a {
    font-size: 12px; }
  .skillsmain .skill-left .skills-hierarchy .last-block span {
    font-size: 12px !important; }
  .skillsmain .skill-inner .skill-rep a span {
    font-size: 14px; } }

.priskill-bar {
  display: flex;
  width: 100%;
  margin-bottom: -5px;
  border: 1px solid #e9eef1; }
  .priskill-bar .priskill {
    background: #fff;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-right: 0px; }
    .priskill-bar .priskill .priskill-list {
      border-right: solid 1px #ccc;
      line-height: 18px;
      margin-right: -15px;
      float: left;
      width: 100%;
      height: 100%; }
      .priskill-bar .priskill .priskill-list .pskill-img {
        float: left;
        margin: 5px 15px 15px 0px;
        width: 33px;
        color: #a1a1a1;
        font-size: 35px; }
      .priskill-bar .priskill .priskill-list .priskill-name {
        text-transform: uppercase;
        font-size: large;
        float: left;
        width: calc(100% - 60px); }
        .priskill-bar .priskill .priskill-list .priskill-name .primary-functional {
          text-transform: uppercase;
          font-size: large;
          margin: 0px;
          padding: 0px;
          font-weight: 400 !important;
          float: left;
          width: calc(100% - 30px); }
        .priskill-bar .priskill .priskill-list .priskill-name h5 {
          text-transform: uppercase;
          font-size: large;
          margin: 0px;
          padding: 0px;
          font-weight: 400 !important;
          float: left; }
        .priskill-bar .priskill .priskill-list .priskill-name .marg-left {
          margin-left: 5px; }
        .priskill-bar .priskill .priskill-list .priskill-name .edit {
          color: #000;
          font-size: 14px;
          font-weight: 100;
          background: none !important;
          border: none !important;
          width: 20px; }
          .priskill-bar .priskill .priskill-list .priskill-name .edit .marg-left {
            margin-left: 5px; }
        .priskill-bar .priskill .priskill-list .priskill-name span {
          color: #0263B2;
          font-size: small;
          text-transform: capitalize;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 80% !important; }
        .priskill-bar .priskill .priskill-list .priskill-name .bredcrum {
          font-size: small;
          text-transform: capitalize;
          color: #000;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 80% !important;
          display: block; }
        .priskill-bar .priskill .priskill-list .priskill-name .trimmed {
          white-space: nowrap;
          min-width: 100px;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 20px;
          line-height: 18px; }
        .priskill-bar .priskill .priskill-list .priskill-name .priskill-links {
          margin-top: 0px;
          float: left;
          width: 100%; }
          .priskill-bar .priskill .priskill-list .priskill-name .priskill-links a {
            float: left;
            width: 100%; }
  .priskill-bar .priskill:last-child .priskill-list {
    border-right: none; }
  .priskill-bar .priskill.active {
    background: #fafafa; }

.priskill-searchsection {
  background: #fafafa;
  position: relative;
  padding: 15px 25px;
  border: #e9eef1 solid 1px;
  border-width: 0px 1px 1px 1px; }
  .priskill-searchsection .searchbar-priskill {
    background: #ccc;
    padding: 3px 4px;
    color: #4d4d4d;
    font-size: 14px;
    border: 0px;
    width: 90% !important;
    float: left; }
  .priskill-searchsection .searchbte-priskill {
    width: 10%;
    text-align: center;
    height: 26px;
    float: right;
    background: #ccc; }
    .priskill-searchsection .searchbte-priskill a {
      color: #4d4d4d !important; }
      .priskill-searchsection .searchbte-priskill a i {
        color: #4d4d4d !important;
        margin-top: 2px; }
  .priskill-searchsection .skillpath-bredcrum {
    font-size: medium;
    color: #000;
    border-bottom: solid 1px #000;
    margin: 0px;
    font-weight: 500;
    padding: 0px 10px 3px 0px; }
    .priskill-searchsection .skillpath-bredcrum ul li {
      display: inline-block;
      margin-right: 5px; }
      .priskill-searchsection .skillpath-bredcrum ul li a {
        color: #0263B2 !important; }
    .priskill-searchsection .skillpath-bredcrum ul li:last-child a {
      pointer-events: none;
      cursor: default;
      color: #000; }
  .priskill-searchsection .skillpath-results {
    overflow-y: auto;
    max-height: 205px;
    margin-bottom: 15px; }
    .priskill-searchsection .skillpath-results .result-item {
      font-size: medium;
      color: #474747;
      border-bottom: solid 1px #9d9d9d;
      padding: 7px 10px 5px 10px;
      text-transform: capitalize;
      cursor: pointer;
      float: left;
      width: 100%; }
      .priskill-searchsection .skillpath-results .result-item:hover, .priskill-searchsection .skillpath-results .result-item:focus {
        background: #0275d1;
        color: #fff; }
        .priskill-searchsection .skillpath-results .result-item:hover .arrow-right, .priskill-searchsection .skillpath-results .result-item:focus .arrow-right {
          color: #fff; }
      .priskill-searchsection .skillpath-results .result-item .arrow-right {
        float: right;
        color: #747574; }
        .priskill-searchsection .skillpath-results .result-item .arrow-right:hover, .priskill-searchsection .skillpath-results .result-item .arrow-right:focus {
          color: #fff; }
    @media screen and (-ms-high-contrast: active) {
      .priskill-searchsection .skillpath-results .result-item:hover, .priskill-searchsection .skillpath-results .result-item:focus {
        background-image: url(/images/oneprofile/whbg.png);
        color: #000; } }
    @media screen and (-ms-high-contrast: black-on-white) {
      .priskill-searchsection .skillpath-results .result-item:hover, .priskill-searchsection .skillpath-results .result-item:focus {
        background-image: url(/images/oneprofile/bhbg.png);
        color: #fff; } }
    @media screen and (-ms-high-contrast: white-on-black) {
      .priskill-searchsection .skillpath-results .result-item:hover, .priskill-searchsection .skillpath-results .result-item:focus {
        background-image: url(/images/oneprofile/whbg.png);
        color: #000; } }
    .priskill-searchsection .skillpath-results .result-item-form {
      font-size: medium;
      color: #474747;
      border-bottom: solid 1px #9d9d9d;
      padding: 0px;
      text-transform: capitalize;
      cursor: pointer;
      width: 100%;
      margin-bottom: 0px !important; }
      .priskill-searchsection .skillpath-results .result-item-form:hover, .priskill-searchsection .skillpath-results .result-item-form:focus {
        background: #808080;
        color: #fff; }
        .priskill-searchsection .skillpath-results .result-item-form:hover input[type=radio]:not(old) + .radioBtn > span, .priskill-searchsection .skillpath-results .result-item-form:focus input[type=radio]:not(old) + .radioBtn > span {
          border: 1px solid #fff; }
        .priskill-searchsection .skillpath-results .result-item-form:hover input[type=radio]:not(old):checked + .radioBtn > span > span, .priskill-searchsection .skillpath-results .result-item-form:focus input[type=radio]:not(old):checked + .radioBtn > span > span {
          background: #fff; }
        .priskill-searchsection .skillpath-results .result-item-form:hover .arrow-right, .priskill-searchsection .skillpath-results .result-item-form:focus .arrow-right {
          color: #fff; }
      @media screen and (-ms-high-contrast: active) {
        .priskill-searchsection .skillpath-results .result-item-form .result-item-form:hover, .priskill-searchsection .skillpath-results .result-item-form .result-item-form:focus {
          background-image: url(/images/oneprofile/whbg.png);
          color: #000; }
          .priskill-searchsection .skillpath-results .result-item-form .result-item-form:hover input[type=radio]:not(old) + .radioBtn > span, .priskill-searchsection .skillpath-results .result-item-form .result-item-form:focus input[type=radio]:not(old) + .radioBtn > span {
            border: 1px solid #000; }
          .priskill-searchsection .skillpath-results .result-item-form .result-item-form:hover input[type=radio]:not(old):checked + .radioBtn > span > span, .priskill-searchsection .skillpath-results .result-item-form .result-item-form:focus input[type=radio]:not(old):checked + .radioBtn > span > span {
            background-image: url(/images/oneprofile/whbg.png); } }
      @media screen and (-ms-high-contrast: black-on-white) {
        .priskill-searchsection .skillpath-results .result-item-form .result-item-form:hover, .priskill-searchsection .skillpath-results .result-item-form .result-item-form:focus {
          background-image: url(/images/oneprofile/bhbg.png);
          color: #fff; }
          .priskill-searchsection .skillpath-results .result-item-form .result-item-form:hover input[type=radio]:not(old) + .radioBtn > span, .priskill-searchsection .skillpath-results .result-item-form .result-item-form:focus input[type=radio]:not(old) + .radioBtn > span {
            border: 1px solid #fff; }
          .priskill-searchsection .skillpath-results .result-item-form .result-item-form:hover input[type=radio]:not(old):checked + .radioBtn > span > span, .priskill-searchsection .skillpath-results .result-item-form .result-item-form:focus input[type=radio]:not(old):checked + .radioBtn > span > span {
            background-image: url(/images/oneprofile/bhbg.png); } }
      @media screen and (-ms-high-contrast: white-on-black) {
        .priskill-searchsection .skillpath-results .result-item-form .result-item-form:hover, .priskill-searchsection .skillpath-results .result-item-form .result-item-form:focus {
          background-image: url(/images/oneprofile/whbg.png);
          color: #000; }
          .priskill-searchsection .skillpath-results .result-item-form .result-item-form:hover input[type=radio]:not(old) + .radioBtn > span, .priskill-searchsection .skillpath-results .result-item-form .result-item-form:focus input[type=radio]:not(old) + .radioBtn > span {
            border: 1px solid #000; }
          .priskill-searchsection .skillpath-results .result-item-form .result-item-form:hover input[type=radio]:not(old):checked + .radioBtn > span > span, .priskill-searchsection .skillpath-results .result-item-form .result-item-form:focus input[type=radio]:not(old):checked + .radioBtn > span > span {
            background-image: url(/images/oneprofile/whbg.png); } }
      .priskill-searchsection .skillpath-results .result-item-form .arrow-right {
        float: right;
        color: #747574; }
        .priskill-searchsection .skillpath-results .result-item-form .arrow-right:hover, .priskill-searchsection .skillpath-results .result-item-form .arrow-right:focus {
          color: #fff; }
      .priskill-searchsection .skillpath-results .result-item-form input[type=radio]:not(old) {
        width: 24px;
        margin: 0;
        padding: 0;
        font-size: 1em;
        opacity: 0;
        position: absolute; }
      .priskill-searchsection .skillpath-results .result-item-form input[type=radio]:not(old) + .radioBtn {
        display: block;
        line-height: 1.5em;
        font-weight: 400;
        width: 100%;
        padding: 5px 0px 3px 10px;
        margin-bottom: 0px; }
      .priskill-searchsection .skillpath-results .result-item-form input[type=radio]:focus + .radioBtn, .priskill-searchsection .skillpath-results .result-item-form input[type=radio]:checked + .radioBtn {
        background: #0275d1;
        color: #fff; }
      .priskill-searchsection .skillpath-results .result-item-form input[type=radio]:checked + .radioBtn:hover {
        background: #808080; }
      @media screen and (-ms-high-contrast: active) {
        .priskill-searchsection .skillpath-results .result-item-form input[type=radio]:checked + .radioBtn:hover,
        .priskill-searchsection .skillpath-results .result-item-form input[type=radio]:focus + .radioBtn, .priskill-searchsection .skillpath-results .result-item-form input[type=radio]:checked + .radioBtn {
          background-image: url(/images/oneprofile/whbg.png);
          color: #000; } }
      @media screen and (-ms-high-contrast: black-on-white) {
        .priskill-searchsection .skillpath-results .result-item-form input[type=radio]:checked + .radioBtn:hover,
        .priskill-searchsection .skillpath-results .result-item-form input[type=radio]:focus + .radioBtn, .priskill-searchsection .skillpath-results .result-item-form input[type=radio]:checked + .radioBtn {
          background-image: url(/images/oneprofile/bhbg.png);
          color: #fff; } }
      @media screen and (-ms-high-contrast: white-on-black) {
        .priskill-searchsection .skillpath-results .result-item-form input[type=radio]:checked + .radioBtn:hover,
        .priskill-searchsection .skillpath-results .result-item-form input[type=radio]:focus + .radioBtn, .priskill-searchsection .skillpath-results .result-item-form input[type=radio]:checked + .radioBtn {
          background-image: url(/images/oneprofile/whbg.png);
          color: #000; } }
    .priskill-searchsection .skillpath-results input[type=radio]:not(old) + .radioBtn > span {
      display: inline-block;
      width: 16px;
      height: 16px;
      margin: 0.25em 0.5em 0.25em 0.25em;
      border: 1px solid #0275d1;
      border-radius: 50%;
      background: none;
      /*background-image :    -moz-linear-gradient(rgb(240,240,240),rgb(224,224,224));
                  background-image :     -ms-linear-gradient(rgb(240,240,240),rgb(224,224,224));
                  background-image :      -o-linear-gradient(rgb(240,240,240),rgb(224,224,224));
                  background-image : -webkit-linear-gradient(rgb(240,240,240),rgb(224,224,224));
                  background-image :         linear-gradient(rgb(240,240,240),rgb(224,224,224));
                  */
      vertical-align: bottom; }
    .priskill-searchsection .skillpath-results input[type=radio]:focus + .radioBtn > span, .priskill-searchsection .skillpath-results input[type=radio]:checked + .radioBtn > span {
      border: 1px solid #fff; }
    .priskill-searchsection .skillpath-results input[type=radio]:focus + .radioBtn > span {
      outline: solid 1px #000; }
    .priskill-searchsection .skillpath-results input[type=radio]:not(old):checked + .radioBtn > span {
      /*background-image :    -moz-linear-gradient(rgb(224,224,224),rgb(240,240,240));
                  background-image :     -ms-linear-gradient(rgb(224,224,224),rgb(240,240,240));
                  background-image :      -o-linear-gradient(rgb(224,224,224),rgb(240,240,240));
                  background-image : -webkit-linear-gradient(rgb(224,224,224),rgb(240,240,240));
                  background-image :         linear-gradient(rgb(224,224,224),rgb(240,240,240));*/ }
    .priskill-searchsection .skillpath-results input[type=radio]:not(old) + .radioBtn > span > span {
      display: block;
      width: 8px;
      height: 8px;
      margin: 3px;
      border: none;
      border-radius: 50%;
      background: none;
      /*background-image :    -moz-linear-gradient(rgb(179,217,140),rgb(153,204,102));
                  background-image :     -ms-linear-gradient(rgb(179,217,140),rgb(153,204,102));
                  background-image :      -o-linear-gradient(rgb(179,217,140),rgb(153,204,102));
                  background-image : -webkit-linear-gradient(rgb(179,217,140),rgb(153,204,102));
                  background-image :         linear-gradient(rgb(179,217,140),rgb(153,204,102));*/ }
    .priskill-searchsection .skillpath-results input[type=radio]:focus + .radioBtn > span > span {
      background: none; }
    .priskill-searchsection .skillpath-results input[type=radio]:checked + .radioBtn > span > span {
      background: #fff; }
  .priskill-searchsection .result-item:last-child {
    margin-bottom: 0px; }
  .priskill-searchsection .nopointer {
    cursor: default; }

.confirmbtn {
  color: #fff;
  padding: 8px 30px;
  background: #0263B2;
  border: solid 1px #0263B2 !important;
  font-size: 16px;
  font-weight: 500;
  text-transform: uppercase;
  border: none; }
  .confirmbtn span {
    font-size: large; }

.confirmbtn[disabled] {
  background: #ccc; }

.clicktoclose {
  background: #e1e1e1;
  color: #377dd7;
  padding: 8px 12px 4px 12px;
  position: absolute;
  right: 0px;
  bottom: 0px;
  border: #e1e1e1 solid 1px;
  border-width: 1px 0px 0px 1px; }
  .clicktoclose i {
    line-height: 14px; }

.clear-modal {
  margin-top: 10%; }
  .clear-modal .modal-content {
    width: 80%;
    float: right;
    border-radius: 0px;
    -webkit-box-shadow: none;
    box-shadow: none;
    z-index: 1070 !important; }
  .clear-modal .modal-header {
    border: none; }
    .clear-modal .modal-header span {
      font-weight: 500;
      font-size: 16px;
      padding: 0px 2px; }
    .clear-modal .modal-header .icon {
      font-size: 20px;
      cursor: pointer; }
  .clear-modal .modal-body {
    padding: 0px 15px !important; }
    .clear-modal .modal-body ul li:first-child span {
      color: #cb3c38;
      font-size: 26px; }
    .clear-modal .modal-body ul li:nth-child(3) {
      font-weight: 700; }
    .clear-modal .modal-body ul li {
      padding: 0px 0px 5px 0px;
      font-size: 14px; }
    .clear-modal .modal-body ul .clearingskill {
      font-weight: 700; }
  .clear-modal .modal-footer {
    border: none;
    text-align: center;
    /*high contrast mode*/ }
    .clear-modal .modal-footer .cancel-modal {
      padding: 7px 35px;
      background: #0078d7;
      border: 1px solid #0078d7;
      color: #fff; }
    @media screen and (-ms-high-contrast: active) {
      .clear-modal .modal-footer .cancel-modal {
        padding: 7px 35px;
        background: #767676;
        border: 1px solid #767676;
        color: #fff; } }
    .clear-modal .modal-footer .ok-modal {
      padding: 5px 35px;
      margin-left: 15px;
      background: transparent;
      border-style: groove;
      border-color: #0078d7;
      color: #0078d7;
      letter-spacing: -1px; }

.modal-backdrop {
  background-color: transparent !important; }

.modal-backdrop.in {
  background-image: url(/images/oneprofile/bhbg.png) !important;
  opacity: 0.3;
  height: 100vh;
  width: 100%;
  -webkit-transition: opacity 200ms linear;
  -moz-transition: opacity 200ms linear;
  -o-transition: opacity 200ms linear;
  -ms-transition: opacity 200ms linear;
  transition: opacity 200ms linear; }

.withOutPrimary {
  height: calc(100vh - 90px); }

.withPrimary {
  height: calc(100vh - 132px); }

.search-main .row .col-md-6 .search-form div:first-child {
  position: relative !important; }

.breakall {
  word-break: break-all; }

.breakword {
  word-break: break-word; }

.clear-both {
  clear: both;
  min-height: 1px; }

@media screen and (max-width: 950px) {
  .priskill-bar {
    display: inherit; }
  .withPrimary {
    height: auto; }
  .scroll-purpose {
    height: auto !important; }
  .skill-middle {
    height: auto !important; }
  .skill-right {
    height: auto !important; }
  .skillaccordion {
    height: auto !important; }
  .skillsmain .search-main .dropdown-menu {
    left: 15px !important; }
  .skillsmain .skill-right .skillaccordion .non-accordian .acc-title-rep .sub-skill .sub-space .sub-rate {
    margin-right: 10px; }
  .skillsmain .skill-right .skillaccordion .non-accordian .acc-title-rep .sub-skill .sub-space .nameofskill {
    padding-left: 15px; } }

@media screen and (max-width: 700px) {
  #footer ul li:last-child {
    float: right !important; }
  .skillsmain .skill-right .skillaccordion .non-accordian .acc-title-rep .sub-skill .sub-space .sub-rate {
    margin-right: 10px; }
  .skillsmain .skill-right .skillaccordion .non-accordian .acc-title-rep .sub-skill .sub-space .nameofskill {
    padding-left: 15px; } }

.rmove {
  right: 0px !important; }

.industry-banner {
  padding-right: 0px; }
  .industry-banner .notification-msg {
    border: 1px solid #A5D5EC;
    height: auto;
    float: left;
    width: 100%;
    padding: 5px;
    margin-bottom: 15px;
    background-color: #D8ECF5;
    font-weight: 400;
    font-size: 14px;
    color: #000; }

.mandatory-trackskill {
  border-left: solid 10px #b61f1a; }

/* don't below remove the code it will impact on accessiblity high high contrast mode, 
                if changes required please cross check on skill page rating selection | START */
@media screen and (-ms-high-contrast: active) {
  .search-main ul#SkillsSearch-list li.selected {
    background-image: url(/images/oneprofile/whbg.png);
    color: #000; }
  .a11y-type-ahead .selected, .a11y-type-ahead ul li:hover {
    background-image: url(/images/oneprofile/whbg.png);
    color: #000; }
  .skillsmain .skill-right .skillaccordion ul li a {
    background: url(/images/oneprofile/toprateinnerbg.png);
    background-position: top left;
    opacity: 0.8; }
  .skillsmain .skill-right .skillaccordion ul li a[style="background-position: left bottom;"],
  .skillsmain .skill-right .skillaccordion ul li .active,
  .skillsmain .skill-right .skillaccordion ul li .hoveractive {
    background: url(/images/oneprofile/toprateinnerbg.png);
    background-position: top right !important;
    opacity: 1; }
  .skillsmain .skill-right-top ul li a[style="background-position: 0px 108px;"],
  .skillsmain .skill-right-top ul li a {
    background: url(/images/oneprofile/topratebg.png);
    background-position: top right !important;
    opacity: 0.8; }
  .skillsmain .skill-right-top ul li a[style="background-position: 0px 108px;"],
  .skillsmain .skill-right ul li .active,
  .skillsmain .skill-right ul li .hoveractive,
  .skillsmain .skill-right-top ul li .active,
  .skillsmain .skill-right-top ul li .hoveractive {
    background: url(/images/oneprofile/topratebg.png);
    background-position: top right !important;
    opacity: 1; } }

@media screen and (-ms-high-contrast: black-on-white) {
  .search-main ul#SkillsSearch-list li.selected {
    background-image: url(/images/oneprofile/bhbg.png);
    color: #fff; }
  .a11y-type-ahead .selected, .a11y-type-ahead ul li:hover {
    background-image: url(/images/oneprofile/bhbg.png);
    color: #fff; }
  .skillsmain .skill-right-top ul li a[style="background-position: 0px 108px;"],
  .skillsmain .skill-right-top ul li .active,
  .skillsmain .skill-right-top ul li .hoveractive {
    background: url(/images/oneprofile/topratebg.png);
    background-position: bottom right !important; }
  .skillsmain .skill-right .skillaccordion ul li a[style="background-position: left bottom;"],
  .skillsmain .skill-right .skillaccordion ul li .active,
  .skillsmain .skill-right .skillaccordion ul li .hoveractive {
    background: url(/images/oneprofile/toprateinnerbg.png);
    background-position: bottom right !important; } }

@media screen and (-ms-high-contrast: white-on-black) {
  .search-main ul#SkillsSearch-list li.selected {
    background-image: url(/images/oneprofile/whbg.png);
    color: #000; }
  .a11y-type-ahead .selected, .a11y-type-ahead ul li:hover {
    background-image: url(/images/oneprofile/whbg.png);
    color: #000; } }

/* don't below remove the code it will impact on accessiblity high high contrast mode, 
                if changes required please cross check on skill page rating selection | END */
/* Non Skill Track Skills | Mandatory | CSS */
.skill-track-mandatory {
  float: left;
  width: 100%; }
  .skill-track-mandatory .btn-select {
    padding: 8px 30px;
    background: #0263B2;
    border: 1px solid #0263B2;
    color: #fff;
    margin: 5px 0px;
    text-transform: uppercase;
    border: 1px solid #fff !important;
    font-size: 14px;
    font-weight: 500; }
    .skill-track-mandatory .btn-select:focus {
      outline: #666 solid 1px; }
  .skill-track-mandatory .stm-skills {
    height: auto;
    min-height: 174px;
    padding: 15px; }
    .skill-track-mandatory .stm-skills .skillnumber {
      float: left;
      line-height: 25px; }
    .skill-track-mandatory .stm-skills .skillnumber.text-danger {
      color: #b61f1a; }
    .skill-track-mandatory .stm-skills .skilltitle {
      padding-bottom: 15px;
      height: auto;
      display: block;
      width: 100%; }
    .skill-track-mandatory .stm-skills .stm-select-skill {
      text-align: center; }
      .skill-track-mandatory .stm-skills .stm-select-skill .stm-skill-title {
        display: inline-block; }
      .skill-track-mandatory .stm-skills .stm-select-skill .prinonskill-heading {
        margin: 0px;
        padding: 0px;
        border: none;
        font-size: 13px;
        font-weight: 500;
        float: left; }
      .skill-track-mandatory .stm-skills .stm-select-skill .h3 {
        color: #4d4d4d;
        font-size: 13px;
        text-transform: uppercase;
        font-weight: normal;
        margin-top: 0px;
        margin-bottom: 5px; }
      .skill-track-mandatory .stm-skills .stm-select-skill i.pskill-icon {
        font-size: 30px;
        color: #a1a1a1;
        line-height: 25px; }
    .skill-track-mandatory .stm-skills .stm-selct-btn {
      float: left;
      width: 100%; }
    .skill-track-mandatory .stm-skills .stm-selcted-skill {
      float: left;
      width: 100%;
      text-align: left; }
    .skill-track-mandatory .stm-skills .stm-selcted-skill a {
      color: #0263B2;
      font-size: small; }
    .skill-track-mandatory .stm-skills .stm-message .text-danger {
      color: #b61f1a;
      font-style: italic;
      font-size: 14px;
      margin-bottom: 0px;
      margin-top: 5px;
      font-weight: 500; }
    .skill-track-mandatory .stm-skills .no-padd-left {
      padding-left: 0px; }

.op-showexp {
  color: #0064c1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 20px;
  font-size: 15px;
  margin: 3px 10px;
  text-decoration: underline; }
  .op-showexp a p {
    color: #0064c1; }

@media screen and (max-width: 1200px) and (min-width: 520px) {
  .skilltracker {
    min-height: 330px; } }

/* Non Skill Track Skills | Mandatory | END */
.block, .resource .profile-main .skill-inner-right .skill-inner-right-bg .standerdtimezone, .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections {
  display: block;
  overflow: hidden; }

.in-block, .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections ul li {
  display: block;
  overflow: hidden; }

.error i {
  color: #e04d4d;
  margin-right: 3px; }

.error span {
  color: #e04d4d;
  font-size: 13px;
  margin-top: 3px; }

.error input {
  background: #f4d6d6; }

/* sales skill rating variables */
/* sales skill rating variables | end */
.custom-alert {
  width: 100%;
  font-size: medium;
  color: #444444;
  float: left;
  /*border: $primary-color 1px solid;
    display: table;*/
  background: #0263B2;
  font-weight: 500; }
  .custom-alert .alert-close {
    position: absolute;
    margin-top: 0px !important;
    right: 15px;
    top: 14px; }
    .custom-alert .alert-close i {
      color: #767676; }
      .custom-alert .alert-close i:hover {
        opacity: .5; }
  .custom-alert .icon-width {
    text-align: center;
    float: left;
    width: 50px; }
    .custom-alert .icon-width i {
      color: #fff;
      font-size: 24px;
      font-weight: 500;
      margin-top: 10px;
      margin-right: 0px; }
  .custom-alert .alert-content {
    width: calc(100% - 50px);
    /*display: table;
        min-height: 40px;*/
    padding: 14px 10px;
    font-size: 14px;
    background-color: rgba(255, 255, 255, 0.9);
    float: left;
    position: relative; }
    .custom-alert .alert-content span {
      /*display: table-cell;
            vertical-align: middle;*/
      width: 99%;
      text-align: left;
      line-height: 24px;
      background: none; }
    .custom-alert .alert-content button.alert-close {
      background: transparent;
      border: none; }
    .custom-alert .alert-content span a i {
      background: none;
      color: #767676;
      font-size: 11px;
      padding: 10px;
      text-align: center;
      vertical-align: middle;
      margin-right: 0px; }
    .custom-alert .alert-content span a :hover {
      opacity: 0.5; }

.alert-main {
  border-width: 0px; }

.alert-main .fly-out {
  padding-right: 1px !important; }

.error {
  background: #bb0000;
  border-color: #bb0000; }

.element-error {
  color: #bb0000;
  font-size: 14px;
  margin-top: 0px;
  display: inline-block; }

.oneprofile-ng-pageslide .element-error {
  color: #FB5F5F; }

.field-error {
  color: #bb0000; }

.field-error-input {
  background: #f5e5e5; }

.warning {
  background: #f4d62d;
  border-color: #f4d62d; }
  .warning span i {
    color: #444444;
    font-size: 20px; }

.information {
  background: #006ebd;
  border-color: #006ebd; }

.success {
  background: #2d8443;
  border-color: #2d8443; }

#simpleDialog1 {
  top: 20px !important; }
  #simpleDialog1 .modal-dialog {
    width: 97%;
    margin-left: 50px; }
    #simpleDialog1 .modal-dialog .modal-content {
      -webkit-border-radius: 0px;
      -moz-border-radius: 0px;
      -ms-border-radius: 0px;
      border-radius: 0px;
      -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
      -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
      padding: 0px;
      background: none;
      border-width: 0px; }
      #simpleDialog1 .modal-dialog .modal-content .modal-body {
        padding: 0px; }
        #simpleDialog1 .modal-dialog .modal-content .modal-body span a {
          cursor: pointer; }

.server-error {
  background: #fff;
  padding: 15px;
  display: block;
  width: 600px;
  margin: 0px auto;
  height: 210px; }
  .server-error h2 {
    margin-top: 0px;
    margin-bottom: 20px;
    font-size: 24px;
    color: #0263B2;
    font-weight: normal; }
  .server-error .server-error-left {
    text-align: center; }
    .server-error .server-error-left i {
      font-size: 130px;
      color: #cb3c38; }
  .server-error .server-error-right {
    border-left: 1px solid #eeeeee; }
    .server-error .server-error-right h3 {
      margin-top: 0px;
      font-size: 22px;
      color: #0263B2;
      font-weight: normal; }
    .server-error .server-error-right p {
      font-size: 14px;
      color: #767676; }
    .server-error .server-error-right .btn-action {
      background: #0263B2;
      padding: 10px 20px;
      margin: 0px 0px 0px 0px;
      border: 0px;
      min-width: 100px;
      border: #0263B2 solid 3px;
      font-size: 15px;
      text-transform: uppercase;
      color: #fff; }

.mandatory {
  color: #fb5f5f;
  font-size: 16px;
  margin: 0 0 0 3px; }

.mandatory-create-profile {
  color: #DF0606;
  font-size: 16px;
  margin: 0 0 0 3px; }

.profile-error {
  padding: 15px;
  display: block;
  margin: 0px auto; }
  .profile-error .row {
    margin: 0px;
    background: #fff;
    padding: 15px; }
    .profile-error .row .profile-error-left {
      text-align: center;
      width: 150px;
      border-right: 1px solid #eeeeee; }
      .profile-error .row .profile-error-left i {
        font-size: 130px;
        color: #cb3c38; }
    .profile-error .row .profile-error-right {
      width: calc(100% - 150px); }
      .profile-error .row .profile-error-right h3 {
        margin-top: 0px;
        font-size: 22px;
        color: #595959;
        font-weight: normal; }
      .profile-error .row .profile-error-right p {
        font-size: 14px;
        color: #767676; }
      .profile-error .row .profile-error-right .btn-action {
        background: #0263B2;
        padding: 0px 15px;
        margin: 0px 0px 0px 0px;
        border: 0px;
        min-width: 100px;
        border: #0263B2 solid 3px;
        font-size: 15px;
        text-transform: uppercase;
        color: #fff;
        height: 30px;
        line-height: 25px; }

/*@import'vx-grid/vx-grid';
@import'vx-grid/vx-grid-custom';*/
.block, .resource .profile-main .skill-inner-right .skill-inner-right-bg .standerdtimezone, .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections {
  display: block;
  overflow: hidden; }

.in-block, .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections ul li {
  display: block;
  overflow: hidden; }

.error i {
  color: #e04d4d;
  margin-right: 3px; }

.error span {
  color: #e04d4d;
  font-size: 13px;
  margin-top: 3px; }

.error input {
  background: #f4d6d6; }

/* sales skill rating variables */
/* sales skill rating variables | end */
.acccred-activeflag {
  font-size: 12px;
  position: relative;
  bottom: 10px;
  margin-left: 10px; }

.op-showexp {
  color: #0064c1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  height: 20px;
  font-size: 15px;
  margin: 3px 10px;
  text-decoration: underline; }
  .op-showexp a p {
    color: #0064c1; }

.resource {
  overflow: auto;
  background: #e9eef1 !important; }
  .resource select::-ms-expand {
    display: none; }
  .resource .accrediation-internal {
    font-weight: 400;
    color: #222;
    margin: 0px 12px; }
    .resource .accrediation-internal:before {
      content: "I";
      font-weight: bold;
      color: #fff;
      background: #680032;
      padding: 2px 8px;
      font-size: smaller;
      height: 25px;
      line-height: 25px;
      border: 1px solid #680032;
      margin-right: 5px; }
  .resource select {
    background: url("../../../../images/arrow_drop_down.png");
    background-size: auto;
    background-color: #fff;
    padding-right: 15px !important;
    -webkit-appearance: none;
    background-repeat: no-repeat;
    background-position: right; }
  @media (max-width: 685px) {
    .resource .profile-main .vx-grid-related .vxTable {
      table-layout: fixed;
      width: 1000px !important; } }
  .resource .profile-main .vxH100 {
    overflow: hidden; }
  .resource .profile-main .vx-grid-related .vxTable .vxHead th .dropdown ul.dropdown-menu li:focus {
    outline: 1px dotted #fff;
    margin: 1px; }
  .resource .profile-main .vx-grid-related .vxTable .vxHead th .dropdown {
    padding: 1px; }
  .resource .profile-main .vx-grid-related .vxTable .vxHead th .open {
    padding: 0px !important; }
  .resource .profile-main .vx-grid-related .vxTable .vxHead th .dropdown ul.dropdown-menu li input[type=checkbox]:focus {
    outline: 2px solid #fff; }
  .resource .profile-main .vx-grid-related .vxTable .vxBody .vxBodyRow td {
    border: none; }
    .resource .profile-main .vx-grid-related .vxTable .vxBody .vxBodyRow td .vxBodyRowCell.renderedDefn {
      padding: 0 20px; }
  .resource .profile-main .vx-grid-related .vxTable .vxBody .vxBodyRow.vs-repeat-repeated-element td {
    padding: 0 20px !important; }
    .resource .profile-main .vx-grid-related .vxTable .vxBody .vxBodyRow.vs-repeat-repeated-element td div {
      overflow: hidden; }
  .resource .profile-main *:focus {
    outline: 2px solid #000; }
  .resource .profile-main .border-red .form-control {
    border: 2px solid #fb5f5f !important; }
  .resource .profile-main .wa {
    width: auto; }
  .resource .profile-main .nav {
    margin-bottom: 15px;
    padding-left: 15px; }
  .resource .profile-main .nav-tabs > li > a {
    color: #333333;
    margin-right: 20px;
    line-height: 1.42857;
    font-weight: 400;
    font-size: medium;
    border: 0px;
    border-radius: 0px;
    background-color: transparent;
    padding: 5px 15px;
    cursor: pointer; }
  .resource .profile-main .nav-tabs > li.active > a, .resource .profile-main .nav-tabs > li.active > a:hover, .resource .profile-main .nav-tabs > li.active > a:focus {
    color: #0263B2;
    background-color: transparent;
    border: 0px;
    border-bottom: 5px solid #0263B2;
    cursor: default;
    font-weight: 400;
    font-size: medium; }
  .resource .profile-main h4 {
    text-transform: uppercase;
    color: #595959;
    margin-top: 0px; }
  .resource .profile-main .skill-top-section {
    padding-right: 0px !important;
    margin: 15px 0px; }
  @media (max-width: 911px) {
    .resource .profile-main .skill-inner-left {
      margin-bottom: 15px;
      padding-right: 0px !important; } }
  .resource .profile-main .skill-inner-left {
    padding-left: 0px;
    padding-right: 15px; }
    .resource .profile-main .skill-inner-left .skill-inner-left-bg-bottom {
      min-height: 453px;
      border: 1px solid #fff; }
      .resource .profile-main .skill-inner-left .skill-inner-left-bg-bottom .tag-parent {
        padding: 0px 0px 0px 15px;
        float: left;
        position: relative;
        /*.fixme{
                                height:90px;
                                overflow:hidden;
                            }*/ }
        .resource .profile-main .skill-inner-left .skill-inner-left-bg-bottom .tag-parent h5 {
          margin-bottom: 15px; }
        .resource .profile-main .skill-inner-left .skill-inner-left-bg-bottom .tag-parent .tag {
          background: #eee;
          border-radius: 15px;
          color: #000;
          float: left;
          margin: 0 5px 5px 0;
          padding: 2px 15px 3px 15px;
          text-transform: capitalize;
          font-size: 14px; }
        .resource .profile-main .skill-inner-left .skill-inner-left-bg-bottom .tag-parent .viewmore {
          position: absolute;
          right: 0px;
          bottom: 0px; }
    .resource .profile-main .skill-inner-left .skill-inner-left-bg {
      background: #fff;
      padding: 15px;
      border: 1px solid #fff; }
      .resource .profile-main .skill-inner-left .skill-inner-left-bg .area-expertise-left-top {
        border: 0px; }
        .resource .profile-main .skill-inner-left .skill-inner-left-bg .area-expertise-left-top h5 {
          text-transform: capitalize; }
        .resource .profile-main .skill-inner-left .skill-inner-left-bg .area-expertise-left-top p {
          text-transform: uppercase; }
      .resource .profile-main .skill-inner-left .skill-inner-left-bg .tag-parent {
        padding: 20px 120px 0px 15px;
        float: left;
        position: relative; }
        .resource .profile-main .skill-inner-left .skill-inner-left-bg .tag-parent h5 {
          margin-bottom: 15px; }
        .resource .profile-main .skill-inner-left .skill-inner-left-bg .tag-parent .tag {
          background: #eee;
          border-radius: 15px;
          color: #000;
          float: left;
          margin: 0 5px 5px 0;
          padding: 2px 15px 3px 15px;
          text-transform: capitalize;
          font-size: 14px; }
        .resource .profile-main .skill-inner-left .skill-inner-left-bg .tag-parent .viewmore {
          position: absolute;
          right: 0px;
          bottom: 0px; }
    .resource .profile-main .skill-inner-left .skill-left-top {
      border-bottom: solid 1px #ececec; }
      .resource .profile-main .skill-inner-left .skill-left-top .skill-left-top-name {
        float: left; }
      .resource .profile-main .skill-inner-left .skill-left-top img {
        float: left;
        margin-right: 15px;
        width: 60px;
        height: 60px;
        margin-top: 0;
        font-size: smaller; }
      .resource .profile-main .skill-inner-left .skill-left-top h2 {
        color: #595959;
        margin: 0;
        /*font-weight: 400;
                    font-size: medium;*/ }
      .resource .profile-main .skill-inner-left .skill-left-top p {
        color: #757575;
        font-weight: 400;
        font-size: 15px;
        margin: 0px;
        font-size: small; }
    .resource .profile-main .skill-inner-left .area-expertise-left-bottom .information-field {
      display: inline-block;
      min-width: 130px; }
    .resource .profile-main .skill-inner-left .skill-left-bottom .basic-profile-bpid-sync {
      background: #fff;
      color: #337ab7;
      font-weight: 600;
      border: 0;
      margin-left: 25px; }
    .resource .profile-main .skill-inner-left .skill-left-bottom .primary-details-row {
      min-height: 55px; }
    .resource .profile-main .skill-inner-left .skill-left-bottom .basic-information-elment-space {
      margin-bottom: 15px; }
    .resource .profile-main .skill-inner-left .skill-left-bottom h3 {
      color: #595959;
      text-transform: uppercase;
      margin-top: 0px;
      margin-bottom: 25px;
      /*font-weight: 600;
                    font-size: medium;*/ }
    .resource .profile-main .skill-inner-left .skill-left-bottom span {
      text-transform: uppercase;
      font-weight: 400;
      color: #757575;
      font-size: small;
      margin-bottom: 0; }
    .resource .profile-main .skill-inner-left .skill-left-bottom .basic-profile-url {
      font-size: small;
      font-weight: 600;
      width: 100%;
      margin: 0px; }
      .resource .profile-main .skill-inner-left .skill-left-bottom .basic-profile-url input[type=url] {
        border: 0px;
        color: #0263B2;
        display: inline-block;
        border-right: 0px;
        padding-right: 15px;
        float: left;
        max-width: calc(100% - 43px) !important;
        width: inherit; }
    .resource .profile-main .skill-inner-left .skill-left-bottom .basic-profile-url-a {
      /*padding-left: 24px;
                    padding-left: 0px;*/
      line-height: 38px;
      top: -10px;
      padding: 0px 10px;
      background: transparent;
      color: #a9acae;
      border: 1px solid #a9acae;
      float: left;
      width: 35px; }
      .resource .profile-main .skill-inner-left .skill-left-bottom .basic-profile-url-a .icon-layout {
        line-height: 9px;
        font-size: 18px; }
    .resource .profile-main .skill-inner-left .skill-left-bottom p {
      font-weight: 400;
      font-size: medium; }
      .resource .profile-main .skill-inner-left .skill-left-bottom p a {
        color: #0263B2;
        word-wrap: break-word;
        display: block; }
  .resource .profile-main ul.ui-select-choices.ui-select-choices-content.ui-select-dropdown.dropdown-menu {
    padding: 0px;
    border: 0px;
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-all;
    min-width: 100px;
    font-size: 12px;
    height: 200px;
    max-height: calc(100vh - 130px); }
  .resource .profile-main .ui-select-bootstrap .ui-select-choices-row.active > span {
    background-color: #3276b1; }
  .resource .profile-main .ui-select-bootstrap .ui-select-choices-row > span {
    white-space: normal;
    padding-left: 3px;
    padding-right: 3px; }
    .resource .profile-main .ui-select-bootstrap .ui-select-choices-row > span > div {
      max-width: calc(100vw - 36px); }
  @media (max-width: 768px) {
    .resource .profile-main span.btn.btn-default.form-control.ui-select-toggle {
      width: 100%; }
    .resource .profile-main .dropdown.edit-input.ui-select-bootstrap.ui-select-container {
      width: 100%; } }
  .resource .profile-main .ui-select-bootstrap {
    margin-top: 5px;
    margin-bottom: 15px; }
  .resource .profile-main .ui-select-bootstrap .ui-select-match {
    width: 80%; }
  .resource .profile-main .ui-select-bootstrap .ui-select-match > span.btn {
    overflow: hidden;
    white-space: normal; }
  .resource .profile-main span.btn.btn-default.form-control.ui-select-toggle {
    overflow: hidden;
    font-weight: 500;
    height: 36px;
    font-size: medium;
    padding: 0 10px;
    line-height: 36px;
    border: 1px solid #595959;
    color: #595959;
    background: none;
    text-shadow: none; }
  .resource .profile-main .area-expertise-certification {
    padding-top: 15px; }
    .resource .profile-main .area-expertise-certification .area-expertise-certification-container {
      padding: 0px 15px; }
    @media (max-width: 767px) {
      .resource .profile-main .area-expertise-certification .btn.sample {
        line-height: 40px;
        padding: 0 15px;
        margin: 5px 5px 0 0; }
      .resource .profile-main .area-expertise-certification .container-fluid.btns {
        height: 100px; }
      .resource .profile-main .area-expertise-certification .vxSample {
        height: 100%;
        margin: 0 auto; }
        .resource .profile-main .area-expertise-certification .vxSample .vxH100 {
          overflow-x: hidden; } }
    @media (min-width: 968px) {
      .resource .profile-main .area-expertise-certification .btn.sample {
        line-height: 40px;
        padding: 0 15px;
        margin: 10px 10px 0 0; }
      .resource .profile-main .area-expertise-certification .container-fluid.btns {
        height: 60px; }
      .resource .profile-main .area-expertise-certification .vxSample {
        height: auto !important;
        margin: 0px 0px 15px 0px !important; }
        .resource .profile-main .area-expertise-certification .vxSample .pad0 {
          max-height: 326px;
          padding: 2px !important; }
        .resource .profile-main .area-expertise-certification .vxSample .vxH100 .vxTableScrollContainer .vxTableContainer .vxTable .vxBody tr td .icon-container-layout:focus {
          margin: 2px; } }
    .resource .profile-main .area-expertise-certification .vx-grid-related .vxTable {
      border: none; }
    .resource .profile-main .area-expertise-certification .vx-grid-related .vxTableContainer.scrollDupHeader.scrollDupHeaderAdded {
      top: 0 !important; }
  .resource .profile-main .area-expertise-accrediation {
    padding-top: 15px; }
    .resource .profile-main .area-expertise-accrediation .area-expertise-accrediation-container {
      padding: 0px 15px; }
      .resource .profile-main .area-expertise-accrediation .area-expertise-accrediation-container h3 {
        margin: 0px; }
    @media (max-width: 767px) {
      .resource .profile-main .area-expertise-accrediation .btn.sample {
        line-height: 40px;
        padding: 0 15px;
        margin: 5px 5px 0 0; }
      .resource .profile-main .area-expertise-accrediation .container-fluid.btns {
        height: 100px; }
      .resource .profile-main .area-expertise-accrediation .vxSample {
        height: 100%;
        margin: 0 auto; }
        .resource .profile-main .area-expertise-accrediation .vxSample .vxH100 {
          overflow-x: hidden; } }
    @media (min-width: 968px) {
      .resource .profile-main .area-expertise-accrediation .btn.sample {
        line-height: 40px;
        padding: 0 15px;
        margin: 10px 10px 0 0; }
      .resource .profile-main .area-expertise-accrediation .container-fluid.btns {
        height: 60px; }
      .resource .profile-main .area-expertise-accrediation .vxSample {
        height: auto !important;
        margin: 0px 0px 15px 0px !important; }
        .resource .profile-main .area-expertise-accrediation .vxSample .pad0 {
          max-height: 326px;
          padding: 2px !important; } }
    .resource .profile-main .area-expertise-accrediation .vx-grid-related .vxTableContainer.scrollDupHeader.scrollDupHeaderAdded {
      top: 0 !important; }
  .resource .profile-main .basic-information .skill-inner-right-bg {
    min-height: 453px; }
  .resource .profile-main .area-expertise {
    padding: 10px 0px; }
    .resource .profile-main .area-expertise .vx-grid-related .vxTableContainer.scrollTableContainer {
      max-height: calc(100vh - 55vh);
      overflow-y: scroll; }
    .resource .profile-main .area-expertise .vx-grid-related .vxTable .vxHead th span.noDdTitle {
      padding-left: 0px; }
    .resource .profile-main .area-expertise .vx-grid-related .vxTable .vxHead th .dropdown .dropdown-toggle span.colTitle {
      padding-left: 0px;
      line-height: 57px;
      width: calc(100% - 25px); }
    .resource .profile-main .area-expertise .vx-grid-related .vxTable .vxHead th .dropdown ul.dropdown-menu {
      overflow-x: hidden; }
    .resource .profile-main .area-expertise .vx-grid-related .vxTable .vxHead th .dropdown .dropdown-toggle {
      padding: 0px 20px; }
      .resource .profile-main .area-expertise .vx-grid-related .vxTable .vxHead th .dropdown .dropdown-toggle:active, .resource .profile-main .area-expertise .vx-grid-related .vxTable .vxHead th .dropdown .dropdown-toggle:focus {
        outline-color: #000;
        outline-style: dotted;
        outline-offset: -5px;
        outline-width: thin; }
    .resource .profile-main .area-expertise .vx-grid-related .vxTable .vxHead th .open .dropdown-toggle {
      padding: 0px 20px; }
      .resource .profile-main .area-expertise .vx-grid-related .vxTable .vxHead th .open .dropdown-toggle:active, .resource .profile-main .area-expertise .vx-grid-related .vxTable .vxHead th .open .dropdown-toggle:focus {
        outline-color: #fff !important; }
    .resource .profile-main .area-expertise .area-expertise-skill i {
      background: none !important;
      line-height: 0px !important;
      font-size: 0.75em;
      margin-left: 12px;
      border: 0px !important; }
    .resource .profile-main .area-expertise .edit-icon .icon-container-layout {
      font-size: 1em;
      margin-right: 15px; }
      .resource .profile-main .area-expertise .edit-icon .icon-container-layout:hover {
        font-size: 1em;
        margin-right: 15px; }
        .resource .profile-main .area-expertise .edit-icon .icon-container-layout:hover i {
          width: 46px;
          line-height: 36px;
          top: 0px;
          margin-right: 1px; }
      .resource .profile-main .area-expertise .edit-icon .icon-container-layout i {
        width: 46px;
        line-height: 36px;
        top: 0px;
        margin-right: 1px; }
    .resource .profile-main .area-expertise .edit-icon input[type="text"] {
      margin-top: 0px !important;
      margin-bottom: 0px !important; }
  .resource .profile-main .skill-inner-right {
    padding-right: 0px;
    padding-left: 0px; }
    .resource .profile-main .skill-inner-right .cp-input .a11y-type-ahead {
      height: auto; }
    .resource .profile-main .skill-inner-right .skill-inner-right-bg {
      border: 1px solid #fff;
      padding-top: 10px;
      background: #fff;
      /*min-height: 453px;*/ }
      .resource .profile-main .skill-inner-right .skill-inner-right-bg .area-expertise-down-inner .travel-detail-sections li {
        border-right: 1px solid #fff; }
      .resource .profile-main .skill-inner-right .skill-inner-right-bg .manage-certification {
        padding: 0px; }
      .resource .profile-main .skill-inner-right .skill-inner-right-bg .area-expertise-inner .manage-accrediation-link {
        font-size: medium;
        font-weight: 400;
        line-height: 0px; }
      .resource .profile-main .skill-inner-right .skill-inner-right-bg .area-expertise-inner .manage-certification-link {
        font-size: medium;
        font-weight: 400;
        float: right; }
      .resource .profile-main .skill-inner-right .skill-inner-right-bg .area-expertise-inner a {
        color: #0263B2;
        padding-left: 14px; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .area-expertise-inner a .icon-container-layout:hover {
          background: #fff; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .area-expertise-inner a:hover {
          text-decoration: none; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .area-expertise-inner a .icon-container-layout {
          font-size: 1em; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .area-expertise-inner a span {
          display: inline-block;
          margin: 5px 0px 0px 0px; }
      .resource .profile-main .skill-inner-right .skill-inner-right-bg .standard-time-zone {
        margin-bottom: 15px; }
      .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .secondary-heading {
        margin-top: 5px;
        margin-bottom: 25px; }
      .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .manageSkills {
        color: #0263B2;
        font-weight: 400; }
      .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .add-certification-button {
        color: #0263B2;
        /*margin: $mar + $mar + $mar $no-mar $mar + 5;*/ }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .add-certification-button .col-md-4 h5 {
          margin: 0px; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .add-certification-button .mcid-add .mcid-search {
          float: right;
          width: 140px; }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .add-certification-button .mcid-add .mcid-search .element-error {
            width: 179px;
            position: relative; }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .add-certification-button .mcid-add .mcid-search .cursor-pointer {
            cursor: pointer; }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .add-certification-button .mcid-add .mcid-search .cursor-default {
            cursor: default; }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .add-certification-button .mcid-add .mcid-search .blur-mcid {
            opacity: 0.5; }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .add-certification-button .mcid-add .mcid-search .mcid-alert {
            position: relative;
            right: 0px;
            width: 187px; }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .add-certification-button .mcid-add .mcid-search input {
            background-color: #e4e6e7;
            border: 1px solid #e4e6e7;
            -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
            -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
            box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
            color: #666666;
            width: 110px;
            font-size: 14px;
            font-style: italic; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .add-certification-button .mcid-add .blur-mcid {
          opacity: 0.5; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .add-certification-button .mcid-add .mcid-value {
          float: left;
          width: auto;
          margin: 0px 0px 0px 15px; }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .add-certification-button .mcid-add .mcid-value .cursor-default {
            cursor: default; }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .add-certification-button .mcid-add .mcid-value .cursor-pointer {
            cursor: pointer; }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .add-certification-button .mcid-add .mcid-value span {
            background-color: #e4e6e7;
            border: 1px solid transparent;
            color: #666;
            width: auto;
            float: left;
            line-height: 30px;
            padding: 0px 10px;
            height: 30px;
            font-size: 14px; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .add-certification-button .mcid-add .icon-container-layout {
          font-size: 1em;
          height: 30px;
          width: 30px;
          margin-right: 0px;
          background: #0263B2;
          border: 1px solid #0263B2;
          color: #fff;
          font-size: 14px;
          padding: 7px 9px; }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .add-certification-button .mcid-add .icon-container-layout:hover {
            background: #0263B2;
            color: #fff; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .add-certification-button .form-control {
          width: 56%;
          height: 30px;
          padding: 6px 12px;
          font-size: 14px;
          line-height: 1.42857;
          float: left;
          border-radius: 0px;
          border: 1px solid; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .add-certification-button .dropdown-menu {
          border-radius: 0px; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .add-certification-button .icon-container-layout {
          font-size: 1em;
          height: 1px;
          width: 1px;
          margin-right: 30px; }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .add-certification-button .icon-container-layout:hover {
            background: none; }
      @media screen and (-ms-contrast: active) {
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .accrediation-master {
          font-size: medium;
          font-weight: 400; }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .accrediation-master:before {
            content: "T";
            color: #fff;
            background: #00a04a;
            padding: 0px 4px;
            font-size: smaller;
            height: 25px;
            line-height: 25px;
            margin: 0px 5px;
            border: 0px !important;
            /*border: 5px solid #fff;
                            border-width: 0px 5px 0px 0px;*/ }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .accrediation-lead {
          font-size: medium;
          font-weight: 400; }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .accrediation-lead:before {
            content: "L";
            color: #fff;
            background: #2e4894;
            padding: 0px 4px;
            font-size: smaller;
            height: 25px;
            line-height: 25px;
            margin: 0px 5px;
            border: 0px !important;
            /*border: 5px solid #fff;
                            border-width: 0px 5px 0px 20px;*/ }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .accrediation-mentor {
          font-size: medium;
          font-weight: 400; }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .accrediation-mentor:before {
            content: "M";
            color: #fff;
            background: #f96505;
            padding: 0px 4px;
            font-size: smaller;
            height: 25px;
            line-height: 25px;
            margin: 0px 5px;
            border: 0px !important;
            /*border: 5px solid #fff;
                            border-width: 0px 5px 0px 20px;*/ } }
      @media screen and (-ms-high-contrast: black-on-white) {
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .accrediation-master {
          font-size: medium;
          font-weight: 400; }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .accrediation-master:before {
            content: "T";
            color: #fff;
            background: #00a04a;
            padding: 0px 4px;
            font-size: smaller;
            height: 25px;
            line-height: 25px;
            margin: 0px 5px;
            border: 0px !important;
            /*border: 5px solid #fff;
                            border-width: 0px 5px 0px 0px;*/ }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .accrediation-professional {
          font-size: medium;
          font-weight: 400; }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .accrediation-professional:before {
            content: "I";
            color: #000 !important;
            background: #fff !important;
            padding: 2px 8px;
            font-size: smaller;
            height: 25px;
            line-height: 25px;
            margin: 0px 5px;
            border: 1px #000 solid;
            font-weight: bold;
            /*border: 5px solid #fff;
                            border-width: 0px 5px 0px 0px;*/ }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .accrediation-lead {
          font-size: medium;
          font-weight: 400; }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .accrediation-lead:before {
            content: "L";
            color: #fff;
            background: #2e4894;
            padding: 0px 4px;
            font-size: smaller;
            height: 25px;
            line-height: 25px;
            margin: 0px 5px;
            border: 0px !important;
            /*border: 5px solid #fff;
                            border-width: 0px 5px 0px 20px;*/ }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .accrediation-mentor {
          font-size: medium;
          font-weight: 400; }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .accrediation-mentor:before {
            content: "M";
            color: #fff;
            background: #f96505;
            padding: 0px 4px;
            font-size: smaller;
            height: 25px;
            line-height: 25px;
            margin: 0px 5px;
            border: 0px !important;
            /*border: 5px solid #fff;
                            border-width: 0px 5px 0px 20px;*/ } }
      @media screen and (-ms-high-contrast: white-on-black) {
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .accrediation-master {
          font-size: medium;
          font-weight: 400; }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .accrediation-master:before {
            content: "T";
            color: #fff;
            background: #00a04a;
            padding: 0px 4px;
            font-size: smaller;
            height: 25px;
            line-height: 25px;
            margin: 0px 5px;
            border: 0px !important;
            /*border: 5px solid #fff;
                            border-width: 0px 5px 0px 0px;*/ }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .accrediation-professional {
          font-size: medium;
          font-weight: 400; }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .accrediation-professional:before {
            content: "I";
            color: #fff !important;
            background: #000 !important;
            padding: 0px 4px;
            font-size: smaller;
            height: 25px;
            line-height: 25px;
            margin: 0px 5px;
            border: 1px #fff solid;
            /*border: 5px solid #fff;
                            border-width: 0px 5px 0px 0px;*/ }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .accrediation-lead {
          font-size: medium;
          font-weight: 400; }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .accrediation-lead:before {
            content: "L";
            color: #fff;
            background: #2e4894;
            padding: 0px 4px;
            font-size: smaller;
            height: 25px;
            line-height: 25px;
            margin: 0px 5px;
            border: 0px !important;
            /*border: 5px solid #fff;
                            border-width: 0px 5px 0px 20px;*/ }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .accrediation-mentor {
          font-size: medium;
          font-weight: 400; }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .accrediation-mentor:before {
            content: "M";
            color: #fff;
            background: #f96505;
            padding: 0px 4px;
            font-size: smaller;
            height: 25px;
            line-height: 25px;
            margin: 0px 5px;
            border: 0px !important;
            /*border: 5px solid #fff;
                            border-width: 0px 5px 0px 20px;*/ } }
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading {
          /* IE10+ CSS styles go here */ }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .accrediation-master {
            font-size: medium;
            font-weight: 400; }
            .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .accrediation-master:before {
              content: "T";
              color: #fff;
              background: #00a04a;
              padding: 0px 4px;
              font-size: smaller;
              height: 25px;
              line-height: 25px;
              margin: 0px 5px;
              border: 0px !important;
              /*border: 5px solid #fff;
                            border-width: 0px 5px 0px 0px;*/ }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .accrediation-professional {
            font-size: medium;
            font-weight: 400; }
            .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .accrediation-professional:before {
              content: "I";
              color: #fff;
              background: #680032;
              padding: 0px 4px;
              font-size: smaller;
              height: 25px;
              line-height: 25px;
              margin: 0px 5px;
              border: 0px;
              /*border: 5px solid #fff;
                            border-width: 0px 5px 0px 0px;*/ }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .accrediation-lead {
            font-size: medium;
            font-weight: 400; }
            .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .accrediation-lead:before {
              content: "L";
              color: #fff;
              background: #2e4894;
              padding: 0px 4px;
              font-size: smaller;
              height: 25px;
              line-height: 25px;
              margin: 0px 5px;
              border: 0px !important;
              /*border: 5px solid #fff;
                            border-width: 0px 5px 0px 20px;*/ }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .accrediation-mentor {
            font-size: medium;
            font-weight: 400; }
            .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .accrediation-mentor:before {
              content: "M";
              color: #fff;
              background: #f96505;
              padding: 0px 4px;
              font-size: smaller;
              height: 25px;
              line-height: 25px;
              margin: 0px 5px;
              border: 0px !important;
              /*border: 5px solid #fff;
                            border-width: 0px 5px 0px 20px;*/ } }
      @supports (-ms-accelerator: true) {
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading {
          /* IE Edge 12+ CSS styles go here */ }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .accrediation-master {
            font-size: medium;
            font-weight: 400; }
            .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .accrediation-master:before {
              content: "T";
              color: #fff;
              background: #00a04a;
              padding: 0px 4px;
              font-size: smaller;
              height: 25px;
              line-height: 25px;
              margin: 0px 5px;
              border: 0px !important;
              /*border: 5px solid #fff;
                            border-width: 0px 5px 0px 0px;*/ }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .accrediation-professional {
            font-size: medium;
            font-weight: 400; }
            .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .accrediation-professional:before {
              content: "I";
              color: #fff;
              background: #680032;
              padding: 0px 4px;
              font-size: smaller;
              height: 25px;
              line-height: 25px;
              margin: 0px 5px;
              border: 0px !important;
              /*border: 5px solid #fff;
                            border-width: 0px 5px 0px 0px;*/ }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .accrediation-lead {
            font-size: medium;
            font-weight: 400; }
            .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .accrediation-lead:before {
              content: "L";
              color: #fff;
              background: #2e4894;
              padding: 0px 4px;
              font-size: smaller;
              height: 25px;
              line-height: 25px;
              margin: 0px 5px;
              border: 0px !important;
              /*border: 5px solid #fff;
                            border-width: 0px 5px 0px 20px;*/ }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .accrediation-mentor {
            font-size: medium;
            font-weight: 400; }
            .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .accrediation-mentor:before {
              content: "M";
              color: #fff;
              background: #f96505;
              padding: 0px 4px;
              font-size: smaller;
              height: 25px;
              line-height: 25px;
              margin: 0px 5px;
              border: 0px !important;
              /*border: 5px solid #fff;
                            border-width: 0px 5px 0px 20px;*/ } }
      .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .accrediation-master {
        font-size: medium;
        font-weight: 400; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .accrediation-master:before {
          content: "T";
          color: #fff;
          background: #00a04a;
          padding: 0px 4px;
          font-size: smaller;
          height: 25px;
          line-height: 25px;
          border: 5px solid #fff;
          border-width: 0px 5px 0px 0px; }
      .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .accrediation-professional {
        font-size: medium;
        font-weight: 400; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .accrediation-professional:before {
          content: "I";
          color: #fff;
          background: #680032;
          padding: 0px 4px;
          font-size: smaller;
          height: 25px;
          line-height: 25px;
          border: 5px solid #fff;
          border-width: 0px 5px 0px 0px; }
      .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .accrediation-lead {
        font-size: medium;
        font-weight: 400; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .accrediation-lead:before {
          content: "L";
          color: #fff;
          background: #2e4894;
          padding: 0px 4px;
          font-size: smaller;
          height: 25px;
          line-height: 25px;
          border: 5px solid #fff;
          border-width: 0px 5px 0px 20px; }
      .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .accrediation-mentor {
        font-size: medium;
        font-weight: 400; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .accrediation-mentor:before {
          content: "M";
          color: #fff;
          background: #f96505;
          padding: 0px 4px;
          font-size: smaller;
          height: 25px;
          line-height: 25px;
          border: 5px solid #fff;
          border-width: 0px 5px 0px 20px; }
      .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .edit-icon .icon-container-layout {
        border: 0.5px solid #e9eef1;
        padding: 11px 14px;
        margin-right: 1px;
        margin-top: 1px; }
      .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .edit-icon .iconDisableoutline {
        outline: none; }
      .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .edit-icon .iconDisable {
        opacity: 0.5;
        cursor: default !important; }
      .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .edit-icon i {
        background: transparent;
        color: #0263B2; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .edit-icon i:hover {
          cursor: pointer; }
      .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .edit-icon .pencil {
        background: transparent;
        color: #0263B2; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-inner-right-bg-heading .edit-icon .pencil:hover {
          cursor: pointer; }
      .resource .profile-main .skill-inner-right .skill-inner-right-bg .profile-edit {
        padding-left: 40px; }
      .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-head-color {
        color: #717171;
        margin-bottom: 5px;
        font-size: small; }
      .resource .profile-main .skill-inner-right .skill-inner-right-bg div .sub-title {
        font-size: medium;
        font-weight: 400; }
      .resource .profile-main .skill-inner-right .skill-inner-right-bg h3 {
        color: #595959;
        /*font-weight: 600;
                    font-size: medium;*/ }
      .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel .show-Cursor:hover {
        cursor: pointer; }
      .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel .standard-time-zone {
        height: auto; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel .standard-time-zone div {
          background: rgba(182, 31, 26, 0.1);
          width: 95%;
          display: block;
          padding: 5px;
          text-align: left; }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel .standard-time-zone div i {
            background: #bb0000;
            height: 40px;
            width: 10px;
            display: inline-block; }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel .standard-time-zone div span {
            background: 0 0;
            display: inline-block;
            vertical-align: top;
            overflow: hidden;
            text-overflow: ellipsis;
            word-wrap: break-word;
            margin: 0 0 0 5px;
            width: calc(98% - 11px); }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel .standard-time-zone select {
          width: 36%;
          font-weight: 500;
          height: 36px;
          font-size: medium;
          padding: 0 10px;
          line-height: 36px;
          margin-top: 5px;
          margin-bottom: 10px;
          border: 1px solid #595959;
          color: #595959;
          text-transform: capitalize; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel .standard-time-zone p {
          font-weight: 600; }
      .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel .add-standerdtimezone:hover {
        color: #0263B2;
        cursor: pointer; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel .add-standerdtimezone:hover .icon-add {
          background: #0263B2 !important;
          color: #fff; }
      @media screen and (-ms-high-contrast: active) {
        .resource .profile-main .skill-inner-right .skill-inner-right-bg select {
          background: url("../../../../images/arrow_drop_down.png") right no-repeat;
          background-color: #000;
          padding-right: 15px !important; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel .add-standerdtimezone:hover {
          color: #0263B2;
          cursor: pointer; }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel .add-standerdtimezone:hover .icon-add {
            background: #0263B2 !important;
            color: #fff; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel .standerdtimezone i {
          background: #ececec;
          text-align: center;
          line-height: 33px;
          border: 1px solid #ececec;
          color: black; } }
      @media screen and (-ms-high-contrast: black-on-white) {
        .resource .profile-main .skill-inner-right .skill-inner-right-bg select {
          background: url("../../../../images/arrow_drop_down.png") right no-repeat;
          background-color: #fff !important;
          padding-right: 15px !important; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel .add-standerdtimezone:hover {
          color: #0263B2;
          cursor: pointer; }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel .add-standerdtimezone:hover .icon-add {
            background: #0263B2 !important;
            color: #fff; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel .standerdtimezone i {
          background: #ececec;
          text-align: center;
          line-height: 33px;
          border: 1px solid #ececec;
          color: black; } }
      @media screen and (-ms-high-contrast: white-on-black) {
        .resource .profile-main .skill-inner-right .skill-inner-right-bg select {
          background: url("../../../../images/arrow_drop_down.png") right no-repeat;
          background-color: #000;
          padding-right: 15px !important; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel .add-standerdtimezone:hover {
          color: #0263B2;
          cursor: pointer; }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel .add-standerdtimezone:hover .icon-add {
            background: #0263B2 !important;
            color: #fff; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel .standerdtimezone i {
          background: #ececec;
          text-align: center;
          line-height: 33px;
          border: 1px solid #ececec;
          color: black; } }
      @media (-ms-high-contrast: none) {
        .resource .profile-main .skill-inner-right .skill-inner-right-bg {
          /* IE10+ CSS styles go here */ }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg select {
            background: url("../../../../images/arrow_drop_down.png") right no-repeat;
            background-color: #fff;
            padding-right: 15px !important; } }
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .resource .profile-main .skill-inner-right .skill-inner-right-bg {
          /* IE10+ CSS styles go here */
          /*select{
                 background: url("../../../../images/arrow_drop_down.png") right no-repeat;
                    background-color:$white-bg-color;
                    padding-right:15px !important;
                }*/ }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel .add-standerdtimezone:hover {
            color: #0263B2;
            cursor: pointer; }
            .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel .add-standerdtimezone:hover .icon-add {
              background: #0263B2 !important;
              color: #fff; }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel .standerdtimezone i {
            background: #ececec;
            text-align: center;
            line-height: 33px;
            border: 1px solid #ececec;
            color: black; } }
      @supports (-ms-accelerator: true) {
        .resource .profile-main .skill-inner-right .skill-inner-right-bg {
          /* IE Edge 12+ CSS styles go here */ }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg select {
            background: url("../../../../images/arrow_drop_down.png") right no-repeat;
            background-color: #fff;
            padding-right: 15px !important; }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel .add-standerdtimezone:hover {
            color: #0263B2;
            cursor: pointer; }
            .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel .add-standerdtimezone:hover .icon-add {
              background: #0263B2 !important;
              color: #fff; }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel .standerdtimezone i {
            background: #ececec;
            text-align: center;
            line-height: 33px;
            border: 1px solid #ececec;
            color: black; } }
      .resource .profile-main .skill-inner-right .skill-inner-right-bg select,
      .resource .profile-main .skill-inner-right .skill-inner-right-bg input[type="text"].edit-input, .resource .profile-main .skill-inner-right .skill-inner-right-bg input[type="url"].edit-input {
        font-weight: 500;
        width: 80%;
        height: 36px;
        font-size: medium;
        padding: 0 10px;
        line-height: 36px;
        margin-top: 5px;
        margin-bottom: 15px;
        border: 1px solid #595959;
        color: #595959;
        max-width: 80%;
        /*background: url("../../../../images/arrow_drop_down.png") right no-repeat;
                    background-color:$white-bg-color;*/ }
      .resource .profile-main .skill-inner-right .skill-inner-right-bg .standerdtimezone {
        margin: 0px;
        padding: 0px;
        line-height: 20px;
        margin-bottom: 15px;
        font-weight: bold;
        font-size: 12px;
        cursor: default; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .standerdtimezone .setting-icon {
          background: #fff; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .standerdtimezone .icon-container-layout {
          width: 45px;
          height: 45px; }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .standerdtimezone .icon-container-layout:hover {
            background: #fff; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .standerdtimezone .col-md-10 span {
          font-weight: 400;
          font-size: 13px;
          display: inline-block; }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .standerdtimezone .col-md-10 span.title {
            text-transform: capitalize;
            font-size: 16px;
            width: 100%;
            font-weight: 500;
            word-break: break-all; }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .standerdtimezone .col-md-10 span.sub-title {
            color: #5c5c5c;
            font-size: 12px; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .standerdtimezone .col-md-8 span {
          font-weight: 600;
          font-size: 14px;
          text-transform: capitalize; }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .standerdtimezone .col-md-8 span.title {
            text-transform: capitalize; }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .standerdtimezone .col-md-8 span.sub-title {
            color: #5c5c5c;
            font-size: 12px; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .standerdtimezone i {
          background: #ececec;
          text-align: center;
          line-height: 33px;
          border: 1px solid #ececec; }
      .resource .profile-main .skill-inner-right .skill-inner-right-bg ul.standerdtimezone li img {
        margin: 0 15px 0 0;
        float: left; }
      .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections {
        padding-bottom: 15px; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections ul {
          margin: 0;
          padding: 0;
          list-style: none; }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections ul li {
            float: left;
            border-right: 1px solid #e1e1e1;
            padding: 15px 15px 0px 15px;
            /*min-height: 280px;*/
            margin-bottom: 15px;
            /*.bgcom-main{
                                position:relative;

                                input[type=checkbox]{
                                    position:absolute;
                                    opacity:0;
                                    width:22px;
                                    height:22px;
                                    z-index:9;
                                    top:-3px;

                                    &:focus {
                                        opacity:0.5;
                                        outline: 1px dotted #666666;
                                    }
                                    
                                }

                            }*/ }
            .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections ul li:last-child {
              border-right: none; }
            .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections ul li .travel-background-check {
              background: #ececec;
              height: 21px;
              line-height: 19px;
              width: 21px;
              font-size: large;
              display: inline-block;
              margin-right: 10px;
              border: 1px solid #ececec; }
            .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections ul li .background-check-content {
              font-size: medium;
              font-weight: 400;
              vertical-align: top; }
            .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections ul li h4.travel-head-color {
              margin-bottom: 10px;
              margin-top: 0px;
              /*font-size: medium;
                                font-weight: 400;*/
              letter-spacing: 1px; }
            .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections ul li .standerdtimezone .icon-skills {
              font-size: 33px;
              font-weight: 100;
              background: none;
              border: none;
              color: #a1a1a1; }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections ul li.active {
            background: #fafafa; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections ul.top-list {
          display: flex; }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections ul.top-list .travel-checkbox input[type=checkbox] {
            opacity: 0; }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections ul.top-list .travel-checkbox .checkbox-outline {
            outline: 1px dotted #000; }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections ul.top-list .travelButton {
            float: left;
            width: 100%;
            display: block;
            background-color: #fff;
            border: none; }
            .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections ul.top-list .travelButton .addInfoButton {
              float: left; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections .top-list li {
          width: 100%; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections ul.top-list.gird-flex {
          flex-wrap: wrap; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections .gird-flex li {
          width: 25% !important; }
      @media screen and (max-width: 1250px) {
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .bdright .Profile-flag {
          float: none !important;
          display: block !important; } }
      .resource .profile-main .skill-inner-right .skill-inner-right-bg .bdright .edit-Profile-flag-list {
        display: block;
        float: left;
        width: 100%; }
      .resource .profile-main .skill-inner-right .skill-inner-right-bg .bdright .edit-Profile-flag {
        margin-bottom: 80px; }
      .resource .profile-main .skill-inner-right .skill-inner-right-bg .bdright .Profile-flag {
        width: 55px;
        height: 42px;
        float: left;
        margin-right: 15px;
        border: 0px;
        border-radius: 0px; }
      .resource .profile-main .skill-inner-right .skill-inner-right-bg .bdright .sections {
        border-right: solid 1px #ccc;
        min-height: 285px;
        margin-bottom: 20px; }
      .resource .profile-main .skill-inner-right .skill-inner-right-bg .bdright .sections.last {
        border-right: none; }
      .resource .profile-main .skill-inner-right .skill-inner-right-bg .weekly-shift {
        margin-bottom: 10px;
        padding-left: 0px;
        padding-top: 15px; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .weekly-shift .w-indicator {
          position: relative;
          color: #0000C0;
          font-size: 12px !important;
          font-weight: 500;
          padding: 0px;
          bottom: 16px;
          left: 16px;
          background: #BAEBEF;
          border: 1px solid #BAEBEF;
          width: 18px;
          display: inline-block; }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .weekly-shift .w-indicator::before {
            content: 'W'; }
      .resource .profile-main .skill-inner-right .skill-inner-right-bg .btn-grm.active.holiday .w-indicator {
        background: #FF7F27;
        border: 1px solid #FF7F27; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .btn-grm.active.holiday .w-indicator::before {
          content: 'H'; }
      .resource .profile-main .skill-inner-right .skill-inner-right-bg .weekLabel .popover.top {
        margin-left: 0px !important; }
      .resource .profile-main .skill-inner-right .skill-inner-right-bg .weekLabel label {
        margin-top: 10px;
        text-transform: uppercase;
        font-weight: 400;
        color: #757575;
        font-size: small;
        letter-spacing: 1px; }
      .resource .profile-main .skill-inner-right .skill-inner-right-bg .weekLabel .weeklyLabel {
        float: right;
        margin-top: 7px; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .weekLabel .weeklyLabel span {
          color: #595959;
          font-size: 12px;
          margin-right: 17px; }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .weekLabel .weeklyLabel span .working, .resource .profile-main .skill-inner-right .skill-inner-right-bg .weekLabel .weeklyLabel span .nonWorking {
            min-width: 10px;
            min-height: 10px;
            margin-right: 5px;
            display: inline-block;
            color: #0000C0;
            font-weight: 500;
            padding: 0 3px; }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .weekLabel .weeklyLabel span .working {
            background-color: #BAEBEF;
            border: 1px solid #BAEBEF; }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .weekLabel .weeklyLabel span .nonWorking {
            background-color: #FF7F27;
            border: 1px solid #FF7F27; }
      .resource .profile-main .skill-inner-right .skill-inner-right-bg .popoverContent {
        outline-color: #fff; }
      .resource .profile-main .skill-inner-right .skill-inner-right-bg #ReportsToIcon {
        margin-top: -12px; }
      .resource .profile-main .skill-inner-right .skill-inner-right-bg .cp-input-icon {
        margin-left: 4px;
        float: left;
        margin-top: 8px; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .cp-input-icon span.tthelp {
          position: relative;
          right: 0;
          top: 0;
          width: 18px;
          height: 18px;
          border: 2px solid #999;
          border-radius: 50%;
          padding: 1px;
          line-height: 13px;
          margin-top: 0px;
          font-size: 12px;
          color: #757575;
          text-align: center; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .cp-input-icon .popover {
          background: #0275d1;
          color: #fff;
          border: 1px solid transparent; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .cp-input-icon .popover.top {
          margin-top: -16px;
          margin-left: -16px; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .cp-input-icon .popover.top > .arrow:after {
          border-top-color: #0275d1 !important; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .cp-input-icon p {
          font-size: 12px;
          margin: 0px; }
      .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-right-con {
        min-height: 55px; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-right-con .cp-input-info label {
          float: left; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-right-con .cp-input i {
          width: 40px;
          text-align: center;
          height: 34px;
          position: absolute;
          right: 23%;
          line-height: 38px;
          top: 24px; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-right-con .cp-input .icon-close {
          background: #0263B2;
          color: #fff;
          cursor: pointer;
          float: left; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-right-con .cp-input-large i {
          width: 40px;
          text-align: center;
          height: 34px;
          position: absolute;
          right: 21.2%;
          line-height: 38px;
          top: 24px; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-right-con .cp-input-large .icon-close {
          background: #0263B2;
          color: #fff;
          cursor: pointer;
          float: left; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-right-con .addbtn {
          padding: 0px !important;
          margin: 28px 0 0 0px !important;
          width: 100% !important;
          min-width: 69px !important; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-right-con .addbtn[disabled] {
          background: #ccc !important;
          border-color: #ccc !important;
          cursor: not-allowed !important; }
        @media screen and (-ms-high-contrast: active) {
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-right-con .updateworklocation .dropdown-menu > .active > a, .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-right-con .updateworklocation .dropdown-menu > .active > a:focus, .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-right-con .updateworklocation .dropdown-menu > .active > a:hover {
            background: #0263B2;
            color: #fff; } }
        @media screen and (-ms-high-contrast: black-on-white) {
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-right-con .updateworklocation .dropdown-menu > .active > a, .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-right-con .updateworklocation .dropdown-menu > .active > a:focus, .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-right-con .updateworklocation .dropdown-menu > .active > a:hover {
            background: #0263B2;
            color: #fff; } }
        @media screen and (-ms-high-contrast: white-on-black) {
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-right-con .updateworklocation .dropdown-menu > .active > a, .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-right-con .updateworklocation .dropdown-menu > .active > a:focus, .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-right-con .updateworklocation .dropdown-menu > .active > a:hover {
            background: #0263B2;
            color: #fff; } }
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-right-con .updateworklocation {
            /* IE10+ CSS styles go here */ }
            .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-right-con .updateworklocation .dropdown-menu > .active > a, .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-right-con .updateworklocation .dropdown-menu > .active > a:focus, .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-right-con .updateworklocation .dropdown-menu > .active > a:hover {
              background: #0263B2;
              color: #fff; } }
        @supports (-ms-accelerator: true) {
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-right-con .updateworklocation {
            /* IE Edge 12+ CSS styles go here */ }
            .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-right-con .updateworklocation .dropdown-menu > .active > a, .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-right-con .updateworklocation .dropdown-menu > .active > a:focus, .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-right-con .updateworklocation .dropdown-menu > .active > a:hover {
              background: #0263B2;
              color: #fff; } }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-right-con .updateworklocation .dropdown-menu {
          display: block;
          overflow-y: scroll;
          max-height: 250px; }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-right-con .updateworklocation .dropdown-menu li a {
            white-space: normal; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-right-con .updateworklocation .loader-bar {
          font-size: small;
          color: #585858;
          margin-top: 20px; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-right-con .input-icon {
          background: url("../../images/input-icon.png") no-repeat center center;
          width: 34px;
          height: 30px;
          display: inline-block;
          overflow: hidden;
          background-size: 100%;
          margin-top: 3px; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-right-con .inner-col {
          height: 76px;
          padding-left: 0px; }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-right-con .inner-col .hideFirstElement {
            display: none; }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-right-con .inner-col .dropdown-menu {
            max-height: 250px;
            width: 95%;
            overflow: auto; }
            .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-right-con .inner-col .dropdown-menu li a {
              white-space: normal; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-right-con input[type="text"] {
          background: #fff !important;
          font-weight: 500; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-right-con input[disabled] {
          background: #6B6B77 !important;
          cursor: not-allowed !important; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-right-con label {
          display: block;
          text-transform: uppercase;
          font-weight: 400;
          color: #757575;
          font-size: small;
          margin-bottom: 0px; }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-right-con label i.tthelp {
            position: inherit !important;
            width: 23px;
            height: 23px;
            border: solid 2px #999;
            border-radius: 50%;
            padding: 1px 3px 5px 3px;
            line-height: 20px;
            margin-top: -5px; }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-right-con label .tthelp + .tooltip > .tooltip-inner {
            background: #0275d1; }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-right-con label .tooltip.bottom .tooltip-arrow {
            border-bottom-color: #0275d1;
            /* black */
            /*border-width: 0 5px 5px;*/ }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-right-con .resumeURl {
          margin-bottom: 0px !important; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-right-con .resume-url-error {
          margin-bottom: 15px; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-right-con .basic-resume-url {
          font-size: small;
          font-weight: 600;
          width: 100%;
          margin: 0px; }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-right-con .basic-resume-url input[type=url] {
            border: 0px;
            color: #0263B2;
            width: inherit;
            display: inline-block;
            border-right: 0px;
            padding-right: 5px;
            padding-top: 5px;
            float: left; }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-right-con .basic-resume-url .basic-profile-url-a {
            /*padding-left: 24px;
                    padding-left: 0px;*/
            line-height: 38px;
            top: -6px;
            padding: 0px 10px;
            background: transparent;
            color: #a9acae;
            border: 1px solid #a9acae;
            float: left; }
            .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-right-con .basic-resume-url .basic-profile-url-a .icon-layout {
              line-height: 9px;
              font-size: 18px; }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-right-con .basic-resume-url a {
            color: #337ab7;
            text-decoration: none; }
            .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-right-con .basic-resume-url a:hover {
              color: #337ab7;
              text-decoration: none; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-right-con p {
          margin: 0px 0px 15px 0px;
          font-weight: 400;
          font-size: medium;
          text-transform: capitalize; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-right-con button.btn-grm {
          margin-bottom: 10px;
          margin-right: 8px; }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-right-con button.btn-grm span {
            font-size: medium; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-right-con button.holiday {
          background: #fff;
          background-image: url("../../../../images/line-bg.png");
          border-top: solid 2px #0263B2;
          border-bottom: solid 2px #fff;
          border-left: solid 2px #fff;
          border-right: solid 2px #fff;
          color: #595959; }
        @media screen and (-ms-high-contrast: active) {
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-right-con button.holiday {
            background: #fff;
            background-image: url("../../../../images/oneprofile/whbg.png");
            border-top: solid 2px #0263B2;
            border-bottom: solid 2px #fff;
            border-left: solid 2px #fff;
            border-right: solid 2px #fff;
            color: #595959; } }
        @media screen and (-ms-high-contrast: black-on-white) {
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-right-con button.holiday {
            background: #fff;
            background-image: url("../../../../images/oneprofile/whbg.png");
            border-top: solid 2px #0263B2;
            border-bottom: solid 2px #fff;
            border-left: solid 2px #fff;
            border-right: solid 2px #fff;
            color: #595959; } }
        @media screen and (-ms-high-contrast: white-on-black) {
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-right-con button.holiday {
            background: #fff;
            background-image: url("../../../../images/oneprofile/bhbg.png");
            border-top: solid 2px #0263B2;
            border-bottom: solid 2px #fff;
            border-left: solid 2px #fff;
            border-right: solid 2px #fff;
            color: #fff; } }
        @media all and (-ms-high-contrast: active) {
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-right-con {
            /* IE10+ CSS styles go here */ }
            .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-right-con button.holiday {
              background: #fff;
              background-image: url("../../../../images/oneprofile/whbg.png");
              border-top: solid 2px #0263B2;
              border-bottom: solid 2px #fff;
              border-left: solid 2px #fff;
              border-right: solid 2px #fff;
              color: #595959; } }
        @supports (-ms-accelerator: true) {
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-right-con {
            /* IE Edge 12+ CSS styles go here */ }
            .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-right-con button.holiday {
              background: #fff;
              background-image: url("../../../../images/oneprofile/whbg.png");
              border-top: solid 2px #0263B2;
              border-bottom: solid 2px #fff;
              border-left: solid 2px #fff;
              border-right: solid 2px #fff;
              color: #595959; } }
      .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-section-profile {
        background: #fafafa;
        padding: 10px 30px;
        position: relative;
        margin-top: -15px;
        margin-bottom: 15px; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-section-profile .clicktoclose {
          background: #e1e1e1;
          color: #0263B2;
          padding: 8px 12px 4px 12px;
          position: absolute;
          right: 0px;
          bottom: 0px; }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-section-profile .clicktoclose i {
            line-height: 14px; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-section-profile .row {
          margin-bottom: 15px; }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-section-profile .row.scroll-div {
            height: 30vh;
            overflow-y: scroll; }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-section-profile .row .filter-alphabet {
            margin: 15px 0; }
            .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-section-profile .row .filter-alphabet div {
              margin: 0px 0 20px 0;
              padding: 0px;
              float: left; }
            .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-section-profile .row .filter-alphabet span {
              list-style: none;
              display: inline;
              margin: 0 5px 0 0;
              text-align: center;
              width: auto;
              min-height: inherit;
              padding: 0px;
              border: 0px;
              float: left; }
            .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-section-profile .row .filter-alphabet button {
              background: transparent;
              border: none;
              /*border-radius: 40%;*/
              padding: 0px 9px !important;
              color: #000;
              font-size: 14px;
              font-weight: 500;
              margin-left: 1px;
              margin-right: 1px;
              float: left; }
              .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-section-profile .row .filter-alphabet button.selectable {
                font-weight: bold; }
              .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-section-profile .row .filter-alphabet button.non-selectable {
                opacity: 0.4;
                cursor: not-allowed; }
            .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-section-profile .row .filter-alphabet .active {
              background: #0263B2;
              color: #fff; }
      .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-section-profile-14px {
        margin-top: -14px; }
      .resource .profile-main .skill-inner-right .skill-inner-right-bg .aoe-skill-tiles {
        display: table;
        height: 80px;
        border-right: solid 1px #e1e1e1;
        position: relative;
        margin-bottom: 15px; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .aoe-skill-tiles .skillname {
          display: table-cell;
          vertical-align: middle;
          font-size: medium;
          font-weight: 400;
          text-transform: uppercase;
          color: #6d6d6d; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .aoe-skill-tiles .skill-count {
          display: table-cell;
          vertical-align: middle;
          color: #4184d8;
          font-size: 36px;
          text-align: right;
          width: 75px; }
        .resource .profile-main .skill-inner-right .skill-inner-right-bg .aoe-skill-tiles .viewall {
          font-size: 14px;
          font-weight: 600;
          position: absolute;
          right: 15px;
          bottom: 0px; }
          .resource .profile-main .skill-inner-right .skill-inner-right-bg .aoe-skill-tiles .viewall i {
            padding: 0px;
            margin: 0px;
            font-size: 12px; }
      .resource .profile-main .skill-inner-right .skill-inner-right-bg .aoe-skill-tiles.active {
        background: #fafafa; }
      .resource .profile-main .skill-inner-right .skill-inner-right-bg .aoe-skill-tiles:nth-child(4n) {
        border: none; }
      .resource .profile-main .skill-inner-right .skill-inner-right-bg .cp-input .dropdown-menu {
        min-width: 74% !important; }
  @media screen and (max-width: 950px) {
    .resource ul.top-list {
      display: inherit !important; } }

.profile-overlay {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 1000; }

.assign-btn, .assign-btn:focus {
  padding: 5px 35px;
  background: #0073c6;
  border: 2px solid #0073c6;
  border-radius: 0;
  color: #FFF;
  font-weight: 600;
  margin-right: 15px;
  margin-bottom: 10px;
  width: 124px;
  height: 34px; }
  .assign-btn:focus, .assign-btn:focus:focus {
    outline: 1px dotted #666666; }
  .assign-btn u, .assign-btn:focus u {
    text-decoration: none;
    font-size: small; }

.canc-btn, .canc-btn:focus, .canc-btn:active {
  padding: 5px 30px;
  border-radius: 0;
  border: 2px solid #0073c6;
  background: transparent;
  color: #FFF;
  font-weight: 600;
  margin-bottom: 10px;
  width: 124px;
  height: 34px; }
  .canc-btn span, .canc-btn:focus span, .canc-btn:active span {
    font-size: small; }

.canc-btn:focus, .assign-btn:focus {
  outline: 1px dotted #fff !important; }

.canc-btn:hover {
  background: transparent;
  border: 2px solid #0073c6;
  color: #FFF;
  margin-bottom: 10px;
  width: 124px;
  height: 34px;
  font-weight: 600; }

.assign-btn:hover {
  padding: 5px 35px;
  background: #0073c6;
  border: 2px solid #0073c6;
  border-radius: 0;
  color: #FFF;
  font-weight: 600;
  margin-bottom: 10px;
  width: 124px;
  height: 34px; }

.oneprofile-ng-pageslide {
  background: #2b2b2b;
  color: #fff;
  border-left: 4px solid #0078d7;
  right: -4px !important;
  overflow-y: auto !important; }
  .oneprofile-ng-pageslide *:focus {
    outline: 1px dotted #666666; }
  .oneprofile-ng-pageslide .fly-out {
    padding-right: 19px;
    max-width: 400px;
    height: calc(100%- 40px);
    overflow-x: auto; }
    .oneprofile-ng-pageslide .fly-out .fly-out-close {
      padding-right: 0px; }
    .oneprofile-ng-pageslide .fly-out a {
      color: #FFF;
      text-decoration: none;
      cursor: pointer;
      margin-top: 10px; }
    .oneprofile-ng-pageslide .fly-out .flyout-container {
      border-bottom: 1px solid #ccc;
      height: auto;
      display: inline-block;
      margin-bottom: 15px;
      width: 100%; }
    .oneprofile-ng-pageslide .fly-out .flyout-bottom {
      margin-bottom: 40px; }
    .oneprofile-ng-pageslide .fly-out .flyout-block {
      padding: 0 0 15px 0;
      width: 100%; }
      .oneprofile-ng-pageslide .fly-out .flyout-block .acr-concert {
        height: 15px;
        width: 15px; }
        .oneprofile-ng-pageslide .fly-out .flyout-block .acr-concert + label {
          float: right;
          width: calc(100% - 20px);
          font-weight: 400;
          font-size: 14px;
          cursor: pointer; }
      .oneprofile-ng-pageslide .fly-out .flyout-block .border-red {
        border: 2px solid #fb5f5f !important; }
      .oneprofile-ng-pageslide .fly-out .flyout-block .dropdown-menu > .active > a, .oneprofile-ng-pageslide .fly-out .flyout-block .dropdown-menu > .active > a:focus, .oneprofile-ng-pageslide .fly-out .flyout-block .dropdown-menu > .active > a:hover {
        background: #0263B2;
        color: #fff; }
      .oneprofile-ng-pageslide .fly-out .flyout-block .icon-warning {
        line-height: inherit;
        margin: 0 0 0 3px; }
      .oneprofile-ng-pageslide .fly-out .flyout-block .custom-datepicker .dropdown-menu {
        overflow: hidden;
        height: 233px;
        /*.btn-default{
                            background:$white-bg-color;
                            text-shadow: none;
                            border-radius:0px;
                           
                        }
                         .active{
                                background:#e0e0e0 !important;
                            }
                        .btn-info{
                            background-color: $primary-color;
                            border-color: #28a4c9;
                        }*/ }
      .oneprofile-ng-pageslide .fly-out .flyout-block .dropdown-menu {
        padding: 0;
        max-height: 250px;
        overflow: auto;
        display: block; }
        .oneprofile-ng-pageslide .fly-out .flyout-block .dropdown-menu .btn-sm {
          width: 100%; }
        .oneprofile-ng-pageslide .fly-out .flyout-block .dropdown-menu li a {
          white-space: normal;
          padding: 3px 10px !important; }
      .oneprofile-ng-pageslide .fly-out .flyout-block ul {
        width: 100%;
        border-radius: 0px;
        padding: 8px;
        margin: 0px; }
        .oneprofile-ng-pageslide .fly-out .flyout-block ul table {
          width: 100%;
          outline-style: none; }
      @media screen and (-ms-high-contrast: active) {
        .oneprofile-ng-pageslide .fly-out .flyout-block {
          /*.custom-datepicker {
                .dropdown-menu {
                                   
                   .btn-default btn-sm active{
                                background: url('../../../images/oneprofile/whbg.png');
                                color: #000000;
                                background-repeat: repeat;
                   }
                }
             }*/ }
          .oneprofile-ng-pageslide .fly-out .flyout-block select {
            background: url("../../../../images/arrow_drop_down.png") right no-repeat;
            background-color: #fff;
            padding-right: 15px !important; }
          .oneprofile-ng-pageslide .fly-out .flyout-block .dropdown-menu > .active > a, .oneprofile-ng-pageslide .fly-out .flyout-block .dropdown-menu > .active > a:focus, .oneprofile-ng-pageslide .fly-out .flyout-block .dropdown-menu > .active > a:hover {
            background: #0263B2;
            color: #fff; }
          .oneprofile-ng-pageslide .fly-out .flyout-block .custom-datepicker .btn-default.active, .oneprofile-ng-pageslide .fly-out .flyout-block .custom-datepicker .btn-default:active {
            background: url("../../../../images/oneprofile/whbg.png");
            color: #000000;
            background-repeat: repeat; } }
      @media screen and (-ms-high-contrast: black-on-white) {
        .oneprofile-ng-pageslide .fly-out .flyout-block {
          /*.custom-datepicker {
                .dropdown-menu {
                                   
                   .btn-default btn-sm active{
                                background: url('../../../images/oneprofile/bhbg.png');
                                color: #000000;
                                background-repeat: repeat;
                   }
                }
             }*/ }
          .oneprofile-ng-pageslide .fly-out .flyout-block select {
            background: url("../../../../images/arrow_drop_down.png") right no-repeat;
            background-color: #fff;
            padding-right: 15px !important; }
          .oneprofile-ng-pageslide .fly-out .flyout-block .dropdown-menu > .active > a, .oneprofile-ng-pageslide .fly-out .flyout-block .dropdown-menu > .active > a:focus, .oneprofile-ng-pageslide .fly-out .flyout-block .dropdown-menu > .active > a:hover {
            background: #0263B2;
            color: #fff; }
          .oneprofile-ng-pageslide .fly-out .flyout-block .custom-datepicker .btn-default.active, .oneprofile-ng-pageslide .fly-out .flyout-block .custom-datepicker .btn-default:active {
            background: url("../../../../images/oneprofile/bhbg.png");
            color: #fff;
            background-repeat: repeat; } }
      @media screen and (-ms-high-contrast: white-on-black) {
        .oneprofile-ng-pageslide .fly-out .flyout-block {
          /*.custom-datepicker {
                .dropdown-menu {
                                   
                   .btn-default btn-sm active{
                                background:url('../../../../images/oneprofile/whbg.png');
                                color: #000000;
                                background-repeat: repeat;
                   }
                }
             }*/ }
          .oneprofile-ng-pageslide .fly-out .flyout-block select {
            background: url("../../../../images/arrow_drop_down.png") right no-repeat;
            background-color: #fff;
            padding-right: 15px !important; }
          .oneprofile-ng-pageslide .fly-out .flyout-block .dropdown-menu > .active > a, .oneprofile-ng-pageslide .fly-out .flyout-block .dropdown-menu > .active > a:focus, .oneprofile-ng-pageslide .fly-out .flyout-block .dropdown-menu > .active > a:hover {
            background: #0263B2;
            color: #fff; }
          .oneprofile-ng-pageslide .fly-out .flyout-block .custom-datepicker .btn-default.active, .oneprofile-ng-pageslide .fly-out .flyout-block .custom-datepicker .btn-default:active {
            background: url("../../../../images/oneprofile/whbg.png");
            color: #000000;
            background-repeat: repeat; } }
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .oneprofile-ng-pageslide .fly-out .flyout-block {
          /* IE10+ CSS styles go here */ }
          .oneprofile-ng-pageslide .fly-out .flyout-block select {
            background: url("../../../../images/arrow_drop_down.png") right no-repeat;
            background-color: #fff;
            padding-right: 15px !important; }
          .oneprofile-ng-pageslide .fly-out .flyout-block .dropdown-menu > .active > a, .oneprofile-ng-pageslide .fly-out .flyout-block .dropdown-menu > .active > a:focus, .oneprofile-ng-pageslide .fly-out .flyout-block .dropdown-menu > .active > a:hover {
            background: #0263B2;
            color: #fff; } }
      @supports (-ms-accelerator: true) {
        .oneprofile-ng-pageslide .fly-out .flyout-block {
          /* IE Edge 12+ CSS styles go here */
          /*.custom-datepicker {
                .dropdown-menu {
                                   
                   .btn-default btn-sm active{
                                background: url('../../../images/oneprofile/whbg.png');
                                color: #000000;
                                background-repeat: repeat;
                   }
                }
             }*/ }
          .oneprofile-ng-pageslide .fly-out .flyout-block .custom-datepicker .btn-default.active, .oneprofile-ng-pageslide .fly-out .flyout-block .custom-datepicker .btn-default:active {
            background: url("../../../../images/oneprofile/whbg.png");
            color: #000000;
            background-repeat: repeat; }
          .oneprofile-ng-pageslide .fly-out .flyout-block select {
            background: url("../../../../images/arrow_drop_down.png") right no-repeat;
            background-color: #fff;
            padding-right: 15px !important; }
          .oneprofile-ng-pageslide .fly-out .flyout-block .dropdown-menu > .active > a, .oneprofile-ng-pageslide .fly-out .flyout-block .dropdown-menu > .active > a:focus, .oneprofile-ng-pageslide .fly-out .flyout-block .dropdown-menu > .active > a:hover {
            background: #0263B2;
            color: #fff; } }
      .oneprofile-ng-pageslide .fly-out .flyout-block select {
        width: 100%;
        border-radius: 0px;
        font-weight: 500;
        border: 1px solid #ccc;
        color: #757575;
        min-width: 100%;
        max-width: 100%; }
        .oneprofile-ng-pageslide .fly-out .flyout-block select .hideFirstElement {
          display: none; }
      .oneprofile-ng-pageslide .fly-out .flyout-block p {
        font-weight: 400;
        font-size: small;
        text-transform: capitalize;
        margin-bottom: 0px; }
      .oneprofile-ng-pageslide .fly-out .flyout-block input[type="text"] {
        width: 100%;
        border-radius: 0px;
        font-weight: 500;
        border: 1px solid #ccc;
        color: #757575;
        min-width: 100%;
        max-width: 100%;
        background: #fff !important; }
      .oneprofile-ng-pageslide .fly-out .flyout-block label {
        color: #757575;
        font-weight: 600;
        font-size: small; }
      .oneprofile-ng-pageslide .fly-out .flyout-block td {
        color: black; }
      .oneprofile-ng-pageslide .fly-out .flyout-block th {
        color: black; }
      .oneprofile-ng-pageslide .fly-out .flyout-block .calender-button {
        background: #0263B2;
        border-radius: 0px;
        height: 34px;
        border: 1px solid #0263B2;
        outline-style: none; }
        .oneprofile-ng-pageslide .fly-out .flyout-block .calender-button:focus {
          outline: 1px solid #000; }
        .oneprofile-ng-pageslide .fly-out .flyout-block .calender-button .flyout-calender-icon {
          line-height: normal;
          color: #fff !important; }
  .oneprofile-ng-pageslide .fly-out-height h5 {
    font-weight: 500;
    font-size: large;
    margin-bottom: 15px; }
  .oneprofile-ng-pageslide .fly-out-height p {
    font-weight: 600;
    font-size: 16px; }

.details h5 {
  margin-bottom: 0px;
  color: #858585; }

.details span {
  color: #fff; }

table .accrediation-master:after {
  content: "T" !important;
  color: #fff !important;
  background: #00a04a !important;
  padding: 0px 6px !important;
  font-size: smaller !important;
  height: 25px !important;
  line-height: 25px !important;
  margin-left: 15px !important; }

table .accrediation-professional:before {
  content: "I" !important;
  color: #fff !important;
  background: #680032 !important;
  padding: 0px 6px !important;
  font-size: smaller !important;
  height: 25px !important;
  width: 25px !important;
  line-height: 25px !important;
  margin-right: 15px !important; }

table .accrediation-lead:after {
  content: "L";
  color: #fff;
  background: #2e4894;
  padding: 0px 6px;
  font-size: smaller;
  height: 25px;
  line-height: 25px;
  margin-left: 32px; }

table .accrediation-mentor:after {
  content: "M";
  color: #fff;
  background: #f96505;
  padding: 0px 4px;
  font-size: smaller;
  height: 25px;
  line-height: 25px;
  margin-left: 13px; }

@media screen and (-ms-high-contrast: active) {
  table .accrediation-master:after {
    content: "T" !important;
    color: #fff !important;
    background: #00a04a !important;
    padding: 0px 6px !important;
    font-size: smaller !important;
    height: 25px !important;
    line-height: 25px !important;
    margin-left: 15px !important; }
  table .accrediation-professional:before {
    content: "I" !important;
    color: #fff !important;
    background: #000 !important;
    padding: 0px 6px !important;
    font-size: smaller !important;
    height: 25px !important;
    width: 25px !important;
    line-height: 25px !important;
    margin-right: 15px !important;
    border: 1px solid #fff; }
  table .accrediation-lead:after {
    content: "L";
    color: #fff;
    background: #2e4894;
    padding: 0px 6px;
    font-size: smaller;
    height: 25px;
    line-height: 25px;
    margin-left: 32px; }
  table .accrediation-mentor:after {
    content: "M";
    color: #fff;
    background: #f96505;
    padding: 0px 4px;
    font-size: smaller;
    height: 25px;
    line-height: 25px;
    margin-left: 13px; } }

@media screen and (-ms-high-contrast: black-on-white) {
  table .accrediation-master:after {
    content: "T" !important;
    color: #fff !important;
    background: #00a04a !important;
    padding: 0px 6px !important;
    font-size: smaller !important;
    height: 25px !important;
    line-height: 25px !important;
    margin-left: 15px !important; }
  table .accrediation-professional:before {
    content: "I" !important;
    color: #000 !important;
    background: #fff !important;
    padding: 0px 6px !important;
    font-size: smaller !important;
    height: 25px !important;
    width: 25px !important;
    line-height: 25px !important;
    margin-right: 15px !important;
    border: 1px solid #000;
    font-weight: bolder; }
  table .accrediation-lead:after {
    content: "L";
    color: #fff;
    background: #2e4894;
    padding: 0px 6px;
    font-size: smaller;
    height: 25px;
    line-height: 25px;
    margin-left: 32px; }
  table .accrediation-mentor:after {
    content: "M";
    color: #fff;
    background: #f96505;
    padding: 0px 4px;
    font-size: smaller;
    height: 25px;
    line-height: 25px;
    margin-left: 13px; } }

@media screen and (-ms-high-contrast: white-on-black) {
  table .accrediation-master:after {
    content: "T" !important;
    color: #fff !important;
    background: #00a04a !important;
    padding: 0px 6px !important;
    font-size: smaller !important;
    height: 25px !important;
    line-height: 25px !important;
    margin-left: 15px !important; }
  table .accrediation-professional:before {
    content: "I" !important;
    color: #fff !important;
    background: #000 !important;
    padding: 0px 6px !important;
    font-size: smaller !important;
    height: 25px !important;
    width: 25px !important;
    line-height: 25px !important;
    margin-right: 15px !important;
    border: 1px solid #fff;
    font-weight: bolder; }
  table .accrediation-lead:after {
    content: "L";
    color: #fff;
    background: #2e4894;
    padding: 0px 6px;
    font-size: smaller;
    height: 25px;
    line-height: 25px;
    margin-left: 32px; }
  table .accrediation-mentor:after {
    content: "M";
    color: #fff;
    background: #f96505;
    padding: 0px 4px;
    font-size: smaller;
    height: 25px;
    line-height: 25px;
    margin-left: 13px; } }

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  table {
    /* IE10+ CSS styles go here */
    /*.accrediation-professional{
        &::before {
            content: "I" !important;
            color: $white-bg-color ;
            background: #680032 ;
            padding: 0px 6px !important;
            font-size: smaller !important;
            height: 25px !important;
           width:25px !important;
            line-height: 25px !important;
            margin-right: 15px !important;
        }
    }*/ }
    table .accrediation-master:after {
      content: "T" !important;
      color: #fff !important;
      background: #00a04a !important;
      padding: 0px 6px !important;
      font-size: smaller !important;
      height: 25px !important;
      line-height: 25px !important;
      margin-left: 15px !important; }
    table .accrediation-lead:after {
      content: "L";
      color: #fff;
      background: #2e4894;
      padding: 0px 6px;
      font-size: smaller;
      height: 25px;
      line-height: 25px;
      margin-left: 32px; }
    table .accrediation-mentor:after {
      content: "M";
      color: #fff;
      background: #f96505;
      padding: 0px 4px;
      font-size: smaller;
      height: 25px;
      line-height: 25px;
      margin-left: 13px; } }

@supports (-ms-accelerator: true) {
  table {
    /* IE Edge 12+ CSS styles go here */ }
    table .accrediation-master:after {
      content: "T" !important;
      color: #fff !important;
      background: #00a04a !important;
      padding: 0px 6px !important;
      font-size: smaller !important;
      height: 25px !important;
      line-height: 25px !important;
      margin-left: 15px !important; }
    table .accrediation-professional:before {
      content: "I" !important;
      color: #fff !important;
      background: #680032 !important;
      padding: 0px 6px !important;
      font-size: smaller !important;
      height: 25px !important;
      width: 25px !important;
      line-height: 25px !important;
      margin-right: 15px !important; }
    table .accrediation-lead:after {
      content: "L";
      color: #fff;
      background: #2e4894;
      padding: 0px 6px;
      font-size: smaller;
      height: 25px;
      line-height: 25px;
      margin-left: 32px; }
    table .accrediation-mentor:after {
      content: "M";
      color: #fff;
      background: #f96505;
      padding: 0px 4px;
      font-size: smaller;
      height: 25px;
      line-height: 25px;
      margin-left: 13px; } }

.mc-id-search-box {
  width: 100%; }
  .mc-id-search-box .submint-btn {
    position: absolute;
    left: 295px; }

button.btn-grm {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-size: medium;
  padding: 0 15px;
  height: 48px;
  text-align: center;
  min-width: 100px;
  border: 2px solid #0073c6;
  color: #0073c6;
  background: transparent;
  transition: all 0.3s linear 0s;
  box-shadow: none;
  margin-right: 15px;
  margin-bottom: 15px;
  text-transform: uppercase;
  cursor: pointer; }
  button.btn-grm:hover, button.btn-grm.active {
    background: #0073c6;
    color: #fff; }
  button.btn-grm:focus {
    /*outline: 1px solid $btn-active-color;*/ }
  button.btn-grm:disabled {
    cursor: default; }

.standerdtimezone path#valueArc {
  fill: #0263B2 !important; }

.standerdtimezone text#text {
  fill: #0263B2 !important; }

.standerdtimezone path#trackArc {
  fill: #F4F4F4 !important; }

@media screen and (-ms-high-contrast: active) {
  .standerdtimezone path#valueArc {
    fill: #fff !important; }
  .standerdtimezone text#text {
    fill: #fff !important; }
  .standerdtimezone path#trackArc {
    fill: #fff !important;
    fill-opacity: .5; } }

@media screen and (-ms-high-contrast: white-on-black) {
  .standerdtimezone path#valueArc {
    fill: #fff !important; }
  .standerdtimezone text#text {
    fill: #fff !important; }
  .standerdtimezone path#trackArc {
    fill: #fff !important;
    fill-opacity: .5; } }

@media screen and (-ms-high-contrast: black-on-white) {
  .standerdtimezone path#valueArc {
    fill: #000 !important; }
  .standerdtimezone text#text {
    fill: #000 !important; }
  .standerdtimezone path#trackArc {
    fill: #000 !important;
    fill-opacity: .5; } }

.skill-wid .col-md-10 {
  width: calc(100% - 60px); }

.skill-wid .col-md-2 {
  width: 60px; }

.updateworklocation .loader-bar {
  margin-top: 0px !important; }

.updateworklocation .element-error {
  margin-bottom: 0px !important; }

.updateworklocation #updateWorkLocation {
  margin: 0px !important;
  height: 45px !important; }
  .updateworklocation #updateWorkLocation input#updateWorkLocation-edit {
    font-weight: 500;
    width: 80%;
    height: 36px;
    font-size: medium;
    padding: 0 10px;
    line-height: 36px;
    margin-top: 5px;
    margin-bottom: 15px;
    border: 1px solid #595959;
    color: #595959;
    max-width: 80%;
    /*background: url("../../../../images/arrow_drop_down.png") right no-repeat;
                    background-color:$white-bg-color;*/ }
  .updateworklocation #updateWorkLocation ul#updateWorkLocation-list {
    position: absolute;
    top: 59px;
    left: 15px;
    z-index: 1000;
    display: none;
    min-width: 160px;
    padding: 5px 0;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border: 1px solid rgba(0, 0, 0, 0.15);
    width: 75%; }
    .updateworklocation #updateWorkLocation ul#updateWorkLocation-list li {
      margin: 0px !important;
      padding: 3px 12px !important;
      border: none !important; }
      .updateworklocation #updateWorkLocation ul#updateWorkLocation-list li:hover {
        border: none !important; }
      .updateworklocation #updateWorkLocation ul#updateWorkLocation-list li a {
        float: left;
        padding: 3px 20px;
        width: 100%;
        outline: none;
        border: none;
        color: #757575; }
      .updateworklocation #updateWorkLocation ul#updateWorkLocation-list li a:hover {
        background-color: #2e6da4;
        /*background-image: -webkit-linear-gradient(top,#337ab7 0,#2e6da4 100%);
                            background-image: -o-linear-gradient(top,#337ab7 0,#2e6da4 100%);
                            background-image: -webkit-gradient(linear,left top,left bottom,from(#337ab7),to(#2e6da4));*/
        background-image: linear-gradient(to bottom, #337ab7 0, #2e6da4 100%);
        /*filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff337ab7', endColorstr='#ff2e6da4', GradientType=0);*/
        background-repeat: repeat-x;
        color: #fff;
        outline: none;
        border: none; }

.flyout-block #professionalServiceName, .flyout-block #OrganizationName, .flyout-block #CertificationName {
  margin: 0px !important;
  height: auto !important;
  position: relative; }
  .flyout-block #professionalServiceName .form-control, .flyout-block #OrganizationName .form-control, .flyout-block #CertificationName .form-control {
    width: 100% !important;
    max-width: 100% !important;
    border-radius: 0px !important; }

.flyout-block #OrganizationName {
  margin: 0px !important;
  height: auto !important;
  position: relative; }
  .flyout-block #OrganizationName .form-control {
    width: 100% !important;
    max-width: 100% !important;
    border-radius: 0px !important; }

.flyout-block #CertificationName {
  margin: 0px !important;
  height: auto !important;
  position: relative; }
  .flyout-block #CertificationName .form-control {
    width: 100% !important;
    max-width: 100% !important;
    border-radius: 0px !important; }

.flyout-block #travelCountry {
  margin: 0px !important;
  height: auto !important;
  position: relative; }
  .flyout-block #travelCountry .form-control {
    width: 100% !important;
    max-width: 100% !important;
    border-radius: 0px !important; }

ul#professionalServiceName-list, ul#OrganizationName-list, ul#CertificationName-list, ul#travelCountry-list {
  z-index: 9999;
  color: #757575;
  max-height: 320px;
  height: auto !important;
  position: absolute;
  top: 34px; }
  ul#professionalServiceName-list li, ul#OrganizationName-list li, ul#CertificationName-list li, ul#travelCountry-list li {
    /*border-bottom:solid 1px #bdbdbd;*/
    font-size: 14px;
    /*border-top:none;*/ }
    ul#professionalServiceName-list li span .custom-type-ahead-option, ul#OrganizationName-list li span .custom-type-ahead-option, ul#CertificationName-list li span .custom-type-ahead-option, ul#travelCountry-list li span .custom-type-ahead-option {
      font-size: 14px; }

.mcidpopup .modal-header span {
  font-size: 30px;
  color: #3b3b3b;
  font-weight: 400; }

.mcidpopup .modal-footer {
  text-align: left; }
  .mcidpopup .modal-footer button.ok-modal {
    padding: 7px 35px;
    background: #0078d7;
    border: 1px solid #0078d7;
    color: #fff; }
  .mcidpopup .modal-footer button.cancel-modal {
    padding: 5px 35px;
    margin-left: 15px;
    background: transparent;
    border-style: groove;
    border-color: #0078d7;
    color: #0078d7;
    letter-spacing: -1px; }

::-webkit-scrollbar {
  width: 5px;
  height: 5px; }

::-webkit-scrollbar-thumb {
  background: #708090; }

::-webkit-scrollbar-track {
  background: #b8c0c8; }

.tooltable label {
  display: block;
  text-transform: uppercase;
  font-weight: 400;
  color: #757575;
  font-size: small;
  margin-bottom: 0px; }

.tooltable select,
.tooltable input[type="text"].edit-input, .tooltable input[type="url"].edit-input {
  font-weight: 500;
  width: 97.5% !important;
  height: 36px;
  font-size: medium;
  padding: 0 10px;
  line-height: 36px;
  margin-top: 5px;
  margin-bottom: 15px;
  border: 1px solid #595959;
  color: #595959;
  max-width: 97.5% !important;
  /*background: url("../../../../images/arrow_drop_down.png") right no-repeat;
                    background-color:$white-bg-color;*/ }

.tooltable .addbtn {
  padding: 4px !important;
  margin: 23px 0 0 0px !important;
  width: 100% !important;
  min-width: 69px !important;
  background: #0263B2;
  border: #0263B2 solid 3px;
  text-transform: uppercase;
  color: #fff;
  height: 36px;
  cursor: pointer;
  float: left; }

.tooltable .addbtn[disabled], .tooltable button[disabled], .tooltable .btn-cp[disabled] {
  background: #ccc !important;
  border-color: #ccc !important;
  cursor: not-allowed !important; }

.tooltable .parent-corp-table .p-c-text {
  font-size: medium; }

.op-a11y-type-ahead .loader-bar, .op-a11y-type-ahead .element-error {
  margin-top: 12px; }

.op-a11y-type-ahead input.a11ysearch {
  font-weight: 500;
  width: 80%;
  height: 36px;
  font-size: medium;
  padding: 0 10px;
  line-height: 36px;
  margin-top: 5px;
  margin-bottom: 15px;
  border: 1px solid #595959;
  color: #595959;
  max-width: 80%;
  /*background: url("../../../../images/arrow_drop_down.png") right no-repeat;
                    background-color:$white-bg-color;*/ }

.op-a11y-type-ahead ul {
  position: absolute !important;
  z-index: 1000 !important;
  display: block;
  float: left;
  padding: 5px 6px;
  margin: 2px 0 0;
  font-size: 14px;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0;
  height: 200px;
  overflow-x: overlay;
  /*top: 164px;*/
  left: 14px;
  overflow: auto;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
  width: inherit;
  top: 60px; }
  .op-a11y-type-ahead ul li {
    border: none !important;
    padding: 2px 5px 2px 5px !important; }
    .op-a11y-type-ahead ul li.selected a {
      color: #fff; }
    .op-a11y-type-ahead ul li:hover, .op-a11y-type-ahead ul li.active {
      background: #E8F0F7;
      border: none;
      color: #000;
      padding: 2px 5px 2px 5px !important; }
      .op-a11y-type-ahead ul li:hover a, .op-a11y-type-ahead ul li.active a {
        color: #fff; }
    .op-a11y-type-ahead ul li .custom-type-ahead-option {
      display: table;
      width: 100%; }
      .op-a11y-type-ahead ul li .custom-type-ahead-option .skill-path,
      .op-a11y-type-ahead ul li .custom-type-ahead-option .data {
        display: block;
        width: 100%; }

.skill-inner-right-bg .tooltable {
  margin-top: 20px; }

.skill-inner-right-bg .nls_head,
.skill-inner-right-bg .nls_data {
  float: left;
  width: 100%; }

.resource .profile-main .skill-inner-right .skill-inner-right-bg .allratedskills {
  width: auto;
  max-width: 290px; }

@media all and (-ms-high-contrast: active) {
  .resource .profile-main .skill-inner-right .skill-inner-right-bg .btn-grm.holiday .w-indicator {
    color: #fff !important;
    border-color: #fff !important; } }

@media all and (-ms-high-contrast: white-on-black) {
  .resource .profile-main .skill-inner-right .skill-inner-right-bg .btn-grm.holiday .w-indicator {
    color: #fff !important;
    border-color: #fff !important; } }

@media all and (-ms-high-contrast: black-on-white) {
  .resource .profile-main .skill-inner-right .skill-inner-right-bg .btn-grm.holiday .w-indicator {
    color: #595959 !important;
    border-color: #595959 !important; } }

@media (max-width: 768px) {
  .resource .profile-main .skill-inner-right .skill-inner-right-bg .skill-right-con .cp-input i {
    height: 36px;
    right: 15px; }
  .resource .profile-main .skill-inner-right .skill-inner-right-bg #profileReportsTo input#profileReportsTo-edit,
  .resource .profile-main .skill-inner-right .skill-inner-right-bg #profileSupplierCompany input#profileSupplierCompany-edit,
  .resource .profile-main .skill-inner-right .skill-inner-right-bg input[type=text].edit-input,
  .resource .profile-main .skill-inner-right .skill-inner-right-bg input[type=url].edit-input,
  .resource .profile-main .skill-inner-right .skill-inner-right-bg select {
    width: 100%;
    max-width: 100%;
    right: 14px; }
  .resource .profile-main .skill-inner-right .skill-inner-right-bg skill-right-con .cp-input i {
    height: 36px;
    right: 14px; } }

.parent-corp-table {
  margin: 0 15px;
  width: 98%; }
  .parent-corp-table th {
    padding: 10px 0px;
    text-transform: uppercase;
    font-weight: 400;
    color: #757575;
    font-size: small;
    margin-bottom: 0; }
  .parent-corp-table .corp-table td {
    padding: 10px 15px; }
    .parent-corp-table .corp-table td button.deltool {
      background: #950000;
      color: #fff;
      border: 0px;
      border-radius: 50%;
      width: 24px;
      height: 24px;
      text-align: center;
      font-size: 14px; }

.spacesupplercompany {
  margin-top: 20px; }

.accessibility-table {
  background-color: white;
  border-bottom: 5px solid gray;
  padding-right: 10px;
  overflow-y: auto;
  overflow-x: hidden; }
  .accessibility-table table {
    padding: 10px;
    border-collapse: collapse; }
    .accessibility-table table thead tr th {
      background-color: white !important;
      position: sticky;
      top: 0;
      border-bottom: 2px solid black; }
    .accessibility-table table th:after, .accessibility-table table th:before {
      content: '';
      position: absolute;
      left: 0;
      width: 100%; }
    .accessibility-table table th:before {
      top: -1px;
      border-top: 2px solid black; }
    .accessibility-table table th:after {
      bottom: -1px;
      border-bottom: 2px solid black; }
    .accessibility-table table tbody tr {
      border-bottom: 1px solid black; }
    .accessibility-table table .accrediation-lead:after {
      margin-left: 5px; }
    .accessibility-table table .accrediation-master:after {
      margin-left: 5px; }
    .accessibility-table table .accrediation-mentor:after {
      margin-left: 5px; }
    .accessibility-table table .icon-container-layout {
      padding-top: 10px; }
    .accessibility-table table .icon-layout {
      position: initial; }

.resource .profile-main .area-expertise-accrediation .vxSample {
  max-height: 250px;
  overflow: auto; }
  .resource .profile-main .area-expertise-accrediation .vxSample td {
    padding: 10px; }

.resource .profile-main .area-expertise-certification-container .vxSample {
  max-height: 250px;
  overflow: auto; }
  .resource .profile-main .area-expertise-certification-container .vxSample td {
    padding: 10px; }

.accrediation-main .accrediation-heading a {
  color: #0263b2; }

.block, .resource .profile-main .skill-inner-right .skill-inner-right-bg .standerdtimezone, .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections {
  display: block;
  overflow: hidden; }

.in-block, .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections ul li {
  display: block;
  overflow: hidden; }

.error i {
  color: #e04d4d;
  margin-right: 3px; }

.error span {
  color: #e04d4d;
  font-size: 13px;
  margin-top: 3px; }

.error input {
  background: #f4d6d6; }

/* sales skill rating variables */
/* sales skill rating variables | end */
.accrediation-main {
  /*.manage-table{
        .vxTable .vxHead tr th{
           background: $bg-color !important;
        }
    }*/ }
  .accrediation-main select::-ms-expand {
    display: none; }
  .accrediation-main select {
    background: url(../../images/down_arrow.png) #fff;
    background-repeat: no-repeat;
    background-position: right 10px top 10px;
    background-size: 10px 15px; }
  .accrediation-main .vx-grid-related .vxTable .vxHead th .dropdown {
    padding: 1px; }
    .accrediation-main .vx-grid-related .vxTable .vxHead th .dropdown button[tabindex="-1"] {
      cursor: default !important; }
  .accrediation-main .vx-grid-related .vxTable .vxHead th .open {
    padding: 0px !important; }
  .accrediation-main .vx-grid-related .vxTable .vxHead th .dropdown .dropdown-toggle span.colTitle {
    font-size: medium;
    line-height: 57px;
    width: calc(100% - 25px); }
  .accrediation-main .vx-grid-related .vxTable .vxHead th .dropdown ul.dropdown-menu {
    overflow-x: hidden; }
  .accrediation-main .vx-grid-related .vxTable .vxHead th .dropdown .dropdown-toggle {
    padding: 0px 20px; }
    .accrediation-main .vx-grid-related .vxTable .vxHead th .dropdown .dropdown-toggle:active, .accrediation-main .vx-grid-related .vxTable .vxHead th .dropdown .dropdown-toggle:focus {
      outline-color: #000;
      outline-style: dotted;
      outline-offset: -5px;
      outline-width: thin; }
  .accrediation-main .vx-grid-related .vxTable .vxHead th .open .dropdown-toggle:active, .accrediation-main .vx-grid-related .vxTable .vxHead th .open .dropdown-toggle:focus {
    outline-color: #fff !important; }
  .accrediation-main .vx-grid-related .vxTable .vxBody .vxBodyRow.vs-repeat-repeated-element td {
    padding-left: 0px !important;
    font-size: medium; }
  .accrediation-main .accrediation-heading {
    margin-top: 15px; }
    .accrediation-main .accrediation-heading h2 {
      /*font-size:medium;
            font-weight:500;*/
      margin: 0px; }
  .accrediation-main .add-accrediation-button {
    color: #0263B2;
    font-size: medium; }
    .accrediation-main .add-accrediation-button .icon-layout {
      line-height: normal; }
    .accrediation-main .add-accrediation-button .icon-container-layout {
      font-size: 1em;
      height: 1px;
      width: 1px;
      margin-right: 30px; }
      .accrediation-main .add-accrediation-button .icon-container-layout:hover {
        background: none; }
      .accrediation-main .add-accrediation-button .icon-container-layout .icon-layout {
        line-height: normal; }
    .accrediation-main .add-accrediation-button:hover {
      cursor: pointer; }
  @media (max-width: 967px) {
    .accrediation-main .btn.sample {
      line-height: 40px;
      padding: 0 15px;
      margin: 5px 5px 0 0; }
    .accrediation-main .container-fluid.btns {
      height: 100px; }
    .accrediation-main .vxSample {
      height: 100%;
      margin: 0 auto; }
      .accrediation-main .vxSample .vxH100 {
        overflow-x: hidden; } }
  @media (min-width: 968px) {
    .accrediation-main .btn.sample {
      line-height: 40px;
      padding: 0 15px;
      margin: 10px 10px 0 0; }
    .accrediation-main .container-fluid.btns {
      height: 60px; }
    .accrediation-main .vx-grid-related .vxTable .vxHead th .dropdown ul.dropdown-menu li:focus {
      outline: 1px dotted #fff;
      margin: 1px; }
    .accrediation-main .vx-grid-related .vxTable .vxHead th .dropdown ul.dropdown-menu li input[type=checkbox]:focus {
      outline: 2px solid #fff; }
    .accrediation-main .vxSample {
      height: calc(100vh - 205px) !important;
      margin: 0px 0px 15px 0px !important; }
      .accrediation-main .vxSample .pad0 {
        padding: 2px !important; }
      .accrediation-main .vxSample .vxH100 .vxTableScrollContainer .vxTableContainer .vxTable .vxBody tr td .icon-container-layout:focus {
        margin: 2px; }
    .accrediation-main .vx-grid-related .vxTable .vxHead tr > th {
      background: #e9eef1 !important; } }
  .accrediation-main .vx-grid-related .vxTableContainer.scrollDupHeader.scrollDupHeaderAdded {
    top: 0 !important; }
  .accrediation-main .acrediation-table {
    /*.vxTable{
         .vxHead{
             tr{
                  th{
                      &:last-child{
                          color:$white-bg-color;
                      }

                  }
             }
         }
     }
    .vxTableContainer {
  
	&.vxTableContainer:not(.scrollDupHeader) {
		border: none;
        height: calc(96vh - 175px);
        margin-bottom:50px;
	     }
	&.scrollDupHeader.scrollDupHeaderAdded.attrPaneOpen {
		top: 0;
	        }
        }*/ }

.accreditationButton {
  margin-top: 5px; }

.oneprofile-ng-pageslide select::-ms-expand {
  display: none; }

.oneprofile-ng-pageslide select {
  background: url(../../images/down_arrow.png) #fff;
  background-repeat: no-repeat;
  background-position: right 10px top 10px;
  background-size: 10px 15px; }

.oneprofile-ng-pageslide .fly-out a {
  color: #757575;
  background: transparent; }

.oneprofile-ng-pageslide .fly-out .fly-out-close .icon-cancel {
  color: #fff; }

.oneprofile-ng-pageslide .fly-out .flyout-block .hideFirstElement {
  display: none; }

.oneprofile-ng-pageslide .fly-out .flyout-block label {
  color: #fff; }

.oneprofile-ng-pageslide .fly-out .flyout-block .custom-datepicker .dropdown-menu {
  overflow: hidden;
  height: 233px;
  /*.btn-default{
                            background:$white-bg-color;
                            text-shadow: none;
                            border-radius:0px;
                           
                        }
                         .active{
                                background:#e0e0e0 !important;
                            }
                        .btn-info{
                            background-color: $primary-color;
                            border-color: #28a4c9;
                        }*/ }

.block, .resource .profile-main .skill-inner-right .skill-inner-right-bg .standerdtimezone, .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections {
  display: block;
  overflow: hidden; }

.in-block, .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections ul li {
  display: block;
  overflow: hidden; }

.error i {
  color: #e04d4d;
  margin-right: 3px; }

.error span {
  color: #e04d4d;
  font-size: 13px;
  margin-top: 3px; }

.error input {
  background: #f4d6d6; }

/* sales skill rating variables */
/* sales skill rating variables | end */
.certification-main {
  /*.skill-breadcrumb{
                    margin:0px !important;
                }*/
  /*.manage-table{
        .vxTable .vxHead tr th{
           background: $bg-color !important;
        }
    }*/ }
  .certification-main .vx-grid-related .vxTable .vxHead th .dropdown {
    padding: 1px; }
  .certification-main .vx-grid-related .vxTable .vxHead th .open {
    padding: 0px !important; }
  .certification-main .vx-grid-related .vxTable .vxHead th .dropdown .dropdown-toggle span.colTitle {
    font-size: medium;
    line-height: 57px;
    width: calc(100% - 25px); }
  .certification-main .vx-grid-related .vxTable .vxBody .vxBodyRow.vs-repeat-repeated-element td {
    padding-left: 0px !important;
    font-size: medium; }
  .certification-main .vx-grid-related .vxTable .vxHead th .dropdown .dropdown-toggle {
    padding: 0px 20px; }
    .certification-main .vx-grid-related .vxTable .vxHead th .dropdown .dropdown-toggle:active, .certification-main .vx-grid-related .vxTable .vxHead th .dropdown .dropdown-toggle:focus {
      outline-color: #000;
      outline-style: dotted;
      outline-offset: -5px;
      outline-width: thin; }
  .certification-main .vx-grid-related .vxTable .vxHead th .open .dropdown-toggle:active, .certification-main .vx-grid-related .vxTable .vxHead th .open .dropdown-toggle:focus {
    outline-color: #fff !important; }
  .certification-main .vx-grid-related .vxTable .vxHead th .dropdown ul.dropdown-menu {
    overflow-x: hidden; }
  .certification-main .mcid-add .cursor-pointer {
    cursor: pointer; }
  .certification-main .mcid-add .cursor-default {
    cursor: default; }
  .certification-main .mcid-add .blur-mcid {
    opacity: 0.5; }
  .certification-main .mcid-add .mcid-search {
    float: right;
    width: 140px; }
    .certification-main .mcid-add .mcid-search .mcid-alert {
      position: relative;
      right: 0px;
      width: 187px; }
    .certification-main .mcid-add .mcid-search input {
      background-color: #e4e6e7;
      border: 1px solid #e4e6e7;
      -webkit-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
      -moz-box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0);
      color: #666666;
      width: 110px;
      font-size: 14px;
      font-style: italic; }
  .certification-main .mcid-add .blur-mcid {
    opacity: 0.5; }
  .certification-main .mcid-add .mcid-value {
    float: left;
    width: auto;
    margin: 0px 15px 0px 0px; }
    .certification-main .mcid-add .mcid-value .cursor-default {
      cursor: default; }
    .certification-main .mcid-add .mcid-value .cursor-pointer {
      cursor: pointer; }
    .certification-main .mcid-add .mcid-value span {
      background-color: #e4e6e7;
      border: 1px solid transparent;
      color: #666;
      width: auto;
      float: left;
      line-height: 30px;
      padding: 0px 10px;
      height: 30px;
      font-size: 14px; }
  .certification-main .mcid-add .icon-container-layout {
    font-size: 1em !important;
    height: 30px !important;
    width: 30px !important;
    margin-right: 0px !important;
    background: #0263B2 !important;
    color: #fff !important;
    font-size: 14px !important;
    z-index: 9;
    padding: 7px 9px;
    border: 1px solid #0263B2; }
    .certification-main .mcid-add .icon-container-layout:hover {
      background: #0263B2 !important;
      color: #fff !important; }
  .certification-main .certification-heading {
    margin-top: 15px; }
    .certification-main .certification-heading h2 {
      /*font-size:medium;
            font-weight:500;*/
      margin: 0px;
      line-height: 27px; }
  .certification-main .add-certification-button {
    color: #0263B2;
    font-size: 14px; }
    .certification-main .add-certification-button .accrediation-add-icon {
      margin-left: 15px;
      cursor: pointer;
      font-size: medium; }
      .certification-main .add-certification-button .accrediation-add-icon .icon-layout {
        line-height: normal;
        margin: 6px 0px 0px 5px; }
    .certification-main .add-certification-button .row {
      margin-bottom: 5px; }
    .certification-main .add-certification-button .add-certification-icon {
      padding-top: 5px; }
      .certification-main .add-certification-button .add-certification-icon:hover {
        cursor: pointer; }
    .certification-main .add-certification-button .form-control {
      width: 72%;
      height: 30px;
      padding: 6px 12px;
      font-size: 14px;
      line-height: 1.42857;
      float: left;
      border-radius: 0px;
      border: 0px; }
    .certification-main .add-certification-button .dropdown-menu {
      border-radius: 0px; }
    .certification-main .add-certification-button .edit-icon .icon-container-layout {
      font-size: 1em;
      height: 30px;
      width: 30px;
      margin-right: 0px;
      background: #0263B2;
      color: #fff; }
      .certification-main .add-certification-button .edit-icon .icon-container-layout:hover {
        background: #0263B2;
        color: #fff; }
      .certification-main .add-certification-button .edit-icon .icon-container-layout .icon-layout {
        line-height: 31px; }
    .certification-main .add-certification-button .icon-container-layout {
      font-size: 1em;
      height: 1px;
      width: 1px;
      margin-right: 30px; }
      .certification-main .add-certification-button .icon-container-layout:hover {
        background: none; }
  @media (max-width: 967px) {
    .certification-main .btn.sample {
      line-height: 40px;
      padding: 0 15px;
      margin: 5px 5px 0 0; }
    .certification-main .container-fluid.btns {
      height: 100px; }
    .certification-main .vxSample {
      height: 100%;
      margin: 0 auto; }
      .certification-main .vxSample .vxH100 {
        overflow-x: hidden; } }
  @media (min-width: 968px) {
    .certification-main .btn.sample {
      line-height: 40px;
      padding: 0 15px;
      margin: 10px 10px 0 0; }
    .certification-main .container-fluid.btns {
      height: 60px; }
    .certification-main .vx-grid-related .vxTable .vxHead th .dropdown ul.dropdown-menu li:focus {
      outline: 1px dotted #fff;
      margin: 1px; }
    .certification-main .vx-grid-related .vxTable .vxHead th .dropdown ul.dropdown-menu li input[type=checkbox]:focus {
      outline: 2px solid #fff; }
    .certification-main .vxSample {
      height: calc(100vh - 205px) !important;
      margin: 0px 0px 15px 0px !important; }
      .certification-main .vxSample .pad0 {
        padding: 2px !important; }
      .certification-main .vxSample .vxH100 .vxTableScrollContainer .vxTableContainer .vxTable .vxBody tr td .icon-container-layout:focus {
        margin: 2px; }
    .certification-main .vx-grid-related .vxTable .vxHead tr > th {
      background: #e9eef1 !important; } }
  .certification-main .vx-grid-related .vxTableContainer.scrollDupHeader.scrollDupHeaderAdded {
    top: 0 !important; }
  .certification-main .acrediation-table {
    /*.vxTable{
         .vxHead{
             tr{
                  th{
                      &:last-child{
                          color:$white-bg-color;
                      }

                  }
             }
         }
     }
    .vxTableContainer {
    
	&.vxTableContainer:not(.scrollDupHeader) {
		border: none;
        height: calc(96vh - 175px);
        margin-bottom:50px;
	     }
	&.scrollDupHeader.scrollDupHeaderAdded.attrPaneOpen {
		top: 0;
	        }
        }*/ }
  .certification-main select,
  .certification-main input[type="text"].edit-input {
    font-weight: 500;
    width: 37%;
    height: 30px;
    font-size: medium;
    padding: 0 10px;
    line-height: 30px;
    /* margin-top: 5px; */
    /* margin-bottom: 35px; */
    border: 1px solid #505050;
    color: #505050;
    text-transform: capitalize; }
  @media (max-width: 685px) {
    .certification-main {
      /*.vx-grid-related .vxtable{
         width:100% !important;
     }*/ }
      .certification-main .vx-grid-related .vxTableContainer.scrollTableContainer {
        height: 50px;
        overflow-y: scroll; } }

.oneprofile-ng-pageslide select::-ms-expand {
  display: none; }

.oneprofile-ng-pageslide select {
  background: url(../../images/down_arrow.png) #fff;
  background-repeat: no-repeat;
  background-position: right 10px top 10px;
  background-size: 10px 15px; }

.oneprofile-ng-pageslide .fly-out a {
  color: #757575;
  background: transparent; }

.oneprofile-ng-pageslide .fly-out .fly-out-close .icon-cancel {
  color: #fff; }

.oneprofile-ng-pageslide .fly-out .flyout-block .hideFirstElement {
  display: none; }

.oneprofile-ng-pageslide .fly-out .flyout-block label {
  color: #fff; }

.oneprofile-ng-pageslide .fly-out .flyout-block .custom-datepicker .dropdown-menu {
  overflow: hidden;
  height: 233px;
  /*.btn-default{
                            background:$white-bg-color;
                            text-shadow: none;
                            border-radius:0px;
                           
                        }
                         .active{
                                background:#e0e0e0 !important;
                            }
                        .btn-info{
                            background-color: $primary-color;
                            border-color: #28a4c9;
                        }*/ }

.block, .resource .profile-main .skill-inner-right .skill-inner-right-bg .standerdtimezone, .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections {
  display: block;
  overflow: hidden; }

.in-block, .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections ul li {
  display: block;
  overflow: hidden; }

.error i {
  color: #e04d4d;
  margin-right: 3px; }

.error span {
  color: #e04d4d;
  font-size: 13px;
  margin-top: 3px; }

.error input {
  background: #f4d6d6; }

/* sales skill rating variables */
/* sales skill rating variables | end */
.one-profile-admin {
  padding-top: 15px; }
  .one-profile-admin ul li {
    padding-left: 0px; }
    .one-profile-admin ul li a {
      background: #fff;
      height: 65px;
      font-size: 18px;
      line-height: 65px;
      width: 100%;
      float: left;
      color: #757575;
      margin-bottom: 15px;
      text-transform: uppercase;
      font-weight: 600; }
      .one-profile-admin ul li a i {
        background: #0263B2;
        height: 50px;
        width: 50px;
        margin: 7px 10px 0 10px;
        text-align: center;
        color: #fff;
        line-height: 50px;
        -webkit-border-radius: 45px;
        -moz-border-radius: 45px;
        -ms-border-radius: 45px;
        border-radius: 45px;
        font-size: 24px;
        font-weight: normal;
        margin-top: 5px; }

.one-profile-bulkusertagsmain select {
  background: url("../../../../images/arrow_drop_down.png");
  background-size: auto;
  background-color: #fff;
  padding-right: 15px !important;
  -webkit-appearance: none;
  background-repeat: no-repeat;
  background-position: right;
  width: 100%; }

.one-profile-bulkusertagsmain select::-ms-expand {
  display: none; }

@media (max-width: 992px) {
  .one-profile-bulkusertagsmain select {
    margin-top: 20px; }
  .one-profile-bulkusertagsmain #TagType {
    margin-top: 10px;
    max-width: 85%; }
  .one-profile-bulkusertagsmain .btn-upload {
    margin-top: 20px; } }

.ops-autoskill-search {
  color: #000; }

.auto-skill-typeahead .loader-bar {
  margin-top: 10px; }

.auto-skill-typeahead .element-error {
  margin-top: 10px; }

.auto-skill-info {
  color: #4286f4; }

.block, .resource .profile-main .skill-inner-right .skill-inner-right-bg .standerdtimezone, .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections {
  display: block;
  overflow: hidden; }

.in-block, .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections ul li {
  display: block;
  overflow: hidden; }

.error i {
  color: #e04d4d;
  margin-right: 3px; }

.error span {
  color: #e04d4d;
  font-size: 13px;
  margin-top: 3px; }

.error input {
  background: #f4d6d6; }

/* sales skill rating variables */
/* sales skill rating variables | end */
/*@import'../vx-grid/vx-grid-custom.scss';*/
.one-profile-bulkusertagsmain,
.one-profile-bulkmain {
  margin-bottom: 15px; }
  .one-profile-bulkusertagsmain *:focus,
  .one-profile-bulkmain *:focus {
    outline: 1px dotted #666666; }
  .one-profile-bulkusertagsmain .bulk-inner,
  .one-profile-bulkmain .bulk-inner {
    background: #fff;
    float: left;
    width: 100%; }
    .one-profile-bulkusertagsmain .bulk-inner .bulk-upload-main h2,
    .one-profile-bulkmain .bulk-inner .bulk-upload-main h2 {
      text-transform: uppercase;
      color: #757575;
      font-size: large; }
    .one-profile-bulkusertagsmain .bulk-inner .bulk-upload-main .bulk-upload,
    .one-profile-bulkmain .bulk-inner .bulk-upload-main .bulk-upload {
      margin-top: 20px;
      margin-bottom: 20px; }
      .one-profile-bulkusertagsmain .bulk-inner .bulk-upload-main .bulk-upload h3,
      .one-profile-bulkmain .bulk-inner .bulk-upload-main .bulk-upload h3 {
        font-size: medium;
        text-transform: uppercase;
        font-weight: 700;
        color: #757575;
        margin-bottom: 30px; }
      .one-profile-bulkusertagsmain .bulk-inner .bulk-upload-main .bulk-upload .cp-option input[type="radio"],
      .one-profile-bulkmain .bulk-inner .bulk-upload-main .bulk-upload .cp-option input[type="radio"] {
        margin-top: 5px;
        margin-right: 5px;
        float: left;
        width: 20px; }
      .one-profile-bulkusertagsmain .bulk-inner .bulk-upload-main .bulk-upload .cp-option label,
      .one-profile-bulkmain .bulk-inner .bulk-upload-main .bulk-upload .cp-option label {
        font-size: medium;
        font-weight: 500;
        float: left;
        width: calc(100% - 30px);
        margin: 0px 0px 0px 0px; }
      .one-profile-bulkusertagsmain .bulk-inner .bulk-upload-main .bulk-upload .cp-option .selection,
      .one-profile-bulkmain .bulk-inner .bulk-upload-main .bulk-upload .cp-option .selection {
        font-weight: 500;
        height: 36px;
        font-size: medium;
        padding: 0 10px;
        line-height: 36px;
        margin-top: -10px;
        border: 1px solid #595959;
        color: #595959; }
      .one-profile-bulkusertagsmain .bulk-inner .bulk-upload-main .bulk-upload label,
      .one-profile-bulkmain .bulk-inner .bulk-upload-main .bulk-upload label {
        font-size: medium;
        text-transform: uppercase;
        font-weight: 500;
        float: left;
        width: 100%;
        color: #757575;
        margin-bottom: 10px; }
      .one-profile-bulkusertagsmain .bulk-inner .bulk-upload-main .bulk-upload .bulk-input,
      .one-profile-bulkmain .bulk-inner .bulk-upload-main .bulk-upload .bulk-input {
        position: relative; }
        .one-profile-bulkusertagsmain .bulk-inner .bulk-upload-main .bulk-upload .bulk-input input,
        .one-profile-bulkmain .bulk-inner .bulk-upload-main .bulk-upload .bulk-input input {
          border: 2px solid #757575;
          height: 40px;
          padding: 5px;
          font-style: italic;
          font-size: medium;
          width: 100%;
          color: #757575;
          max-width: 100%; }
        .one-profile-bulkusertagsmain .bulk-inner .bulk-upload-main .bulk-upload .bulk-input input[type=text]::-ms-clear,
        .one-profile-bulkmain .bulk-inner .bulk-upload-main .bulk-upload .bulk-input input[type=text]::-ms-clear {
          display: none; }
        .one-profile-bulkusertagsmain .bulk-inner .bulk-upload-main .bulk-upload .bulk-input i,
        .one-profile-bulkmain .bulk-inner .bulk-upload-main .bulk-upload .bulk-input i {
          width: 40px;
          text-align: center;
          height: 38px;
          background: #0263B2;
          color: #fff;
          line-height: 38px;
          position: absolute;
          right: 0px;
          border: 1px solid #757575; }
      .one-profile-bulkusertagsmain .bulk-inner .bulk-upload-main .bulk-upload .inline-error,
      .one-profile-bulkmain .bulk-inner .bulk-upload-main .bulk-upload .inline-error {
        color: red;
        font-size: medium;
        margin-top: 5px; }
      .one-profile-bulkusertagsmain .bulk-inner .bulk-upload-main .bulk-upload .inline-success,
      .one-profile-bulkmain .bulk-inner .bulk-upload-main .bulk-upload .inline-success {
        color: green;
        font-size: medium;
        margin-top: 5px; }
      .one-profile-bulkusertagsmain .bulk-inner .bulk-upload-main .bulk-upload .btn-upload,
      .one-profile-bulkmain .bulk-inner .bulk-upload-main .bulk-upload .btn-upload {
        background: #0263B2;
        padding: 0px 25px;
        margin: 0px 15px 0px 0px;
        border: 0px;
        min-width: 100px;
        border: #0263B2 solid 3px;
        font-size: medium;
        text-transform: uppercase;
        color: #fff;
        height: 38px;
        line-height: 35px;
        cursor: pointer;
        font-weight: 700; }
        .one-profile-bulkusertagsmain .bulk-inner .bulk-upload-main .bulk-upload .btn-upload:disabled,
        .one-profile-bulkmain .bulk-inner .bulk-upload-main .bulk-upload .btn-upload:disabled {
          background: #757575;
          cursor: default;
          border: #757575 solid 3px; }
        .one-profile-bulkusertagsmain .bulk-inner .bulk-upload-main .bulk-upload .btn-upload span,
        .one-profile-bulkmain .bulk-inner .bulk-upload-main .bulk-upload .btn-upload span {
          font-size: medium; }
      .one-profile-bulkusertagsmain .bulk-inner .bulk-upload-main .bulk-upload .refer-link,
      .one-profile-bulkmain .bulk-inner .bulk-upload-main .bulk-upload .refer-link {
        font-size: medium;
        color: #0263B2;
        font-weight: 500;
        width: auto;
        margin-top: 15px;
        margin-bottom: 10px; }
        .one-profile-bulkusertagsmain .bulk-inner .bulk-upload-main .bulk-upload .refer-link i,
        .one-profile-bulkmain .bulk-inner .bulk-upload-main .bulk-upload .refer-link i {
          font-size: 12px; }
    .one-profile-bulkusertagsmain .bulk-inner .bulk-table-main,
    .one-profile-bulkmain .bulk-inner .bulk-table-main {
      /*.vx-grid-related .vxTableContainer.scrollTableContainer{
                width:100%;
            }*/
      /*.vx-grid-related .vxTable .vxHead th .dropdown ul.dropdown-menu li input[type=checkbox] {
            &:focus {
                outline: 2px solid #fff;
            }
        }*/ }
      .one-profile-bulkusertagsmain .bulk-inner .bulk-table-main .vx-grid-related .vxTable .vxHead th .dropdown ul.dropdown-menu li:focus,
      .one-profile-bulkmain .bulk-inner .bulk-table-main .vx-grid-related .vxTable .vxHead th .dropdown ul.dropdown-menu li:focus {
        outline: 1px dotted #fff;
        margin: 1px; }
      .one-profile-bulkusertagsmain .bulk-inner .bulk-table-main #accrediation-table .vx-grid-related .vxTable .vxBody .vxBodyRow td,
      .one-profile-bulkmain .bulk-inner .bulk-table-main #accrediation-table .vx-grid-related .vxTable .vxBody .vxBodyRow td {
        border: none; }
        .one-profile-bulkusertagsmain .bulk-inner .bulk-table-main #accrediation-table .vx-grid-related .vxTable .vxBody .vxBodyRow td .vxBodyRowCell.renderedDefn,
        .one-profile-bulkmain .bulk-inner .bulk-table-main #accrediation-table .vx-grid-related .vxTable .vxBody .vxBodyRow td .vxBodyRowCell.renderedDefn {
          padding: 0 20px; }
      .one-profile-bulkusertagsmain .bulk-inner .bulk-table-main .vx-grid-related .col-xs-12.vxTableHolder.pad0 .vxTable thead tr th,
      .one-profile-bulkmain .bulk-inner .bulk-table-main .vx-grid-related .col-xs-12.vxTableHolder.pad0 .vxTable thead tr th {
        padding: 1px; }
      .one-profile-bulkusertagsmain .bulk-inner .bulk-table-main .vx-grid-related .vxTable .vxHead th .dropdown ul.dropdown-menu li input[type=checkbox]:focus,
      .one-profile-bulkmain .bulk-inner .bulk-table-main .vx-grid-related .vxTable .vxHead th .dropdown ul.dropdown-menu li input[type=checkbox]:focus {
        outline: 2px solid #fff; }
      .one-profile-bulkusertagsmain .bulk-inner .bulk-table-main .vx-grid-related .vxTable .vxBody .vxBodyRow.vs-repeat-repeated-element td,
      .one-profile-bulkmain .bulk-inner .bulk-table-main .vx-grid-related .vxTable .vxBody .vxBodyRow.vs-repeat-repeated-element td {
        padding: 0 20px !important; }
      .one-profile-bulkusertagsmain .bulk-inner .bulk-table-main .vx-grid-related .vxTable .vxHead th .dropdown .dropdown-toggle:focus,
      .one-profile-bulkmain .bulk-inner .bulk-table-main .vx-grid-related .vxTable .vxHead th .dropdown .dropdown-toggle:focus {
        outline-color: #000;
        outline-style: dotted;
        outline-offset: -5px;
        outline-width: thin; }
      .one-profile-bulkusertagsmain .bulk-inner .bulk-table-main .vx-grid-related .vxTable .vxHead tr > th .dropdown.open .dropdown-toggle:focus,
      .one-profile-bulkmain .bulk-inner .bulk-table-main .vx-grid-related .vxTable .vxHead tr > th .dropdown.open .dropdown-toggle:focus {
        outline-color: #fff;
        outline-style: dotted;
        outline-offset: -5px;
        outline-width: thin; }
      .one-profile-bulkusertagsmain .bulk-inner .bulk-table-main .reload-main,
      .one-profile-bulkmain .bulk-inner .bulk-table-main .reload-main {
        height: 50px;
        padding: 0px; }
        .one-profile-bulkusertagsmain .bulk-inner .bulk-table-main .reload-main .reload-left,
        .one-profile-bulkmain .bulk-inner .bulk-table-main .reload-main .reload-left {
          padding-left: 0px;
          width: 130px; }
          .one-profile-bulkusertagsmain .bulk-inner .bulk-table-main .reload-main .reload-left button,
          .one-profile-bulkmain .bulk-inner .bulk-table-main .reload-main .reload-left button {
            padding: 5px 30px;
            border-radius: 0;
            border: 2px solid #757575;
            background: #757575;
            color: #fff;
            font-weight: 500;
            width: 124px;
            height: 34px; }
            .one-profile-bulkusertagsmain .bulk-inner .bulk-table-main .reload-main .reload-left button:disabled,
            .one-profile-bulkmain .bulk-inner .bulk-table-main .reload-main .reload-left button:disabled {
              opacity: .5;
              cursor: none; }
        .one-profile-bulkusertagsmain .bulk-inner .bulk-table-main .reload-main .reload-right,
        .one-profile-bulkmain .bulk-inner .bulk-table-main .reload-main .reload-right {
          width: calc(100% - 130px);
          padding: 0px; }
          .one-profile-bulkusertagsmain .bulk-inner .bulk-table-main .reload-main .reload-right div,
          .one-profile-bulkmain .bulk-inner .bulk-table-main .reload-main .reload-right div {
            background: rgba(244, 214, 45, 0.1);
            height: 34px;
            width: 100%;
            display: block;
            padding: 5px;
            text-align: left; }
            .one-profile-bulkusertagsmain .bulk-inner .bulk-table-main .reload-main .reload-right div i,
            .one-profile-bulkmain .bulk-inner .bulk-table-main .reload-main .reload-right div i {
              background: #f4d62d;
              height: 26px;
              width: 5px;
              display: inline-block; }
            .one-profile-bulkusertagsmain .bulk-inner .bulk-table-main .reload-main .reload-right div span,
            .one-profile-bulkmain .bulk-inner .bulk-table-main .reload-main .reload-right div span {
              background: transparent;
              display: inline-block;
              vertical-align: top;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              word-wrap: break-word;
              margin: 0px 0px 0px 5px;
              width: 95%;
              line-height: 24px; }
      .one-profile-bulkusertagsmain .bulk-inner .bulk-table-main .icon-check,
      .one-profile-bulkmain .bulk-inner .bulk-table-main .icon-check {
        color: #2d8443; }
      .one-profile-bulkusertagsmain .bulk-inner .bulk-table-main .icon-alert-outline, .one-profile-bulkusertagsmain .bulk-inner .bulk-table-main .icon-dots-horizontal,
      .one-profile-bulkmain .bulk-inner .bulk-table-main .icon-alert-outline,
      .one-profile-bulkmain .bulk-inner .bulk-table-main .icon-dots-horizontal {
        color: #bb0000; }
      .one-profile-bulkusertagsmain .bulk-inner .bulk-table-main h2,
      .one-profile-bulkmain .bulk-inner .bulk-table-main h2 {
        text-transform: uppercase;
        color: #757575;
        font-size: large; }
      .one-profile-bulkusertagsmain .bulk-inner .bulk-table-main .bulk-table,
      .one-profile-bulkmain .bulk-inner .bulk-table-main .bulk-table {
        margin: 0px !important;
        height: calc(100vh - 535px); }
        .one-profile-bulkusertagsmain .bulk-inner .bulk-table-main .bulk-table .vx-grid-related .vxTableContainer.scrollDupHeader.scrollDupHeaderAdded,
        .one-profile-bulkmain .bulk-inner .bulk-table-main .bulk-table .vx-grid-related .vxTableContainer.scrollDupHeader.scrollDupHeaderAdded {
          top: 0px !important; }
        .one-profile-bulkusertagsmain .bulk-inner .bulk-table-main .bulk-table .vx-grid-related .vxTable .vxBody .vxBodyRow.vs-repeat-repeated-element td:nth-child(2),
        .one-profile-bulkmain .bulk-inner .bulk-table-main .bulk-table .vx-grid-related .vxTable .vxBody .vxBodyRow.vs-repeat-repeated-element td:nth-child(2) {
          padding-left: 10px; }
      .one-profile-bulkusertagsmain .bulk-inner .bulk-table-main .bulk-table .icon-down,
      .one-profile-bulkmain .bulk-inner .bulk-table-main .bulk-table .icon-down {
        height: 28px; }
      .one-profile-bulkusertagsmain .bulk-inner .bulk-table-main .vx-grid-related .vxTable .vxHead th span.noDdTitle,
      .one-profile-bulkmain .bulk-inner .bulk-table-main .vx-grid-related .vxTable .vxHead th span.noDdTitle {
        color: #fff; }
      .one-profile-bulkusertagsmain .bulk-inner .bulk-table-main .vxH100 .vxTableContainer,
      .one-profile-bulkmain .bulk-inner .bulk-table-main .vxH100 .vxTableContainer {
        /*overflow-x: hidden;*/ }
        .one-profile-bulkusertagsmain .bulk-inner .bulk-table-main .vxH100 .vxTableContainer.vxTableContainer:not(.scrollDupHeader),
        .one-profile-bulkmain .bulk-inner .bulk-table-main .vxH100 .vxTableContainer.vxTableContainer:not(.scrollDupHeader) {
          /*height: calc(100vh - 460px);*/ }
      .one-profile-bulkusertagsmain .bulk-inner .bulk-table-main .vx-grid-related .vxTable .vxHead th .dropdown .dropdown-toggle,
      .one-profile-bulkmain .bulk-inner .bulk-table-main .vx-grid-related .vxTable .vxHead th .dropdown .dropdown-toggle {
        padding: 0px 20px; }
        .one-profile-bulkusertagsmain .bulk-inner .bulk-table-main .vx-grid-related .vxTable .vxHead th .dropdown .dropdown-toggle span.colTitle,
        .one-profile-bulkmain .bulk-inner .bulk-table-main .vx-grid-related .vxTable .vxHead th .dropdown .dropdown-toggle span.colTitle {
          font-size: medium; }
      .one-profile-bulkusertagsmain .bulk-inner .bulk-table-main .vx-grid-related .vxTable .vxHead th .dropdown .dropdown-toggle span.colTitle,
      .one-profile-bulkmain .bulk-inner .bulk-table-main .vx-grid-related .vxTable .vxHead th .dropdown .dropdown-toggle span.colTitle {
        font-size: medium; }
  @media screen and (max-width: 1100px) {
    .one-profile-bulkusertagsmain .bulk-table,
    .one-profile-bulkmain .bulk-table {
      height: auto !important; } }

@media screen and (max-width: 1100px) {
  .one-profile-bulkmain {
    height: calc(100vh - 120px);
    overflow-y: scroll; } }

.one-profile-bulkmain .bulk-inner .bulk-table-main .bulk-table {
  min-height: 200px; }

.rm_bulk_op_wrapper {
  max-height: calc(100vh - 98px);
  overflow: auto; }

@media screen and (max-width: 911px) {
  .one-profile-bulkmain .bulk-inner .bulk-upload-main .bulk-upload .uploadButton {
    padding-top: 15px; } }

.one-profile-bulkusertagsmain .cp-option {
  margin-top: 10px;
  margin-bottom: 10px; }

.one-profile-bulkusertagsmain .refer-link {
  margin-top: 0px !important; }

.one-profile-bulkusertagsmain .btn-generate {
  background: #0263B2;
  padding: 0 25px;
  margin: 0px 0px 10px 15px;
  min-width: 100px;
  border: 3px solid #0263B2;
  font-size: medium;
  text-transform: uppercase;
  color: #fff;
  height: 38px;
  line-height: 35px;
  cursor: pointer;
  font-weight: 700; }

.one-profile-bulkusertagsmain .btn-generate:disabled {
  background: #6b6b77;
  border: 3px solid #6b6b77; }

.one-profile-bulkusertagsmain .template-generate-note {
  margin-left: 15px; }

.one-profile-bulkusertagsmain .bulk-table {
  min-height: 200px; }

.block, .resource .profile-main .skill-inner-right .skill-inner-right-bg .standerdtimezone, .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections {
  display: block;
  overflow: hidden; }

.in-block, .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections ul li {
  display: block;
  overflow: hidden; }

.error i {
  color: #e04d4d;
  margin-right: 3px; }

.error span {
  color: #e04d4d;
  font-size: 13px;
  margin-top: 3px; }

.error input {
  background: #f4d6d6; }

/* sales skill rating variables */
/* sales skill rating variables | end */
.one-profile-createprofile {
  height: calc(100vh - 100px);
  overflow-y: auto; }
  .one-profile-createprofile .disabledStyles {
    background-color: #ccc !important;
    cursor: not-allowed !important; }
  .one-profile-createprofile *:focus {
    outline: 1px dotted #666666; }
  .one-profile-createprofile .cp-formGroup {
    max-width: 980px; }
  .one-profile-createprofile .cp-inner {
    background: #fff;
    float: left;
    width: 100%;
    margin-top: 15px;
    margin-bottom: 15px; }
    .one-profile-createprofile .cp-inner .cp-top {
      margin-bottom: 30px; }
      .one-profile-createprofile .cp-inner .cp-top h2 {
        text-transform: uppercase;
        color: #333333;
        font-size: large; }
      .one-profile-createprofile .cp-inner .cp-top .bulk-newupload {
        margin-top: 80px;
        margin-bottom: 60px; }
      .one-profile-createprofile .cp-inner .cp-top .bulk-upload {
        /* margin-top: 80px;
                margin-bottom: 60px; */ }
        .one-profile-createprofile .cp-inner .cp-top .bulk-upload h3 {
          font-size: medium;
          text-transform: uppercase;
          font-weight: 700; }
        .one-profile-createprofile .cp-inner .cp-top .bulk-upload .cp-input-icon {
          margin-left: 4px;
          float: left; }
          .one-profile-createprofile .cp-inner .cp-top .bulk-upload .cp-input-icon span.tthelp {
            position: relative;
            right: 0;
            top: 0;
            width: 18px;
            height: 18px;
            border: 2px solid #999;
            border-radius: 50%;
            padding: 1px;
            line-height: 13px;
            margin-top: 0px;
            font-size: 12px;
            color: #757575;
            text-align: center; }
          .one-profile-createprofile .cp-inner .cp-top .bulk-upload .cp-input-icon .popover {
            background: #0275d1;
            color: #fff;
            border: 1px solid transparent; }
          .one-profile-createprofile .cp-inner .cp-top .bulk-upload .cp-input-icon .popover.top {
            margin-left: 6px; }
          .one-profile-createprofile .cp-inner .cp-top .bulk-upload .cp-input-icon .popover.top > .arrow:after {
            border-top-color: #0275d1 !important; }
          .one-profile-createprofile .cp-inner .cp-top .bulk-upload .cp-input-icon p {
            font-size: 12px;
            margin: 0px; }
        .one-profile-createprofile .cp-inner .cp-top .bulk-upload .cp-option {
          margin-bottom: 10px; }
          .one-profile-createprofile .cp-inner .cp-top .bulk-upload .cp-option input[type="radio"] {
            margin-top: 5px;
            margin-right: 5px;
            float: left; }
          .one-profile-createprofile .cp-inner .cp-top .bulk-upload .cp-option label {
            font-size: medium;
            font-weight: 500;
            width: auto;
            margin: 0px 80px 0px 0px; }
        .one-profile-createprofile .cp-inner .cp-top .bulk-upload label {
          font-size: medium;
          font-weight: 500;
          float: left;
          margin-bottom: 5px; }
          .one-profile-createprofile .cp-inner .cp-top .bulk-upload label i.tthelp {
            position: inherit !important;
            width: 26px;
            height: 26px;
            border: solid 2px #999;
            border-radius: 50%;
            padding: 1px 3px 5px 3px;
            line-height: 20px;
            margin-top: -4px; }
          .one-profile-createprofile .cp-inner .cp-top .bulk-upload label .tthelp + .tooltip > .tooltip-inner {
            background: #0275d1; }
          .one-profile-createprofile .cp-inner .cp-top .bulk-upload label .tooltip.bottom .tooltip-arrow {
            border-bottom-color: #0275d1;
            /* black */
            /*border-width: 0 5px 5px;*/ }
        .one-profile-createprofile .cp-inner .cp-top .bulk-upload .cp-input {
          position: relative;
          margin-bottom: 10px; }
          .one-profile-createprofile .cp-inner .cp-top .bulk-upload .cp-input input {
            border: 2px solid #a9acae;
            height: 40px;
            padding: 5px 45px 5px 5px;
            font-size: 14px;
            width: 100%;
            color: #333333;
            max-width: 100%; }
          .one-profile-createprofile .cp-inner .cp-top .bulk-upload .cp-input select {
            border: 2px solid #a9acae;
            height: 40px;
            padding: 5px 45px 5px 5px;
            font-size: 14px;
            color: #333333;
            width: 100%;
            max-width: 100%;
            background: url("../../../../images/arrow_drop_down.png");
            background-size: auto;
            background-color: #fff;
            padding-right: 15px !important;
            -webkit-appearance: none;
            background-repeat: no-repeat;
            background-position: right; }
          .one-profile-createprofile .cp-inner .cp-top .bulk-upload .cp-input select::-ms-expand {
            display: none; }
          .one-profile-createprofile .cp-inner .cp-top .bulk-upload .cp-input .field-error-input {
            background: #fff !important;
            border: 2px solid #bb0000; }
          .one-profile-createprofile .cp-inner .cp-top .bulk-upload .cp-input i {
            width: 40px;
            text-align: center;
            height: 40px;
            position: absolute;
            right: 15px;
            line-height: 38px;
            top: 28px; }
          .one-profile-createprofile .cp-inner .cp-top .bulk-upload .cp-input .oneprofile-i-close {
            top: 0px;
            margin: 0px;
            min-width: 0px;
            padding: 0px;
            line-height: 30px; }
          .one-profile-createprofile .cp-inner .cp-top .bulk-upload .cp-input .icon-close {
            background: #0263B2;
            color: #fff;
            cursor: pointer;
            float: left;
            border: 1px solid transparent; }
          .one-profile-createprofile .cp-inner .cp-top .bulk-upload .cp-input .icon-zoom {
            color: #757575; }
        .one-profile-createprofile .cp-inner .cp-top .bulk-upload .btn-cp {
          background: #0263B2;
          padding: 0px 20px;
          margin: 0px 15px 0px 0px;
          border: 0px;
          min-width: 100px;
          border: #0263B2 solid 3px;
          font-size: medium;
          text-transform: uppercase;
          color: #fff;
          height: 38px;
          cursor: pointer;
          font-weight: 700;
          float: left; }
          .one-profile-createprofile .cp-inner .cp-top .bulk-upload .btn-cp span {
            font-size: medium; }
        .one-profile-createprofile .cp-inner .cp-top .bulk-upload .addbtn {
          padding: 4px !important;
          font-weight: 500;
          margin: 28px 0 0 0px !important;
          width: 100% !important;
          min-width: 69px !important; }
        .one-profile-createprofile .cp-inner .cp-top .bulk-upload .btn-cancel {
          background: #fff;
          padding: 0px 20px;
          margin: 0px 0px 0px 0px;
          border: 0px;
          min-width: 100px;
          border: #0263B2 solid 3px;
          font-size: medium;
          text-transform: uppercase;
          color: #0263B2;
          height: 38px;
          line-height: 35px;
          cursor: pointer;
          font-weight: 700;
          float: left; }
          .one-profile-createprofile .cp-inner .cp-top .bulk-upload .btn-cancel span {
            font-size: medium; }
        .one-profile-createprofile .cp-inner .cp-top .bulk-upload .refer-link {
          font-size: medium;
          color: #0263B2;
          margin-top: 10px; }
          .one-profile-createprofile .cp-inner .cp-top .bulk-upload .refer-link i {
            font-size: 12px; }
        .one-profile-createprofile .cp-inner .cp-top .bulk-upload .inline-error {
          color: #bb0000;
          font-size: medium;
          margin-bottom: 5px; }
        .one-profile-createprofile .cp-inner .cp-top .bulk-upload .inline-success {
          color: green;
          font-size: medium;
          margin: 5px 0px; }
    .one-profile-createprofile .cp-inner .cp-bottom {
      border-top: solid 1px #eeeeee; }
      .one-profile-createprofile .cp-inner .cp-bottom h2 {
        text-transform: uppercase;
        color: #333333;
        font-size: large;
        margin-bottom: 20px; }
      .one-profile-createprofile .cp-inner .cp-bottom .cp-input-rep {
        margin-bottom: 30px;
        font-size: medium;
        min-height: 75px; }
        .one-profile-createprofile .cp-inner .cp-bottom .cp-input-rep label {
          float: left;
          width: 100%;
          font-weight: normal;
          margin-bottom: 5px;
          color: #333333;
          text-transform: uppercase;
          font-size: medium; }
        .one-profile-createprofile .cp-inner .cp-bottom .cp-input-rep span {
          float: left;
          width: 100%;
          font-weight: 700; }
  .one-profile-createprofile .popover.top {
    left: 0px !important; }
  .one-profile-createprofile .popover.top > .arrow {
    left: 112px; }

.vfpbtn {
  padding: 0px !important;
  margin: 20px 0 !important;
  width: 200px !important;
  background: #0263B2;
  border: #0263B2 solid 3px;
  text-transform: uppercase;
  color: #fff;
  cursor: pointer;
  font-weight: 700;
  float: right; }

.addbtn {
  padding: 0px !important;
  margin: 28px 0 0 0px !important;
  width: 100% !important; }

.addbtn[disabled], .btn-cp[disabled] {
  background: #ccc !important;
  border-color: #ccc !important;
  cursor: not-allowed !important; }

button[disabled] {
  cursor: not-allowed !important; }

.parent-corp-table {
  margin-bottom: 15px; }

.corp-table:first-child {
  border-top: solid 1px #e2e2e2; }

.corp-table {
  border-bottom: solid 1px #e2e2e2;
  margin: 0px;
  padding: 10px 0 7px 0; }
  .corp-table a.deltool {
    background: #950000;
    color: #fff;
    border-radius: 50%;
    width: 24px;
    height: 24px;
    text-align: center;
    float: left;
    padding: 1px 0 0 0;
    margin: 0 0 0 48%; }
    .corp-table a.deltool i {
      font-size: 14px !important;
      font-weight: 600 !important; }

#partner-form .cp-input .dropdown-menu {
  min-width: 92.5% !important; }

#partner-form .a11y-type-ahead {
  height: auto; }

.a11y-type-ahead {
  margin: 0px !important; }
  .a11y-type-ahead .form-control {
    border-radius: 0px !important; }
  .a11y-type-ahead ul {
    z-index: 999 !important; }

#update-form {
  min-height: 80px; }
  #update-form .loader-bar, #update-form .element-error {
    margin-top: 45px; }

.customClass .tooltip-inner {
  color: #fff;
  background-color: rgba(0, 120, 215, 0.85);
  word-wrap: break-word; }

#cp_Form .op-a11y-type-ahead ul {
  top: 67px; }

.block, .resource .profile-main .skill-inner-right .skill-inner-right-bg .standerdtimezone, .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections {
  display: block;
  overflow: hidden; }

.in-block, .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections ul li {
  display: block;
  overflow: hidden; }

.error i {
  color: #e04d4d;
  margin-right: 3px; }

.error span {
  color: #e04d4d;
  font-size: 13px;
  margin-top: 3px; }

.error input {
  background: #f4d6d6; }

/* sales skill rating variables */
/* sales skill rating variables | end */
.nomangerendorsescroll {
  overflow: hidden !important;
  max-height: 100% !important; }

.resource {
  z-index: 0;
  position: relative; }

.opsfaq-modal .modal-content {
  border-radius: 0px;
  padding: 15px;
  font-weight: 400;
  font-size: 18px; }
  .opsfaq-modal .modal-content #faqmodalBody {
    width: 100%;
    float: left; }
  .opsfaq-modal .modal-content .close {
    color: #000;
    opacity: 1;
    font-weight: normal;
    font-size: 18px; }
  .opsfaq-modal .modal-content .modalHeader {
    padding: 15px;
    float: left;
    width: 100%; }
    .opsfaq-modal .modal-content .modalHeader span.h4 {
      color: #595959;
      font-size: 18px !important;
      margin-top: 10px;
      display: inline;
      font-weight: 500; }
  .opsfaq-modal .modal-content #faqmodalBody {
    font-size: 14px;
    max-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;
    width: calc(100% - 15px);
    min-height: 220px; }
    .opsfaq-modal .modal-content #faqmodalBody > div {
      float: left;
      width: 100%;
      margin-bottom: 10px; }
      .opsfaq-modal .modal-content #faqmodalBody > div .answer,
      .opsfaq-modal .modal-content #faqmodalBody > div .question {
        margin: 0px;
        float: left;
        width: 100%; }
    .opsfaq-modal .modal-content #faqmodalBody .faq1 .faq-text {
      float: left;
      width: 90%; }
    .opsfaq-modal .modal-content #faqmodalBody .faq1 .faq-h {
      float: left;
      min-width: 25px;
      width: 2%; }
    .opsfaq-modal .modal-content #faqmodalBody .question {
      font-weight: 500;
      width: 100%;
      float: left;
      padding-bottom: 5px; }
    .opsfaq-modal .modal-content #faqmodalBody .answer {
      margin-bottom: 17px;
      float: left;
      width: 100%;
      padding-bottom: 15px; }

.endorseResourceManger {
  display: flex; }

@font-face {
  font-family: 'ms_icon_family';
  src: url("/fonts/ms_icon_family.eot?54llek");
  src: url("/fonts/ms_icon_family.eot?54llek#iefix") format("embedded-opentype"), url("/fonts/ms_icon_family.ttf?54llek") format("truetype"), url("/fonts/ms_icon_family.woff?54llek") format("woff"), url("/fonts/ms_icon_family.svg?54llek#ms_icon_family") format("svg");
  font-weight: normal;
  font-style: normal; }

.endorsewrapperbg {
  background: rgba(255, 255, 255, 0.1);
  position: absolute;
  top: 0px;
  height: 100%;
  left: 0px;
  width: 100%;
  z-index: 1; }

.endorsewrapper {
  position: relative; }
  .endorsewrapper .popover-content {
    padding: 0px; }
  .endorsewrapper .fw600 {
    font-weight: 600;
    color: #717171; }
  .endorsewrapper .popover {
    min-width: 400px; }
  .endorsewrapper #manageEndorsedPopup {
    min-height: 250px; }
    .endorsewrapper #manageEndorsedPopup .grid-body {
      min-height: 220px; }
  .endorsewrapper .vmodal {
    position: absolute;
    top: 0px;
    background: #fff;
    z-index: 5;
    border: 1px solid #ccc;
    box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5);
    overflow: hidden;
    overflow-y: auto;
    padding-bottom: 8px;
    width: 100%; }
    .endorsewrapper .vmodal .modal-content {
      border: none;
      max-height: 100%;
      box-shadow: none; }
  .endorsewrapper .vmodal.opsfaq-modal {
    overflow: hidden;
    min-height: 250px; }

.endorsecontainer {
  width: 100%;
  padding: 0;
  display: block;
  position: relative; }
  .endorsecontainer #lastEndorsedHelpIcon .icon-customhelp,
  .endorsecontainer .icon-info-alert {
    font-size: 14px; }
  .endorsecontainer .icon-blue,
  .endorsecontainer .icon-customhelp {
    color: #0263B2;
    font-weight: 700;
    font-size: 14px;
    cursor: pointer; }
  .endorsecontainer .endorsement, .endorsecontainer .resourceEndorse .managerEndorse, .resourceEndorse .endorsecontainer .managerEndorse {
    border-width: 2px 0px 2px 2px;
    border-color: #adafb2;
    border-style: solid;
    min-height: 176px;
    padding-bottom: 10px;
    padding-top: 10px;
    background-color: #fff; }
    .endorsecontainer .endorsement #lastendorsed, .endorsecontainer .resourceEndorse .managerEndorse #lastendorsed, .resourceEndorse .endorsecontainer .managerEndorse #lastendorsed {
      color: #717171; }
      .endorsecontainer .endorsement #lastendorsed .status, .endorsecontainer .resourceEndorse .managerEndorse #lastendorsed .status, .resourceEndorse .endorsecontainer .managerEndorse #lastendorsed .status {
        font-weight: 400;
        font-size: 13px; }
    .endorsecontainer .endorsement .lastValidHeading, .endorsecontainer .resourceEndorse .managerEndorse .lastValidHeading, .resourceEndorse .endorsecontainer .managerEndorse .lastValidHeading {
      font-weight: bold;
      margin-bottom: 10px; }
    .endorsecontainer .endorsement .commentsPopupClick, .endorsecontainer .resourceEndorse .managerEndorse .commentsPopupClick, .resourceEndorse .endorsecontainer .managerEndorse .commentsPopupClick {
      display: block;
      float: left;
      margin-top: 10px; }
    .endorsecontainer .endorsement div.popover, .endorsecontainer .resourceEndorse .managerEndorse div.popover, .resourceEndorse .endorsecontainer .managerEndorse div.popover {
      border-radius: 0px;
      background-color: #fff; }
      .endorsecontainer .endorsement div.popover .popoverContent, .endorsecontainer .resourceEndorse .managerEndorse div.popover .popoverContent, .resourceEndorse .endorsecontainer .managerEndorse div.popover .popoverContent {
        margin: 15px;
        font-weight: 400;
        font-style: normal;
        font-size: 14px;
        color: #000;
        padding: 5px; }
        .endorsecontainer .endorsement div.popover .popoverContent .lastValidhead, .endorsecontainer .resourceEndorse .managerEndorse div.popover .popoverContent .lastValidhead, .resourceEndorse .endorsecontainer .managerEndorse div.popover .popoverContent .lastValidhead {
          font-weight: bold;
          margin-bottom: 5px; }
        .endorsecontainer .endorsement div.popover .popoverContent li, .endorsecontainer .resourceEndorse .managerEndorse div.popover .popoverContent li, .resourceEndorse .endorsecontainer .managerEndorse div.popover .popoverContent li {
          margin-bottom: 5px; }
          .endorsecontainer .endorsement div.popover .popoverContent li i:before, .endorsecontainer .resourceEndorse .managerEndorse div.popover .popoverContent li i:before, .resourceEndorse .endorsecontainer .managerEndorse div.popover .popoverContent li i:before {
            padding: 0px 3px;
            margin-right: 10px; }
        .endorsecontainer .endorsement div.popover .popoverContent a, .endorsecontainer .resourceEndorse .managerEndorse div.popover .popoverContent a, .resourceEndorse .endorsecontainer .managerEndorse div.popover .popoverContent a {
          text-decoration: underline;
          font-weight: normal; }
      .endorsecontainer .endorsement div.popover .arrow, .endorsecontainer .resourceEndorse .managerEndorse div.popover .arrow, .resourceEndorse .endorsecontainer .managerEndorse div.popover .arrow {
        visibility: hidden; }
    .endorsecontainer .endorsement .mgrendorse, .endorsecontainer .resourceEndorse .managerEndorse .mgrendorse, .resourceEndorse .endorsecontainer .managerEndorse .mgrendorse {
      margin-bottom: 25px;
      width: 100%;
      float: left; }
      .endorsecontainer .endorsement .mgrendorse #CustomHelpIcon, .endorsecontainer .resourceEndorse .managerEndorse .mgrendorse #CustomHelpIcon, .resourceEndorse .endorsecontainer .managerEndorse .mgrendorse #CustomHelpIcon {
        margin-left: 5px; }
      .endorsecontainer .endorsement .mgrendorse #head, .endorsecontainer .resourceEndorse .managerEndorse .mgrendorse #head, .resourceEndorse .endorsecontainer .managerEndorse .mgrendorse #head {
        color: #595959;
        font-size: 18px !important;
        margin-top: 10px;
        display: inline;
        text-transform: uppercase; }
        .endorsecontainer .endorsement .mgrendorse #head #CustomHelpIcon, .endorsecontainer .resourceEndorse .managerEndorse .mgrendorse #head #CustomHelpIcon, .resourceEndorse .endorsecontainer .managerEndorse .mgrendorse #head #CustomHelpIcon {
          font-size: 14px; }
    .endorsecontainer .endorsement #newlyrated, .endorsecontainer .resourceEndorse .managerEndorse #newlyrated, .resourceEndorse .endorsecontainer .managerEndorse #newlyrated {
      padding-left: 0px; }
      .endorsecontainer .endorsement #newlyrated .newtitle, .endorsecontainer .resourceEndorse .managerEndorse #newlyrated .newtitle, .resourceEndorse .endorsecontainer .managerEndorse #newlyrated .newtitle {
        color: #717171; }
      .endorsecontainer .endorsement #newlyrated .numDisabled, .endorsecontainer .resourceEndorse .managerEndorse #newlyrated .numDisabled, .resourceEndorse .endorsecontainer .managerEndorse #newlyrated .numDisabled {
        color: #5c5c5c !important;
        cursor: default !important;
        font-size: 13px;
        font-weight: 400; }
      .endorsecontainer .endorsement #newlyrated .noEndorsedSkills, .endorsecontainer .resourceEndorse .managerEndorse #newlyrated .noEndorsedSkills, .resourceEndorse .endorsecontainer .managerEndorse #newlyrated .noEndorsedSkills {
        font-size: 13px;
        color: #5c5c5c;
        font-weight: 400; }
    .endorsecontainer .endorsement .count, .endorsecontainer .resourceEndorse .managerEndorse .count, .resourceEndorse .endorsecontainer .managerEndorse .count {
      font-weight: 600;
      font-size: 14px;
      border-right: 1px solid #adafb2;
      margin-left: 0px;
      min-height: 55px;
      list-style: none;
      padding-top: 3px; }
      .endorsecontainer .endorsement .count #lastendorsed, .endorsecontainer .resourceEndorse .managerEndorse .count #lastendorsed, .resourceEndorse .endorsecontainer .managerEndorse .count #lastendorsed {
        color: #717171; }
      .endorsecontainer .endorsement .count sup, .endorsecontainer .resourceEndorse .managerEndorse .count sup, .resourceEndorse .endorsecontainer .managerEndorse .count sup {
        margin-right: 10px; }
      .endorsecontainer .endorsement .count i, .endorsecontainer .resourceEndorse .managerEndorse .count i, .resourceEndorse .endorsecontainer .managerEndorse .count i {
        font-weight: bold; }
      .endorsecontainer .endorsement .count #num, .endorsecontainer .resourceEndorse .managerEndorse .count #num, .resourceEndorse .endorsecontainer .managerEndorse .count #num {
        font-weight: 600;
        color: #0263B2;
        font-size: 14px;
        cursor: pointer;
        float: left; }
    .endorsecontainer .endorsement #mgrFaq, .endorsecontainer .resourceEndorse .managerEndorse #mgrFaq, .resourceEndorse .endorsecontainer .managerEndorse #mgrFaq {
      border-right: 0px;
      width: 30%; }
  .endorsecontainer a {
    font-weight: 600;
    font-style: normal;
    color: #0263B2;
    cursor: pointer; }

#skillRate {
  border: 2px solid #adafb2;
  background-color: #E9EEF1;
  min-height: 100px;
  height: auto;
  padding: 15px 30px; }
  #skillRate .skillupdate {
    border-bottom: 1px solid #cacaca; }
    #skillRate .skillupdate .update {
      font-weight: 300;
      font-style: normal;
      font-size: 13px;
      color: #5c5c5c; }
  #skillRate .skillComp {
    border-width: 0px;
    left: 0px;
    top: 0px;
    font-weight: 600;
    font-style: normal;
    font-size: 16px;
    color: #717171;
    padding-right: 20px;
    text-transform: uppercase; }
    #skillRate .skillComp .skillpercent {
      font-size: 14px;
      width: 100%;
      float: left;
      margin-bottom: 5px; }
      #skillRate .skillComp .skillpercent .skillName {
        float: left;
        color: #717171;
        font-weight: 600;
        text-transform: none; }
      #skillRate .skillComp .skillpercent .skillRate {
        float: right;
        font-weight: 600; }
      #skillRate .skillComp .skillpercent .skill-success {
        color: #2F7600; }
      #skillRate .skillComp .skillpercent .skill-warning {
        color: #bf4200; }
      #skillRate .skillComp .skillpercent .skill-danger {
        color: #b60000; }

.resourceEndorse {
  border: 1px solid #fff;
  padding: 10px 0px;
  background-color: #fff;
  width: 100%; }
  .resourceEndorse .commentsPopupClick {
    display: block;
    margin-top: 10px; }
  .resourceEndorse i {
    font-weight: bold; }
  .resourceEndorse .updateDate {
    font-weight: 400;
    font-size: 14px;
    margin-top: 10px;
    min-height: 50px;
    width: 25%;
    border-right: 1px solid #adafb2;
    color: #5c5c5c; }
  .resourceEndorse .managerEndorse {
    text-align: left;
    font-weight: 600;
    font-size: 14px;
    border: none !important;
    min-height: 0px !important;
    background-color: transparent;
    padding-top: 5px;
    color: #717171; }
    .resourceEndorse .managerEndorse .status {
      font-weight: 400;
      font-size: 13px; }
    .resourceEndorse .managerEndorse .commentsPopupClick {
      display: block;
      margin-top: 10px; }
    .resourceEndorse .managerEndorse sup {
      margin-right: 10px; }
    .resourceEndorse .managerEndorse p {
      margin: 0px; }
    .resourceEndorse .managerEndorse a {
      font-weight: 600;
      font-style: normal;
      color: #0263B2;
      text-decoration: none;
      cursor: pointer; }

@media (max-width: 768px) {
  .pCbox .pcboxbody {
    padding-top: 55px; }
  #mgrFaq {
    width: 100% !important; }
  .endorseResourceManger {
    display: block; }
  .endorsecontainer {
    display: block; }
    .endorsecontainer .endorsement #newlyrated, .endorsecontainer .resourceEndorse .managerEndorse #newlyrated, .resourceEndorse .endorsecontainer .managerEndorse #newlyrated, .endorsecontainer .endorsement #lastendorsed, .endorsecontainer .resourceEndorse .managerEndorse #lastendorsed, .resourceEndorse .endorsecontainer .managerEndorse #lastendorsed {
      width: 100%;
      padding: 0px 15px;
      text-align: left;
      border: none;
      margin: 10px 0px; } }

@media (max-width: 1094px) {
  .opsfaq-modal .modal-content .modal-body {
    width: 100%;
    min-height: 150px; } }

/* manager endorse popup */
#manageEndorsePopup {
  overflow: hidden; }
  #manageEndorsePopup .modal-content {
    border-radius: 0px; }

#manageEndorsePopup .modal-dialog {
  width: calc( 100% - 72px);
  margin-top: 195px;
  float: right;
  margin-right: 14px;
  overflow: hidden;
  height: calc(100% - 235px);
  border: 1px solid #999;
  background: #fff;
  box-shadow: 0 3px 9px rgba(0, 0, 0, 0.5); }

#manageEndorsePopup .modal-content {
  box-shadow: none;
  border: 0px; }

/* previous comments css */
.previousComments {
  padding: 15px 5px 15px 15px;
  z-index: 55;
  font-size: 14px;
  color: #333333;
  width: 100%;
  right: 0px;
  text-align: left; }

.pCbox {
  padding-right: 10px;
  position: relative; }
  .pCbox .pCboxHeader {
    position: absolute;
    background: #fff;
    width: 100%;
    z-index: 55; }
    .pCbox .pCboxHeader h3 {
      color: #595959;
      font-size: 14px;
      font-weight: 600;
      line-height: 1.1;
      margin-top: 0px; }
  .pCbox .pcboxbody {
    padding-top: 35px;
    height: 40vh;
    overflow-y: auto;
    overflow-x: hidden; }
  .pCbox h2 {
    font-size: 16px;
    margin: 0px;
    margin-bottom: 20px;
    color: #717171;
    text-transform: uppercase; }
  .pCbox .pCClose {
    text-align: right;
    margin-bottom: 15px;
    font-size: 22px;
    line-height: 15px; }
    .pCbox .pCClose .alert-close {
      background: transparent;
      border: none;
      font-size: 14px;
      font-weight: bold; }
  .pCbox .pComment {
    margin-bottom: 25px; }
    .pCbox .pComment .pCHeader {
      color: #6f6f6f;
      font-size: 12px;
      padding-right: 10px; }
      .pCbox .pComment .pCHeader .pCtime {
        margin-bottom: 10px; }
    .pCbox .pComment .pCDesc p {
      font-weight: normal;
      font-size: 12px; }
    .pCbox .pComment .pCClose span {
      cursor: pointer; }

/* previous comments css  | end */
.manageEndorsePopup {
  background: #fff;
  display: table;
  width: 100%;
  color: #333;
  /* grid head | end */ }
  .manageEndorsePopup .endorseBody {
    padding: 0px 30px; }
    .manageEndorsePopup .endorseBody .text-sm {
      font-size: 9.4px;
      color: #767676;
      line-height: 12px;
      float: right;
      width: 100%;
      margin-top: 15px; }
    .manageEndorsePopup .endorseBody .gridPagination {
      margin-top: 0px;
      font-size: 14px; }
      .manageEndorsePopup .endorseBody .gridPagination .no-padding-right {
        padding-right: 0px; }
      .manageEndorsePopup .endorseBody .gridPagination .pagination-count {
        padding: 7px 15px;
        border-radius: 3px;
        /* new pagination style */
        position: absolute;
        right: 0;
        margin-right: 54px;
        padding: 5px 10px;
        font-size: 15px;
        font-weight: 500;
        top: 0px;
        /* new pagination style */ }
        .manageEndorsePopup .endorseBody .gridPagination .pagination-count input[type='text'] {
          max-width: 22px !important;
          text-align: center;
          height: auto;
          border: 1px solid #008eff;
          padding: 0px; }
        .manageEndorsePopup .endorseBody .gridPagination .pagination-count input[disabled] {
          background: #bdbdbd;
          border: 1px solid gray; }
      .manageEndorsePopup .endorseBody .gridPagination .pagination-sm {
        margin: 0px; }
        .manageEndorsePopup .endorseBody .gridPagination .pagination-sm #gp-next {
          padding-right: 0px; }
        .manageEndorsePopup .endorseBody .gridPagination .pagination-sm .pagination-prev {
          float: left;
          display: block;
          margin-right: 105px; }
          .manageEndorsePopup .endorseBody .gridPagination .pagination-sm .pagination-prev a:before {
            font-family: 'Glyphicons Halflings';
            content: "\e257";
            padding-top: 1px;
            float: left; }
        .manageEndorsePopup .endorseBody .gridPagination .pagination-sm .pagination-next a:before {
          font-family: 'Glyphicons Halflings';
          content: "\e258";
          padding-top: 1px;
          float: right; }
      .manageEndorsePopup .endorseBody .gridPagination .pagination-sm > li:first-child > a {
        padding-left: 0px; }
      .manageEndorsePopup .endorseBody .gridPagination .pagination-sm > li > a {
        font-size: 15px;
        color: #0263B2;
        font-weight: 500;
        border: none; }
      .manageEndorsePopup .endorseBody .gridPagination .pagination-sm > li.disabled > a {
        color: #8e8e8e; }
  .manageEndorsePopup .endorse-header {
    float: left;
    width: 100%;
    padding-top: 10px; }
    .manageEndorsePopup .endorse-header h2 {
      color: #595959;
      font-size: 18px !important;
      margin-top: 10px; }
    .manageEndorsePopup .endorse-header .h3 {
      color: #333333; }
    .manageEndorsePopup .endorse-header #endorseSkillCount {
      color: #0263B2;
      padding: 0px 5px; }
    .manageEndorsePopup .endorse-header .close {
      padding: 10px 0px;
      color: #000;
      opacity: 1;
      font-weight: normal;
      font-size: 18px; }
  .manageEndorsePopup .grid-head {
    float: left;
    width: 100%;
    /* endorse  data | end */ }
    .manageEndorsePopup .grid-head .endorse-result {
      width: 10%;
      float: left; }
    .manageEndorsePopup .grid-head .endorse-skills {
      float: left;
      width: 100%; }
    .manageEndorsePopup .grid-head .endorse-data {
      margin: 0px;
      float: left;
      width: 90%;
      border-right: 1px solid #ccc;
      padding-bottom: 15px; }
      .manageEndorsePopup .grid-head .endorse-data p {
        color: #333; }
      .manageEndorsePopup .grid-head .endorse-data .breadcrumb {
        background: transparent;
        padding-left: 0px;
        margin: 0px;
        padding: 0px;
        padding-top: 5px;
        width: 90%;
        margin-top: 10px; }
        .manageEndorsePopup .grid-head .endorse-data .breadcrumb li {
          font-size: 12px;
          color: #5c5c5c !important; }
      .manageEndorsePopup .grid-head .endorse-data .breadcrumb > li + li:before {
        content: ">"; }
      .manageEndorsePopup .grid-head .endorse-data .endorse-record {
        width: 100%;
        position: relative;
        float: left; }
        .manageEndorsePopup .grid-head .endorse-data .endorse-record .endorse-prev {
          right: -5px; }
      .manageEndorsePopup .grid-head .endorse-data .h4 {
        font-size: 12px;
        font-weight: 500; }
      .manageEndorsePopup .grid-head .endorse-data .endorse-prev i,
      .manageEndorsePopup .grid-head .endorse-data .endorse-updated i {
        font-size: 12px;
        font-weight: 500;
        padding: 0px 10px;
        display: block;
        margin: 4px 0;
        font-style: normal;
        text-align: center;
        position: absolute; }
      .manageEndorsePopup .grid-head .endorse-data .endorse-data-left {
        float: left;
        width: 85%; }
      .manageEndorsePopup .grid-head .endorse-data .endorse-data-right {
        width: 15%;
        float: left; }
      .manageEndorsePopup .grid-head .endorse-data .endorse-skill-head {
        font-size: 16px;
        text-align: left;
        color: #595959;
        font-weight: 500; }
      .manageEndorsePopup .grid-head .endorse-data .endorse-prev {
        padding: 0px 10px;
        display: block;
        position: relative;
        margin-right: 10px;
        margin-bottom: 5px;
        text-align: right; }
        .manageEndorsePopup .grid-head .endorse-data .endorse-prev path#valueArc {
          fill: #a5a5a5 !important; }
        .manageEndorsePopup .grid-head .endorse-data .endorse-prev i {
          font-size: 12px;
          color: #a5a5a5;
          font-weight: 500; }
      .manageEndorsePopup .grid-head .endorse-data .endorse-prev {
        position: absolute;
        top: 0px; }
      .manageEndorsePopup .grid-head .endorse-data .endorse-updated {
        position: absolute;
        right: -42px;
        top: 0px; }
        .manageEndorsePopup .grid-head .endorse-data .endorse-updated i {
          border-color: #87a4d0;
          color: #87a4d0; }
  .manageEndorsePopup .endorse-grid .grid-header {
    overflow-y: hidden;
    overflow-x: hidden; }
    .manageEndorsePopup .endorse-grid .grid-header .grid-head {
      margin-bottom: 0px;
      border-bottom: 1px solid #ccc;
      width: calc(100%- 27px); }
      .manageEndorsePopup .endorse-grid .grid-header .grid-head .endorse-data {
        padding-bottom: 0px !important; }
        .manageEndorsePopup .endorse-grid .grid-header .grid-head .endorse-data .endorse-data-left {
          margin-top: 15px; }
      .manageEndorsePopup .endorse-grid .grid-header .grid-head .endorse-updated,
      .manageEndorsePopup .endorse-grid .grid-header .grid-head .endorse-prev {
        color: #333;
        line-height: 18px;
        padding: 5px;
        font-size: 14px;
        display: block;
        position: relative; }
      .manageEndorsePopup .endorse-grid .grid-header .grid-head .endorse-updated {
        font-weight: bold;
        margin-left: 5px; }
  .manageEndorsePopup .endorse-grid .grid-body {
    float: left;
    width: 100%;
    position: relative;
    height: 40vh;
    overflow: hidden;
    overflow-y: scroll;
    width: calc(100% - 10px); }
  .manageEndorsePopup .endorse-comment {
    padding-top: 5vh; }
    .manageEndorsePopup .endorse-comment .comment-previous {
      margin-bottom: 20px; }
      .manageEndorsePopup .endorse-comment .comment-previous .pCbox .pcboxbody {
        height: 30vh; }
      .manageEndorsePopup .endorse-comment .comment-previous .bottom-right {
        right: 0;
        left: auto !important; }
      .manageEndorsePopup .endorse-comment .comment-previous .view-previous {
        font-size: 14px;
        text-align: right; }
        .manageEndorsePopup .endorse-comment .comment-previous .view-previous .popover .arrow {
          visibility: hidden; }
        .manageEndorsePopup .endorse-comment .comment-previous .view-previous span {
          font-weight: 600;
          color: #0263B2;
          cursor: pointer; }
      .manageEndorsePopup .endorse-comment .comment-previous .comment-link {
        color: #0078d6;
        cursor: pointer; }
    .manageEndorsePopup .endorse-comment .comment-textarea textarea {
      width: 100%;
      min-height: 120px;
      max-width: 100%;
      border: 1px solid #ccc;
      padding: 10px;
      font-size: 14px;
      resize: none; }
      .manageEndorsePopup .endorse-comment .comment-textarea textarea.ng-dirty.ng-invalid {
        border-color: red !important; }
    .manageEndorsePopup .endorse-comment .comment-textarea .remaningComemnts {
      font-size: 14px;
      float: left; }
    .manageEndorsePopup .endorse-comment .comment-textarea .text-danger {
      font-size: 14px; }
    .manageEndorsePopup .endorse-comment .comment-textarea button {
      float: right;
      background: #0078d6;
      border: none;
      color: #fff;
      border-radius: 0px;
      margin: 15px 0px;
      padding: 5px 20px;
      margin-bottom: 5px; }
      .manageEndorsePopup .endorse-comment .comment-textarea button:hover {
        background: #005ca5; }
    .manageEndorsePopup .endorse-comment .comment-textarea .text-sm {
      font-size: 10px;
      color: #8a8a8a;
      line-height: 12px;
      float: right;
      width: 50%;
      text-align: right;
      margin-top: 0px; }

@media (max-width: 992px) {
  #manageEndorsedPopup {
    overflow-y: scroll; }
  #manageEndorsePopup {
    padding-left: 0px !important; }
  #manageEndorsePopup {
    overflow: scroll; }
  .previousComments {
    width: 100%; }
  .gridPagination {
    min-width: 255px;
    overflow-y: auto; }
  .manageEndorsePopup .grid-head .endorse-result {
    width: 20%; }
  .manageEndorsePopup .grid-head .endorse-data {
    width: 80%; }
    .manageEndorsePopup .grid-head .endorse-data .endorse-data-right {
      width: 20%; }
    .manageEndorsePopup .grid-head .endorse-data .endorse-data-left {
      width: 75%; }
    .manageEndorsePopup .grid-head .endorse-data .breadcrumb {
      width: 70%; } }

@media (max-width: 768px) {
  .resourceEndorse .updateDate {
    width: 100%;
    border: none; }
  .remaningComemnts {
    float: none;
    width: 100%; }
  #manageEndorsePopup .modal-dialog,
  .opsfaq-modal .modal-dialog {
    margin-top: 80px !important; }
  .endorsecontainer .endorsement div.popover, .endorsecontainer .resourceEndorse .managerEndorse div.popover, .resourceEndorse .endorsecontainer .managerEndorse div.popover,
  .endorsecontainer .resourceEndorse .managerEndorse div.popover,
  .resourceEndorse .endorsecontainer .managerEndorse div.popover {
    min-width: 100%;
    left: 0px !important; }
  .manageEndorsePopup {
    width: 100%; }
    .manageEndorsePopup .endorse-comment {
      padding-top: 20px; }
    .manageEndorsePopup .endorseBody {
      padding: 0px 20px; }
    .manageEndorsePopup .comment-textarea {
      float: left;
      width: 100%;
      padding-bottom: 20px; }
      .manageEndorsePopup .comment-textarea .text-sm {
        width: 100%; }
      .manageEndorsePopup .comment-textarea textarea {
        min-height: 100px; } }

@media (max-width: 420px) {
  .endorsewrapper .popover, .endorsecontainer .endorsement div.popover, .endorsecontainer .resourceEndorse .managerEndorse div.popover, .resourceEndorse .endorsecontainer .managerEndorse div.popover,
  .endorsecontainer .resourceEndorse .managerEndorse div.popover,
  .resourceEndorse .endorsecontainer .managerEndorse div.popover {
    left: 0px !important;
    width: 100%; } }

/* manager endorse popup | end */
.opsendorseConfirm-modal.in + .modal-backdrop.in,
.faq-modal-backdrop.in,
.opsfaq-modal + .modal-backdrop.in {
  background-image: none !important;
  transition: 0s !important; }

/* opsendorseConfirm Modal */
.opsendorseConfirm-modal {
  background: rgba(0, 0, 0, 0.5); }
  .opsendorseConfirm-modal .icon-alert-outline {
    font-size: 30px;
    text-align: center;
    padding: 0px;
    border: none;
    float: left;
    padding-right: 15px;
    margin-top: 5px;
    color: #ff3434;
    width: 10%;
    max-width: 50px; }
  .opsendorseConfirm-modal .alert-text {
    float: left;
    float: left;
    width: 90%; }
  .opsendorseConfirm-modal .close {
    padding: 10px 0;
    color: #000;
    opacity: 1;
    font-weight: 400;
    font-size: 18px; }
  .opsendorseConfirm-modal .modal-dialog {
    margin-top: 15%; }
  .opsendorseConfirm-modal .modal-content {
    display: table;
    border-radius: 0;
    padding: 18px 5px 0px;
    width: 100%;
    margin: 0 auto;
    max-width: 450px; }
    .opsendorseConfirm-modal .modal-content .no-padding-left {
      padding-left: 0px; }
    .opsendorseConfirm-modal .modal-content .no-padding-left .btn {
      background: #fff; }
    .opsendorseConfirm-modal .modal-content .opsbtn-block {
      width: 100%;
      padding: 30px 0px 20px;
      max-width: 200px;
      margin: 0 auto;
      padding-left: 15px; }
      .opsendorseConfirm-modal .modal-content .opsbtn-block .btn {
        border-radius: 0;
        border: 2px solid #0078d6;
        width: 80px;
        padding: 6px 0px;
        box-shadow: none;
        background: none; }
        .opsendorseConfirm-modal .modal-content .opsbtn-block .btn:focus, .opsendorseConfirm-modal .modal-content .opsbtn-block .btn:hover {
          background: #005ca5;
          border-color: #005ca5;
          color: #fff;
          outline: 1px solid #000; }
      .opsendorseConfirm-modal .modal-content .opsbtn-block .btn-primary {
        background: #0078d6; }
        .opsendorseConfirm-modal .modal-content .opsbtn-block .btn-primary:focus, .opsendorseConfirm-modal .modal-content .opsbtn-block .btn-primary:hover {
          background: #005ca5;
          outline: 1px solid #000; }
    .opsendorseConfirm-modal .modal-content .msg .no-padding-right {
      padding-right: 0px; }
    .opsendorseConfirm-modal .modal-content .msg .close {
      font-size: 18px;
      padding-top: 0px; }
    .opsendorseConfirm-modal .modal-content .msg p {
      font-size: 14px;
      margin-bottom: 5px; }
    .opsendorseConfirm-modal .modal-content .msg .h3 {
      color: #595959;
      font-size: 18px !important;
      margin-bottom: 30px;
      margin-top: 0px; }

.opmgrnotification {
  background-color: #0078d6;
  text-decoration: none;
  padding: 5px;
  padding-left: 10px;
  padding-right: 10px;
  position: relative;
  border: none;
  font-weight: 600;
  color: white;
  font-size: 14px;
  border-radius: 3px; }

.opmgrnotification .opmgrbadge {
  position: absolute;
  top: -15px;
  right: -25px;
  padding: 5px 10px;
  border-radius: 50%;
  background: yellow;
  color: black;
  font-size: small; }

.modal-open .resource {
  overflow: hidden; }

/* opsendorseConfirm Modal | end */
/* only chrome css */
@media screen and (-webkit-min-device-pixel-ratio: 0) {
  .opsfaq-modal .modal-dialog {
    width: calc(100% - 72px);
    margin-right: 14px; }
  #manageEndorsePopup .modal-dialog {
    width: calc(100% - 67px);
    margin-right: 14px; }
  .manageEndorsePopup .endorse-grid .grid-header {
    width: calc(100% - 10px); }
  .manageEndorsePopup .endorse-grid .grid-body {
    width: calc(100% - 5px); } }

@supports (-webkit-appearance: none) {
  .manageEndorsePopup .endorse-grid .grid-header .grid-head {
    width: calc(100% - 7px); } }

@media screen and (-ms-high-contrast: active) {
  .endorsewrapper a,
  .endorsewrapper .icon-blue,
  .endorsewrapper .view-previous span,
  .endorsewrapper #num,
  .endorsewrapper .icon-customhelp {
    color: #8080ff; }
  .opsbtn-block .btn-block,
  .endorsewrapper #endorseDisBtn {
    border: 1px solid #fff;
    color: #fff; }
  .endorsewrapper #valueArc {
    fill: black !important; }
  .opsbtn-block .btn-block:hover,
  .opsbtn-block .btn-block:focus {
    border: 2px solid #fff; } }

@media screen and (-ms-high-contrast: black-on-white) {
  .endorsewrapper a,
  .endorsewrapper .icon-blue,
  .endorsewrapper .view-previous span,
  .endorsewrapper #num,
  .endorsewrapper .icon-customhelp {
    color: #00009f; }
  .opsbtn-block .btn-block,
  .endorsewrapper #endorseDisBtn {
    border: 1px solid #00009f;
    color: #00009f; }
  .endorsewrapper #valueArc {
    fill: #000 !important; }
  .opsbtn-block .btn-block:hover,
  .opsbtn-block .btn-block:focus {
    border: 2px solid #00009f; } }

@media screen and (-ms-high-contrast: white-on-black) {
  .endorsewrapper a,
  .endorsewrapper .icon-blue,
  .endorsewrapper .view-previous span,
  .endorsewrapper #num,
  .endorsewrapper .icon-customhelp {
    color: yellow; }
  .opsbtn-block .btn-block,
  .endorsewrapper #endorseDisBtn {
    border: 1px solid #fff;
    color: #fff; }
  .endorsewrapper #valueArc {
    fill: #fff !important; }
  .opsbtn-block .btn-block:hover,
  .opsbtn-block .btn-block:focus {
    border: 2px solid #fff; } }

.icon-container-layout {
  border: 0;
  width: auto;
  display: block;
  float: left;
  min-width: 0;
  min-height: 0;
  font-size: em(20px);
  height: 48px;
  width: 48px;
  text-align: center;
  background-color: transparent; }
  .icon-container-layout span {
    margin-left: 10px;
    position: relative;
    top: -3px;
    /* fix for improper positioning */ }

.icon {
  font-family: "SegoeUI MDL2 Assets";
  font-weight: normal;
  font-style: normal; }
  .icon.icon-ScrollChevronDownLegacy:before {
    content: "\E011" !important; }
  .icon.icon-close:before {
    content: "\E711" !important; }
  .icon.icon-right:before {
    content: "\E0AD" !important; }
  .icon.icon-left:before {
    content: "\E0A6" !important; }
  .icon.icon-up:before {
    content: "\E74A" !important; }
  .icon.icon-down:before {
    content: "\E74B" !important; }
  .icon.icon-blockedLegacy:before {
    content: "\E1F6" !important; }
  .icon.icon-filter:before {
    content: "\E16E" !important; }

.icon-layout {
  font-family: "SegoeUI MDL2 Assets";
  font-weight: normal;
  font-style: normal;
  position: relative;
  top: 1px;
  display: inline-block;
  line-height: 48px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*---------------Skill search pahe icons--------------------*/ }
  .icon-layout.icon-notification:before {
    content: "\E91C"; }
  .icon-layout.icon-add:before {
    content: "\E710"; }
  .icon-layout.icon-calendar:before {
    content: "\E787"; }
  .icon-layout.icon-back:before {
    content: "\E112"; }
  .icon-layout.icon-close:before {
    content: "\E10A"; }
  .icon-layout.icon-remove-sign:before {
    content: "\f057"; }
  .icon-layout.icon-hamburger:before {
    content: "\E700"; }
  .icon-layout.icon-search:before {
    content: "\E094"; }
  .icon-layout.icon-user:before {
    content: "\E2AF"; }
  .icon-layout.icon-settings:before {
    content: "\E713"; }
  .icon-layout.icon-chevron-up:before {
    content: "\E09C"; }
  .icon-layout.icon-chevron-down:before {
    content: "\E09D"; }
  .icon-layout.icon-chevron-right:before {
    content: "\E00F"; }
  .icon-layout.icon-star:before {
    content: "\E1CE"; }
  .icon-layout.icon-star-filled:before {
    content: "\E1CF"; }
  .icon-layout.icon-basket:before {
    content: "\E821"; }
  .icon-layout.icon-pipe:before {
    content: "\E17B"; }
  .icon-layout.icon-accept:before {
    content: "\E001"; }
  .icon-layout.icon-pipe-health:before {
    content: "\E17A"; }
  .icon-layout.icon-bulb:before {
    content: "\E82F"; }
  .icon-layout.icon-alert:before {
    content: "\E1DE"; }
  .icon-layout.icon-eye:before {
    content: "\E052"; }
  .icon-layout.icon-square:before {
    content: "\E25E"; }
  .icon-layout.icon-circle:before {
    content: "\EC14"; }
  .icon-layout.icon-home:before {
    content: "\E10F"; }
  .icon-layout.icon-apps:before {
    content: "\E74C"; }
  .icon-layout.icon-pointer:before {
    content: "\E1E3"; }
  .icon-layout.icon-next:before {
    content: "\E0AB"; }
  .icon-layout.icon-home2:before {
    content: "\E80F"; }
  .icon-layout.icon-devices:before {
    content: "\E70C"; }
  .icon-layout.icon-clock:before {
    content: "\E823"; }
  .icon-layout.icon-collapse:before {
    content: "\E92C"; }
  .icon-layout.icon-book:before {
    content: "\E82D"; }
  .icon-layout.icon-calender:before {
    content: "\E163"; }
  .icon-layout.icon-resize:before {
    content: "\E73F"; }
  .icon-layout.icon-favoriteStar:before {
    content: "\E734"; }
  .icon-layout.icon-emoji2:before {
    content: "\E76E"; }
  .icon-layout.icon-delete:before {
    content: "\E74D"; }
  .icon-layout.icon-fullscreen:before {
    content: "\E740"; }
  .icon-layout.icon-glyph-733:before {
    content: "\EA6C"; }
  .icon-layout.icon-world-wire:before {
    content: "\E12B"; }
  .icon-layout.icon-glyph-533:before {
    content: "\E8AB"; }
  .icon-layout.icon-glyph-378:before {
    content: "\E7EE"; }
  .icon-layout.icon-other-user:before {
    content: "\E7EE"; }
  .icon-layout.icon-check-outline-check:before {
    content: "\E0A2"; }
  .icon-layout.icon-plus:before {
    content: "\E948"; }
  .icon-layout.icon-glyph-81e:before {
    content: "\E81E"; }
  .icon-layout.icon-glyph-7c4:before {
    content: "\E7C4"; }
  .icon-layout.icon-contact-info:before {
    content: "\E779"; }
  .icon-layout.icon-theme:before {
    content: "\E771"; }
  .icon-layout.icon-users:before {
    content: "\E125"; }
  .icon-layout.icon-glyph-957:before {
    content: "\E957"; }
  .icon-layout.icon-glyph-1a6:before {
    content: "\E1A6"; }
  .icon-layout.icon-glyph-91f:before {
    content: "\E91F"; }
  .icon-layout.icon-tick:before {
    content: "\E73E"; }
  .icon-layout.icon-account-outline:before {
    content: "\E13D"; }
  .icon-layout.icon-edit:before {
    content: "\E70F"; }
  .icon-layout.icon-usersearch:before {
    content: "\E8CF"; }
  .icon-layout.icon-sucsess-alert:before {
    content: "\E930"; }
  .icon-layout.icon-info-alert:before {
    content: "\E946"; }
  .icon-layout.icon-security-alert:before {
    content: "\E1DE"; }
  .icon-layout.icon-warning:before {
    content: "\E7BA"; }
  .icon-layout.icon-stop:before {
    content: "\E71A"; }
  .icon-layout.icon-save:before {
    content: "\E74E"; }
  .icon-layout.icon-publish:before {
    content: "\E930"; }
  .icon-layout.icon-cancelpublish:before {
    content: "\EA39"; }
  .icon-layout.icon-arrowHTMLMirroredLegacy:before {
    content: "\E0AE"; }
  .icon-layout.icon-arrowHTMLLegacy:before {
    content: "\E0D5"; }
  .icon-layout.icon-account-plus:before {
    content: "\E1E2"; }
  .icon-layout.icon-add-friend:before {
    content: "\E8FA"; }
  .icon-layout.icon-glyph-8cc:before {
    content: "\E8CC"; }
  .icon-layout.icon-glyph-152:before {
    content: "\E152"; }
  .icon-layout.icon-transcribe:before {
    content: "\E929"; }
  .icon-layout.icon-caption:before {
    content: "\E8BA"; }
  .icon-layout.icon-details:before {
    content: "\E12A"; }
  .icon-layout.icon-accept2:before {
    content: "\E8FB"; }
  .icon-layout.icon-disconnect-drive:before {
    content: "\E8CD"; }
  .icon-layout.icon-lock:before {
    content: "\E1F6"; }
  .icon-layout.icon-refresh:before {
    content: "\E1CD"; }
  .icon-layout.icon-new-window:before {
    content: "\E2B4"; }
  .icon-layout.icon-forward:before {
    content: "\E72A"; }
  .icon-layout.icon-back2:before {
    content: "\E72B"; }
  .icon-layout.icon-glyph-411:before {
    content: "\E81C"; }
  .icon-layout.icon-folder-move:before {
    content: "\E8DE"; }
  .icon-layout.icon-info:before {
    content: "\E946"; }
  .icon-layout.icon-arrow-left:before {
    content: "\E0A6"; }
  .icon-layout.icon-arrow-right:before {
    content: "\E0AD"; }
  .icon-layout.icon-email-opened:before {
    content: "\E166"; }
  .icon-layout.icon-send:before {
    content: "\E122"; }
  .icon-layout.icon-compare:before {
    content: "\E11E"; }
  .icon-layout.icon-cancel:before {
    content: "\E711"; }
  .icon-layout.icon-up:before {
    content: "\E74A"; }
  .icon-layout.icon-down:before {
    content: "\E74A"; }
  .icon-layout.icon-arrow-down:before {
    content: "\E74B"; }
  .icon-layout.icon-repair:before {
    content: "\E90F"; }
  .icon-layout.icon-pagination-previous:before {
    content: "\E892"; }
  .icon-layout.icon-pagination-next:before {
    content: "\E893"; }
  .icon-layout.icon-assignback:before {
    content: "\E845"; }
  .icon-layout.icon-scrollchevron-left:before {
    content: "\E016"; }
  .icon-layout.icon-scrollchevron-right:before {
    content: "\E017"; }
  .icon-layout.icon-scrollchevron-up:before {
    content: "\E018"; }
  .icon-layout.icon-scrollchevron-down:before {
    content: "\E019"; }
  .icon-layout.icon-check:before {
    content: "\E739"; }
  .icon-layout.icon-uncheck:before {
    content: "\E73A"; }
  .icon-layout.icon-filter:before {
    content: "\E71C"; }
  .icon-layout.icon-error-icon:before {
    content: "\E7BA"; }
  .icon-layout.icon-rating-star:before {
    content: "\E1CE"; }
  .icon-layout.icon-solid-star:before {
    content: "\E1CF"; }
  .icon-layout.icon-flag-outline:before {
    content: "\EB50"; }
  .icon-layout.icon-add-plus:before {
    content: "\E710"; }
  .icon-layout.icon-utilization:before {
    content: "\E716"; }
  .icon-layout.icon-copy:before {
    content: "\E8C8"; }
  .icon-layout.icon-bench:before {
    content: "\E81C"; }
  .icon-layout.icon-sync:before {
    content: "\E895"; }
  .icon-layout.icon-timer:before {
    content: "\E916"; }
  .icon-layout.icon-flag:before {
    content: "\E7C1"; }
  .icon-layout.icon-availability:before {
    content: "\E930"; }
  .icon-layout.icon-calendar1:before {
    content: "\E787"; }
  .icon-layout.icon-lost-labour:before {
    content: "\E8F8"; }
  .icon-layout.icon-average-time:before {
    content: "\E823"; }
  .icon-layout.icon-variance:before {
    content: "\E2AE"; }
  .icon-layout.icon-checkbox:before {
    content: "\E739"; }
  .icon-layout.icon-CheckboxComposite:before {
    content: "\E73A"; }
  .icon-layout.icon-checkboxindeterminate:before {
    content: "\E73C"; }
  .icon-layout.icon-error:before {
    content: "?"; }
  .icon-layout.icon-error-alert:before {
    content: "\E7BA"; }
  .icon-layout.icon-warningalert:before {
    content: "\E1DE"; }
  .icon-layout.icon-infoalert:before {
    content: "\E946"; }
  .icon-layout.icon-success-alert:before {
    content: "\E930"; }
  .icon-layout.icon-show-note:before {
    content: "\E70B"; }
  .icon-layout.icon-assign-resource:before {
    content: "\E136"; }
  .icon-layout.icon-quick-assign:before {
    content: "\E1E2"; }
  .icon-layout.icon-gylph-255:before {
    content: "\E248"; }
  .icon-layout.icon-wrench:before {
    content: "\E15E"; }
  .icon-layout.icon-gylph-89:before {
    content: "\E14C"; }
  .icon-layout.icon-account-frame-outline:before {
    content: "\E156"; }
  .icon-layout.icon-gylph-336:before {
    content: "\E779"; }
  .icon-layout.icon-success:before {
    content: "\E930"; }
  .icon-layout.icon-fielderror:before {
    content: "\E7BA"; }
  .icon-layout.icon-cross:before {
    content: "\E947"; }
  .icon-layout.icon-droupdown-arrow:before {
    content: "\E70D"; }
  .icon-layout.icon-checkblack:before {
    content: "\E73D"; }
  .icon-layout.icon-help:before {
    content: "\E8C9"; }
  .icon-layout.icon-flipped {
    -moz-transform: scale(1, -1);
    -webkit-transform: scale(1, -1);
    -o-transform: scale(1, -1);
    -ms-transform: scale(1, -1);
    transform: scale(1, -1); }

.icon-requestor {
  font-family: "SegoeUI MDL2 Assets";
  font-weight: normal;
  font-style: normal;
  position: relative;
  top: 1px;
  display: inline-block;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  .icon-requestor.icon-notification:before {
    content: "\E91C"; }
  .icon-requestor.icon-back:before {
    content: "\E112"; }
  .icon-requestor.icon-close:before {
    content: "\E10A"; }
  .icon-requestor.icon-hamburger:before {
    content: "\E700"; }
  .icon-requestor.icon-search:before {
    content: "\E094"; }
  .icon-requestor.icon-user:before {
    content: "\E2AF"; }
  .icon-requestor.icon-settings:before {
    content: "\E713"; }
  .icon-requestor.icon-chevron-up:before {
    content: "\E09C"; }
  .icon-requestor.icon-chevron-down:before {
    content: "\E09D"; }
  .icon-requestor.icon-chevron-right:before {
    content: "\E00F"; }
  .icon-requestor.icon-star:before {
    content: "\E1CE"; }
  .icon-requestor.icon-star-filled:before {
    content: "\E1CF"; }
  .icon-requestor.icon-basket:before {
    content: "\E821"; }
  .icon-requestor.icon-pipe:before {
    content: "\E17B"; }
  .icon-requestor.icon-accept:before {
    content: "\E001"; }
  .icon-requestor.icon-pipe-health:before {
    content: "\E17A"; }
  .icon-requestor.icon-bulb:before {
    content: "\E82F"; }
  .icon-requestor.icon-alert:before {
    content: "\E1DE"; }
  .icon-requestor.icon-eye:before {
    content: "\E052"; }
  .icon-requestor.icon-square:before {
    content: "\E25E"; }
  .icon-requestor.icon-circle:before {
    content: "\EC14"; }
  .icon-requestor.icon-home:before {
    content: "\E10F"; }
  .icon-requestor.icon-apps:before {
    content: "\E74C"; }
  .icon-requestor.icon-pointer:before {
    content: "\E1E3"; }
  .icon-requestor.icon-next:before {
    content: "\E0AB"; }
  .icon-requestor.icon-home2:before {
    content: "\E80F"; }
  .icon-requestor.icon-devices:before {
    content: "\E70C"; }
  .icon-requestor.icon-clock:before {
    content: "\E823"; }
  .icon-requestor.icon-collapse:before {
    content: "\E92C"; }
  .icon-requestor.icon-book:before {
    content: "\E82D"; }
  .icon-requestor.icon-calender:before {
    content: "\E163"; }
  .icon-requestor.icon-resize:before {
    content: "\E73F"; }
  .icon-requestor.icon-favoriteStar:before {
    content: "\E734"; }
  .icon-requestor.icon-emoji2:before {
    content: "\E76E"; }
  .icon-requestor.icon-delete:before {
    content: "\E74D"; }
  .icon-requestor.icon-fullscreen:before {
    content: "\E740"; }
  .icon-requestor.icon-glyph-733:before {
    content: "\EA6C"; }
  .icon-requestor.icon-world-wire:before {
    content: "\E12B"; }
  .icon-requestor.icon-glyph-533:before {
    content: "\E8AB"; }
  .icon-requestor.icon-glyph-378:before {
    content: "\E7EE"; }
  .icon-requestor.icon-other-user:before {
    content: "\E7EE"; }
  .icon-requestor.icon-check-outline-check:before {
    content: "\E0A2"; }
  .icon-requestor.icon-plus:before {
    content: "\E948"; }
  .icon-requestor.icon-glyph-81e:before {
    content: "\E81E"; }
  .icon-requestor.icon-glyph-7c4:before {
    content: "\E7C4"; }
  .icon-requestor.icon-contact-info:before {
    content: "\E779"; }
  .icon-requestor.icon-theme:before {
    content: "\E771"; }
  .icon-requestor.icon-users:before {
    content: "\E125"; }
  .icon-requestor.icon-glyph-957:before {
    content: "\E957"; }
  .icon-requestor.icon-glyph-1a6:before {
    content: "\E1A6"; }
  .icon-requestor.icon-glyph-91f:before {
    content: "\E91F"; }
  .icon-requestor.icon-tick:before {
    content: "\E73E"; }
  .icon-requestor.icon-account-outline:before {
    content: "\E13D"; }
  .icon-requestor.icon-edit:before {
    content: "\E104"; }
  .icon-requestor.icon-stop:before {
    content: "\E71A"; }
  .icon-requestor.icon-save:before {
    content: "\E74E"; }
  .icon-requestor.icon-publish:before {
    content: "\E930"; }
  .icon-requestor.icon-arrowHTMLMirroredLegacy:before {
    content: "\E0AE"; }
  .icon-requestor.icon-arrowHTMLLegacy:before {
    content: "\E0D5"; }
  .icon-requestor.icon-account-plus:before {
    content: "\E1E2"; }
  .icon-requestor.icon-add-friend:before {
    content: "\E8FA"; }
  .icon-requestor.icon-glyph-8cc:before {
    content: "\E8CC"; }
  .icon-requestor.icon-glyph-152:before {
    content: "\E152"; }
  .icon-requestor.icon-transcribe:before {
    content: "\E929"; }
  .icon-requestor.icon-caption:before {
    content: "\E8BA"; }
  .icon-requestor.icon-details:before {
    content: "\E12A"; }
  .icon-requestor.icon-accept2:before {
    content: "\E8FB"; }
  .icon-requestor.icon-disconnect-drive:before {
    content: "\E8CD"; }
  .icon-requestor.icon-lock:before {
    content: "\E1F6"; }
  .icon-requestor.icon-refresh:before {
    content: "\E1CD"; }
  .icon-requestor.icon-new-window:before {
    content: "\E2B4"; }
  .icon-requestor.icon-forward:before {
    content: "\E72A"; }
  .icon-requestor.icon-back2:before {
    content: "\E72B"; }
  .icon-requestor.icon-glyph-411:before {
    content: "\E81C"; }
  .icon-requestor.icon-folder-move:before {
    content: "\E8DE"; }
  .icon-requestor.icon-info:before {
    content: "\E946"; }
  .icon-requestor.icon-arrow-left:before {
    content: "\E0A6"; }
  .icon-requestor.icon-arrow-right:before {
    content: "\E0AD"; }
  .icon-requestor.icon-email-opened:before {
    content: "\E166"; }
  .icon-requestor.icon-send:before {
    content: "\E122"; }
  .icon-requestor.icon-compare:before {
    content: "\E11E"; }
  .icon-requestor.icon-downarrow:before {
    content: "\E019"; }
  .icon-requestor.icon-addplus:before {
    content: "\E710"; }
  .icon-requestor.icon-duplicate:before {
    content: "\E8C8"; }
  .icon-requestor.icon-remove:before {
    content: "\E711"; }
  .icon-requestor.icon-calender:before {
    content: "\E787"; }
  .icon-requestor.icon-edit:before {
    content: "\E70F"; }
  .icon-requestor.icon-search:before {
    content: "\E71E"; }
  .icon-requestor.icon-check:before {
    content: "\E739"; }
  .icon-requestor.icon-uncheck:before {
    content: "\E005"; }
  .icon-requestor.icon-transfer:before {
    content: "\E716"; }
  .icon-requestor.icon-suspend:before {
    content: "\E876"; }
  .icon-requestor.icon-cancel:before {
    content: "\E871"; }
  .icon-requestor.icon-droupdown:before {
    content: "\E0E4"; }
  .icon-requestor.icon-gylph-255:before {
    content: "\E248"; }
  .icon-requestor.icon-wrench:before {
    content: "\E15E"; }
  .icon-requestor.icon-gylph-89:before {
    content: "\E14C"; }
  .icon-requestor.icon-account-frame-outline:before {
    content: "\E156"; }
  .icon-requestor.icon-gylph-336:before {
    content: "\E779"; }
  .icon-requestor.icon-success:before {
    content: "\E930"; }
  .icon-requestor.icon-fielderror:before {
    content: "\E7BA"; }
  .icon-requestor.icon-cross:before {
    content: "\E947"; }
  .icon-requestor.icon-droupdown-arrow:before {
    content: "\E70D"; }
  .icon-requestor.icon-requestor.icon-calendar-week:before {
    content: "\E162"; }
  .icon-requestor.icon-notes:before {
    content: "\E130"; }

.icon-endorse.icon-cancel:before {
  content: "\E25B"; }

.icon-endorse.icon-alert-outline:before {
  content: "\E7BA"; }

.icon-endorse.icon-gylph-588:before {
  content: "\E930"; }

.icon-endorse.icon-check:before {
  content: "\E001"; }

.icon-customhelp {
  font-family: "ms_icon_family";
  font-style: normal; }
  .icon-customhelp.icon-help:before {
    content: "\e972"; }
  .icon-customhelp.icon-skills:before {
    content: "\E97C"; }

.block, .resource .profile-main .skill-inner-right .skill-inner-right-bg .standerdtimezone, .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections {
  display: block;
  overflow: hidden; }

.in-block, .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections ul li {
  display: block;
  overflow: hidden; }

.error i {
  color: #e04d4d;
  margin-right: 3px; }

.error span {
  color: #e04d4d;
  font-size: 13px;
  margin-top: 3px; }

.error input {
  background: #f4d6d6; }

/* sales skill rating variables */
/* sales skill rating variables | end */
.sr_learner .sr_container .row-10 {
  margin-left: -10px;
  margin-right: -10px; }

.sr_learner .sr_container .pad-l-r-10 {
  padding-left: 10px;
  padding-right: 10px; }

.sr_learner .sr_container .sr_l_planning {
  background: url(../../../../images/your_planning.png);
  background-repeat: no-repeat;
  background-size: 150px;
  background-position: right bottom; }

.sr_learner .sr_container .sr_learner_title {
  background: #fff; }

.sr_learner .sr_container .tabs_section {
  justify-content: center;
  display: flex;
  height: 100%; }

.sr_learner .sr_container .sr_l_desc {
  font-family: Segoe UI;
  font-size: 16px;
  color: #3c3c3c; }

.sr_learner .sr_container .sr_title {
  padding-right: 0;
  word-wrap: break-word;
  font-weight: 300;
  margin-top: 10px; }

.sr_learner .sr_container .sr_heading {
  font-size: 18px; }

.sr_learner .sr_container .sr_subHeading {
  font-size: 15px; }

.sr_learner .sr_container .guided-tour-container {
  text-align: right;
  margin-top: 7px; }
  .sr_learner .sr_container .guided-tour-container .settingsBtn {
    color: #3c3c3c;
    float: right;
    font-size: 25px;
    cursor: pointer; }

.sr_learner .sr_container .sr_l_tiles {
  font-size: 13px; }

.sr_learner .sr_container .sr_l_tile {
  display: block;
  background-color: #fff;
  margin-bottom: 20px;
  position: relative;
  border: 1px solid transparent;
  cursor: default; }
  .sr_learner .sr_container .sr_l_tile--clickable:hover, .sr_learner .sr_container .sr_l_tile--clickable:focus {
    outline: 1px solid #0071cb;
    transition: all 100ms ease-in-out;
    cursor: pointer; }
  .sr_learner .sr_container .sr_l_tile .sr_l_video_placeholder {
    position: absolute;
    width: 100%;
    height: 100%;
    color: #fff;
    text-align: center;
    top: 0px;
    left: 0px; }
    .sr_learner .sr_container .sr_l_tile .sr_l_video_placeholder .video_placeholder-loader {
      top: -25px;
      position: relative; }
    .sr_learner .sr_container .sr_l_tile .sr_l_video_placeholder p {
      font-size: 20px;
      line-height: 120px; }
    .sr_learner .sr_container .sr_l_tile .sr_l_video_placeholder a {
      color: #0063b1; }
    .sr_learner .sr_container .sr_l_tile .sr_l_video_placeholder iframe {
      position: absolute;
      left: 0;
      top: 0; }
  .sr_learner .sr_container .sr_l_tile.sr_l_min-320 {
    min-height: 320px; }
  .sr_learner .sr_container .sr_l_tile.sr_l_min-345 {
    min-height: 345px; }
  .sr_learner .sr_container .sr_l_tile.sr_l_min-350 {
    min-height: 350px; }
  .sr_learner .sr_container .sr_l_tile.sr_l_min-150 {
    min-height: 150px; }
  .sr_learner .sr_container .sr_l_tile.sr_l_min-200 {
    min-height: 200px; }
  .sr_learner .sr_container .sr_l_tile .sr_l_t_header {
    width: 100%;
    float: left;
    min-height: 64px;
    margin-bottom: 20px;
    border-bottom: 1px solid #ddd;
    display: flex;
    align-items: center; }
    .sr_learner .sr_container .sr_l_tile .sr_l_t_header .sr_l_t_step {
      font-size: 15px;
      text-align: center;
      margin-right: 20px;
      color: #666666;
      padding: 10px;
      border-right: 1px solid #ddd;
      min-height: 60px;
      width: 70px; }
      .sr_learner .sr_container .sr_l_tile .sr_l_t_header .sr_l_t_step.sr_l_t_mar-10 {
        margin-right: 10px; }
      .sr_learner .sr_container .sr_l_tile .sr_l_t_header .sr_l_t_step.no-bar {
        border: none; }
      .sr_learner .sr_container .sr_l_tile .sr_l_t_header .sr_l_t_step .icon {
        font-size: 16px;
        color: #0063b1; }
        .sr_learner .sr_container .sr_l_tile .sr_l_t_header .sr_l_t_step .icon.icon-link {
          font-size: 32px;
          margin-right: 7px;
          margin-top: 7px; }
      .sr_learner .sr_container .sr_l_tile .sr_l_t_header .sr_l_t_step p {
        margin: 0px;
        color: #303030; }
        .sr_learner .sr_container .sr_l_tile .sr_l_t_header .sr_l_t_step p.sr_l_t_count {
          font-weight: bold;
          font-size: 20px;
          line-height: 15px; }
    .sr_learner .sr_container .sr_l_tile .sr_l_t_header .sr_l_t_title {
      width: calc(100% - 125px);
      color: #0063b1; }
      .sr_learner .sr_container .sr_l_tile .sr_l_t_header .sr_l_t_title a.h2,
      .sr_learner .sr_container .sr_l_tile .sr_l_t_header .sr_l_t_title span.h2 {
        font-weight: 300;
        font-size: 24px;
        margin: 0;
        display: flex;
        align-items: center; }
        .sr_learner .sr_container .sr_l_tile .sr_l_t_header .sr_l_t_title a.h2 .sr_a_title,
        .sr_learner .sr_container .sr_l_tile .sr_l_t_header .sr_l_t_title span.h2 .sr_a_title {
          width: calc(100% - 20px);
          float: left; }
        .sr_learner .sr_container .sr_l_tile .sr_l_t_header .sr_l_t_title a.h2 .chevron,
        .sr_learner .sr_container .sr_l_tile .sr_l_t_header .sr_l_t_title span.h2 .chevron {
          font-size: 16px;
          float: right;
          transform: translateX(23px); }
  .sr_learner .sr_container .sr_l_tile .sr_l_t_loader {
    position: relative;
    top: 75px; }
  .sr_learner .sr_container .sr_l_tile .sr_l_t_body {
    width: 100%;
    float: left;
    padding: 0px 20px; }
    .sr_learner .sr_container .sr_l_tile .sr_l_t_body .sr_l_complete {
      display: none; }
    .sr_learner .sr_container .sr_l_tile .sr_l_t_body.sr_l_review_complete .sr_l_complete {
      text-align: center;
      color: #008000;
      display: block; }
      .sr_learner .sr_container .sr_l_tile .sr_l_t_body.sr_l_review_complete .sr_l_complete .sr_l_icon {
        border-radius: 50%;
        display: inline-block;
        background: #008000;
        color: white;
        padding: 1px;
        margin-right: 5px;
        top: 2px; }
    .sr_learner .sr_container .sr_l_tile .sr_l_t_body .sr_l_knob {
      position: relative;
      width: 182px;
      color: #333333;
      margin: 10px auto;
      display: table; }
      .sr_learner .sr_container .sr_l_tile .sr_l_t_body .sr_l_knob .sr_l_kn_details {
        position: absolute;
        top: 37px;
        left: 40px;
        width: 120px; }
      .sr_learner .sr_container .sr_l_tile .sr_l_t_body .sr_l_knob .sr_l_k_l1 {
        color: #3a8639; }
        .sr_learner .sr_container .sr_l_tile .sr_l_t_body .sr_l_knob .sr_l_k_l1 p {
          font-size: 52px;
          float: left;
          font-weight: 200;
          margin: 0px;
          line-height: 40px; }
          .sr_learner .sr_container .sr_l_tile .sr_l_t_body .sr_l_knob .sr_l_k_l1 p.smaller {
            font-size: 40px; }
        .sr_learner .sr_container .sr_l_tile .sr_l_t_body .sr_l_knob .sr_l_k_l1 .small {
          font-size: 12px;
          display: block;
          text-align: left; }
      .sr_learner .sr_container .sr_l_tile .sr_l_t_body .sr_l_knob .sr_l_k_l2 {
        float: left;
        margin-top: 25px;
        font-weight: 200; }
        .sr_learner .sr_container .sr_l_tile .sr_l_t_body .sr_l_knob .sr_l_k_l2 p {
          font-size: 34px;
          margin-top: 0px;
          float: left;
          margin-bottom: 0px;
          color: #666666; }
          .sr_learner .sr_container .sr_l_tile .sr_l_t_body .sr_l_knob .sr_l_k_l2 p.smaller {
            font-size: 25px; }
        .sr_learner .sr_container .sr_l_tile .sr_l_t_body .sr_l_knob .sr_l_k_l2 .small {
          margin-top: 8px;
          line-height: 0px;
          float: left;
          font-size: 32px;
          margin-left: -5px;
          color: #b2b2b2; }
      .sr_learner .sr_container .sr_l_tile .sr_l_t_body .sr_l_knob .sr_l_k_l3 {
        width: 100%;
        float: left;
        font-size: 12px;
        color: #666666;
        text-align: center;
        top: 4px;
        position: relative;
        left: -3px; }
    .sr_learner .sr_container .sr_l_tile .sr_l_t_body .sr_l_list {
      float: left;
      width: 100%;
      margin: 0px;
      padding: 0px;
      list-style: none; }
      .sr_learner .sr_container .sr_l_tile .sr_l_t_body .sr_l_list li::before {
        content: "\2022";
        color: #0063b1;
        font-size: 21px;
        line-height: 27px;
        position: relative;
        top: 3px;
        margin-right: 5px; }
        .sr_learner .sr_container .sr_l_tile .sr_l_t_body .sr_l_list li::before a {
          font-size: 16px;
          color: #0063b1; }
  .sr_learner .sr_container .sr_l_tile .sr_l_t_footer {
    padding: 20px;
    float: left;
    width: 100%;
    padding-top: 0px;
    color: black; }
    .sr_learner .sr_container .sr_l_tile .sr_l_t_footer .sr_l_t_review p {
      margin: 0px;
      color: #666666; }
      .sr_learner .sr_container .sr_l_tile .sr_l_t_footer .sr_l_t_review p.sr_l_t_review_time--error {
        color: #BB4114; }
        .sr_learner .sr_container .sr_l_tile .sr_l_t_footer .sr_l_t_review p.sr_l_t_review_time--error::before {
          content: "\E7BA";
          color: #BB4114;
          font-family: 'segoe mdl2 assets';
          top: 1px;
          position: relative;
          margin-right: 3px;
          font-size: 10px;
          text-transform: inherit; }
    .sr_learner .sr_container .sr_l_tile .sr_l_t_footer.pull-top {
      top: -18px;
      position: relative;
      padding-bottom: 19px; }

/* skillrating Learner | Dashboard | start */
/* services U Team  CSS | start */
su-learningplan-summary-step-two,
su-learningplan-summary-step-three {
  display: inline-block;
  width: 100%; }
  su-learningplan-summary-step-two .su-dashboardtile-container,
  su-learningplan-summary-step-three .su-dashboardtile-container {
    margin-bottom: 20px;
    width: 100%;
    text-align: left; }
    su-learningplan-summary-step-two .su-dashboardtile-container .su-dashboardtile-left-section,
    su-learningplan-summary-step-three .su-dashboardtile-container .su-dashboardtile-left-section {
      height: 70px;
      width: 55px;
      padding: 10px 0;
      margin-right: 20px; }
    su-learningplan-summary-step-two .su-dashboardtile-container .su-dashboardtile-right-section,
    su-learningplan-summary-step-three .su-dashboardtile-container .su-dashboardtile-right-section {
      width: calc(100% - 75px);
      height: 70px;
      font-weight: 400;
      padding: 0;
      display: flex;
      align-items: center; }
      su-learningplan-summary-step-two .su-dashboardtile-container .su-dashboardtile-right-section .su-dashboardtile-right-section-headertext,
      su-learningplan-summary-step-three .su-dashboardtile-container .su-dashboardtile-right-section .su-dashboardtile-right-section-headertext {
        font-Family: Segoe UI; }

/* services U Team  CSS | end  */
/* responsive css */
@media (max-width: 768px) {
  .sr_learner .sr_container .sr_l_tile.sr_l_min-345 {
    min-height: 350px; } }

/* responsive css | end */
/* org view(My Role) code start*/
.org_view {
  margin-top: 20px; }

.tabs_separator li:first-child a:after {
  height: 40%;
  width: 2px;
  position: absolute;
  background: #3c3c3c;
  right: -2px;
  content: '';
  top: 15px; }

.sales_tabs {
  border-bottom: none !important;
  margin-top:  2px;
  font-size: 18px;
  font-family: Segoe UI;
  color: #3c3c3c;
  font-weight: 500; }
  .sales_tabs .active {
    border-width: 0 0 2px 0 !important;
    border-color:   #0078D7 !important;
    border-style: solid !important;
    color: #0078D7 !important; }
    .sales_tabs .active a {
      color: #0078D7 !important;
      border: none !important; }
      .sales_tabs .active a:focus {
        border: none !important; }
  .sales_tabs li a {
    border: none !important;
    color: #3c3c3c !important;
    background-color: #fff; }
    .sales_tabs li a:hover {
      background-color: #fff; }
    .sales_tabs li a:active {
      color: #0078D7 !important; }
    .sales_tabs li a:focus {
      border: none !important;
      outline: #3c3c3c 1px dotted;
      background-color: #fff; }

/* org view(My Role)code end*/
.block, .resource .profile-main .skill-inner-right .skill-inner-right-bg .standerdtimezone, .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections {
  display: block;
  overflow: hidden; }

.in-block, .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections ul li {
  display: block;
  overflow: hidden; }

.error i {
  color: #e04d4d;
  margin-right: 3px; }

.error span {
  color: #e04d4d;
  font-size: 13px;
  margin-top: 3px; }

.error input {
  background: #f4d6d6; }

/* sales skill rating variables */
/* sales skill rating variables | end */
/* barChat Directive css | start */
.sr-bar-chart {
  float: left;
  width: 100%; }
  .sr-bar-chart p.ratingText {
    width: calc(100% - 70px);
    float: left;
    font-size: 15px;
    text-align: left;
    margin: 0px;
    margin-bottom: 4px; }
  .sr-bar-chart p.sr-per {
    float: right;
    width: 60px;
    margin-top: -22px;
    font-size: 20px;
    font-weight: 600;
    padding-right: 0px;
    margin-bottom: 0px;
    text-align: center; }
  .sr-bar-chart .sr-bar-chart-bg {
    width: calc(100% - 70px);
    background: #eaeaea;
    box-sizing: border-box;
    float: left;
    border: 1px solid transparent; }
    .sr-bar-chart .sr-bar-chart-bg .sr-bar-chart-progress {
      box-sizing: border-box;
      float: left;
      height: 5px; }
    .sr-bar-chart .sr-bar-chart-bg.sr-per i {
      font-size: 16px;
      color: #fff;
      float: right;
      margin-top: 6px; }

/* barChat Directive css | end */
/* manager dashboard css | start */
.sr_m_note {
  margin-top: 20px;
  font-family: Segoe UI;
  font-size: 16px;
  margin-bottom: 20px;
  color: #3c3c3c; }

.sr_t_dashboard {
  padding-bottom: 15px;
  float: left;
  width: 100%;
  background: inherit; }
  .sr_t_dashboard .pad-left-right-23 {
    padding-left: 23px;
    padding-right: 23px; }
  .sr_t_dashboard .sr_t_tabs {
    background: #fff;
    border-bottom: 1px solid #ddd;
    width: 100%;
    float: left; }
    .sr_t_dashboard .sr_t_tabs .nav-tabs {
      padding-left: 8px;
      width: 100%; }
      .sr_t_dashboard .sr_t_tabs .nav-tabs li button[role=tab] {
        background: transparent;
        border: none;
        color: #666666; }
      .sr_t_dashboard .sr_t_tabs .nav-tabs li button {
        padding: 8px 14px;
        font-size: 18px; }
      .sr_t_dashboard .sr_t_tabs .nav-tabs li.active button {
        color: #0063b1;
        border-bottom: 2px solid #0063b1;
        font-weight: 500; }
    .sr_t_dashboard .sr_t_tabs.sr_t_tabs_level2 .nav-tabs {
      border-bottom: none; }
    .sr_t_dashboard .sr_t_tabs.sr_t_tabs_level2 button {
      font-size: 15px !important;
      padding: 8px 23px !important; }
    .sr_t_dashboard .sr_t_tabs .sr_pull-right {
      margin-right: 27px; }
  .sr_t_dashboard .sr_pad-left-20 {
    padding-left: 20px; }
  .sr_t_dashboard .sr_pad-right-10 {
    padding-left: 20px; }
  .sr_t_dashboard .sr_pad_skill_dev {
    padding-left: 0px;
    padding-right: 0px; }
  .sr_t_dashboard .sr_t_filters {
    font-size: 13px;
    float: left;
    width: 100%;
    margin-bottom: 15px;
    margin-top: 20px; }
    .sr_t_dashboard .sr_t_filters .sr_thumbDropDown {
      width: 130px; }
      .sr_t_dashboard .sr_t_filters .sr_thumbDropDown .dropdown-toggle {
        background: transparent;
        border: none;
        color: #333333;
        box-shadow: none;
        padding: 5px;
        margin-top: -5px;
        text-shadow: none;
        font-weight: 500;
        font-size: 15px; }
        .sr_t_dashboard .sr_t_filters .sr_thumbDropDown .dropdown-toggle:hover, .sr_t_dashboard .sr_t_filters .sr_thumbDropDown .dropdown-toggle:focus {
          background: transparent;
          border: none;
          color: #333333;
          box-shadow: none;
          outline: 1px dashed #333333; }
        .sr_t_dashboard .sr_t_filters .sr_thumbDropDown .dropdown-toggle i {
          margin-top: 3px;
          float: right;
          margin-left: 12px;
          font-size: 12px;
          font-weight: bold; }
    .sr_t_dashboard .sr_t_filters .sr_t_nopad {
      padding-left: 0px;
      padding-right: 0px; }
    .sr_t_dashboard .sr_t_filters .sr_t_nopad-right {
      padding-right: 0px; }
    .sr_t_dashboard .sr_t_filters .sr_t_nopad-left {
      padding-left: 0px; }
    .sr_t_dashboard .sr_t_filters .sr_t_filters_210 {
      width: 220px; }
    .sr_t_dashboard .sr_t_filters .sr_t_filters_140 {
      width: 140px; }
    .sr_t_dashboard .sr_t_filters .sr_t_filters_86 {
      width: 155px !important; }
    .sr_t_dashboard .sr_t_filters .sr_t_filters_120 {
      width: 120px;
      float: left; }
      .sr_t_dashboard .sr_t_filters .sr_t_filters_120 p.h3 {
        margin-top: -10px;
        border-right: 2px solid #ccc;
        margin-right: 10px;
        padding: 5px 0px; }
    .sr_t_dashboard .sr_t_filters .sr_t_filters_220 {
      width: 270px;
      padding-right: 0px; }
    .sr_t_dashboard .sr_t_filters p.fr-text {
      width: 65px;
      float: left;
      font-weight: 300;
      font-size: 15px;
      text-transform: capitalize; }
    .sr_t_dashboard .sr_t_filters select {
      width: 100px;
      float: left; }
    .sr_t_dashboard .sr_t_filters .sr_td_legend {
      width: 120px;
      float: left;
      margin-right: 10px;
      display: flex;
      align-items: center;
      margin-top: -6px; }
      .sr_t_dashboard .sr_t_filters .sr_td_legend:last-child {
        margin-right: 0px; }
      .sr_t_dashboard .sr_t_filters .sr_td_legend p {
        float: left;
        width: 100px;
        padding-right: 5px;
        text-align: right;
        margin-top: 7px; }
      .sr_t_dashboard .sr_t_filters .sr_td_legend .sr_td_icon {
        width: 13px;
        color: #fff;
        float: left;
        position: relative;
        border: 1px solid transparent;
        border-bottom: 0px; }
        .sr_t_dashboard .sr_t_filters .sr_td_legend .sr_td_icon p {
          position: absolute;
          color: #fff;
          top: 4px;
          left: 4px;
          width: 10px; }
  .sr_t_dashboard .sr_td_tile {
    float: left;
    width: 100%;
    margin-bottom: 20px;
    position: relative; }
    .sr_t_dashboard .sr_td_tile .sr_viewmore {
      text-align: center;
      position: absolute;
      bottom: 0px;
      width: 100%; }
      .sr_t_dashboard .sr_td_tile .sr_viewmore button {
        font-size: 11px;
        border: none;
        background: #fff;
        color: #0076D2;
        border: 1px solid #e5e5e5;
        border-radius: 3px;
        border-bottom-right-radius: 0px;
        border-bottom-left-radius: 0px;
        border-bottom: none;
        padding: 4px 5px;
        font-weight: bold; }
        .sr_t_dashboard .sr_td_tile .sr_viewmore button span i {
          color: #0076D2;
          font-size: 6px;
          margin: 0px 3px;
          font-weight: bold;
          text-shadow: 0px 0px #0076D2; }
        .sr_t_dashboard .sr_td_tile .sr_viewmore button:hover, .sr_t_dashboard .sr_td_tile .sr_viewmore button:focus {
          color: #fff;
          background: #0076D2; }
          .sr_t_dashboard .sr_td_tile .sr_viewmore button:hover i, .sr_t_dashboard .sr_td_tile .sr_viewmore button:focus i {
            color: #fff; }
    .sr_t_dashboard .sr_td_tile .sr_td_header {
      background: #666666;
      display: table;
      width: 100%;
      color: #fff;
      border: 1px solid transparent; }
      .sr_t_dashboard .sr_td_tile .sr_td_header span {
        float: left;
        margin-top: 7px;
        margin-left: 6px;
        text-decoration: underline; }
      .sr_t_dashboard .sr_td_tile .sr_td_header .sr_td_h_img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        overflow: hidden;
        float: left;
        margin-top: 7px;
        background: #fff; }
        .sr_t_dashboard .sr_td_tile .sr_td_header .sr_td_h_img img {
          width: 100%;
          border-radius: 50%; }
      .sr_t_dashboard .sr_td_tile .sr_td_header small {
        float: left;
        font-size: 12px;
        line-height: 44px; }
      .sr_t_dashboard .sr_td_tile .sr_td_header .sr_td_h_text {
        margin-left: 12px;
        float: left;
        margin-top: 8px;
        font-size: 18px;
        margin-right: 10px; }
        .sr_t_dashboard .sr_td_tile .sr_td_header .sr_td_h_text p small {
          margin-left: 7px;
          font-size: 12px; }
      .sr_t_dashboard .sr_td_tile .sr_td_header .sr_td_graybg {
        background: #3c3c3c;
        border-left: 1px solid transparent;
        display: flex;
        min-height: 44px;
        height: 100%; }
        .sr_t_dashboard .sr_td_tile .sr_td_header .sr_td_graybg .sr_td_items {
          cursor: pointer;
          height: 100%;
          padding-right: 20px;
          padding-top: 3px;
          padding-left: 5px;
          margin-bottom: 7px;
          float: left;
          width: 100%; }
          .sr_t_dashboard .sr_td_tile .sr_td_header .sr_td_graybg .sr_td_items a {
            float: left;
            width: 100%; }
          .sr_t_dashboard .sr_td_tile .sr_td_header .sr_td_graybg .sr_td_items button {
            background: transparent;
            border: none;
            padding: 0px;
            width: 100%; }
            .sr_t_dashboard .sr_td_tile .sr_td_header .sr_td_graybg .sr_td_items button:hover, .sr_t_dashboard .sr_td_tile .sr_td_header .sr_td_graybg .sr_td_items button:focus {
              outline: 1px dashed; }
          .sr_t_dashboard .sr_td_tile .sr_td_header .sr_td_graybg .sr_td_items.br-right {
            border-right: 1px solid #949494; }
            .sr_t_dashboard .sr_td_tile .sr_td_header .sr_td_graybg .sr_td_items.br-right button {
              float: left;
              width: 100%; }
              .sr_t_dashboard .sr_td_tile .sr_td_header .sr_td_graybg .sr_td_items.br-right button i {
                font-size: 16px;
                margin-top: 5px;
                float: right; }
          .sr_t_dashboard .sr_td_tile .sr_td_header .sr_td_graybg .sr_td_items .sr_viewsdp {
            padding-top: 4px;
            display: block;
            font-size: 16px;
            line-height: 30px;
            padding-left: 12px; }
            .sr_t_dashboard .sr_td_tile .sr_td_header .sr_td_graybg .sr_td_items .sr_viewsdp i {
              margin-top: 5px;
              float: right; }
        .sr_t_dashboard .sr_td_tile .sr_td_header .sr_td_graybg a {
          color: #fff; }
          .sr_t_dashboard .sr_td_tile .sr_td_header .sr_td_graybg a i {
            font-size: 14px;
            color: #fff;
            float: right;
            margin-top: 6px;
            padding-left: 3px; }
    .sr_t_dashboard .sr_td_tile .sr_td_body {
      background: #fff;
      padding: 10px;
      float: left;
      width: 100%;
      display: flex;
      border: 1px solid transparent;
      border-top: 0px; }
      .sr_t_dashboard .sr_td_tile .sr_td_body .sr_td_na p {
        font-size: 12px;
        color: #2a2a2a; }
      .sr_t_dashboard .sr_td_tile .sr_td_body.sr_td_whitebg {
        border: 1px solid transparent; }
        .sr_t_dashboard .sr_td_tile .sr_td_body.sr_td_whitebg .sr_td_top {
          font-weight: bold;
          margin-bottom: 17px;
          font-size: 13px;
          margin-top: 7px;
          color: #707070; }
        .sr_t_dashboard .sr_td_tile .sr_td_body.sr_td_whitebg .col-sm-6:nth-child(2) .sr_flag:after {
          left: -3px; }
      .sr_t_dashboard .sr_td_tile .sr_td_body .sr_td_br {
        padding-left: 10px; }
      .sr_t_dashboard .sr_td_tile .sr_td_body .sr_td_brd {
        border-right: 1px solid #ccc; }
      .sr_t_dashboard .sr_td_tile .sr_td_body ul {
        list-style: none;
        padding: 0px;
        margin: 0px; }
        .sr_t_dashboard .sr_td_tile .sr_td_body ul li {
          margin-bottom: 10px;
          float: left;
          width: 100%; }
          .sr_t_dashboard .sr_td_tile .sr_td_body ul li .sr_td_list .sr_td_icon {
            width: 15px;
            color: #fff;
            float: left;
            position: relative;
            margin-top: 4px; }
            .sr_t_dashboard .sr_td_tile .sr_td_body ul li .sr_td_list .sr_td_icon .sr_flag:after {
              top: -4px;
              left: -4px; }
            .sr_t_dashboard .sr_td_tile .sr_td_body ul li .sr_td_list .sr_td_icon p {
              position: absolute;
              color: white;
              font-family: "Segoe UI", arial;
              top: 0px;
              left: 4px; }
          .sr_t_dashboard .sr_td_tile .sr_td_body ul li .sr_td_list .sr_td_l_data {
            float: left;
            width: calc(100% - 25px);
            margin-left: 10px; }
            .sr_t_dashboard .sr_td_tile .sr_td_body ul li .sr_td_list .sr_td_l_data .sr_td_l_highrarcy {
              color: #666666;
              font-size: 10px;
              margin-bottom: 0px; }
            .sr_t_dashboard .sr_td_tile .sr_td_body ul li .sr_td_list .sr_td_l_data .sr_td_l_name {
              font-size: 12px;
              color: #2a2a2a; }
  .sr_t_dashboard .sr_flag {
    color: white;
    background-color: #0063b1;
    display: inline-block;
    width: 1px;
    height: 24px;
    border-style: solid;
    border-width: 0 5.5px 5px;
    border-color: transparent transparent #E4E4E4 transparent; }
    .sr_t_dashboard .sr_flag:after {
      font-size: 10px;
      position: relative;
      top: -1px;
      left: -4px;
      font-style: normal;
      font-weight: bold; }
    .sr_t_dashboard .sr_flag.strength {
      background: #0063b1; }
      .sr_t_dashboard .sr_flag.strength:after {
        content: "S";
        left: -3px !important; }
    .sr_t_dashboard .sr_flag.opportunity {
      background: #004B50; }
      .sr_t_dashboard .sr_flag.opportunity:after {
        content: "O"; }
  .sr_t_dashboard .sr_td_body .sr_flag {
    border-color: transparent transparent #fff transparent; }

/* manager dashboard css | end  */
/* Responsive css */
@media (max-width: 992px) {
  .sr_t_dashboard .sr_td_tile .sr_td_header .no-pad-res {
    padding: 0px; }
  .sr_t_dashboard .sr_td_tile .sr_td_header .sr_pad_skill_dev {
    padding: 0px 15px;
    margin-bottom: 5px; }
  .sr_t_dashboard .sr_td_tile .sr_td_header .sr_td_graybg {
    height: auto;
    display: block; }
    .sr_t_dashboard .sr_td_tile .sr_td_header .sr_td_graybg .br-right {
      padding-left: 10px;
      border-right: 0px !important;
      border-bottom: 1px solid #949494;
      width: 100%;
      padding-bottom: 15px; }
  .sr_t_dashboard .sr_td_tile .sr_td_body {
    display: block; } }

@media (max-width: 768px) {
  .sr_t_filters_210 {
    padding-left: 0px; } }

@media screen and (-ms-high-contrast: active) {
  .sr_t_dashboard .sr_flag.opportunity {
    border-color: #000 #000 transparent;
    color: #fff; }
  .sr-bar-chart-progress {
    background: #fff !important; }
  .sr_td_graybg a:focus {
    border: 1px dashed transparent; }
  .sr_thumbDropDown .dropdown-toggle {
    outline: 1px solid transparent !important; } }

@media screen and (-ms-high-contrast: white-on-black) {
  .sr_t_dashboard .sr_flag.strength, .sr_t_dashboard .sr_flag.opportunity {
    border-color: #fff #fff transparent;
    color: #000; }
  .sr_t_dashboard .sr_td_tile .sr_td_header .sr_td_graybg button {
    color: yellow; }
  .sr_t_dashboard .sr-bar-chart-progress {
    background: #fff !important; } }

@media screen and (-ms-high-contrast: black-on-white) {
  .sr_t_dashboard .sr_flag.strength, .sr_t_dashboard .sr_flag.opportunity {
    border-color: #000 #000 transparent;
    color: #fff; }
  .sr_t_dashboard .sr_td_tile .sr_td_header .sr_td_graybg button {
    color: #00009f; }
  .sr_t_dashboard .sr-bar-chart-progress {
    background: #000 !important; } }

.dashboard-error-right {
  margin-top: 10px;
  font-size: 20px;
  color: #0263B2;
  font-weight: 400; }

.dashboard-error-left {
  text-align: center;
  border-right: 1px solid #eee; }
  .dashboard-error-left i {
    font-size: 40px;
    color: #cb3c38; }

/* dashboard css | end */
.block, .resource .profile-main .skill-inner-right .skill-inner-right-bg .standerdtimezone, .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections {
  display: block;
  overflow: hidden; }

.in-block, .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections ul li {
  display: block;
  overflow: hidden; }

.error i {
  color: #e04d4d;
  margin-right: 3px; }

.error span {
  color: #e04d4d;
  font-size: 13px;
  margin-top: 3px; }

.error input {
  background: #f4d6d6; }

/* sales skill rating variables */
/* sales skill rating variables | end */
/* Sales Skill Rating page CSS | Start */
.skill_rating {
  float: left;
  /* skill rating page left tiles css */
  /* skill rating page right tiles css | start */ }
  .skill_rating .mCustomScrollBox > .mCSB_scrollTools {
    margin-right: -2px;
    width: 7px !important; }
  .skill_rating .sr_tc_desc-btn {
    margin: 10px 0px;
    background: transparent;
    border: none;
    width: 100%; }
    .skill_rating .sr_tc_desc-btn:hover .srd_tile, .skill_rating .sr_tc_desc-btn:focus .srd_tile {
      border-color: #2378d7; }
    .skill_rating .sr_tc_desc-btn:focus .srd_tile {
      box-shadow: none; }
  .skill_rating .sr_tc_dc_list-btn {
    width: 100%;
    padding-left: 23px;
    overflow: hidden;
    height: calc(100vh - 285px);
    overflow-y: auto; }
    .skill_rating .sr_tc_dc_list-btn .sr_tc_desc-btn {
      margin-top: 0px;
      position: relative;
      margin-right: 15px;
      width: calc(100% - 15px); }
    .skill_rating .sr_tc_dc_list-btn.sr_tc_dc_role {
      padding-left: 0px !important; }
      .skill_rating .sr_tc_dc_list-btn.sr_tc_dc_role .sr_tile_active::after {
        top: 20px !important; }
  .skill_rating .sr_catogory {
    float: left;
    width: 100%; }
    .skill_rating .sr_catogory .row {
      margin-left: -10px;
      margin-right: -10px; }
    .skill_rating .sr_catogory .sr_tc_desc {
      padding-left: 10px;
      padding-right: 10px;
      display: flex; }
  .skill_rating .sr_tab_tiles {
    height: calc(100vh - 130px);
    overflow-y: auto;
    padding-right: 23px;
    padding-bottom: 20px; }
  .skill_rating .sr_tab_tile_data {
    width: 100%;
    float: left;
    padding-bottom: 20px; }
  .skill_rating .sr_tabs_data {
    padding-right: 0px;
    padding-left: 23px;
    overflow: hidden; }
    .skill_rating .sr_tabs_data div[ui-view] {
      overflow: hidden; }
  .skill_rating #functional .sr_tc_desc {
    overflow-y: auto;
    margin-top: 20px; }
  .skill_rating .sr_tc_desc .src_tc_blue {
    border-color: #0078d7 !important; }
  .skill_rating .sr_tc_desc .src_tc_orange {
    border-color: #BB4114 !important; }
  .skill_rating .sr_tc_desc .sr_tc_dc_tile {
    margin-bottom: 10px; }
  .skill_rating .sr_tc_desc .sr_tc_dc_active {
    padding: 13px;
    box-shadow: -1px 1px 4px 0 #ccc;
    margin-left: 0px;
    float: right;
    background: #fff;
    margin-bottom: 20px;
    margin-right: -15px;
    width: 100%; }
    .skill_rating .sr_tc_desc .sr_tc_dc_active p {
      color: #0263B2;
      float: left;
      width: calc(100% - 30px);
      font-weight: bold;
      font-size: 13px;
      margin: 0px; }
  .skill_rating .sr_tc_desc .sr_tc_dc .sr_tc_dc_tile {
    padding: 15px;
    border-left: 6px solid transparent;
    font-weight: bold;
    position: relative; }
    .skill_rating .sr_tc_desc .sr_tc_dc .sr_tc_dc_tile p {
      width: calc(100% - 25px); }
    .skill_rating .sr_tc_desc .sr_tc_dc .sr_tc_dc_tile span.sr_pending_count {
      color: #BB4114;
      float: right;
      width: 38px;
      height: 100%;
      padding: 10px;
      top: 0px;
      border-left: 1px solid #ccc;
      display: block;
      font-size: 20px;
      font-weight: 500;
      position: absolute;
      right: 0; }
    .skill_rating .sr_tc_desc .sr_tc_dc .sr_tc_dc_tile i {
      font-size: 14px;
      font-weight: bold; }
  .skill_rating .sr_tc_desc .sr_tc_dc_list {
    margin-left: 10px; }
  .skill_rating .sr_tabs .nav-tabs li button {
    color: #666;
    border: none;
    padding: 8px 15px;
    border-bottom: 1px solid transparent;
    font-size: 18px;
    background: transparent; }
    .skill_rating .sr_tabs .nav-tabs li button.sr_menu {
      background: transparent;
      border: none;
      padding: 9px;
      margin: 0px 5px; }
    .skill_rating .sr_tabs .nav-tabs li button:hover, .skill_rating .sr_tabs .nav-tabs li button:focus {
      transition: outline 200ms;
      outline: 1px dashed #0263B2; }
  .skill_rating .sr_tabs .nav-tabs li.active button,
  .skill_rating .sr_tabs .nav-tabs li.active button:focus,
  .skill_rating .sr_tabs .nav-tabs li.active button:hover {
    color: #0063b1;
    background-color: #fff;
    border: none;
    cursor: pointer;
    border-bottom: 2px solid #0063b1;
    font-weight: 500; }
  .skill_rating .sr_tabs .sr_tab_content .tab-pane span.sr_tc_notok {
    width: 15px;
    height: 15px;
    background: #ccc;
    border: 1px solid #ccc;
    border-radius: 50%;
    font-size: 10px;
    float: right;
    padding: 1px 2px 2px 1px !important;
    color: #fff;
    margin-right: 5px; }
    @media screen and (-ms-high-contrast: active) {
      .skill_rating .sr_tabs .sr_tab_content .tab-pane span.sr_tc_notok.icon.icon-tick:not(.sr_tc_ok)::before {
        content: ''; } }
    @media screen and (-ms-high-contrast: black-on-white) {
      .skill_rating .sr_tabs .sr_tab_content .tab-pane span.sr_tc_notok.icon.icon-tick:not(.sr_tc_ok)::before {
        content: ''; } }
    @media screen and (-ms-high-contrast: white-on-black) {
      .skill_rating .sr_tabs .sr_tab_content .tab-pane span.sr_tc_notok.icon.icon-tick:not(.sr_tc_ok)::before {
        content: ''; } }
  .skill_rating .sr_tabs .sr_tab_content .tab-pane span.sr_tc_text {
    width: calc(100% - 23px);
    line-height: 18px;
    margin-top: -2px;
    color: #004578;
    font-family: 'Segoe UI';
    font-size: 14px;
    font-weight: 500; }
  .skill_rating .sr_tabs .sr_tab_content .tab-pane span.sr_tc_ok {
    background: #008000 !important;
    color: #fff;
    border-color: #008000 !important; }
  .skill_rating .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate {
    background: #fff;
    padding-bottom: 30px;
    padding: 0px 20px;
    border-left: 1px solid transparent; }
    .skill_rating .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board {
      position: relative;
      width: 100%;
      float: left;
      margin-top: 13px; }
      .skill_rating .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_heading {
        width: 100%;
        float: left;
        font-size: 20px; }
        .skill_rating .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_heading #viewDefinitionButton {
          color: #0063b1;
          background: none;
          border: 1px dashed transparent;
          font-size: 14px;
          padding: 5px 0px 0px 17px;
          min-height: 0px; }
          .skill_rating .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_heading #viewDefinitionButton:hover, .skill_rating .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_heading #viewDefinitionButton:focus {
            text-decoration: underline;
            outline: 1px solid #000; }
        .skill_rating .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_heading .fxp-btn--action {
          font-size: 15px; }
        .skill_rating .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_heading #reviewRating span {
          width: 100%; }
          .skill_rating .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_heading #reviewRating span.reviewText {
            margin-bottom: 5px;
            color: #004578; }
          .skill_rating .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_heading #reviewRating span.reviewTitle {
            color: #004578;
            font-weight: 500; }
      .skill_rating .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_skill .sr_tc_scroll {
        height: calc(100vh - 290px);
        overflow-y: auto;
        float: left;
        width: 100%; }
      .skill_rating .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_skill .sr_tc_s_level1 {
        float: left;
        width: 100%;
        padding-right: 10px; }
        .skill_rating .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_skill .sr_tc_s_level1 .h3 {
          text-transform: uppercase;
          font-weight: 500;
          color: #666666;
          font-size: 15px;
          margin-bottom: 15px;
          margin: 0px;
          padding: 10px 0px; }
        .skill_rating .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_skill .sr_tc_s_level1 p {
          font-weight: bold; }
        .skill_rating .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_skill .sr_tc_s_level1 .sr_prof p.h3 {
          margin: 0px;
          padding: 10px 0px;
          font-size: 15px; }
        .skill_rating .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_skill .sr_tc_s_level1 .sr_prof button {
          font-size: 14px;
          color: #0063b1;
          font-weight: 400;
          background: transparent;
          border: 1px solid transparent; }
        .skill_rating .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_skill .sr_tc_s_level1 .sr_prof .reviewText {
          color: #004578;
          font-size: 14px;
          font-weight: 600;
          font-family: 'Segoe UI'; }
      .skill_rating .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_skill .sr_tc_s_level2 {
        float: left;
        width: calc(100% - 8px);
        border: 1px solid #C8C8C8;
        margin-bottom: 20px;
        padding-bottom: 18px;
        padding-left: 5px;
        padding-right: 5px;
        background: #f8f8f8;
        margin-right: 5px; }
        .skill_rating .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_skill .sr_tc_s_level2:focus {
          border-color: #0263B2; }
        .skill_rating .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_skill .sr_tc_s_level2 .h3 {
          font-weight: 500;
          color: #333333;
          font-size: 13px;
          margin-bottom: 20px;
          position: relative;
          padding-left: 15px;
          float: left;
          width: 100%;
          margin-bottom: 8px; }
      .skill_rating .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_skill .rating {
        margin: 10px 0px;
        padding: 0;
        display: flex;
        width: 90%;
        float: left; }
        .skill_rating .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_skill .rating li {
          padding: 1px;
          color: #ccc;
          text-align: center;
          font-weight: bold;
          font-size: 20px;
          list-style-type: none;
          width: calc(25% - 20px);
          margin-right: 5px; }
          .skill_rating .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_skill .rating li p {
            color: #585858;
            font-size: 11px;
            text-align: left;
            margin-bottom: 10px; }
          .skill_rating .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_skill .rating li:last-child {
            margin-right: 0px; }
          .skill_rating .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_skill .rating li .srdactive {
            background: #5C2D91 !important;
            border-color: #5C2D91;
            color: #fff !important; }
            .skill_rating .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_skill .rating li .srdactive span {
              display: block;
              padding-bottom: 2px;
              color: #fff; }
        .skill_rating .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_skill .rating .bar {
          width: 100%;
          height: 30px;
          border: 1px solid #a9acae;
          position: relative;
          float: left;
          color: #333333;
          font-size: 13px;
          padding-bottom: 2px;
          background: #fff;
          width: 100%;
          border: 1px solid #ccc;
          position: relative;
          float: left;
          font-size: 13px;
          padding-bottom: 2px; }
          .skill_rating .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_skill .rating .bar.isreadonly:hover, .skill_rating .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_skill .rating .bar.isreadonly:focus {
            background: #f8f4fc;
            border-color: #8f59cb;
            color: #000; }
          .skill_rating .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_skill .rating .bar .srdmarkerimg {
            display: none; }
          .skill_rating .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_skill .rating .bar span {
            color: #333333;
            padding-bottom: 2px;
            display: block; }
  .skill_rating .sr_tabs_personal .nav-tabs li div {
    padding: 8px 15px;
    font-size: 18px;
    color: #0063b1;
    background-color: #fff;
    border: none;
    border-bottom: 2px solid #0063b1;
    font-weight: 500; }
    .skill_rating .sr_tabs_personal .nav-tabs li div.sr_menu {
      background: transparent;
      border: none;
      padding: 9px;
      margin: 0px 5px; }

/* Sales Skill Rating CSS | END */
@media screen and (-ms-high-contrast: active) {
  .sr_tabs .nav-tabs li button {
    border: none !important;
    color: #00009f; }
  .sr_tabs .nav-tabs li.active button {
    border-bottom: 2px solid #00009f !important; }
  .sr_tc_skill .srdactive {
    background: #000;
    color: #fff; } }

@media screen and (-ms-high-contrast: white-on-black) {
  .sr_tabs .nav-tabs li button {
    border: none !important;
    color: yellow; }
  .sr_tabs .nav-tabs li.active button {
    border-bottom: 2px solid yellow !important; }
  .skill_rating .sr_tc_skill .srdactive {
    background: #fff;
    color: #000; } }

.block, .resource .profile-main .skill-inner-right .skill-inner-right-bg .standerdtimezone, .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections {
  display: block;
  overflow: hidden; }

.in-block, .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections ul li {
  display: block;
  overflow: hidden; }

.error i {
  color: #e04d4d;
  margin-right: 3px; }

.error span {
  color: #e04d4d;
  font-size: 13px;
  margin-top: 3px; }

.error input {
  background: #f4d6d6; }

/* sales skill rating variables */
/* sales skill rating variables | end */
/* skill tile Directive CSS start */
.srd_tile {
  background: #fff;
  box-sizing: border-box;
  border: 1px solid #D8DDE1;
  border-left: 0px;
  font-family: arial;
  display: flex;
  min-height: 54px;
  align-items: center;
  font-size: 13px;
  padding: 2px 0px;
  position: relative;
  transition: border 100ms ease-in-out; }
  .srd_tile .srd_tile_status {
    text-transform: uppercase;
    color: #666666;
    font-size: 8px !important;
    margin-bottom: 3px !important;
    margin-top: -5px;
    line-height: inherit !important;
    display: block; }
  .srd_tile .srd_tile_reviewed {
    text-transform: uppercase;
    color: #666666;
    font-size: 8px !important;
    margin-bottom: 3px !important;
    margin-top: -5px;
    display: block;
    line-height: inherit !important; }
  .srd_tile::before {
    content: '';
    width: 6px;
    height: 100%;
    top: 0px;
    position: absolute;
    background: transparent; }
  .srd_tile .srd_tile_text {
    padding: 3px 10px 3px 21px;
    width: calc(100% - 50px);
    float: left;
    font-size: 15px;
    font-weight: 500;
    color: #666666;
    text-align: left;
    line-height: 16px; }
    .srd_tile .srd_tile_text p {
      margin: 0px; }
  .srd_tile .srd_tile_count {
    width: 50px;
    text-align: center;
    float: right; }
    .srd_tile .srd_tile_count p {
      font-size: 25px;
      margin-bottom: 0px; }
    .srd_tile .srd_tile_count::after {
      background: #95989A;
      top: 0px;
      height: 100%;
      content: '';
      width: 1px;
      right: 50px;
      position: absolute;
      border-right: 1px solid transparent; }
  .srd_tile .srd_tile_tick {
    width: 50px;
    text-align: center;
    float: right; }
    .srd_tile .srd_tile_tick i {
      background: #008000;
      color: #fff;
      padding: 2px;
      border-radius: 50%;
      font-size: 10px;
      border: 1px solid transparent;
      margin-left: 3px; }
  .srd_tile.srd_tile_error::before {
    background: #A80000;
    border: 1px solid transparent; }
  .srd_tile.srd_tile_error .srd_tile_count {
    color: #A80000; }
  .srd_tile.srd_tile_primary::before {
    background: #0263B2; }
  .srd_tile.srd_tile_active .srd_tile_text {
    color: #004578 !important;
    font-weight: bold; }
  .srd_tile.srd_tile_warning {
    position: relative;
    border-left: 0px; }
    .srd_tile.srd_tile_warning::before {
      background-color: #D83B01;
      background-image: -webkit-linear-gradient(45deg, #D83B01 25%, #D83B01 25%, #efefef 26%, #fff 50%, #fff 50%, #D83B01 50%, #D83B01 74%, #fff 74%);
      background-image: -o-linear-gradient(45deg, #D83B01 25%, #D83B01 25%, #efefef 26%, #fff 50%, #fff 50%, #D83B01 50%, #D83B01 74%, #fff 74%);
      background-image: linear-gradient(45deg, #D83B01 25%, #D83B01 25%, #efefef 26%, #fff 50%, #fff 50%, #D83B01 50%, #D83B01 74%, #fff 74%);
      background-repeat: repeat;
      background-size: 11px 8px; }
    .srd_tile.srd_tile_warning .srd_tile_count {
      color: #D83B01; }
  .srd_tile.sr_tc_large {
    border-left-width: 6px !important; }
  .srd_tile.srd_tile_success::before {
    background: #008000; }
  .srd_tile.srd_tile_success .srd_tile_count {
    color: #008000; }
  .srd_tile.sr_level2 {
    min-height: 44px; }
    .srd_tile.sr_level2::before {
      width: 4px; }
    .srd_tile.sr_level2 .srd_tile_text {
      font-size: 13px;
      width: calc(100% - 40px); }
    .srd_tile.sr_level2 .srd_tile_count {
      font-size: 20px;
      width: 41px;
      min-height: 44px; }
      .srd_tile.sr_level2 .srd_tile_count p {
        font-size: 20px;
        line-height: 40px; }
      .srd_tile.sr_level2 .srd_tile_count::after {
        right: 40px; }
    .srd_tile.sr_level2 .srd_tile_tick {
      width: 50px;
      min-height: 44px;
      margin-top: -4px; }
      .srd_tile.sr_level2 .srd_tile_tick i {
        margin-top: 14px !important; }
      .srd_tile.sr_level2 .srd_tile_tick .srd_tile_reviewed {
        font-size: 9px !important;
        margin-bottom: 0px !important;
        margin-top: 0px !important; }
  .srd_tile.srd_tile_manager .srd_tile_tick i {
    margin-top: 12px; }
  .srd_tile.srd_tile_manager .srd_tile_tick {
    min-height: 44px; }

.sr_rating_dc_active {
  margin-bottom: 10px;
  padding-right: 15px; }
  .sr_rating_dc_active .srd_tile {
    height: 54px; }
  .sr_rating_dc_active .srd_tile_count {
    line-height: 50px; }
  .sr_rating_dc_active.srd_tile_manager .srd_tile_tick i {
    margin-top: 9px; }
  .sr_rating_dc_active .sr_level2 {
    min-height: 44px;
    border-left-width: 6px; }
    .sr_rating_dc_active .sr_level2 .srd_tile_text {
      font-size: 15px;
      width: calc(100% - 44px); }
    .sr_rating_dc_active .sr_level2.srd_tile_warning::before {
      width: 6px; }
    .sr_rating_dc_active .sr_level2 .srd_tile_count {
      font-size: 25px;
      width: 44px;
      min-height: 50px; }
      .sr_rating_dc_active .sr_level2 .srd_tile_count p {
        line-height: 55px; }
    .sr_rating_dc_active .sr_level2 .srd_tile_tick {
      width: 44px;
      min-height: 50px; }
      .sr_rating_dc_active .sr_level2 .srd_tile_tick i {
        margin-top: 18px; }

.sr_tile_active {
  width: 100% !important; }
  .sr_tile_active .srd_tile_text {
    color: #004578;
    width: calc(100% - 60px) !important; }
  .sr_tile_active .srd_tile {
    border-right-color: #fff; }
  .sr_tile_active .srd_tile_count,
  .sr_tile_active .srd_tile_tick {
    visibility: hidden; }
    .sr_tile_active .srd_tile_count::after,
    .sr_tile_active .srd_tile_tick::after {
      width: 0px; }
  .sr_tile_active::after {
    content: "\E00F";
    right: 30px;
    top: 30% !important;
    font-family: "SegoeUI MDL2 Assets";
    font-weight: 400;
    font-style: normal;
    position: absolute;
    top: 1px;
    height: 44px;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

/* role perfromance tab css | start */
.sr_tc_dc_role .srd_tile {
  min-height: 57px !important; }

/* role perfromance tab css | start */
/* skill tile Directive CSS | end  */
/* reporteeThumbnail Directive CSS | start */
.sr_thumbDropDown {
  text-align: left;
  width: 220px; }
  .sr_thumbDropDown.sr_thumb .dropdown-menu {
    max-height: 243px;
    overflow-y: auto; }
  .sr_thumbDropDown.open .icon-chevronDownMed {
    transform: rotate(180deg); }
  .sr_thumbDropDown #mCSB_6_scrollbar_vertical {
    background: #b7b7b7; }
  .sr_thumbDropDown.open .glyphicon-menu-down {
    transform: rotate(180deg); }
  .sr_thumbDropDown .glyphicon-menu-down {
    float: right;
    position: absolute;
    right: 15px;
    top: 14px;
    font-size: 12px;
    color: #0263B2; }
  .sr_thumbDropDown ul {
    width: 100%;
    border: none;
    border-radius: 0px;
    box-shadow: none;
    background: #fff;
    padding: 0px;
    border: 1px solid #95989A; }
    .sr_thumbDropDown ul li {
      width: 100%;
      float: left; }
      .sr_thumbDropDown ul li:first-child a {
        border-top: 1px solid #95989A !important; }
      .sr_thumbDropDown ul li a {
        padding-top: 7px;
        padding-bottom: 7px;
        border-bottom: 1px solid #95989A !important;
        width: 100%;
        float: left;
        color: #333333 !important;
        text-transform: capitalize;
        border-left: 3px solid transparent !important;
        background: #fff;
        padding-left: 10px; }
        .sr_thumbDropDown ul li a .srtdselect, .sr_thumbDropDown ul li a:hover, .sr_thumbDropDown ul li a:active, .sr_thumbDropDown ul li a:focus {
          background: #eeeeee; }
      .sr_thumbDropDown ul li:last-child a {
        border-bottom: 0px !important; }
  .sr_thumbDropDown .srtd-img {
    width: 25px;
    border-radius: 50%;
    border: 1px solid #a9acae;
    height: 25px;
    overflow: hidden; }
    .sr_thumbDropDown .srtd-img img {
      width: 100%; }
  .sr_thumbDropDown .srtd-name {
    width: calc(100% - 40px);
    text-align: left;
    margin-top: 2px;
    overflow: hidden;
    float: left !important;
    margin-left: 8px !important; }
  .sr_thumbDropDown #srtd-btn {
    background: #fff;
    color: inherit;
    text-shadow: none;
    border: none;
    border-radius: 0px;
    padding: 7px 21px;
    width: 100%;
    border: 1px solid transparent;
    text-transform: capitalize;
    color: #585858 !important;
    border: 1px solid transparent;
    border-left: 3px solid transparent;
    border-right: 1px solid #a9acae; }
    .sr_thumbDropDown #srtd-btn:hover, .sr_thumbDropDown #srtd-btn:active, .sr_thumbDropDown #srtd-btn:focus {
      background: #fff;
      box-shadow: none;
      border-color: #a9acae; }
    .sr_thumbDropDown #srtd-btn .icon-chevronDownMed {
      font-size: 13px;
      float: right;
      margin-right: -14px;
      margin-top: 7px;
      color: #0263B2; }

.sr_thumb ul li a {
  padding-left: 22px; }

/* reporteeThumbnail Directive CSS | end  */
/* view defination blade/popup CSS | start */
.oneprofile-proficiency-definitions-modal-backdrop.in {
  background: none !important; }

.modal.oneprofile-proficiency-definitions-modal-backdrop.modal-backdrop.in {
  background: none !important; }

.modal.oneprofile-proficiency-definitions-modal .modal-dialog {
  right: 0;
  margin: 0;
  position: absolute;
  height: 100vh;
  transform: translate(100%, 0);
  max-width: 452px;
  width: 80%;
  color: #fff; }
  .modal.oneprofile-proficiency-definitions-modal .modal-dialog button {
    color: #fff;
    font-size: 14px;
    float: right;
    margin-top: 2px; }
    .modal.oneprofile-proficiency-definitions-modal .modal-dialog button:hover {
      opacity: 0.8; }
    .modal.oneprofile-proficiency-definitions-modal .modal-dialog button:focus {
      outline-color: #fff; }
    .modal.oneprofile-proficiency-definitions-modal .modal-dialog button i {
      background: #fff;
      color: #000;
      border-radius: 50%;
      padding: 3px;
      font-size: 12px !important;
      width: 22px;
      height: 22px;
      line-height: 17px; }
  .modal.oneprofile-proficiency-definitions-modal .modal-dialog .modal-content {
    border-radius: 0;
    padding: 15px;
    overflow: auto;
    padding-top: 0px;
    background: #313131;
    box-shadow: none; }
    .modal.oneprofile-proficiency-definitions-modal .modal-dialog .modal-content .icon-close {
      top: 2px;
      left: -10px;
      font-size: 15px; }
    .modal.oneprofile-proficiency-definitions-modal .modal-dialog .modal-content h1 {
      font-weight: 100;
      font-size: 18px;
      margin-bottom: 30px;
      color: #fff;
      margin-top: 30px; }
    .modal.oneprofile-proficiency-definitions-modal .modal-dialog .modal-content dt {
      font-size: 16px;
      font-weight: 600; }
    .modal.oneprofile-proficiency-definitions-modal .modal-dialog .modal-content dl dd {
      margin-bottom: 20px;
      font-weight: 400;
      font-size: 14px;
      margin-top: 10px; }

.modal.oneprofile-proficiency-definitions-modal.in .modal-dialog {
  transform: translate(0, 0); }

/* view defination blade/popup CSS | END */
@media screen and (-ms-high-contrast: active) {
  .srd_tile_warning::before {
    background: #666; }
  .srt_warning {
    background: #666; }
  .srt_success {
    background: #fff;
    color: #fff; }
  .srd_tile_success::before {
    background: #fff; } }

@media screen and (-ms-high-contrast: white-on-black) {
  .srt_success {
    background: #fff;
    color: #fff; }
  .srd_tile_success::before {
    background: #fff; } }

@media screen and (-ms-high-contrast: black-on-white) {
  .srt_success {
    background: #000;
    color: #000; }
  .srd_tile_success::before {
    background: #000; } }

.block, .resource .profile-main .skill-inner-right .skill-inner-right-bg .standerdtimezone, .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections {
  display: block;
  overflow: hidden; }

.in-block, .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections ul li {
  display: block;
  overflow: hidden; }

.error i {
  color: #e04d4d;
  margin-right: 3px; }

.error span {
  color: #e04d4d;
  font-size: 13px;
  margin-top: 3px; }

.error input {
  background: #f4d6d6; }

/* sales skill rating variables */
/* sales skill rating variables | end */
/* applying sales background color | start*/
.master-bg {
  height: 100%; }

.op-skill-bg {
  background-color: #E4E4E4 !important; }

.managerbg {
  height: 100%; }

.dashboard-link {
  cursor: pointer;
  border: none;
  background: 0 0;
  padding: 0px 3px; }

.btn-dashbd {
  padding-right: 0; }

.btn-cmd-info {
  padding: 8px 0px 8px 14px !important; }

.border-line {
  border-right: 1px solid #DDD;
  padding-left: 10px;
  margin-right: 2px;
  height: 35px;
  font-size: 20px; }

.icon-data-info {
  cursor: pointer;
  border: none;
  background: 0 0;
  padding: 2px !important; }

.sr_learner.op-skill-bg {
  height: 100%;
  overflow-y: auto; }

/* applying sales background color | end */
/* skill category/rating page  | start */
.skill_rating {
  background: url("../../../../images/skill_assessment.png");
  background-repeat: no-repeat;
  background-position: right bottom;
  background-size: 300px;
  height: 100%;
  width: 100%;
  /* back button */
  /* back button  | end */ }
  .skill_rating #functional .srd_tile_status,
  .skill_rating #functional .srd_tile_reviewed {
    display: none; }
  .skill_rating.op-skill-bg {
    overflow: hidden; }
  .skill_rating .sr_tabs_nav {
    background: #fff;
    border-bottom: 1px solid #ddd;
    width: 100%;
    float: left; }
    .skill_rating .sr_tabs_nav .readonlyview {
      font-size: 15px;
      color: #707070;
      font-weight: 500;
      float: right;
      margin-right: 20px;
      margin-top: 2px; }
      .skill_rating .sr_tabs_nav .readonlyview span {
        margin-top: 9px;
        float: left;
        margin-right: 5px; }
      .skill_rating .sr_tabs_nav .readonlyview i {
        color: #d83b01;
        font-size: 20px;
        font-size: 20px;
        margin-top: 10px;
        float: right; }
  .skill_rating .sr_tab_header .backtoskill {
    margin: 20px 0px;
    font-size: 15px;
    text-transform: uppercase;
    margin-bottom: 0px; }
    .skill_rating .sr_tab_header .backtoskill i {
      font-size: 14px;
      margin-right: 5px; }
    .skill_rating .sr_tab_header .backtoskill button {
      color: #666666;
      background: no-repeat;
      border: none;
      margin-left: 1px; }
      .skill_rating .sr_tab_header .backtoskill button:focus, .skill_rating .sr_tab_header .backtoskill button:hover {
        outline: 1px dashed; }
  .skill_rating .slct_skl_grp {
    font-weight: 500;
    font-size: 18px;
    padding-top: 10px;
    padding-bottom: 10px;
    color: #004578;
    margin-bottom: 5px; }

/* skill category/rating page  | end */
/* skill category/rating page legends | start */
.sr_td_legend {
  float: right;
  margin-top: 20px;
  margin-bottom: 0px; }
  .sr_td_legend.sr-td-legend-dm {
    margin-right: 5px;
    margin-top: 5px; }
  .sr_td_legend li {
    font-size: 14px;
    color: #666666;
    float: left;
    width: 155px;
    margin-right: 30px; }
    .sr_td_legend li:last-child {
      margin-right: 0px; }
    .sr_td_legend li p:nth-child(2) {
      float: right;
      font-size: 15px; }
    .sr_td_legend li p:first-child {
      float: right;
      margin-top: 4px;
      margin-left: 4px;
      width: 15px; }
      .sr_td_legend li p:first-child .srtlegend {
        border: 1px solid #bfbfbf;
        width: 6px;
        display: table;
        height: 15px;
        background: #f9f9f9;
        float: right;
        border-left: 0px; }
      .sr_td_legend li p:first-child .srtreview {
        background: transparent;
        width: 9px;
        height: 15px;
        display: table;
        float: left; }
        .sr_td_legend li p:first-child .srtreview.srt_success {
          background: #008000; }
        .sr_td_legend li p:first-child .srtreview.srt_warning {
          background-color: #D83B01;
          background-image: -webkit-linear-gradient(45deg, #D83B01 25%, #D83B01 25%, #efefef 26%, #fff 50%, #fff 50%, #D83B01 50%, #D83B01 74%, #fff 74%);
          background-image: -o-linear-gradient(45deg, #D83B01 25%, #D83B01 25%, #efefef 26%, #fff 50%, #fff 50%, #D83B01 50%, #D83B01 74%, #fff 74%);
          background-image: linear-gradient(45deg, #D83B01 25%, #D83B01 25%, #efefef 26%, #fff 50%, #fff 50%, #D83B01 50%, #D83B01 74%, #fff 74%);
          background-repeat: repeat;
          background-size: 11px 8px; }
        .sr_td_legend li p:first-child .srtreview.srt_error {
          background: #A80000;
          border: 1px solid transparent; }
  .sr_td_legend.managerLengends li {
    float: right;
    margin-right: 23px; }
    .sr_td_legend.managerLengends li:first-child {
      margin-right: 0px; }
    .sr_td_legend.managerLengends li:last-child {
      margin-right: 25px; }

/* skill category/rating page legends | end  */
.mgrdashboard {
  width: 100%;
  padding: 2%;
  background-color: #eaeaea; }
  .mgrdashboard tr {
    padding: 1%;
    margin: 1%;
    background-color: white; }

.profile-info {
  float: left;
  margin-right: 15px;
  width: 60px;
  height: 60px;
  margin-top: 0;
  font-size: smaller; }

.block, .resource .profile-main .skill-inner-right .skill-inner-right-bg .standerdtimezone, .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections {
  display: block;
  overflow: hidden; }

.in-block, .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections ul li {
  display: block;
  overflow: hidden; }

.error i {
  color: #e04d4d;
  margin-right: 3px; }

.error span {
  color: #e04d4d;
  font-size: 13px;
  margin-top: 3px; }

.error input {
  background: #f4d6d6; }

/* sales skill rating variables */
/* sales skill rating variables | end */
.OneProfile-loader {
  position: relative;
  padding-top: 100px;
  width: 40px;
  margin: auto; }
  .OneProfile-loader .circle {
    position: absolute;
    width: 38px;
    height: 38px;
    opacity: 0;
    transform: rotate(225deg);
    animation-iteration-count: infinite;
    animation-name: orbit;
    animation-duration: 5.5s; }
    .OneProfile-loader .circle:after {
      content: '';
      position: absolute;
      width: 5px;
      height: 5px;
      border-radius: 5px;
      background: #0063b1;
      /* Pick a color */ }
    .OneProfile-loader .circle:nth-child(2) {
      animation-delay: 240ms; }
    .OneProfile-loader .circle:nth-child(3) {
      animation-delay: 480ms; }
    .OneProfile-loader .circle:nth-child(4) {
      animation-delay: 720ms; }
    .OneProfile-loader .circle:nth-child(5) {
      animation-delay: 960ms; }

@keyframes orbit {
  0% {
    transform: rotate(225deg);
    opacity: 1;
    animation-timing-function: ease-out; }
  7% {
    transform: rotate(345deg);
    animation-timing-function: linear; }
  30% {
    transform: rotate(455deg);
    animation-timing-function: ease-in-out; }
  39% {
    transform: rotate(690deg);
    animation-timing-function: linear; }
  70% {
    transform: rotate(815deg);
    opacity: 1;
    animation-timing-function: ease-out; }
  75% {
    transform: rotate(945deg);
    animation-timing-function: ease-out; }
  76% {
    transform: rotate(945deg);
    opacity: 0; }
  100% {
    transform: rotate(945deg);
    opacity: 0; } }

.block, .resource .profile-main .skill-inner-right .skill-inner-right-bg .standerdtimezone, .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections {
  display: block;
  overflow: hidden; }

.in-block, .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections ul li {
  display: block;
  overflow: hidden; }

.error i {
  color: #e04d4d;
  margin-right: 3px; }

.error span {
  color: #e04d4d;
  font-size: 13px;
  margin-top: 3px; }

.error input {
  background: #f4d6d6; }

/* sales skill rating variables */
/* sales skill rating variables | end */
/* team skill summary tab  | manager dashboard css | start  */
.managerbg .sr-role-title {
  font-size: 15px;
  color: #0063b1;
  background: #fff;
  padding: 12px 20px;
  float: left;
  width: 100%;
  margin-top: 0px;
  margin-bottom: 0px;
  border-bottom: 1px solid transparent; }

.sr-role-panel {
  float: left;
  width: 100%;
  padding: 20px;
  padding-bottom: 0px; }
  .sr-role-panel .table-bordered {
    border: none;
    margin-bottom: 0px; }
    .sr-role-panel .table-bordered > tbody > tr > td {
      margin-left: 0px;
      border: none;
      vertical-align: middle; }
    .sr-role-panel .table-bordered thead tr th {
      border: none; }
  .sr-role-panel p {
    margin: 0px;
    width: 100%;
    word-wrap: break-word; }
  .sr-role-panel .icon {
    font-size: 14px;
    color: #333333;
    text-transform: capitalize; }
  .sr-role-panel .text-success {
    font-size: 20px !important;
    font-weight: 500 !important;
    color: #004b1c !important; }
  .sr-role-panel .text-warning {
    font-size: 20px !important;
    font-weight: 500 !important;
    color: #BB4114 !important; }
  .sr-role-panel thead {
    background: #5d5d5d;
    border: 1px solid transparent; }
    .sr-role-panel thead button,
    .sr-role-panel thead p {
      color: #fff;
      font-weight: 500;
      margin: 0px;
      padding-left: 15px;
      font-size: 15px; }
      .sr-role-panel thead button .ms-Icon,
      .sr-role-panel thead p .ms-Icon {
        font-size: 14px;
        color: #f7f7f7; }
    .sr-role-panel thead button {
      background: transparent;
      border: none; }
      .sr-role-panel thead button:focus {
        outline: 1px dashed #fff; }
    .sr-role-panel thead .sr-r-sort button {
      padding-left: 0px; }
      .sr-role-panel thead .sr-r-sort button i {
        color: #fff !important; }
  .sr-role-panel .sr-r-rolename {
    width: 15%;
    margin-left: 0px; }
  .sr-role-panel .sr-r-skillname {
    width: 37%; }
  .sr-role-panel .sr-r-target {
    width: 12%; }
  .sr-role-panel .sr-r-abovetarget {
    width: 15%; }
  .sr-role-panel .sr-r-belowtarget {
    width: 15%; }
  .sr-role-panel .sr-r-sort {
    width: 6%;
    text-align: center; }
    .sr-role-panel .sr-r-sort button {
      background: none;
      border: none;
      margin: 0 auto; }
  .sr-role-panel .panel-body {
    padding: 0px;
    width: 100%; }
    .sr-role-panel .panel-body .sr-r-sort button {
      padding: 14px; }
  .sr-role-panel .sr-r-row {
    border: 1px solid transparent;
    border-bottom: 15px solid #E4E4E4;
    width: 100%; }
    .sr-role-panel .sr-r-row td {
      background: #fff; }
    .sr-role-panel .sr-r-row.sr-r-nomargin {
      border-bottom: 0px solid #E4E4E4; }
    .sr-role-panel .sr-r-row.sr-r-header .sr-r-sort-bg {
      background: #f7f7f7; }
    .sr-role-panel .sr-r-row.sr-r-header .sr-r-target p {
      font-size: 13px !important;
      color: #1a1a1a !important;
      text-transform: capitalize; }
    .sr-role-panel .sr-r-row.sr-r-header .sr-r-rolename,
    .sr-role-panel .sr-r-row.sr-r-header .sr-r-target,
    .sr-role-panel .sr-r-row.sr-r-header .sr-r-belowtarget,
    .sr-role-panel .sr-r-row.sr-r-header .sr-r-abovetarget,
    .sr-role-panel .sr-r-row.sr-r-header .sr-r-skillname {
      border-right: 1px solid #707070;
      padding: 15px 20px; }
    .sr-role-panel .sr-r-row.sr-r-header .sr-r-rolename p,
    .sr-role-panel .sr-r-row.sr-r-header .sr-r-sort p,
    .sr-role-panel .sr-r-row.sr-r-header .sr-r-target p,
    .sr-role-panel .sr-r-row.sr-r-header .sr-r-belowtarget p,
    .sr-role-panel .sr-r-row.sr-r-header .sr-r-abovetarget p {
      margin: 0 auto; }
    .sr-role-panel .sr-r-row.sr-r-header .sr-r-target p,
    .sr-role-panel .sr-r-row.sr-r-header .sr-r-rolename p {
      float: left;
      margin: 0px; }
    .sr-role-panel .sr-r-row.sr-r-header .sr-r-sort button {
      background: none;
      border: none;
      margin: 0 auto; }
    .sr-role-panel .sr-r-row.sr-r-header .small {
      font-size: 10px;
      color: #666666;
      margin-bottom: 5px; }
    .sr-role-panel .sr-r-row.sr-r-header p {
      font-size: 12px;
      color: #2a2a2a; }
  .sr-role-panel .sr-r-dropdown {
    background: #f7f7f7;
    padding-top: 15px;
    padding-bottom: 15px;
    border: 1px solid transparent;
    border-top: none;
    width: 100%;
    border-bottom: 15px solid #E4E4E4; }
    .sr-role-panel .sr-r-dropdown td {
      width: 100%; }
    .sr-role-panel .sr-r-dropdown .table-bordered > tbody > tr > td {
      min-height: 0px !important; }
    .sr-role-panel .sr-r-dropdown .row {
      margin: 8px 0px 4px 0px;
      width: 100%; }
    .sr-role-panel .sr-r-dropdown .col-xs-12 {
      width: 100%; }
    .sr-role-panel .sr-r-dropdown .sr-r-d-title {
      float: left;
      max-width: 150px; }
    .sr-role-panel .sr-r-dropdown .text-success {
      font-size: 14px !important; }
    .sr-role-panel .sr-r-dropdown .text-warning {
      font-size: 14px !important; }
    .sr-role-panel .sr-r-dropdown .list-inline {
      float: left;
      width: calc(100% - 150px);
      margin-left: 0px; }
      .sr-role-panel .sr-r-dropdown .list-inline li {
        font-size: 14px;
        color: #666666;
        border-right: 1px solid #707070;
        line-height: 14px;
        padding: 0px 10px;
        text-transform: capitalize; }
        .sr-role-panel .sr-r-dropdown .list-inline li:last-child {
          border-right: none; }

@media (max-width: 768px) {
  .sr-role-panel .sr-r-skillname {
    width: 25%; }
  .sr-role-panel .sr-r-abovetarget,
  .sr-role-panel .sr-r-belowtarget {
    width: 20%; }
  .sr-role-panel .table-bordered > tbody > tr > th,
  .sr-role-panel .table-bordered > tbody > tr > td {
    white-space: inherit;
    min-height: 190px; }
  .sr-r-dropdown td {
    min-height: 0px !important; } }

@media screen and (-ms-high-contrast: active) {
  .sr-role-panel .sr-r-row .sr-r-header .sr-r-abovetarget,
  .sr-role-panel .sr-r-row .sr-r-header .sr-r-belowtarget,
  .sr-role-panel .sr-r-row .sr-r-header .sr-r-rolename,
  .sr-role-panel .sr-r-row .sr-r-header .sr-r-skillname,
  .sr-role-panel .sr-r-row .sr-r-header .sr-r-target {
    border-right: 1px solid; }
  .sr-role-panel .sr-r-row .sr-r-dropdown .container-fluid .list-inline li {
    border-right: 1px solid; } }

@media screen and (-ms-high-contrast: black-on-white) {
  .sr-role-panel button {
    color: #00009f; }
    .sr-role-panel button i {
      color: #00009f; }
  .sr_t_dashboard .sr_t_tabs .nav-tabs li button {
    color: #00009f; }
  .sr_t_dashboard .sr_t_tabs .nav-tabs li.active button {
    border-bottom-color: #00009f; }
  .sr-role-panel .sr-r-sort button,
  .sr-role-panel .panel-header button {
    color: #00009f; } }

@media screen and (-ms-high-contrast: white-on-black) {
  .sr-role-panel button {
    color: yellow; }
    .sr-role-panel button i {
      color: yellow; }
  .sr_t_dashboard .sr_t_tabs .nav-tabs li button {
    color: yellow; }
  .sr_t_dashboard .sr_t_tabs .nav-tabs li.active button {
    border-bottom-color: yellow; }
  .sr-role-panel .sr-r-sort button,
  .sr-role-panel .panel-header button {
    color: yellow; } }

/* team skill summary tab  | manager dashboard css | end */
.block, .resource .profile-main .skill-inner-right .skill-inner-right-bg .standerdtimezone, .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections {
  display: block;
  overflow: hidden; }

.in-block, .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections ul li {
  display: block;
  overflow: hidden; }

.error i {
  color: #e04d4d;
  margin-right: 3px; }

.error span {
  color: #e04d4d;
  font-size: 13px;
  margin-top: 3px; }

.error input {
  background: #f4d6d6; }

/* sales skill rating variables */
/* sales skill rating variables | end */
#DialogLearnerPreference {
  color: #3c3c3c !important;
  background-color: #fff !important;
  border: 0px !important; }

.settings-flyout .fly-out-close .closeBtn {
  margin-top: 10px;
  margin-right: -20px;
  background: transparent; }

.flyout-data {
  font-family: Segoe UI; }
  .flyout-data .heading {
    margin: 20px 20px 10px 20px; }
    .flyout-data .heading .hname {
      font-weight: 500;
      margin-top: 50px;
      display: block;
      font-size: 16px;
      color: #3c3c3c;
      margin-left: -15px; }
    .flyout-data .heading h3 {
      font-size: 20px; }

.opssettingIcon {
  font-size: 25px;
  float: left;
  margin-right: 10px;
  margin-top: -5px; }

.opspreference-radio-section {
  border-top: 1px rgba(0, 0, 0, 0.4) solid;
  padding-top: 20px; }
  .opspreference-radio-section .radio-item {
    margin-left: 20px !important;
    display: block;
    position: relative;
    padding: 0 6px;
    margin: 10px 0 0;
    margin-bottom: 15px; }
  .opspreference-radio-section .radio-item input[type='radio'] {
    height: 20px;
    width: 20px; }
  .opspreference-radio-section .radio-item input[type=radio]:checked + label:before {
    border-color: #0073c6 !important; }
  .opspreference-radio-section .radio-item label {
    font-weight: normal;
    font-size: 16px;
    font-weight: 500;
    top: -32px;
    position: relative;
    left: 0px; }
    .opspreference-radio-section .radio-item label span {
      float: right;
      width: calc(100% - 30px); }
  .opspreference-radio-section .radio-item label:before {
    content: " ";
    display: inline-block;
    position: relative;
    top: 5px;
    margin: 0 5px 0 0;
    width: 20px;
    height: 20px;
    border-radius: 11px;
    border: 1px solid #3c3c3c;
    background-color: #fff; }
  .opspreference-radio-section .radio-item input[type=radio]:checked + label:after {
    border-radius: 11px;
    width: 12px;
    height: 12px;
    position: absolute;
    top: 9px;
    left: 4px;
    content: " ";
    display: block;
    background: #0073c6; }
  .opspreference-radio-section .save-btn {
    margin-bottom: 30px;
    margin-left: 25px; }
  .opspreference-radio-section p {
    margin-left: 20px;
    font-weight: normal;
    font-size: 16px;
    margin-left: 25px; }
    .opspreference-radio-section p i {
      font-weight: 600; }

.optinout-modal .modal-dialog {
  margin-top: 10%; }

.optinout-modal .modal-content {
  display: table;
  border-radius: 0;
  color: #3c3c3c;
  width: 100%;
  font-family: Segoe UI;
  width: 700px;
  height: 400px; }
  .optinout-modal .modal-content .heading {
    margin: 20px; }
    .optinout-modal .modal-content .heading h3 {
      font-size: 20px; }

@media screen and (-ms-high-contrast: white-on-black) {
  #DialogLearnerPreference {
    border-left: 0.5px #fcfcfc solid !important; }
  .fly-out-close .closeBtn {
    border: 1px #fcfcfc solid; }
  .radio-item input[type=radio]:checked + label:after {
    background-color: #fcfcfc !important; } }

@media screen and (-ms-high-contrast: black-on-white) {
  #DialogLearnerPreference {
    border-left: 0.5px #3c3c3c solid !important; }
  .fly-out-close .closeBtn {
    border: 1px #3c3c3c solid; }
  .radio-item input[type=radio]:checked + label:after {
    border-color: #3c3c3c !important; } }

.block, .resource .profile-main .skill-inner-right .skill-inner-right-bg .standerdtimezone, .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections {
  display: block;
  overflow: hidden; }

.in-block, .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections ul li {
  display: block;
  overflow: hidden; }

.error i {
  color: #e04d4d;
  margin-right: 3px; }

.error span {
  color: #e04d4d;
  font-size: 13px;
  margin-top: 3px; }

.error input {
  background: #f4d6d6; }

/* sales skill rating variables */
/* sales skill rating variables | end */
/* Sales Skill Rating page CSS | Start */
.explorer_view {
  /* skill rating page left tiles css */
  /* skill rating page right tiles css | start */ }
  .explorer_view .srd_role_tile_reviewed {
    text-transform: uppercase;
    color: #666;
    font-size: 8px !important;
    margin-bottom: 3px !important;
    margin-top: 0px;
    display: block;
    line-height: inherit !important; }
  .explorer_view .role_tile_section {
    padding: 0; }
  .explorer_view .role_section_resize {
    overflow: auto;
    height: 23vh; }
  .explorer_view .personal_view_dashboard {
    width: 100%;
    float: left;
    margin-top: 10px;
    padding: 0; }
    .explorer_view .personal_view_dashboard .close_dashboard_btn {
      border: none;
      background-color: transparent;
      float: right;
      margin-top: 5px;
      margin-right: 5px; }
    .explorer_view .personal_view_dashboard .role_skill_heading {
      display: inline-block;
      font-size: 18px;
      font-family: Segoe UI;
      font-weight: 500;
      padding-left: 10px;
      color: #3c3c3c;
      margin-top: 10px; }
      .explorer_view .personal_view_dashboard .role_skill_heading:focus {
        border: 1px dotted #000;
        outline: none; }
  .explorer_view .heading_section {
    margin: 25px 0;
    padding: 0;
    border-bottom: 1px solid gray;
    padding-bottom: 10px; }
    .explorer_view .heading_section .select_text {
      font-size: 18px;
      font-family: Segoe UI;
      font-weight: 500;
      padding-left: 0;
      color: #3c3c3c; }
    .explorer_view .heading_section input::-ms-clear {
      display: none; }
    .explorer_view .heading_section .search_box {
      padding-right: 0;
      text-align: right;
      position: relative; }
      .explorer_view .heading_section .search_box .searchclear {
        position: absolute;
        top: 2px;
        right: 0px;
        background: 0 0;
        border: none;
        font-size: 18px;
        width: 23px; }
      .explorer_view .heading_section .search_box input {
        margin: 0;
        height: 29px;
        width: 250px;
        font-style: italic;
        padding-left: 5px; }
        .explorer_view .heading_section .search_box input:focus {
          outline: none; }
      .explorer_view .heading_section .search_box .search-icon-div {
        margin-top: -24px;
        margin-right: 10px; }
    @media screen and (-ms-high-contrast: black-on-white) {
      .explorer_view .heading_section .search_box input:-ms-input-placeholder {
        color: black; } }
    @media screen and (-ms-high-contrast: white-on-black) {
      .explorer_view .heading_section .search_box input:-ms-input-placeholder {
        color: white; } }
  .explorer_view .srd_tile_review {
    border: 2px solid #666666; }
  .explorer_view .srd_tile_selection {
    border: 2px solid #008000 !important; }
    .explorer_view .srd_tile_selection .learner_srd_tile .learner_srd_tile_count::after {
      background: #95989A; }
    .explorer_view .srd_tile_selection .role_tile_tick i {
      color: #008000 !important;
      font-weight: 600; }
  .explorer_view .srd_role_tile {
    background: #F8F9F9;
    box-sizing: border-box;
    border: none;
    border-left: 0px;
    font-family: 'Segoe UI';
    display: flex;
    min-height: 52px;
    align-items: center;
    font-size: 14px;
    position: relative;
    transition: border 100ms ease-in-out; }
    .explorer_view .srd_role_tile::before {
      content: '';
      width: 6px;
      height: 100%;
      top: 0px;
      position: absolute;
      background: transparent; }
    .explorer_view .srd_role_tile .role_tile_tick {
      width: 55px;
      text-align: center;
      float: right; }
      .explorer_view .srd_role_tile .role_tile_tick i {
        padding: 2px;
        font-size: 20px;
        margin-left: 58px;
        color: #707070; }
    .explorer_view .srd_role_tile .srd_tile_role_text {
      padding: 3px 10px 3px 21px;
      width: calc(100% - 50px);
      float: left;
      font-size: 15px;
      font-weight: 500;
      color: #666666;
      text-align: left;
      line-height: 16px; }
      .explorer_view .srd_role_tile .srd_tile_role_text p {
        margin: 0px; }
    .explorer_view .srd_role_tile .srd_role_tile_count {
      width: 50px;
      text-align: center;
      float: right; }
      .explorer_view .srd_role_tile .srd_role_tile_count p {
        font-size: 18px;
        margin-bottom: 0px;
        color: #3c3c3c; }
      .explorer_view .srd_role_tile .srd_role_tile_count::after {
        background: #707070;
        top: 0px;
        height: 100%;
        content: '';
        width: 1px;
        right: 55px;
        position: absolute;
        border-right: 1px solid transparent; }
  .explorer_view .sr_role_tc_desc {
    padding: 10px;
    display: flex; }
  .explorer_view .sr_role_tc_desc-btn {
    border: 2px solid #fff;
    position: relative;
    width: 100%; }
    .explorer_view .sr_role_tc_desc-btn:focus .srd_role_tile {
      box-shadow: none; }
  .explorer_view .sr_tc_dc_list-btn {
    width: 100%;
    padding-left: 23px;
    overflow: hidden;
    height: calc(100vh - 285px);
    overflow-y: auto; }
    .explorer_view .sr_tc_dc_list-btn .sr_tc_desc-btn {
      margin-top: 0px;
      position: relative;
      margin-right: 15px;
      width: calc(100% - 15px); }
    .explorer_view .sr_tc_dc_list-btn.sr_tc_dc_role {
      padding-left: 0px !important; }
      .explorer_view .sr_tc_dc_list-btn.sr_tc_dc_role .sr_tile_active::after {
        top: 20px !important; }
  .explorer_view .sr_tab_tiles {
    height: calc(100vh - 130px);
    overflow-y: auto;
    padding-right: 23px;
    padding-bottom: 20px; }
  .explorer_view .sr_tab_tile_data {
    width: 100%;
    float: left;
    padding-bottom: 20px; }
  .explorer_view .sr_tabs_data {
    padding-right: 0px;
    padding-left: 23px;
    overflow: hidden; }
    .explorer_view .sr_tabs_data div[ui-view] {
      overflow: hidden; }
  .explorer_view #functional .sr_tc_desc {
    overflow-y: auto;
    margin-top: 20px; }
  .explorer_view .sr_tc_desc .src_tc_blue {
    border-color: #0078d7 !important; }
  .explorer_view .sr_tc_desc .src_tc_orange {
    border-color: #BB4114 !important; }
  .explorer_view .sr_tc_desc .sr_tc_dc_tile {
    margin-bottom: 10px; }
  .explorer_view .sr_tc_desc .sr_tc_dc_active {
    padding: 13px;
    box-shadow: -1px 1px 4px 0 #ccc;
    margin-left: 0px;
    float: right;
    background: #fff;
    margin-bottom: 20px;
    margin-right: -15px;
    width: 100%; }
    .explorer_view .sr_tc_desc .sr_tc_dc_active p {
      color: #0263B2;
      float: left;
      width: calc(100% - 30px);
      font-weight: bold;
      font-size: 13px;
      margin: 0px; }
  .explorer_view .sr_tc_desc .sr_tc_dc .sr_tc_dc_tile {
    padding: 15px;
    border-left: 6px solid transparent;
    font-weight: bold;
    position: relative; }
    .explorer_view .sr_tc_desc .sr_tc_dc .sr_tc_dc_tile p {
      width: calc(100% - 25px); }
    .explorer_view .sr_tc_desc .sr_tc_dc .sr_tc_dc_tile span.sr_pending_count {
      color: #BB4114;
      float: right;
      width: 38px;
      height: 100%;
      padding: 10px;
      top: 0px;
      border-left: 1px solid #ccc;
      display: block;
      font-size: 20px;
      font-weight: 500;
      position: absolute;
      right: 0; }
    .explorer_view .sr_tc_desc .sr_tc_dc .sr_tc_dc_tile i {
      font-size: 14px;
      font-weight: bold; }
  .explorer_view .sr_tc_desc .sr_tc_dc_list {
    margin-left: 10px; }
  .explorer_view .sr_tabs .nav-tabs li button {
    color: #666;
    border: none;
    padding: 8px 15px;
    border-bottom: 1px solid transparent;
    font-size: 18px;
    background: transparent; }
    .explorer_view .sr_tabs .nav-tabs li button.sr_menu {
      background: transparent;
      border: none;
      padding: 9px;
      margin: 0px 5px; }
    .explorer_view .sr_tabs .nav-tabs li button:hover, .explorer_view .sr_tabs .nav-tabs li button:focus {
      transition: outline 200ms;
      outline: 1px dashed #0263B2; }
  .explorer_view .sr_tabs .nav-tabs li.active button,
  .explorer_view .sr_tabs .nav-tabs li.active button:focus,
  .explorer_view .sr_tabs .nav-tabs li.active button:hover {
    color: #0063b1;
    background-color: #fff;
    border: none;
    cursor: pointer;
    border-bottom: 2px solid #0063b1;
    font-weight: 500; }
  .explorer_view .sr_tabs .sr_tab_content .tab-pane span.sr_tc_notok {
    width: 15px;
    height: 15px;
    background: #ccc;
    border: 1px solid #ccc;
    border-radius: 50%;
    font-size: 10px;
    float: right;
    padding: 1px 2px 2px 1px !important;
    color: #fff;
    margin-right: 5px; }
    @media screen and (-ms-high-contrast: active) {
      .explorer_view .sr_tabs .sr_tab_content .tab-pane span.sr_tc_notok.icon.icon-tick:not(.sr_tc_ok)::before {
        content: ''; } }
    @media screen and (-ms-high-contrast: black-on-white) {
      .explorer_view .sr_tabs .sr_tab_content .tab-pane span.sr_tc_notok.icon.icon-tick:not(.sr_tc_ok)::before {
        content: ''; } }
    @media screen and (-ms-high-contrast: white-on-black) {
      .explorer_view .sr_tabs .sr_tab_content .tab-pane span.sr_tc_notok.icon.icon-tick:not(.sr_tc_ok)::before {
        content: ''; } }
  .explorer_view .sr_tabs .sr_tab_content .tab-pane span.sr_tc_text {
    width: calc(100% - 23px);
    line-height: 18px;
    margin-top: -2px;
    color: #004578;
    font-family: 'Segoe UI';
    font-size: 14px;
    font-weight: 500; }
  .explorer_view .sr_tabs .sr_tab_content .tab-pane span.sr_tc_ok {
    background: #008000 !important;
    color: #fff;
    border-color: #008000 !important; }
  .explorer_view .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate {
    background: #fff;
    padding-bottom: 30px;
    padding: 0px 20px;
    border-left: 1px solid transparent; }
    .explorer_view .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board {
      position: relative;
      width: 100%;
      float: left;
      margin-top: 13px; }
      .explorer_view .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_heading {
        width: 100%;
        float: left;
        font-size: 20px; }
        .explorer_view .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_heading #viewDefinitionButton {
          color: #0063b1;
          background: none;
          border: 1px dashed transparent;
          font-size: 14px;
          padding: 5px 0px 0px 17px;
          min-height: 0px; }
          .explorer_view .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_heading #viewDefinitionButton:hover, .explorer_view .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_heading #viewDefinitionButton:focus {
            text-decoration: underline;
            outline: 1px solid #000; }
        .explorer_view .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_heading .fxp-btn--action {
          font-size: 15px; }
        .explorer_view .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_heading #reviewRating span {
          width: 100%; }
          .explorer_view .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_heading #reviewRating span.reviewText {
            margin-bottom: 5px;
            color: #004578; }
          .explorer_view .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_heading #reviewRating span.reviewTitle {
            color: #004578;
            font-weight: 500; }
      .explorer_view .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_skill .sr_tc_scroll {
        height: calc(100vh - 290px);
        overflow-y: auto;
        float: left;
        width: 100%; }
      .explorer_view .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_skill .sr_tc_s_level1 {
        float: left;
        width: 100%;
        padding-right: 10px; }
        .explorer_view .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_skill .sr_tc_s_level1 .h3 {
          text-transform: uppercase;
          font-weight: 500;
          color: #666666;
          font-size: 15px;
          margin-bottom: 15px;
          margin: 0px;
          padding: 10px 0px; }
        .explorer_view .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_skill .sr_tc_s_level1 p {
          font-weight: bold; }
        .explorer_view .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_skill .sr_tc_s_level1 .sr_prof p.h3 {
          margin: 0px;
          padding: 10px 0px;
          font-size: 15px; }
        .explorer_view .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_skill .sr_tc_s_level1 .sr_prof button {
          font-size: 14px;
          color: #0063b1;
          font-weight: 400;
          background: transparent;
          border: 1px solid transparent; }
        .explorer_view .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_skill .sr_tc_s_level1 .sr_prof .reviewText {
          color: #004578;
          font-size: 14px;
          font-weight: 600;
          font-family: 'Segoe UI'; }
      .explorer_view .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_skill .sr_tc_s_level2 {
        float: left;
        width: calc(100% - 8px);
        border: 1px solid #C8C8C8;
        margin-bottom: 20px;
        padding-bottom: 18px;
        padding-left: 5px;
        padding-right: 5px;
        background: #f8f8f8;
        margin-right: 5px; }
        .explorer_view .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_skill .sr_tc_s_level2:focus {
          border-color: #0263B2; }
        .explorer_view .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_skill .sr_tc_s_level2 .h3 {
          font-weight: 500;
          color: #333333;
          font-size: 13px;
          margin-bottom: 20px;
          position: relative;
          padding-left: 15px;
          float: left;
          width: 100%;
          margin-bottom: 8px; }
      .explorer_view .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_skill .rating {
        margin: 10px 0px;
        padding: 0;
        display: flex;
        width: 90%;
        float: left; }
        .explorer_view .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_skill .rating li {
          padding: 1px;
          color: #ccc;
          text-align: center;
          font-weight: bold;
          font-size: 20px;
          list-style-type: none;
          width: calc(25% - 20px);
          margin-right: 5px; }
          .explorer_view .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_skill .rating li p {
            color: #585858;
            font-size: 11px;
            text-align: left;
            margin-bottom: 10px; }
          .explorer_view .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_skill .rating li:last-child {
            margin-right: 0px; }
          .explorer_view .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_skill .rating li .srdactive {
            background: #5C2D91 !important;
            border-color: #5C2D91;
            color: #fff !important; }
            .explorer_view .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_skill .rating li .srdactive span {
              display: block;
              padding-bottom: 2px;
              color: #fff; }
        .explorer_view .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_skill .rating .bar {
          width: 100%;
          height: 30px;
          border: 1px solid #a9acae;
          position: relative;
          float: left;
          color: #333333;
          font-size: 13px;
          padding-bottom: 2px;
          background: #fff;
          width: 100%;
          border: 1px solid #ccc;
          position: relative;
          float: left;
          font-size: 13px;
          padding-bottom: 2px; }
          .explorer_view .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_skill .rating .bar.isreadonly:hover, .explorer_view .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_skill .rating .bar.isreadonly:focus {
            background: #f8f4fc;
            border-color: #8f59cb;
            color: #000; }
          .explorer_view .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_skill .rating .bar .srdmarkerimg {
            display: none; }
          .explorer_view .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_skill .rating .bar span {
            color: #333333;
            padding-bottom: 2px;
            display: block; }

.block, .resource .profile-main .skill-inner-right .skill-inner-right-bg .standerdtimezone, .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections {
  display: block;
  overflow: hidden; }

.in-block, .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections ul li {
  display: block;
  overflow: hidden; }

.error i {
  color: #e04d4d;
  margin-right: 3px; }

.error span {
  color: #e04d4d;
  font-size: 13px;
  margin-top: 3px; }

.error input {
  background: #f4d6d6; }

/* sales skill rating variables */
/* sales skill rating variables | end */
/* back button */
.learner_sr_tab_header .backtoskill {
  margin: 20px 0px;
  font-size: 15px;
  text-transform: uppercase;
  margin-bottom: 0px; }
  .learner_sr_tab_header .backtoskill i {
    font-size: 14px;
    margin-right: 5px; }
  .learner_sr_tab_header .backtoskill button {
    color: #666666;
    background: no-repeat;
    border: none;
    margin-left: 1px; }
    .learner_sr_tab_header .backtoskill button:focus, .learner_sr_tab_header .backtoskill button:hover {
      outline: 1px dashed; }

@media screen and (-ms-high-contrast: black-on-white) {
  .row .learner_sr_tc_desc .learner_sr_tc_desc-btn .learner_srd_tile {
    border: 1px solid black; }
  .learner_sr_rating_dc_active .learner_srd_tile {
    border: 1px solid black; } }

@media screen and (-ms-high-contrast: white-on-black) {
  .row .learner_sr_tc_desc .learner_sr_tc_desc-btn .learner_srd_tile {
    border: 1px solid white; }
  .learner_sr_rating_dc_active .learner_srd_tile {
    border: 1px solid white; } }

/* back button  | end */
.learner_slct_skl_grp {
  font-weight: 500;
  font-size: 18px;
  padding-top: 10px;
  padding-bottom: 10px;
  color: #004578;
  margin-bottom: 5px; }

/* Sales Skill Rating page CSS | Start */
.learner_mCustomScrollBox > .learner_mCSB_scrollTools {
  margin-right: -2px;
  width: 7px !important; }

.learner_sr_tc_desc-btn {
  margin: 10px 0px;
  background: transparent;
  border: none;
  width: 100%; }
  .learner_sr_tc_desc-btn:hover .learner_srd_tile, .learner_sr_tc_desc-btn:focus .learner_srd_tile {
    border-color: #2378d7; }
  .learner_sr_tc_desc-btn:focus .learner_srd_tile {
    box-shadow: none; }

/* skill rating page left tiles css */
.learner_sr_tc_dc_list-btn {
  width: 100%;
  padding-left: 23px;
  overflow: hidden;
  height: calc(100vh - 285px);
  overflow-y: auto; }
  .learner_sr_tc_dc_list-btn .learner_sr_tc_desc-btn {
    margin-top: 0px;
    position: relative;
    margin-right: 15px;
    width: calc(100% - 15px); }
  .learner_sr_tc_dc_list-btn.learner_sr_tc_dc_role {
    padding-left: 0px !important; }
    .learner_sr_tc_dc_list-btn.learner_sr_tc_dc_role .learner_sr_tile_active::after {
      top: 20px !important; }

.learner_sr_catogory {
  float: left;
  width: 100%; }
  .learner_sr_catogory .row {
    margin-left: -10px;
    margin-right: -10px; }
  .learner_sr_catogory .learner_sr_tc_desc {
    padding-left: 10px;
    padding-right: 10px;
    display: flex; }

/* skill rating page right tiles css | start */
.learner_sr_tab_tiles {
  height: calc(100vh - 130px);
  overflow-y: auto;
  padding-right: 23px;
  padding-bottom: 20px; }

.learner_sr_tab_tile_data {
  width: 100%;
  float: left;
  padding-bottom: 20px; }

.learner_sr_tabs_data {
  padding-right: 0px;
  padding-left: 23px;
  overflow: hidden; }
  .learner_sr_tabs_data div[ui-view] {
    overflow: hidden; }

#functional .learner_sr_tc_desc {
  overflow-y: auto;
  margin-top: 20px; }

.learner_sr_tc_desc .learner_src_tc_blue {
  border-color: #0078d7 !important; }

.learner_sr_tc_desc .learner_src_tc_orange {
  border-color: #BB4114 !important; }

.learner_sr_tc_desc .learner_sr_tc_dc_tile {
  margin-bottom: 10px; }

.learner_sr_tc_desc .learner_sr_tc_dc_active {
  padding: 13px;
  box-shadow: -1px 1px 4px 0 #ccc;
  margin-left: 0px;
  float: right;
  background: #fff;
  margin-bottom: 20px;
  margin-right: -15px;
  width: 100%; }
  .learner_sr_tc_desc .learner_sr_tc_dc_active p {
    color: #0263B2;
    float: left;
    width: calc(100% - 30px);
    font-weight: bold;
    font-size: 13px;
    margin: 0px; }

.learner_sr_tc_desc .learner_sr_tc_dc .learner_sr_tc_dc_tile {
  padding: 15px;
  border-left: 6px solid transparent;
  font-weight: bold;
  position: relative; }
  .learner_sr_tc_desc .learner_sr_tc_dc .learner_sr_tc_dc_tile p {
    width: calc(100% - 25px); }
  .learner_sr_tc_desc .learner_sr_tc_dc .learner_sr_tc_dc_tile span.learner_sr_pending_count {
    color: #BB4114;
    float: right;
    width: 38px;
    height: 100%;
    padding: 10px;
    top: 0px;
    border-left: 1px solid #ccc;
    display: block;
    font-size: 20px;
    font-weight: 500;
    position: absolute;
    right: 0; }
  .learner_sr_tc_desc .learner_sr_tc_dc .learner_sr_tc_dc_tile i {
    font-size: 14px;
    font-weight: bold; }

.learner_sr_tc_desc .learner_sr_tc_dc_list {
  margin-left: 10px; }

.learner_sr_tabs .learner_nav-tabs li button {
  color: #666;
  border: none;
  padding: 8px 15px;
  border-bottom: 1px solid transparent;
  font-size: 18px;
  background: transparent; }
  .learner_sr_tabs .learner_nav-tabs li button.learner_sr_menu {
    background: transparent;
    border: none;
    padding: 9px;
    margin: 0px 5px; }
  .learner_sr_tabs .learner_nav-tabs li button:hover, .learner_sr_tabs .learner_nav-tabs li button:focus {
    transition: outline 200ms;
    outline: 1px dashed #0263B2; }

.learner_sr_tabs .learner_nav-tabs li.learner_active button,
.learner_sr_tabs .learner_nav-tabs li.learner_active button:focus,
.learner_sr_tabs .learner_nav-tabs li.learner_active button:hover {
  color: #0063b1;
  background-color: #fff;
  border: none;
  cursor: pointer;
  border-bottom: 2px solid #0063b1;
  font-weight: 500; }

.learner_sr_tabs .learner_sr_tab_content .tab-pane span.learner_sr_tc_notok {
  width: 15px;
  height: 15px;
  background: #ccc;
  border: 1px solid #ccc;
  border-radius: 50%;
  font-size: 10px;
  float: right;
  padding: 1px 2px 2px 1px !important;
  color: #fff;
  margin-right: 5px; }
  @media screen and (-ms-high-contrast: active) {
    .learner_sr_tabs .learner_sr_tab_content .tab-pane span.learner_sr_tc_notok.learner_icon.learner_icon-tick:not(.learner_sr_tc_ok)::before {
      content: ''; } }
  @media screen and (-ms-high-contrast: black-on-white) {
    .learner_sr_tabs .learner_sr_tab_content .tab-pane span.learner_sr_tc_notok.learner_icon.learner_icon-tick:not(.learner_sr_tc_ok)::before {
      content: ''; } }
  @media screen and (-ms-high-contrast: white-on-black) {
    .learner_sr_tabs .learner_sr_tab_content .tab-pane span.learner_sr_tc_notok.learner_icon.learner_icon-tick:not(.learner_sr_tc_ok)::before {
      content: ''; } }

.learner_sr_tabs .learner_sr_tab_content .tab-pane span.learner_sr_tc_text {
  width: calc(100% - 23px);
  line-height: 18px;
  margin-top: -2px;
  color: #004578;
  font-family: 'Segoe UI';
  font-size: 14px;
  font-weight: 500; }

.learner_sr_tabs .learner_sr_tab_content .tab-pane span.learner_sr_tc_ok {
  background: #008000 !important;
  color: #fff;
  border-color: #008000 !important; }

.learner_sr_tabs .learner_sr_tab_content .tab-pane .learner_sr_tc_rate {
  background: #fff;
  padding-bottom: 30px;
  padding: 0px 20px;
  border-left: 1px solid transparent; }
  .learner_sr_tabs .learner_sr_tab_content .tab-pane .learner_sr_tc_rate .learner_sr_tc_board {
    position: relative;
    width: 100%;
    float: left;
    margin-top: 13px; }
    .learner_sr_tabs .learner_sr_tab_content .tab-pane .learner_sr_tc_rate .learner_sr_tc_board .learner_sr_tc_heading {
      width: 100%;
      float: left;
      font-size: 20px; }
      .learner_sr_tabs .learner_sr_tab_content .tab-pane .learner_sr_tc_rate .learner_sr_tc_board .learner_sr_tc_heading #viewDefinitionButton {
        color: #0063b1;
        background: none;
        border: 1px dashed transparent;
        font-size: 14px;
        padding: 5px 0px 0px 17px;
        min-height: 0px; }
        .learner_sr_tabs .learner_sr_tab_content .tab-pane .learner_sr_tc_rate .learner_sr_tc_board .learner_sr_tc_heading #viewDefinitionButton:hover, .learner_sr_tabs .learner_sr_tab_content .tab-pane .learner_sr_tc_rate .learner_sr_tc_board .learner_sr_tc_heading #viewDefinitionButton:focus {
          text-decoration: underline;
          outline: 1px solid #000; }
      .learner_sr_tabs .learner_sr_tab_content .tab-pane .learner_sr_tc_rate .learner_sr_tc_board .learner_sr_tc_heading .fxp-btn--action {
        font-size: 15px; }
      .learner_sr_tabs .learner_sr_tab_content .tab-pane .learner_sr_tc_rate .learner_sr_tc_board .learner_sr_tc_heading #reviewRating span {
        width: 100%; }
        .learner_sr_tabs .learner_sr_tab_content .tab-pane .learner_sr_tc_rate .learner_sr_tc_board .learner_sr_tc_heading #reviewRating span.reviewText {
          margin-bottom: 5px;
          color: #004578; }
        .learner_sr_tabs .learner_sr_tab_content .tab-pane .learner_sr_tc_rate .learner_sr_tc_board .learner_sr_tc_heading #reviewRating span.reviewTitle {
          color: #004578;
          font-weight: 500; }
    .learner_sr_tabs .learner_sr_tab_content .tab-pane .learner_sr_tc_rate .learner_sr_tc_board .learner_sr_tc_skill .learner_sr_tc_scroll {
      height: calc(100vh - 290px);
      overflow-y: auto;
      float: left;
      width: 100%; }
    .learner_sr_tabs .learner_sr_tab_content .tab-pane .learner_sr_tc_rate .learner_sr_tc_board .learner_sr_tc_skill .learner_sr_tc_s_level1 {
      float: left;
      width: 100%;
      padding-right: 10px; }
      .learner_sr_tabs .learner_sr_tab_content .tab-pane .learner_sr_tc_rate .learner_sr_tc_board .learner_sr_tc_skill .learner_sr_tc_s_level1 .h3 {
        text-transform: uppercase;
        font-weight: 500;
        color: #666666;
        font-size: 15px;
        margin-bottom: 15px;
        margin: 0px;
        padding: 10px 0px; }
      .learner_sr_tabs .learner_sr_tab_content .tab-pane .learner_sr_tc_rate .learner_sr_tc_board .learner_sr_tc_skill .learner_sr_tc_s_level1 p {
        font-weight: bold; }
      .learner_sr_tabs .learner_sr_tab_content .tab-pane .learner_sr_tc_rate .learner_sr_tc_board .learner_sr_tc_skill .learner_sr_tc_s_level1 .learner_sr_prof p.h3 {
        margin: 0px;
        padding: 10px 0px;
        font-size: 15px; }
      .learner_sr_tabs .learner_sr_tab_content .tab-pane .learner_sr_tc_rate .learner_sr_tc_board .learner_sr_tc_skill .learner_sr_tc_s_level1 .learner_sr_prof button {
        font-size: 14px;
        color: #0063b1;
        font-weight: 400;
        background: transparent;
        border: 1px solid transparent; }
      .learner_sr_tabs .learner_sr_tab_content .tab-pane .learner_sr_tc_rate .learner_sr_tc_board .learner_sr_tc_skill .learner_sr_tc_s_level1 .learner_sr_prof .reviewText {
        color: #004578;
        font-size: 14px;
        font-weight: 600;
        font-family: 'Segoe UI'; }
    .learner_sr_tabs .learner_sr_tab_content .tab-pane .learner_sr_tc_rate .learner_sr_tc_board .learner_sr_tc_skill .learner_sr_tc_s_level2 {
      float: left;
      width: calc(100% - 8px);
      border: 1px solid #C8C8C8;
      margin-bottom: 20px;
      padding-bottom: 18px;
      padding-left: 5px;
      padding-right: 5px;
      background: #f8f8f8;
      margin-right: 5px; }
      .learner_sr_tabs .learner_sr_tab_content .tab-pane .learner_sr_tc_rate .learner_sr_tc_board .learner_sr_tc_skill .learner_sr_tc_s_level2:focus {
        border-color: #0263B2; }
      .learner_sr_tabs .learner_sr_tab_content .tab-pane .learner_sr_tc_rate .learner_sr_tc_board .learner_sr_tc_skill .learner_sr_tc_s_level2 .h3 {
        font-weight: 500;
        color: #333333;
        font-size: 13px;
        margin-bottom: 20px;
        position: relative;
        padding-left: 15px;
        float: left;
        width: 100%;
        margin-bottom: 8px; }
    .learner_sr_tabs .learner_sr_tab_content .tab-pane .learner_sr_tc_rate .learner_sr_tc_board .learner_sr_tc_skill .rating {
      margin: 10px 0px;
      padding: 0;
      display: flex;
      width: 90%;
      float: left; }
      .learner_sr_tabs .learner_sr_tab_content .tab-pane .learner_sr_tc_rate .learner_sr_tc_board .learner_sr_tc_skill .rating li {
        padding: 1px;
        color: #ccc;
        text-align: center;
        font-weight: bold;
        font-size: 20px;
        list-style-type: none;
        width: calc(25% - 20px);
        margin-right: 5px; }
        .learner_sr_tabs .learner_sr_tab_content .tab-pane .learner_sr_tc_rate .learner_sr_tc_board .learner_sr_tc_skill .rating li p {
          color: #585858;
          font-size: 11px;
          text-align: left;
          margin-bottom: 10px; }
        .learner_sr_tabs .learner_sr_tab_content .tab-pane .learner_sr_tc_rate .learner_sr_tc_board .learner_sr_tc_skill .rating li:last-child {
          margin-right: 0px; }
        .learner_sr_tabs .learner_sr_tab_content .tab-pane .learner_sr_tc_rate .learner_sr_tc_board .learner_sr_tc_skill .rating li .srdactive {
          background: #5C2D91 !important;
          border-color: #5C2D91;
          color: #fff !important; }
          .learner_sr_tabs .learner_sr_tab_content .tab-pane .learner_sr_tc_rate .learner_sr_tc_board .learner_sr_tc_skill .rating li .srdactive span {
            display: block;
            padding-bottom: 2px;
            color: #fff; }
      .learner_sr_tabs .learner_sr_tab_content .tab-pane .learner_sr_tc_rate .learner_sr_tc_board .learner_sr_tc_skill .rating .bar {
        width: 100%;
        height: 30px;
        border: 1px solid #a9acae;
        position: relative;
        float: left;
        color: #333333;
        font-size: 13px;
        padding-bottom: 2px;
        background: #fff;
        width: 100%;
        border: 1px solid #ccc;
        position: relative;
        float: left;
        font-size: 13px;
        padding-bottom: 2px; }
        .learner_sr_tabs .learner_sr_tab_content .tab-pane .learner_sr_tc_rate .learner_sr_tc_board .learner_sr_tc_skill .rating .bar.isreadonly:hover, .learner_sr_tabs .learner_sr_tab_content .tab-pane .learner_sr_tc_rate .learner_sr_tc_board .learner_sr_tc_skill .rating .bar.isreadonly:focus {
          background: #f8f4fc;
          border-color: #8f59cb;
          color: #000; }
        .learner_sr_tabs .learner_sr_tab_content .tab-pane .learner_sr_tc_rate .learner_sr_tc_board .learner_sr_tc_skill .rating .bar .learner_srdmarkerimg {
          display: none; }
        .learner_sr_tabs .learner_sr_tab_content .tab-pane .learner_sr_tc_rate .learner_sr_tc_board .learner_sr_tc_skill .rating .bar span {
          color: #333333;
          padding-bottom: 2px;
          display: block; }

/* Sales Skill Rating CSS | END */
@media screen and (-ms-high-contrast: active) {
  .learner_sr_tabs .nav-tabs li button {
    border: none !important;
    color: #00009f; }
  .learner_sr_tabs .nav-tabs li.learner_active button {
    border-bottom: 2px solid #00009f !important; }
  .learner_sr_tc_skill .srdactive {
    background: #000;
    color: #fff; } }

@media screen and (-ms-high-contrast: white-on-black) {
  .learner_sr_tabs .learner_nav-tabs li button {
    border: none !important;
    color: yellow; }
  .learner_sr_tabs .learner_nav-tabs li.learner_active button {
    border-bottom: 2px solid yellow !important; }
  .learner_skill_rating .learner_sr_tc_skill .srdactive {
    background: #fff;
    color: #000; } }

/* skill tile Directive CSS start */
.learner_srd_tile {
  background: #fff;
  box-sizing: border-box;
  height: 0px;
  font-family: arial;
  display: flex;
  min-height: 54px;
  align-items: center;
  font-size: 13px;
  padding: 2px 0px;
  position: relative;
  transition: border 100ms ease-in-out; }
  .learner_srd_tile i {
    font-size: 20px; }
  .learner_srd_tile .selectedIcon {
    color: #008000 !important;
    font-weight: 600; }
  .learner_srd_tile .learner_srd_tile_status {
    text-transform: uppercase;
    color: #666666;
    font-size: 8px !important;
    margin-bottom: 3px !important;
    margin-top: -5px;
    line-height: inherit !important;
    display: block; }
  .learner_srd_tile .learner_srd_tile_reviewed {
    text-transform: uppercase;
    color: #666666;
    font-size: 8px !important;
    margin-bottom: 3px !important;
    margin-top: -5px;
    display: block;
    line-height: inherit !important; }
  .learner_srd_tile::before {
    content: '';
    width: 6px;
    height: 100%;
    top: 0px;
    position: absolute;
    background: transparent; }
  .learner_srd_tile .learner_srd_tile_text {
    padding: 3px 10px 3px 21px;
    width: calc(100% - 50px);
    float: left;
    font-size: 15px;
    font-weight: 500;
    color: #666666;
    text-align: left;
    line-height: 16px; }
    .learner_srd_tile .learner_srd_tile_text p {
      margin: 0px; }
  .learner_srd_tile .learner_srd_tile_count {
    width: 50px;
    text-align: center;
    float: right; }
    .learner_srd_tile .learner_srd_tile_count p {
      font-size: 25px;
      margin-bottom: 0px; }
    .learner_srd_tile .learner_srd_tile_count::after {
      background: #95989A;
      top: 0px;
      height: 100%;
      content: '';
      width: 1px;
      right: 50px;
      position: absolute;
      border-right: 1px solid transparent; }
  .learner_srd_tile .learner_srd_tile_tick {
    width: 50px;
    text-align: center;
    float: right; }
    .learner_srd_tile .learner_srd_tile_tick i {
      background: #008000;
      color: #fff;
      padding: 2px;
      border-radius: 50%;
      font-size: 10px;
      border: 1px solid transparent;
      margin-left: 3px; }
  .learner_srd_tile.learner_srd_tile_error::before {
    background: #A80000;
    border: 1px solid transparent; }
  .learner_srd_tile.learner_srd_tile_error .learner_srd_tile_count {
    color: #A80000; }
  .learner_srd_tile.learner_srd_tile_primary::before {
    background: #0263B2; }
  .learner_srd_tile.learner_srd_tile_active .learner_srd_tile_text {
    color: #004578 !important;
    font-weight: bold; }
  .learner_srd_tile.learner_srd_tile_warning {
    position: relative;
    border-left: 0px; }
    .learner_srd_tile.learner_srd_tile_warning::before {
      background-color: #D83B01;
      background-image: -webkit-linear-gradient(45deg, #D83B01 25%, #D83B01 25%, #efefef 26%, #fff 50%, #fff 50%, #D83B01 50%, #D83B01 74%, #fff 74%);
      background-image: -o-linear-gradient(45deg, #D83B01 25%, #D83B01 25%, #efefef 26%, #fff 50%, #fff 50%, #D83B01 50%, #D83B01 74%, #fff 74%);
      background-image: linear-gradient(45deg, #D83B01 25%, #D83B01 25%, #efefef 26%, #fff 50%, #fff 50%, #D83B01 50%, #D83B01 74%, #fff 74%);
      background-repeat: repeat;
      background-size: 11px 8px; }
    .learner_srd_tile.learner_srd_tile_warning .learner_srd_tile_count {
      color: #D83B01; }
  .learner_srd_tile.learner_sr_tc_large {
    border-left-width: 6px !important; }
  .learner_srd_tile.learner_srd_tile_success::before {
    background: #008000; }
  .learner_srd_tile.learner_srd_tile_success .learner_srd_tile_count {
    color: #008000; }
  .learner_srd_tile.learner_sr_level2 {
    min-height: 50px; }
    .learner_srd_tile.learner_sr_level2::before {
      width: 4px; }
    .learner_srd_tile.learner_sr_level2 .learner_srd_tile_text {
      font-size: 13px;
      width: calc(100% - 40px); }
    .learner_srd_tile.learner_sr_level2 .learner_srd_tile_count {
      font-size: 16px;
      width: 41px;
      min-height: 44px;
      margin-left: -4px; }
      .learner_srd_tile.learner_sr_level2 .learner_srd_tile_count p {
        font-size: 20px;
        line-height: 40px; }
      .learner_srd_tile.learner_sr_level2 .learner_srd_tile_count::after {
        right: 50px; }
    .learner_srd_tile.learner_sr_level2 .learner_srd_tile_tick {
      width: 50px;
      min-height: 44px;
      margin-top: -4px; }
      .learner_srd_tile.learner_sr_level2 .learner_srd_tile_tick i {
        margin-top: 14px !important; }
      .learner_srd_tile.learner_sr_level2 .learner_srd_tile_tick .learner_srd_tile_reviewed {
        font-size: 9px !important;
        margin-bottom: 0px !important;
        margin-top: 0px !important; }
  .learner_srd_tile.learner_srd_tile_manager .learner_srd_tile_tick i {
    margin-top: 12px; }
  .learner_srd_tile.learner_srd_tile_manager .learner_srd_tile_tick {
    min-height: 44px; }

.learner_sr_rating_dc_active {
  margin-bottom: 10px;
  padding-right: 15px; }
  .learner_sr_rating_dc_active .learner_srd_tile {
    height: 52px; }
  .learner_sr_rating_dc_active.learner_srd_tile_manager .learner_srd_tile_tick i {
    margin-top: 9px; }
  .learner_sr_rating_dc_active .learner_sr_level2 {
    min-height: 50px;
    border-left-width: 6px; }
    .learner_sr_rating_dc_active .learner_sr_level2 .learner_srd_tile_text {
      font-size: 15px;
      width: calc(100% - 44px); }
    .learner_sr_rating_dc_active .learner_sr_level2.learner_srd_tile_warning::before {
      width: 6px; }
    .learner_sr_rating_dc_active .learner_sr_level2 .learner_srd_tile_count {
      font-size: 25px;
      width: 44px;
      min-height: 50px; }
      .learner_sr_rating_dc_active .learner_sr_level2 .learner_srd_tile_count p {
        line-height: 55px; }
    .learner_sr_rating_dc_active .learner_sr_level2 .learner_srd_tile_tick {
      width: 44px;
      min-height: 50px; }
      .learner_sr_rating_dc_active .learner_sr_level2 .learner_srd_tile_tick i {
        margin-top: 18px; }

.learner_sr_tile_active {
  width: 100% !important; }
  .learner_sr_tile_active .learner_srd_tile_text {
    color: #004578;
    width: calc(100% - 60px) !important; }
  .learner_sr_tile_active .learner_srd_tile {
    border-right-color: #fff; }
  .learner_sr_tile_active .learner_srd_tile_count,
  .learner_sr_tile_active .learner_srd_tile_tick {
    visibility: hidden; }
    .learner_sr_tile_active .learner_srd_tile_count::after,
    .learner_sr_tile_active .learner_srd_tile_tick::after {
      width: 0px; }
  .learner_sr_tile_active::after {
    content: "\E00F";
    right: 30px;
    top: 30% !important;
    font-family: "SegoeUI MDL2 Assets";
    font-weight: 400;
    font-style: normal;
    position: absolute;
    top: 1px;
    height: 44px;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }

/* role perfromance tab css | start */
.learner_sr_tc_dc_role .learner_srd_tile {
  min-height: 57px !important; }

/* role perfromance tab css | start */
/* skill tile Directive CSS | end  */
/* reporteeThumbnail Directive CSS | start */
.learner_sr_thumbDropDown {
  text-align: left;
  width: 220px; }
  .learner_sr_thumbDropDown.learner_sr_thumb .dropdown-menu {
    max-height: 243px;
    overflow-y: auto; }
  .learner_sr_thumbDropDown.open .icon-chevronDownMed {
    transform: rotate(180deg); }
  .learner_sr_thumbDropDown #mCSB_6_scrollbar_vertical {
    background: #b7b7b7; }
  .learner_sr_thumbDropDown.open .glyphicon-menu-down {
    transform: rotate(180deg); }
  .learner_sr_thumbDropDown .glyphicon-menu-down {
    float: right;
    position: absolute;
    right: 15px;
    top: 14px;
    font-size: 12px;
    color: #0263B2; }
  .learner_sr_thumbDropDown ul {
    width: 100%;
    border: none;
    border-radius: 0px;
    box-shadow: none;
    background: #fff;
    padding: 0px;
    border: 1px solid #95989A; }
    .learner_sr_thumbDropDown ul li {
      width: 100%;
      float: left; }
      .learner_sr_thumbDropDown ul li:first-child a {
        border-top: 1px solid #95989A !important; }
      .learner_sr_thumbDropDown ul li a {
        padding-top: 7px;
        padding-bottom: 7px;
        border-bottom: 1px solid #95989A !important;
        width: 100%;
        float: left;
        color: #333333 !important;
        text-transform: capitalize;
        border-left: 3px solid transparent !important;
        background: #fff;
        padding-left: 10px; }
        .learner_sr_thumbDropDown ul li a .learner_srtdselect, .learner_sr_thumbDropDown ul li a:hover, .learner_sr_thumbDropDown ul li a:active, .learner_sr_thumbDropDown ul li a:focus {
          background: #eeeeee; }
      .learner_sr_thumbDropDown ul li:last-child a {
        border-bottom: 0px !important; }
  .learner_sr_thumbDropDown .learner_srtd-img {
    width: 25px;
    border-radius: 50%;
    border: 1px solid #a9acae;
    height: 25px;
    overflow: hidden; }
    .learner_sr_thumbDropDown .learner_srtd-img img {
      width: 100%; }
  .learner_sr_thumbDropDown .learner_srtd-name {
    width: calc(100% - 40px);
    text-align: left;
    margin-top: 2px;
    overflow: hidden;
    float: left !important;
    margin-left: 8px !important; }
  .learner_sr_thumbDropDown #srtd-btn {
    background: #fff;
    color: inherit;
    text-shadow: none;
    border: none;
    border-radius: 0px;
    padding: 7px 21px;
    width: 100%;
    border: 1px solid transparent;
    text-transform: capitalize;
    color: #585858 !important;
    border: 1px solid transparent;
    border-left: 3px solid transparent;
    border-right: 1px solid #a9acae; }
    .learner_sr_thumbDropDown #srtd-btn:hover, .learner_sr_thumbDropDown #srtd-btn:active, .learner_sr_thumbDropDown #srtd-btn:focus {
      background: #fff;
      box-shadow: none;
      border-color: #a9acae; }
    .learner_sr_thumbDropDown #srtd-btn .icon-chevronDownMed {
      font-size: 13px;
      float: right;
      margin-right: -14px;
      margin-top: 7px;
      color: #0263B2; }

.learner_sr_thumb ul li a {
  padding-left: 22px; }

/* reporteeThumbnail Directive CSS | end  */
/*view explore role information popup CSS | start */
.sr_tabs_nav .expoler_other_roles {
  padding-top: 10px;
  padding-left: 0;
  padding-right: 10px;
  font-size: 17px;
  font-family: 'Segoe UI';
  font-weight: 600;
  color: #727272; }
  .sr_tabs_nav .expoler_other_roles button {
    font-size: 16px;
    border: none;
    background-color: white;
    padding: 0px 10px;
    float: right; }
  .sr_tabs_nav .expoler_other_roles p {
    display: inline; }

.oneprofile-explorer-roles-modal-backdrop.in {
  background: none !important; }

.modal.oneprofile-explorer-roles-modal-backdrop.modal-backdrop.in {
  background: none !important; }

.modal.oneprofile-explorer-roles-modal .modal-dialog {
  right: 10px;
  margin: 0;
  position: absolute;
  height: 220px;
  transform: translate(0, 0);
  width: 340px;
  color: #666666;
  top: 110px; }
  .modal.oneprofile-explorer-roles-modal .modal-dialog button {
    font-size: 14px;
    float: right;
    margin-top: 2px;
    font-weight: 700;
    color: #666666; }
    .modal.oneprofile-explorer-roles-modal .modal-dialog button:hover {
      opacity: 0.8; }
    .modal.oneprofile-explorer-roles-modal .modal-dialog button:focus {
      outline-color: #666666; }
    .modal.oneprofile-explorer-roles-modal .modal-dialog button i {
      background: #666666;
      color: #fff;
      border-radius: 50%;
      padding: 3px;
      font-size: 12px !important;
      width: 22px;
      height: 22px;
      line-height: 17px; }
  .modal.oneprofile-explorer-roles-modal .modal-dialog .modal-content {
    border-radius: 0;
    overflow: auto;
    box-shadow: none;
    border: 3px solid #666666; }
    .modal.oneprofile-explorer-roles-modal .modal-dialog .modal-content .icon-close {
      top: 2px;
      left: -10px;
      font-size: 15px; }
    .modal.oneprofile-explorer-roles-modal .modal-dialog .modal-content .roleIfno {
      padding: 0 15px; }
      .modal.oneprofile-explorer-roles-modal .modal-dialog .modal-content .roleIfno h1 {
        font-size: 18px;
        margin: 5px 0px 25px 0px; }
      .modal.oneprofile-explorer-roles-modal .modal-dialog .modal-content .roleIfno p {
        font-size: 14px; }

.modal.oneprofile-explorer-roles-modal.in .modal-dialog {
  transform: translate(0, 0) !important; }

/* view explore role information blade/popup CSS | END */
/* view defination blade/popup CSS | start */
.oneprofile-proficiency-definitions-modal-backdrop.in {
  background: none !important; }

.modal.oneprofile-proficiency-definitions-modal-backdrop.modal-backdrop.in {
  background: none !important; }

.modal.oneprofile-proficiency-definitions-modal .modal-dialog {
  right: 0;
  margin: 0;
  position: absolute;
  height: 100vh;
  transform: translate(100%, 0);
  max-width: 452px;
  width: 80%;
  color: #fff; }
  .modal.oneprofile-proficiency-definitions-modal .modal-dialog button {
    color: #fff;
    font-size: 14px;
    float: right;
    margin-top: 2px; }
    .modal.oneprofile-proficiency-definitions-modal .modal-dialog button:hover {
      opacity: 0.8; }
    .modal.oneprofile-proficiency-definitions-modal .modal-dialog button:focus {
      outline-color: #fff; }
    .modal.oneprofile-proficiency-definitions-modal .modal-dialog button i {
      background: #fff;
      color: #000;
      border-radius: 50%;
      padding: 3px;
      font-size: 12px !important;
      width: 22px;
      height: 22px;
      line-height: 17px; }
  .modal.oneprofile-proficiency-definitions-modal .modal-dialog .modal-content {
    border-radius: 0;
    padding: 15px;
    overflow: auto;
    padding-top: 0px;
    background: #313131;
    box-shadow: none; }
    .modal.oneprofile-proficiency-definitions-modal .modal-dialog .modal-content .icon-close {
      top: 2px;
      left: -10px;
      font-size: 15px; }
    .modal.oneprofile-proficiency-definitions-modal .modal-dialog .modal-content h1 {
      font-weight: 100;
      font-size: 18px;
      margin-bottom: 30px;
      color: #fff;
      margin-top: 30px; }
    .modal.oneprofile-proficiency-definitions-modal .modal-dialog .modal-content dt {
      font-size: 16px;
      font-weight: 600; }
    .modal.oneprofile-proficiency-definitions-modal .modal-dialog .modal-content dl dd {
      margin-bottom: 20px;
      font-weight: 400;
      font-size: 14px;
      margin-top: 10px; }

.modal.oneprofile-proficiency-definitions-modal.in .modal-dialog {
  transform: translate(0, 0); }

/* view defination blade/popup CSS | END */
@media screen and (-ms-high-contrast: active) {
  .learner_srd_tile_warning::before {
    background: #666; }
  .learner_srt_warning {
    background: #666; }
  .learner_srt_success {
    background: #fff;
    color: #fff; }
  .learner_srd_tile_success::before {
    background: #fff; } }

@media screen and (-ms-high-contrast: white-on-black) {
  .learner_srt_success {
    background: #fff;
    color: #fff; }
  .learner_srd_tile_success::before {
    background: #fff; } }

@media screen and (-ms-high-contrast: black-on-white) {
  .learner_srt_success {
    background: #000;
    color: #000; }
  .learner_srd_tile_success::before {
    background: #000; } }

.personal_view .explorer_view_text {
  color: #303030;
  font-family: Segoe UI;
  font-size: 20px; }

.expand_collapse {
  float: right; }

.sr_accordion {
  clear: both;
  border-bottom: 1px solid #A7AAAC;
  padding-bottom: 15px; }

.role_active {
  height: 28vh;
  overflow: auto; }

.roles_accordian .panel-group .panel-heading + .panel-collapse > .list-group, .roles_accordian .panel-group .panel-heading + .panel-collapse > .panel-body {
  border-top: none; }

.roles_accordian .panel {
  background: transparent;
  border: none;
  box-shadow: none; }
  .roles_accordian .panel .panel-body {
    border-top: none;
    padding: 0; }
  .roles_accordian .panel div[role="tab"] {
    background: transparent;
    padding: 5px 0px;
    border: none;
    margin-bottom: 25px; }
    .roles_accordian .panel div[role="tab"] i.glyphicon {
      line-height: 1; }
    .roles_accordian .panel div[role="tab"] i.glyphicon,
    .roles_accordian .panel div[role="tab"] a[role="button"] {
      background: #e4e4e4;
      position: relative;
      z-index: 1;
      color: #666666;
      padding-left: 1px;
      float: left;
      padding-bottom: 0px;
      outline: none;
      padding-top: 2px; }
      .roles_accordian .panel div[role="tab"] i.glyphicon:focus,
      .roles_accordian .panel div[role="tab"] a[role="button"]:focus {
        border: 1px dotted #000000; }
        .roles_accordian .panel div[role="tab"] i.glyphicon:focus i.glyphicon,
        .roles_accordian .panel div[role="tab"] a[role="button"]:focus i.glyphicon {
          border: none !important;
          border-bottom: 1px dotted #000 !important; }

.roles_accordian .panel-title {
  position: relative;
  display: block; }
  .roles_accordian .panel-title:after {
    content: '';
    height: 1px;
    background: #A7AAAC;
    width: 100%;
    position: absolute;
    top: 10px;
    left: 0px; }
  .roles_accordian .panel-title span {
    position: relative;
    width: 100%;
    padding-right: 10px;
    padding-left: 5px;
    z-index: 1;
    padding-bottom: 1px; }
    .roles_accordian .panel-title span span {
      color: #004578 !important;
      font-size: 16px;
      font-weight: 500; }

.explorer_view {
  float: left;
  width: 100%;
  min-height: 70vh; }
  .explorer_view .uib-dropdown {
    padding-right: 0; }
  .explorer_view .roles_accordian_data {
    padding: 0;
    margin-top: -20px; }
  @media screen and (-ms-high-contrast: black-on-white) {
    .explorer_view .roles_accordian .panel-title span span {
      color: darkblue; }
    .explorer_view .roles_accordian .panel div[role=tab] i.glyphicon {
      color: darkblue; }
    .explorer_view .btn-group .dropdown-menu-list a {
      color: darkblue; } }
  @media screen and (-ms-high-contrast: white-on-black) {
    .explorer_view .roles_accordian .panel-title span span {
      color: yellow; }
    .explorer_view .roles_accordian .panel div[role=tab] i.glyphicon {
      color: yellow; }
    .explorer_view .btn-group .dropdown-menu-list a {
      color: yellow; } }
  @media screen and (-ms-high-contrast: black-on-white) {
    .explorer_view .roles_accordian .panel-title span span {
      color: darkblue; }
    .explorer_view .roles_accordian .panel div[role=tab] i.glyphicon {
      color: darkblue; }
    .explorer_view .btn-group .dropdown-menu-list a {
      color: darkblue; } }
  @media screen and (-ms-high-contrast: white-on-black) {
    .explorer_view .roles_accordian .panel-title span span {
      color: yellow; }
    .explorer_view .roles_accordian .panel div[role=tab] i.glyphicon {
      color: yellow; }
    .explorer_view .btn-group .dropdown-menu-list a {
      color: yellow; } }
  .explorer_view .dropdown-menu > li > a:focus, .explorer_view .dropdown-menu > li > a:hover {
    background: none !important;
    outline: none; }
  .explorer_view .dropdown-menu {
    border-radius: 0 !important;
    padding: 0;
    min-width: 125px !important; }
  .explorer_view .dropdown-toggle:focus {
    outline: 1px dotted #3c3c3c; }
  .explorer_view .dropdown-menu > li > a {
    margin: 5px 0 5px 0;
    color: inherit !important; }
  .explorer_view .dropdown-toggle {
    border: none;
    background: 0;
    margin: 0;
    margin-bottom: 0;
    box-shadow: none;
    -webkit-box-shadow: none;
    font-size: 14px;
    font-family: Segoe UI;
    color: #000000;
    font-weight: 400; }
    .explorer_view .dropdown-toggle .btn-default:active:hover, .explorer_view .dropdown-toggle .open > .dropdown-toggle.btn-default:hover {
      color: none;
      background-color: none;
      border-color: none; }
    .explorer_view .dropdown-toggle span {
      font-weight: 600; }
      .explorer_view .dropdown-toggle span .glyphicon {
        color: #666666;
        padding-left: 4px;
        margin-top: 1px; }
  .explorer_view .btn-group.open .dropdown-toggle {
    box-shadow: none;
    -webkit-box-shadow: none; }
  .explorer_view .btn-group .sort_filter {
    font-size: 14px;
    font-family: Segoe UI;
    font-weight: 400;
    padding-left: 0;
    padding-right: 0;
    margin-top: 2px;
    float: left; }
  .explorer_view .btn-group .sort_btn {
    width: 60px; }
  .explorer_view .icon_btn.dropdown-toggle {
    font-size: 14px;
    font-family: Segoe UI;
    font-weight: 600;
    margin-top: -5px;
    padding-left: 0; }
  .explorer_view .btn-group .dropdown-menu-list {
    border-bottom: 1px solid #A7AAAC;
    background-color: none;
    float: left;
    width: 100%;
    padding: 0px; }
    .explorer_view .btn-group .dropdown-menu-list a {
      margin: 0px;
      padding: 8px 10px; }
      .explorer_view .btn-group .dropdown-menu-list a:focus, .explorer_view .btn-group .dropdown-menu-list a:active, .explorer_view .btn-group .dropdown-menu-list a:hover {
        background-color: #0078d7 !important;
        color: #fff !important; }
  .explorer_view .expand_collapse {
    padding-bottom: 5px;
    font-size: 11px;
    font-family: Segoe UI;
    font-weight: 600; }
    .explorer_view .expand_collapse .active {
      color: #0078D7 !important; }
    .explorer_view .expand_collapse button {
      border: none;
      background: transparent;
      padding-left: 10px;
      color: #000000; }
      .explorer_view .expand_collapse button:active, .explorer_view .expand_collapse button:hover {
        color: #0078D7 !important; }

.modal.oneprofile-learner-dashboard-modal-backdrop.modal-backdrop.in {
  background: none !important; }

.modal.oneprofile-learner-dashboard-modal .modal-dialog {
  right: 10px;
  margin: 0;
  position: absolute;
  height: 90px;
  transform: translate(0, 0);
  width: 340px;
  color: #666666;
  top: 115px; }
  .modal.oneprofile-learner-dashboard-modal .modal-dialog button {
    font-size: 14px;
    float: right;
    margin-top: 2px;
    font-weight: 700;
    color: #666666; }
    .modal.oneprofile-learner-dashboard-modal .modal-dialog button:hover {
      opacity: 0.8; }
    .modal.oneprofile-learner-dashboard-modal .modal-dialog button:focus {
      outline-color: #666666; }
    .modal.oneprofile-learner-dashboard-modal .modal-dialog button i {
      background: #666666;
      color: #fff;
      border-radius: 50%;
      padding: 3px;
      font-size: 12px !important;
      width: 22px;
      height: 22px;
      line-height: 17px; }
  .modal.oneprofile-learner-dashboard-modal .modal-dialog .modal-content {
    border-radius: 0;
    overflow: auto;
    box-shadow: none;
    border: 1px solid #666666; }
    .modal.oneprofile-learner-dashboard-modal .modal-dialog .modal-content .icon-close {
      top: 2px;
      left: -10px;
      font-size: 10px; }
    .modal.oneprofile-learner-dashboard-modal .modal-dialog .modal-content .roleIfno {
      padding: 0 15px; }
      .modal.oneprofile-learner-dashboard-modal .modal-dialog .modal-content .roleIfno h1 {
        font-size: 18px;
        margin: 5px 0px 25px 0px; }
      .modal.oneprofile-learner-dashboard-modal .modal-dialog .modal-content .roleIfno p {
        font-size: 14px; }

.modal.oneprofile-learner-dashboard-modal.in .modal-dialog {
  transform: translate(0, 0) !important; }

.modal.oneprofile-mgr-dashboard-modal-backdrop.modal-backdrop.in {
  background: none !important; }

.modal.oneprofile-mgr-dashboard-modal .modal-dialog {
  right: 10px;
  margin: 0;
  position: absolute;
  height: 70px;
  transform: translate(0, 0);
  width: 340px;
  color: #666666;
  top: 200px; }
  .modal.oneprofile-mgr-dashboard-modal .modal-dialog button {
    font-size: 14px;
    float: right;
    margin-top: 2px;
    font-weight: 700;
    color: #666666; }
    .modal.oneprofile-mgr-dashboard-modal .modal-dialog button:hover {
      opacity: 0.8; }
    .modal.oneprofile-mgr-dashboard-modal .modal-dialog button:focus {
      outline-color: #666666; }
    .modal.oneprofile-mgr-dashboard-modal .modal-dialog button i {
      background: #666666;
      color: #fff;
      border-radius: 50%;
      padding: 3px;
      font-size: 12px !important;
      width: 22px;
      height: 22px;
      line-height: 17px; }
  .modal.oneprofile-mgr-dashboard-modal .modal-dialog .modal-content {
    border-radius: 0;
    overflow: auto;
    box-shadow: none;
    border: 1px solid #666666; }
    .modal.oneprofile-mgr-dashboard-modal .modal-dialog .modal-content .icon-close {
      top: 2px;
      left: -10px;
      font-size: 10px; }
    .modal.oneprofile-mgr-dashboard-modal .modal-dialog .modal-content .roleIfno {
      padding: 0 15px; }
      .modal.oneprofile-mgr-dashboard-modal .modal-dialog .modal-content .roleIfno h1 {
        font-size: 18px;
        margin: 5px 0px 25px 0px; }
      .modal.oneprofile-mgr-dashboard-modal .modal-dialog .modal-content .roleIfno p {
        font-size: 14px; }

.modal.oneprofile-mgr-dashboard-modal.in .modal-dialog {
  transform: translate(0, 0) !important; }

.block, .resource .profile-main .skill-inner-right .skill-inner-right-bg .standerdtimezone, .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections {
  display: block;
  overflow: hidden; }

.in-block, .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections ul li {
  display: block;
  overflow: hidden; }

.error i {
  color: #e04d4d;
  margin-right: 3px; }

.error span {
  color: #e04d4d;
  font-size: 13px;
  margin-top: 3px; }

.error input {
  background: #f4d6d6; }

/* sales skill rating variables */
/* sales skill rating variables | end */
.cxp .srd_tile .srd_tile_count p {
  font-size: 16px;
  color: #666; }

.cxp .srd_tile.sr_level2 .srd_tile_count p {
  font-size: 13px;
  line-height: 50px; }

.cxp .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_skill .rating {
  width: 100%; }

@media screen and (max-width: 1280px) {
  .cxp .sr_tabs .sr_tab_content .tab-pane .sr_tc_rate .sr_tc_board .sr_tc_skill .rating .bar {
    height: 45px; } }

.block, .resource .profile-main .skill-inner-right .skill-inner-right-bg .standerdtimezone, .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections {
  display: block;
  overflow: hidden; }

.in-block, .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections ul li {
  display: block;
  overflow: hidden; }

.error i {
  color: #e04d4d;
  margin-right: 3px; }

.error span {
  color: #e04d4d;
  font-size: 13px;
  margin-top: 3px; }

.error input {
  background: #f4d6d6; }

/* sales skill rating variables */
/* sales skill rating variables | end */
.cxp .sr_tab_tile_data {
  padding-right: 23px;
  width: 100%;
  float: left;
  padding-bottom: 20px; }

.cxp .srd_tile_count {
  width: 77px; }
  .cxp .srd_tile_count::after {
    right: 65px; }

.cxp.sr_learner .sr_container .sr_l_tile .sr_l_t_body .sr_l_knob .sr_l_kn_details {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 180px;
  padding: 36px; }

.cxp.sr_learner .sr_container .sr_l_tile .sr_l_t_body .sr_l_knob .sr_l_k_l1 p {
  font-size: 32px;
  min-width: 50px;
  text-align: right; }

.cxp.sr_learner .sr_container .sr_l_tile .sr_l_t_body .sr_l_knob .sr_l_k_l2 p {
  font-size: 32px; }

.cxp.sr_learner .sr_container .sr_l_tile .sr_l_t_body .sr_l_knob .sr_l_k_l3 {
  text-align: right; }

.cxp.sr_learner .sr_container .sr_l_tile .sr_l_t_footer {
  padding: 0px;
  float: left;
  width: 100%;
  color: #000; }

.cxp .sr_l_t_body_mgr {
  text-align: center; }

.cxp .size-groupIcon {
  font-size: 170px;
  -webkit-text-fill-color: #c6c69b;
  -webkit-text-stroke-color: #fff;
  -webkit-text-stroke-width: 4px; }

.block, .resource .profile-main .skill-inner-right .skill-inner-right-bg .standerdtimezone, .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections {
  display: block;
  overflow: hidden; }

.in-block, .resource .profile-main .skill-inner-right .skill-inner-right-bg .travel-detail-sections ul li {
  display: block;
  overflow: hidden; }

.error i {
  color: #e04d4d;
  margin-right: 3px; }

.error span {
  color: #e04d4d;
  font-size: 13px;
  margin-top: 3px; }

.error input {
  background: #f4d6d6; }

/* sales skill rating variables */
/* sales skill rating variables | end */
.cxp_mgr .sr_t_dashboard .sr_td_tile {
  width: 85%;
  max-width: 85%; }

.cxp_mgr p.sr-per {
  width: 65px; }

.cxpace .acefullwidth {
  width: 100%; }

.cxpace .acetoppadding {
  padding-top: 10px; }

.cxpace .aceformcontrol {
  border-radius: 0px;
  -webkit-appearance: none;
  text-indent: 1px; }

.cxpace .acebutton {
  border-radius: 0px;
  border: 0px;
  height: 34px;
  width: 100%;
  background-color: #0760a7;
  margin-top: 8px; }

.cxpace .acebutton[disabled] {
  border-radius: 0px;
  border: 0px;
  height: 34px;
  width: 100%;
  background-color: #ccc; }

.cxpace .btn {
  border-radius: 0px;
  height: 34px;
  width: 100%; }

.cxpace .btn-group {
  border: 1px;
  height: 34px;
  width: 100%; }

.cxpace .dropdown-menu {
  width: 100%; }

.cxpace .btn-default:focus {
  background-position: 0; }

.cxpace .btn-default:hover {
  background-position: 0; }

.cxpace .colTitle {
  margin-left: 10px; }

.cxpace .vxH100 {
  overflow-x: hidden; }

.cxpace .vxHeadRow {
  background-color: blue; }

.cxpace .vx-grid-related .vxTable .vxHead th .dropdown .dropdown-toggle {
  background: 0 0;
  background-color: #e4e4e4;
  margin: 0px; }

.cxpace .colTitle {
  width: 100%; }

.cxpace .backtoskill button {
  color: #666;
  background: no-repeat;
  border: none;
  margin-left: 1px; }

.cxpace .infotext {
  font-size: 13px;
  font-style: italic; }

.cxpace .acesearchbutton {
  border-radius: 0px;
  border: 0px;
  height: 34px;
  width: 100%;
  background-color: white; }

.cxpace .aceFinderSummary {
  font-family: Segoe UI;
  font-size: 16px;
  color: #3c3c3c; }

.cxpace .aceLabelReducedMargin {
  margin-bottom: -7px; }

.cxpace .aceMultiSelect .btn {
  background: url(https://grmesxpuatcdn.azureedge.net/images/select-down.png) 97% 50% no-repeat #fff !important; }

.cxpace .aceMultiSelect .form-control:focus {
  border-color: #66afe9;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(102, 175, 233, 0.6); }

@media (max-width: 767px) {
  .cxpace .vxSample {
    height: calc(100vh - 205px);
    margin: 0 auto; } }

@media (min-width: 768px) {
  .cxpace .vxSample {
    height: calc(100vh - 205px) !important;
    margin: 0px 0px 15px 0px !important; }
    .cxpace .vxSample .pad0 {
      padding: 2px !important; }
    .cxpace .vxSample .vxH100 .vxTableScrollContainer .vxTableContainer .vxTable .vxBody tr td .icon-container-layout:focus {
      margin: 2px; } }

.sales .sr_td_legend li {
  width: auto; }

.sales .managerflash {
  padding: 5px; }

.sales .alert {
  margin-bottom: 0px; }

.sales .alert-dismissable .close, .sales .alert-dismissible .close {
  right: -10px; }
