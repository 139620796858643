


@font-face {
  font-family: 'FabricMDL2Icons';
  src: url('../../../../fonts/fabric/FabExMDL2.woff') format('woff2'), url('../../../../fonts/fabric/FabExMDL2.woff') format('woff'), url('../../../../fonts/fabric/FabExMDL2.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


.ms-Icon {
    display: inline-block;
    font-family: FabricMDL2Icons;
    font-style: normal;
    font-weight: 400;
    speak: none
}

.ms-Icon--circle {
    position: relative;
    display: inline-block;
    font-size: 1rem;
    width: 1em;
    height: 1em;
    margin: 0 .5em 0 0;
    padding: 0;
    text-align: left
}

.ms-Icon--circle::after,
.ms-Icon--circle::before {
    line-height: 1;
    font-size: inherit
}

.ms-Icon--circle::before {
    display: block;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    vertical-align: top;
    position: absolute
}

.ms-Icon--circle::after {
    content: '\e000';
    position: absolute;
    top: 0;
    left: 0;
    transform: scale(2);
    transform-origin: 50% 50%;
    z-index: 0
}

.ms-Icon--xs {
    font-size: 10px
}

.ms-Icon--s {
    font-size: 12px
}

.ms-Icon--m {
    font-size: 16px
}

.ms-Icon--l {
    font-size: 20px
}

.ms-Icon--SwayLogo::before {
    content: '\ED29'
}

.ms-Icon--ClassNotebookLogo::before {
    content: '\EDC8'
}

.ms-Icon--DocsLogo::before {
    content: '\EDCB'
}

.ms-Icon--OneNoteEduLogo::before {
    content: '\EDD0'
}

.ms-Icon--ProjectLogo::before {
    content: '\EDD4'
}

.ms-Icon--AdminALogo::before {
    content: '\ED6A'
}

.ms-Icon--AdminCLogo::before {
    content: '\ED6B'
}

.ms-Icon--AdminDLogo::before {
    content: '\ED6C'
}

.ms-Icon--AdminELogo::before {
    content: '\ED6D'
}

.ms-Icon--AdminLLogo::before {
    content: '\ED6E'
}

.ms-Icon--AdminMLogo::before {
    content: '\ED6F'
}

.ms-Icon--AdminOLogo::before {
    content: '\ED70'
}

.ms-Icon--AdminPLogo::before {
    content: '\ED71'
}

.ms-Icon--AdminSLogo::before {
    content: '\ED72'
}

.ms-Icon--AdminYLogo::before {
    content: '\ED73'
}

.ms-Icon--OfficeFormLogo::before {
    content: '\EF86'
}

.ms-Icon--AADLogo::before {
    content: '\ED68'
}

.ms-Icon--Accept::before {
    content: '\E8FB'
}

.ms-Icon--AccessLogo::before {
    content: '\ED69'
}

.ms-Icon--AccessLogoFill::before {
    content: '\F1DB'
}

.ms-Icon--Accounts::before {
    content: '\E910'
}

.ms-Icon--ActivityFeed::before {
    content: '\F056'
}

.ms-Icon--Add::before {
    content: '\E710'
}

.ms-Icon--AddEvent::before {
    content: '\EEB5'
}

.ms-Icon--AddFavorite::before {
    content: '\F0C8'
}

.ms-Icon--AddFavoriteFill::before {
    content: '\F0C9'
}

.ms-Icon--AddFriend::before {
    content: '\E8FA'
}

.ms-Icon--AddGroup::before {
    content: '\EE3D'
}

.ms-Icon--AddOnlineMeeting::before {
    content: '\ED8E'
}

.ms-Icon--AddPhone::before {
    content: '\ED96'
}

.ms-Icon--AddTo::before {
    content: '\ECC8'
}

.ms-Icon--Admin::before {
    content: '\E7EF'
}

.ms-Icon--AdminALogo32::before {
    content: '\F4BA'
}

.ms-Icon--AdminALogoFill32::before {
    content: '\F4BB'
}

.ms-Icon--AdminALogoInverse32::before {
    content: '\ED6A'
}

.ms-Icon--AdminCLogoInverse32::before {
    content: '\ED6B'
}

.ms-Icon--AdminDLogoInverse32::before {
    content: '\ED6C'
}

.ms-Icon--AdminELogoInverse32::before {
    content: '\ED6D'
}

.ms-Icon--AdminLLogoInverse32::before {
    content: '\ED6E'
}

.ms-Icon--AdminMLogoInverse32::before {
    content: '\ED6F'
}

.ms-Icon--AdminOLogoInverse32::before {
    content: '\ED70'
}

.ms-Icon--AdminPLogoInverse32::before {
    content: '\ED71'
}

.ms-Icon--AdminSLogoInverse32::before {
    content: '\ED72'
}

.ms-Icon--AdminYLogoInverse32::before {
    content: '\ED73'
}

.ms-Icon--Airplane::before {
    content: '\E709'
}

.ms-Icon--AirplaneSolid::before {
    content: '\EB4C'
}

.ms-Icon--AirTickets::before {
    content: '\EF7A'
}

.ms-Icon--AlarmClock::before {
    content: '\E919'
}

.ms-Icon--Album::before {
    content: '\E7AB'
}

.ms-Icon--AlbumRemove::before {
    content: '\EC62'
}

.ms-Icon--AlertSolid::before {
    content: '\F331'
}

.ms-Icon--AlignCenter::before {
    content: '\E8E3'
}

.ms-Icon--AlignLeft::before {
    content: '\E8E4'
}

.ms-Icon--AlignRight::before {
    content: '\E8E2'
}

.ms-Icon--AnalyticsLogo::before {
    content: '\F1DE'
}

.ms-Icon--AnalyticsQuery::before {
    content: '\F1DF'
}

.ms-Icon--AnalyticsReport::before {
    content: '\F1E1'
}

.ms-Icon--AndroidLogo::before {
    content: '\EF8B'
}

.ms-Icon--Annotation::before {
    content: '\E924'
}

.ms-Icon--AppIconDefault::before {
    content: '\ECAA'
}

.ms-Icon--Archive::before {
    content: '\F03F'
}

.ms-Icon--AreaChart::before {
    content: '\E9D2'
}

.ms-Icon--Arrivals::before {
    content: '\EB34'
}

.ms-Icon--ArrowDownRight8::before {
    content: '\EED5'
}

.ms-Icon--ArrowDownRightMirrored8::before {
    content: '\EEF0'
}

.ms-Icon--ArrowTallDownLeft::before {
    content: '\F2BF'
}

.ms-Icon--ArrowTallDownRight::before {
    content: '\F2C0'
}

.ms-Icon--ArrowTallUpLeft::before {
    content: '\F2BD'
}

.ms-Icon--ArrowTallUpRight::before {
    content: '\F2BE'
}

.ms-Icon--ArrowUpRight8::before {
    content: '\EED4'
}

.ms-Icon--ArrowUpRightMirrored8::before {
    content: '\EEEF'
}

.ms-Icon--Articles::before {
    content: '\EAC1'
}

.ms-Icon--Ascending::before {
    content: '\EDC0'
}

.ms-Icon--AspectRatio::before {
    content: '\E799'
}

.ms-Icon--AssessmentGroup::before {
    content: '\F31A'
}

.ms-Icon--AssessmentGroupTemplate::before {
    content: '\F2B1'
}

.ms-Icon--AssetLibrary::before {
    content: '\EEB6'
}

.ms-Icon--Assign::before {
    content: '\E9D3'
}

.ms-Icon--Asterisk::before {
    content: '\EA38'
}

.ms-Icon--AsteriskSolid::before {
    content: '\F34D'
}

.ms-Icon--ATPLogo::before {
    content: '\EF85'
}

.ms-Icon--Attach::before {
    content: '\E723'
}

.ms-Icon--AustralianRules::before {
    content: '\EE70'
}

.ms-Icon--AutoEnhanceOff::before {
    content: '\E78E'
}

.ms-Icon--AutoEnhanceOn::before {
    content: '\E78D'
}

.ms-Icon--AutoFillTemplate::before {
    content: '\F313'
}

.ms-Icon--AutoRacing::before {
    content: '\EB24'
}

.ms-Icon--AwayStatus::before {
    content: '\EE6A'
}

.ms-Icon--AzureAPIManagement::before {
    content: '\F37F'
}

.ms-Icon--AzureKeyVault::before {
    content: '\F3B4'
}

.ms-Icon--AzureLogo::before {
    content: '\EB6A'
}

.ms-Icon--AzureServiceEndpoint::before {
    content: '\F380'
}

.ms-Icon--Back::before {
    content: '\E72B'
}

.ms-Icon--Backlog::before {
    content: '\F2AC'
}

.ms-Icon--BacklogBoard::before {
    content: '\F444'
}

.ms-Icon--BackToWindow::before {
    content: '\E73F'
}

.ms-Icon--Badge::before {
    content: '\EC1B'
}

.ms-Icon--Balloons::before {
    content: '\ED7E'
}

.ms-Icon--BankSolid::before {
    content: '\F34F'
}

.ms-Icon--BarChart4::before {
    content: '\EAE7'
}

.ms-Icon--BarChartHorizontal::before {
    content: '\E9EB'
}

.ms-Icon--BarChartVertical::before {
    content: '\E9EC'
}

.ms-Icon--Baseball::before {
    content: '\EB20'
}

.ms-Icon--BeerMug::before {
    content: '\F49E'
}

.ms-Icon--BidiLtr::before {
    content: '\E9AA'
}

.ms-Icon--BidiRtl::before {
    content: '\E9AB'
}

.ms-Icon--BingLogo::before {
    content: '\EB6B'
}

.ms-Icon--BlockContact::before {
    content: '\E8F8'
}

.ms-Icon--Blocked::before {
    content: '\E733'
}

.ms-Icon--Blocked2::before {
    content: '\ECE4'
}

.ms-Icon--BlowingSnow::before {
    content: '\E9C9'
}

.ms-Icon--Blur::before {
    content: '\F28E'
}

.ms-Icon--Boards::before {
    content: '\EF68'
}

.ms-Icon--Bold::before {
    content: '\E8DD'
}

.ms-Icon--BookingsLogo::before {
    content: '\EDC7'
}

.ms-Icon--Bookmarks::before {
    content: '\E8A4'
}

.ms-Icon--BookmarksMirrored::before {
    content: '\EA41'
}

.ms-Icon--BoxAdditionSolid::before {
    content: '\F2D4'
}

.ms-Icon--BoxCheckmarkSolid::before {
    content: '\F2D7'
}

.ms-Icon--BoxLogo::before {
    content: '\ED75'
}

.ms-Icon--BoxMultiplySolid::before {
    content: '\F2D5'
}

.ms-Icon--BoxPlaySolid::before {
    content: '\F2D6'
}

.ms-Icon--BoxSubtractSolid::before {
    content: '\F2D3'
}

.ms-Icon--BranchCommit::before {
    content: '\F293'
}

.ms-Icon--BranchCompare::before {
    content: '\F294'
}

.ms-Icon--BranchFork::before {
    content: '\F173'
}

.ms-Icon--BranchFork2::before {
    content: '\F291'
}

.ms-Icon--BranchLocked::before {
    content: '\F292'
}

.ms-Icon--BranchMerge::before {
    content: '\F295'
}

.ms-Icon--BranchPullRequest::before {
    content: '\F296'
}

.ms-Icon--BranchSearch::before {
    content: '\F297'
}

.ms-Icon--BranchShelveset::before {
    content: '\F298'
}

.ms-Icon--Breadcrumb::before {
    content: '\EF8C'
}

.ms-Icon--Breakfast::before {
    content: '\F49C'
}

.ms-Icon--Brightness::before {
    content: '\E706'
}

.ms-Icon--Broom::before {
    content: '\EA99'
}

.ms-Icon--Brunch::before {
    content: '\F49D'
}

.ms-Icon--BucketColor::before {
    content: '\F1B6'
}

.ms-Icon--BucketColorFill::before {
    content: '\F1B7'
}

.ms-Icon--BufferTimeAfter::before {
    content: '\F0D0'
}

.ms-Icon--BufferTimeBefore::before {
    content: '\F0CF'
}

.ms-Icon--BufferTimeBoth::before {
    content: '\F0D1'
}

.ms-Icon--BugSolid::before {
    content: '\F335'
}

.ms-Icon--Build::before {
    content: '\F28F'
}

.ms-Icon--BuildIssue::before {
    content: '\F319'
}

.ms-Icon--BuildQueue::before {
    content: '\F24F'
}

.ms-Icon--BuildQueueNew::before {
    content: '\F250'
}

.ms-Icon--BulletedList::before {
    content: '\E8FD'
}

.ms-Icon--BulletedList2::before {
    content: '\F2C7'
}

.ms-Icon--BulletedList2Mirrored::before {
    content: '\F2C8'
}

.ms-Icon--BulletedListMirrored::before {
    content: '\EA42'
}

.ms-Icon--Bus::before {
    content: '\E806'
}

.ms-Icon--BusinessCenterLogo::before {
    content: '\F4B2'
}

.ms-Icon--BusinessHoursSign::before {
    content: '\F310'
}

.ms-Icon--BusSolid::before {
    content: '\EB47'
}

.ms-Icon--Cafe::before {
    content: '\EC32'
}

.ms-Icon--Cake::before {
    content: '\ECA4'
}

.ms-Icon--CalculatorAddition::before {
    content: '\E948'
}

.ms-Icon--CalculatorEqualTo::before {
    content: '\E94E'
}

.ms-Icon--CalculatorMultiply::before {
    content: '\E947'
}

.ms-Icon--CalculatorNotEqualTo::before {
    content: '\F2D2'
}

.ms-Icon--CalculatorSubtract::before {
    content: '\E949'
}

.ms-Icon--Calendar::before {
    content: '\E787'
}

.ms-Icon--CalendarAgenda::before {
    content: '\EE9A'
}

.ms-Icon--CalendarDay::before {
    content: '\E8BF'
}

.ms-Icon--CalendarMirrored::before {
    content: '\ED28'
}

.ms-Icon--CalendarReply::before {
    content: '\E8F5'
}

.ms-Icon--CalendarWeek::before {
    content: '\E8C0'
}

.ms-Icon--CalendarWorkWeek::before {
    content: '\EF51'
}

.ms-Icon--CaloriesAdd::before {
    content: '\F172'
}

.ms-Icon--Camera::before {
    content: '\E722'
}

.ms-Icon--Cancel::before {
    content: '\E711'
}

.ms-Icon--CannedChat::before {
    content: '\F0F2'
}

.ms-Icon--Car::before {
    content: '\E804'
}

.ms-Icon--CaretBottomLeftCenter8::before {
    content: '\F365'
}

.ms-Icon--CaretBottomLeftSolid8::before {
    content: '\F121'
}

.ms-Icon--CaretBottomRightCenter8::before {
    content: '\F364'
}

.ms-Icon--CaretBottomRightSolid8::before {
    content: '\F122'
}

.ms-Icon--CaretDown8::before {
    content: '\EDD8'
}

.ms-Icon--CaretDownSolid8::before {
    content: '\EDDC'
}

.ms-Icon--CaretHollow::before {
    content: '\E817'
}

.ms-Icon--CaretHollowMirrored::before {
    content: '\EA45'
}

.ms-Icon--CaretLeft8::before {
    content: '\EDD5'
}

.ms-Icon--CaretLeftSolid8::before {
    content: '\EDD9'
}

.ms-Icon--CaretRight::before {
    content: '\F06B'
}

.ms-Icon--CaretRight8::before {
    content: '\EDD6'
}

.ms-Icon--CaretRightSolid8::before {
    content: '\EDDA'
}

.ms-Icon--CaretSolid::before {
    content: '\E818'
}

.ms-Icon--CaretSolid16::before {
    content: '\EE62'
}

.ms-Icon--CaretSolidDown::before {
    content: '\F08E'
}

.ms-Icon--CaretSolidLeft::before {
    content: '\F08D'
}

.ms-Icon--CaretSolidMirrored::before {
    content: '\EA46'
}

.ms-Icon--CaretSolidRight::before {
    content: '\F08F'
}

.ms-Icon--CaretSolidUp::before {
    content: '\F090'
}

.ms-Icon--CaretTopLeftCenter8::before {
    content: '\F367'
}

.ms-Icon--CaretTopLeftSolid8::before {
    content: '\EF54'
}

.ms-Icon--CaretTopRightCenter8::before {
    content: '\F366'
}

.ms-Icon--CaretTopRightSolid8::before {
    content: '\EF55'
}

.ms-Icon--CaretUp8::before {
    content: '\EDD7'
}

.ms-Icon--CaretUpSolid8::before {
    content: '\EDDB'
}

.ms-Icon--Cat::before {
    content: '\ED7F'
}

.ms-Icon--CellPhone::before {
    content: '\E8EA'
}

.ms-Icon--Certificate::before {
    content: '\EB95'
}

.ms-Icon--Chart::before {
    content: '\E999'
}

.ms-Icon--Chat::before {
    content: '\E901'
}

.ms-Icon--ChatInviteFriend::before {
    content: '\ECFE'
}

.ms-Icon--ChatSolid::before {
    content: '\F344'
}

.ms-Icon--Checkbox::before {
    content: '\E739'
}

.ms-Icon--CheckboxComposite::before {
    content: '\E73A'
}

.ms-Icon--CheckboxCompositeReversed::before {
    content: '\E73D'
}

.ms-Icon--CheckboxIndeterminate::before {
    content: '\E73C'
}

.ms-Icon--CheckList::before {
    content: '\E9D5'
}

.ms-Icon--CheckMark::before {
    content: '\E73E'
}

.ms-Icon--ChevronDown::before {
    content: '\E70D'
}

.ms-Icon--ChevronDownEnd6::before {
    content: '\F36F'
}

.ms-Icon--ChevronDownMed::before {
    content: '\E972'
}

.ms-Icon--ChevronDownSmall::before {
    content: '\E96E'
}

.ms-Icon--ChevronFold10::before {
    content: '\F36A'
}

.ms-Icon--ChevronLeft::before {
    content: '\E76B'
}

.ms-Icon--ChevronLeftEnd6::before {
    content: '\F371'
}

.ms-Icon--ChevronLeftMed::before {
    content: '\E973'
}

.ms-Icon--ChevronLeftSmall::before {
    content: '\E96F'
}

.ms-Icon--ChevronRight::before {
    content: '\E76C'
}

.ms-Icon--ChevronRightEnd6::before {
    content: '\F372'
}

.ms-Icon--ChevronRightMed::before {
    content: '\E974'
}

.ms-Icon--ChevronRightSmall::before {
    content: '\E970'
}

.ms-Icon--ChevronUnfold10::before {
    content: '\F369'
}

.ms-Icon--ChevronUp::before {
    content: '\E70E'
}

.ms-Icon--ChevronUpEnd6::before {
    content: '\F370'
}

.ms-Icon--ChevronUpMed::before {
    content: '\E971'
}

.ms-Icon--ChevronUpSmall::before {
    content: '\E96D'
}

.ms-Icon--Chopsticks::before {
    content: '\F4A2'
}

.ms-Icon--ChromeBack::before {
    content: '\E830'
}

.ms-Icon--ChromeBackMirrored::before {
    content: '\EA47'
}

.ms-Icon--ChromeClose::before {
    content: '\E8BB'
}

.ms-Icon--ChromeMinimize::before {
    content: '\E921'
}

.ms-Icon--CircleAddition::before {
    content: '\F2E3'
}

.ms-Icon--CircleAdditionSolid::before {
    content: '\F2E4'
}

.ms-Icon--CircleFill::before {
    content: '\EA3B'
}

.ms-Icon--CircleHalfFull::before {
    content: '\ED9E'
}

.ms-Icon--CirclePause::before {
    content: '\F2D9'
}

.ms-Icon--CirclePauseSolid::before {
    content: '\F2D8'
}

.ms-Icon--CirclePlus::before {
    content: '\EAEE'
}

.ms-Icon--CircleRing::before {
    content: '\EA3A'
}

.ms-Icon--CircleStop::before {
    content: '\F2DC'
}

.ms-Icon--CircleStopSolid::before {
    content: '\F2DB'
}

.ms-Icon--CityNext::before {
    content: '\EC06'
}

.ms-Icon--ClassNotebookLogo16::before {
    content: '\F488'
}

.ms-Icon--ClassNotebookLogo32::before {
    content: '\F486'
}

.ms-Icon--ClassNotebookLogoFill16::before {
    content: '\F489'
}

.ms-Icon--ClassNotebookLogoFill32::before {
    content: '\F487'
}

.ms-Icon--ClassNotebookLogoInverse::before {
    content: '\EDC8'
}

.ms-Icon--ClassNotebookLogoInverse16::before {
    content: '\F48B'
}

.ms-Icon--ClassNotebookLogoInverse32::before {
    content: '\F48A'
}

.ms-Icon--ClassroomLogo::before {
    content: '\EF75'
}

.ms-Icon--Clear::before {
    content: '\E894'
}

.ms-Icon--ClearFilter::before {
    content: '\EF8F'
}

.ms-Icon--ClearFormatting::before {
    content: '\EDDD'
}

.ms-Icon--ClearNight::before {
    content: '\E9C2'
}

.ms-Icon--Clock::before {
    content: '\E917'
}

.ms-Icon--CloneToDesktop::before {
    content: '\F28C'
}

.ms-Icon--ClosedCaption::before {
    content: '\EF84'
}

.ms-Icon--ClosePane::before {
    content: '\E89F'
}

.ms-Icon--ClosePaneMirrored::before {
    content: '\EA49'
}

.ms-Icon--Cloud::before {
    content: '\E753'
}

.ms-Icon--CloudAdd::before {
    content: '\ECA9'
}

.ms-Icon--CloudDownload::before {
    content: '\EBD3'
}

.ms-Icon--CloudUpload::before {
    content: '\EC8E'
}

.ms-Icon--CloudWeather::before {
    content: '\E9BE'
}

.ms-Icon--Cloudy::before {
    content: '\E9BF'
}

.ms-Icon--Cocktails::before {
    content: '\EA9D'
}

.ms-Icon--Code::before {
    content: '\E943'
}

.ms-Icon--Coffee::before {
    content: '\EAEF'
}

.ms-Icon--CoffeeScript::before {
    content: '\F2FA'
}

.ms-Icon--CollapseContent::before {
    content: '\F165'
}

.ms-Icon--CollapseContentSingle::before {
    content: '\F166'
}

.ms-Icon--CollapseMenu::before {
    content: '\EF66'
}

.ms-Icon--CollegeFootball::before {
    content: '\EB26'
}

.ms-Icon--CollegeHoops::before {
    content: '\EB25'
}

.ms-Icon--Color::before {
    content: '\E790'
}

.ms-Icon--ColorSolid::before {
    content: '\F354'
}

.ms-Icon--ColumnLeftTwoThirds::before {
    content: '\F1D6'
}

.ms-Icon--ColumnLeftTwoThirdsEdit::before {
    content: '\F324'
}

.ms-Icon--ColumnOptions::before {
    content: '\F317'
}

.ms-Icon--ColumnRightTwoThirds::before {
    content: '\F1D7'
}

.ms-Icon--ColumnRightTwoThirdsEdit::before {
    content: '\F325'
}

.ms-Icon--Combine::before {
    content: '\EDBB'
}

.ms-Icon--CommandPrompt::before {
    content: '\E756'
}

.ms-Icon--Comment::before {
    content: '\E90A'
}

.ms-Icon--CommentAdd::before {
    content: '\F2B3'
}

.ms-Icon--CommentNext::before {
    content: '\F2B4'
}

.ms-Icon--CommentPrevious::before {
    content: '\F2B5'
}

.ms-Icon--CommentUrgent::before {
    content: '\F307'
}

.ms-Icon--Communications::before {
    content: '\E95A'
}

.ms-Icon--CompassNW::before {
    content: '\E942'
}

.ms-Icon--Completed::before {
    content: '\E930'
}

.ms-Icon--CompletedSolid::before {
    content: '\EC61'
}

.ms-Icon--ConfigurationSolid::before {
    content: '\F334'
}

.ms-Icon--ConstructionCone::before {
    content: '\E98F'
}

.ms-Icon--ConstructionConeSolid::before {
    content: '\F339'
}

.ms-Icon--Contact::before {
    content: '\E77B'
}

.ms-Icon--ContactCard::before {
    content: '\EEBD'
}

.ms-Icon--ContactInfo::before {
    content: '\E779'
}

.ms-Icon--Contrast::before {
    content: '\E7A1'
}

.ms-Icon--Copy::before {
    content: '\E8C8'
}

.ms-Icon--Cotton::before {
    content: '\EAF3'
}

.ms-Icon--CPlusPlus::before {
    content: '\F2F4'
}

.ms-Icon--CPlusPlusLanguage::before {
    content: '\F2F3'
}

.ms-Icon--Cricket::before {
    content: '\EB1E'
}

.ms-Icon--CRMReport::before {
    content: '\EFFE'
}

.ms-Icon--Crown::before {
    content: '\ED01'
}

.ms-Icon--CrownSolid::before {
    content: '\F336'
}

.ms-Icon--CSharp::before {
    content: '\F2F0'
}

.ms-Icon--CSharpLanguage::before {
    content: '\F2EF'
}

.ms-Icon--CSS::before {
    content: '\EBEF'
}

.ms-Icon--CustomList::before {
    content: '\EEBE'
}

.ms-Icon--CustomListMirrored::before {
    content: '\EEBF'
}

.ms-Icon--Cut::before {
    content: '\E8C6'
}

.ms-Icon--Cycling::before {
    content: '\EAC7'
}

.ms-Icon--Database::before {
    content: '\EFC7'
}

.ms-Icon--DataConnectionLibrary::before {
    content: '\EEB7'
}

.ms-Icon--DateTime::before {
    content: '\EC92'
}

.ms-Icon--DateTime2::before {
    content: '\EA17'
}

.ms-Icon--DateTimeMirrored::before {
    content: '\EE93'
}

.ms-Icon--DecisionSolid::before {
    content: '\F350'
}

.ms-Icon--DeclineCall::before {
    content: '\F405'
}

.ms-Icon--DecreaseIndentLegacy::before {
    content: '\E290'
}

.ms-Icon--DefectSolid::before {
    content: '\F449'
}

.ms-Icon--Delete::before {
    content: '\E74D'
}

.ms-Icon--DeliveryTruck::before {
    content: '\EBF4'
}

.ms-Icon--DelveAnalytics::before {
    content: '\EEEE'
}

.ms-Icon--DelveAnalyticsLogo::before {
    content: '\EDCA'
}

.ms-Icon--DelveLogo::before {
    content: '\F280'
}

.ms-Icon--DelveLogoFill::before {
    content: '\F281'
}

.ms-Icon--DelveLogoInverse::before {
    content: '\ED76'
}

.ms-Icon--Deploy::before {
    content: '\F29D'
}

.ms-Icon--Descending::before {
    content: '\EDC1'
}

.ms-Icon--Design::before {
    content: '\EB3C'
}

.ms-Icon--DeveloperTools::before {
    content: '\EC7A'
}

.ms-Icon--Devices3::before {
    content: '\EA6C'
}

.ms-Icon--Devices4::before {
    content: '\EB66'
}

.ms-Icon--Diagnostic::before {
    content: '\E9D9'
}

.ms-Icon--Dialpad::before {
    content: '\E75F'
}

.ms-Icon--DiamondSolid::before {
    content: '\F34C'
}

.ms-Icon--Dictionary::before {
    content: '\E82D'
}

.ms-Icon--DietPlanNotebook::before {
    content: '\EAC8'
}

.ms-Icon--DiffInline::before {
    content: '\F309'
}

.ms-Icon--DiffSideBySide::before {
    content: '\F30A'
}

.ms-Icon--DisableUpdates::before {
    content: '\E8D8'
}

.ms-Icon--Dislike::before {
    content: '\E8E0'
}

.ms-Icon--DislikeSolid::before {
    content: '\F3C0'
}

.ms-Icon--DockLeft::before {
    content: '\E90C'
}

.ms-Icon--DockLeftMirrored::before {
    content: '\EA4C'
}

.ms-Icon--DockRight::before {
    content: '\E90D'
}

.ms-Icon--DocLibrary::before {
    content: '\EEB8'
}

.ms-Icon--DocsLogoInverse::before {
    content: '\EDCB'
}

.ms-Icon--Document::before {
    content: '\E8A5'
}

.ms-Icon--DocumentApproval::before {
    content: '\F28B'
}

.ms-Icon--Documentation::before {
    content: '\EC17'
}

.ms-Icon--DocumentManagement::before {
    content: '\EFFC'
}

.ms-Icon--DocumentReply::before {
    content: '\EF57'
}

.ms-Icon--DocumentSearch::before {
    content: '\EF6C'
}

.ms-Icon--DocumentSet::before {
    content: '\EED6'
}

.ms-Icon--DonutChart::before {
    content: '\F368'
}

.ms-Icon--Door::before {
    content: '\EB75'
}

.ms-Icon--DoubleBookmark::before {
    content: '\EB8F'
}

.ms-Icon--DoubleChevronDown::before {
    content: '\EE04'
}

.ms-Icon--DoubleChevronDown12::before {
    content: '\EE97'
}

.ms-Icon--DoubleChevronDown8::before {
    content: '\F36B'
}

.ms-Icon--DoubleChevronLeft::before {
    content: '\EDBE'
}

.ms-Icon--DoubleChevronLeft12::before {
    content: '\EE98'
}

.ms-Icon--DoubleChevronLeft8::before {
    content: '\F36D'
}

.ms-Icon--DoubleChevronLeftMed::before {
    content: '\E991'
}

.ms-Icon--DoubleChevronLeftMedMirrored::before {
    content: '\EA4D'
}

.ms-Icon--DoubleChevronRight::before {
    content: '\EDBF'
}

.ms-Icon--DoubleChevronRight12::before {
    content: '\EE99'
}

.ms-Icon--DoubleChevronRight8::before {
    content: '\F36E'
}

.ms-Icon--DoubleChevronUp::before {
    content: '\EDBD'
}

.ms-Icon--DoubleChevronUp12::before {
    content: '\EE96'
}

.ms-Icon--DoubleChevronUp8::before {
    content: '\F36C'
}

.ms-Icon--DoubleColumn::before {
    content: '\F1D4'
}

.ms-Icon--DoubleColumnEdit::before {
    content: '\F322'
}

.ms-Icon--Down::before {
    content: '\E74B'
}

.ms-Icon--Download::before {
    content: '\E896'
}

.ms-Icon--DRM::before {
    content: '\ECA8'
}

.ms-Icon--Drop::before {
    content: '\EB42'
}

.ms-Icon--DropboxLogo::before {
    content: '\ED77'
}

.ms-Icon--Dropdown::before {
    content: '\EDC5'
}

.ms-Icon--Duststorm::before {
    content: '\E9CD'
}

.ms-Icon--Dynamics365Logo::before {
    content: '\EDCC'
}

.ms-Icon--DynamicSMBLogo::before {
    content: '\EDCD'
}

.ms-Icon--EatDrink::before {
    content: '\E807'
}

.ms-Icon--EdgeLogo::before {
    content: '\EC60'
}

.ms-Icon--Edit::before {
    content: '\E70F'
}

.ms-Icon--EditMail::before {
    content: '\EF61'
}

.ms-Icon--EditMirrored::before {
    content: '\EB7E'
}

.ms-Icon--EditNote::before {
    content: '\ED9D'
}

.ms-Icon--EditPhoto::before {
    content: '\EF77'
}

.ms-Icon--EditSolid12::before {
    content: '\F4B5'
}

.ms-Icon--EditSolidMirrored12::before {
    content: '\F4B6'
}

.ms-Icon--EditStyle::before {
    content: '\EF60'
}

.ms-Icon--Education::before {
    content: '\E7BE'
}

.ms-Icon--EgnyteLogo::before {
    content: '\F373'
}

.ms-Icon--Embed::before {
    content: '\ECCE'
}

.ms-Icon--EMI::before {
    content: '\E731'
}

.ms-Icon--Emoji::before {
    content: '\E899'
}

.ms-Icon--Emoji2::before {
    content: '\E76E'
}

.ms-Icon--EmojiDisappointed::before {
    content: '\EA88'
}

.ms-Icon--EmojiNeutral::before {
    content: '\EA87'
}

.ms-Icon--EmptyRecycleBin::before {
    content: '\EF88'
}

.ms-Icon--EngineeringGroup::before {
    content: '\F362'
}

.ms-Icon--Equalizer::before {
    content: '\E9E9'
}

.ms-Icon--EraseTool::before {
    content: '\E75C'
}

.ms-Icon--Error::before {
    content: '\E783'
}

.ms-Icon--ErrorBadge::before {
    content: '\EA39'
}

.ms-Icon--Event::before {
    content: '\ECA3'
}

.ms-Icon--EventAccepted::before {
    content: '\F422'
}

.ms-Icon--EventDate::before {
    content: '\F059'
}

.ms-Icon--EventDeclined::before {
    content: '\F425'
}

.ms-Icon--EventInfo::before {
    content: '\ED8B'
}

.ms-Icon--EventTentative::before {
    content: '\F423'
}

.ms-Icon--EventTentativeMirrored::before {
    content: '\F424'
}

.ms-Icon--ExcelDocument::before {
    content: '\EF73'
}

.ms-Icon--ExcelLogo::before {
    content: '\F1E5'
}

.ms-Icon--ExcelLogo16::before {
    content: '\F397'
}

.ms-Icon--ExcelLogoFill::before {
    content: '\F1E6'
}

.ms-Icon--ExcelLogoFill16::before {
    content: '\F398'
}

.ms-Icon--ExcelLogoInverse::before {
    content: '\EC28'
}

.ms-Icon--ExcelLogoInverse16::before {
    content: '\F396'
}

.ms-Icon--ExchangeLogo::before {
    content: '\F284'
}

.ms-Icon--ExchangeLogoFill::before {
    content: '\F285'
}

.ms-Icon--ExchangeLogoInverse::before {
    content: '\ED78'
}

.ms-Icon--ExerciseTracker::before {
    content: '\EACC'
}

.ms-Icon--ExpandMenu::before {
    content: '\EF67'
}

.ms-Icon--ExploreContent::before {
    content: '\ECCD'
}

.ms-Icon--ExploreContentSingle::before {
    content: '\F164'
}

.ms-Icon--Export::before {
    content: '\EDE1'
}

.ms-Icon--ExportMirrored::before {
    content: '\EDE2'
}

.ms-Icon--ExternalBuild::before {
    content: '\F445'
}

.ms-Icon--ExternalTFVC::before {
    content: '\F446'
}

.ms-Icon--ExternalXAML::before {
    content: '\F447'
}

.ms-Icon--FabricAssetLibrary::before {
    content: '\F09C'
}

.ms-Icon--FabricDataConnectionLibrary::before {
    content: '\F09D'
}

.ms-Icon--FabricDocLibrary::before {
    content: '\F09E'
}

.ms-Icon--FabricFolder::before {
    content: '\F0A9'
}

.ms-Icon--FabricFolderFill::before {
    content: '\F0AA'
}

.ms-Icon--FabricFolderSearch::before {
    content: '\F0A4'
}

.ms-Icon--FabricFormLibrary::before {
    content: '\F09F'
}

.ms-Icon--FabricFormLibraryMirrored::before {
    content: '\F0A0'
}

.ms-Icon--FabricMovetoFolder::before {
    content: '\F0A5'
}

.ms-Icon--FabricNewFolder::before {
    content: '\F0AB'
}

.ms-Icon--FabricOpenFolderHorizontal::before {
    content: '\F0A8'
}

.ms-Icon--FabricPictureLibrary::before {
    content: '\F0AC'
}

.ms-Icon--FabricPublicFolder::before {
    content: '\F0A3'
}

.ms-Icon--FabricReportLibrary::before {
    content: '\F0A1'
}

.ms-Icon--FabricReportLibraryMirrored::before {
    content: '\F0A2'
}

.ms-Icon--FabricSyncFolder::before {
    content: '\F0A7'
}

.ms-Icon--FabricUnsyncFolder::before {
    content: '\F0A6'
}

.ms-Icon--FacebookLogo::before {
    content: '\ECB3'
}

.ms-Icon--Family::before {
    content: '\EBDA'
}

.ms-Icon--FangBody::before {
    content: '\ECEB'
}

.ms-Icon--FastForward::before {
    content: '\EB9D'
}

.ms-Icon--Favicon::before {
    content: '\E737'
}

.ms-Icon--FavoriteList::before {
    content: '\E728'
}

.ms-Icon--FavoriteStar::before {
    content: '\E734'
}

.ms-Icon--FavoriteStarFill::before {
    content: '\E735'
}

.ms-Icon--Fax::before {
    content: '\EF5C'
}

.ms-Icon--Feedback::before {
    content: '\ED15'
}

.ms-Icon--FeedbackRequestMirroredSolid::before {
    content: '\F35A'
}

.ms-Icon--FeedbackRequestSolid::before {
    content: '\F359'
}

.ms-Icon--FeedbackResponseSolid::before {
    content: '\F35B'
}

.ms-Icon--Ferry::before {
    content: '\E7E3'
}

.ms-Icon--FerrySolid::before {
    content: '\EB48'
}

.ms-Icon--FieldChanged::before {
    content: '\F2C3'
}

.ms-Icon--FieldEmpty::before {
    content: '\F2C1'
}

.ms-Icon--FieldFilled::before {
    content: '\F2C2'
}

.ms-Icon--FieldNotChanged::before {
    content: '\F2C4'
}

.ms-Icon--FieldReadOnly::before {
    content: '\F442'
}

.ms-Icon--FieldRequired::before {
    content: '\F443'
}

.ms-Icon--FileASPX::before {
    content: '\F2E9'
}

.ms-Icon--FileBug::before {
    content: '\F30D'
}

.ms-Icon--FileCode::before {
    content: '\F30E'
}

.ms-Icon--FileComment::before {
    content: '\F30F'
}

.ms-Icon--FileCSS::before {
    content: '\F2EA'
}

.ms-Icon--FileHTML::before {
    content: '\F2ED'
}

.ms-Icon--FileImage::before {
    content: '\F311'
}

.ms-Icon--FileJAVA::before {
    content: '\F2E8'
}

.ms-Icon--FileLess::before {
    content: '\F2EC'
}

.ms-Icon--FilePDB::before {
    content: '\F2E5'
}

.ms-Icon--FileSass::before {
    content: '\F2EB'
}

.ms-Icon--FileSQL::before {
    content: '\F2E7'
}

.ms-Icon--FileSymlink::before {
    content: '\F312'
}

.ms-Icon--FileTemplate::before {
    content: '\F2E6'
}

.ms-Icon--FileTypeSolution::before {
    content: '\F387'
}

.ms-Icon--Filter::before {
    content: '\E71C'
}

.ms-Icon--Filters::before {
    content: '\E795'
}

.ms-Icon--FilterSolid::before {
    content: '\F412'
}

.ms-Icon--FiltersSolid::before {
    content: '\F353'
}

.ms-Icon--Financial::before {
    content: '\E7BB'
}

.ms-Icon--FinancialMirroredSolid::before {
    content: '\F347'
}

.ms-Icon--FinancialSolid::before {
    content: '\F346'
}

.ms-Icon--Fingerprint::before {
    content: '\E928'
}

.ms-Icon--FiveTileGrid::before {
    content: '\F274'
}

.ms-Icon--Flag::before {
    content: '\E7C1'
}

.ms-Icon--FlameSolid::before {
    content: '\F1F3'
}

.ms-Icon--FlickDown::before {
    content: '\E935'
}

.ms-Icon--FlickLeft::before {
    content: '\E937'
}

.ms-Icon--FlickRight::before {
    content: '\E938'
}

.ms-Icon--FlickUp::before {
    content: '\E936'
}

.ms-Icon--Flow::before {
    content: '\EF90'
}

.ms-Icon--FocalPoint::before {
    content: '\F277'
}

.ms-Icon--Fog::before {
    content: '\E9CB'
}

.ms-Icon--Folder::before {
    content: '\E8B7'
}

.ms-Icon--FolderFill::before {
    content: '\E8D5'
}

.ms-Icon--FolderHorizontal::before {
    content: '\F12B'
}

.ms-Icon--FolderList::before {
    content: '\F2CE'
}

.ms-Icon--FolderListMirrored::before {
    content: '\F2CF'
}

.ms-Icon--FolderOpen::before {
    content: '\E838'
}

.ms-Icon--FolderQuery::before {
    content: '\F2CD'
}

.ms-Icon--FolderSearch::before {
    content: '\EF65'
}

.ms-Icon--Font::before {
    content: '\E8D2'
}

.ms-Icon--FontColor::before {
    content: '\E8D3'
}

.ms-Icon--FontDecrease::before {
    content: '\E8E7'
}

.ms-Icon--FontIncrease::before {
    content: '\E8E8'
}

.ms-Icon--FontSize::before {
    content: '\E8E9'
}

.ms-Icon--FormLibrary::before {
    content: '\EEB9'
}

.ms-Icon--FormLibraryMirrored::before {
    content: '\EEBA'
}

.ms-Icon--Forward::before {
    content: '\E72A'
}

.ms-Icon--ForwardEvent::before {
    content: '\ED8C'
}

.ms-Icon--Freezing::before {
    content: '\E9EF'
}

.ms-Icon--Frigid::before {
    content: '\E9CA'
}

.ms-Icon--FSharp::before {
    content: '\F2F6'
}

.ms-Icon--FSharpLanguage::before {
    content: '\F2F5'
}

.ms-Icon--FullCircleMask::before {
    content: '\E91F'
}

.ms-Icon--FullHistory::before {
    content: '\F31C'
}

.ms-Icon--FullScreen::before {
    content: '\E740'
}

.ms-Icon--FullWidth::before {
    content: '\F2FE'
}

.ms-Icon--FullWidthEdit::before {
    content: '\F2FF'
}

.ms-Icon--GallatinLogo::before {
    content: '\F496'
}

.ms-Icon--Generate::before {
    content: '\E9DA'
}

.ms-Icon--Giftbox::before {
    content: '\EC1F'
}

.ms-Icon--GiftboxOpen::before {
    content: '\F133'
}

.ms-Icon--GiftBoxSolid::before {
    content: '\F341'
}

.ms-Icon--GiftCard::before {
    content: '\EB8E'
}

.ms-Icon--GitGraph::before {
    content: '\F2CA'
}

.ms-Icon--Glasses::before {
    content: '\EA16'
}

.ms-Icon--Glimmer::before {
    content: '\ECF4'
}

.ms-Icon--GlobalNavButton::before {
    content: '\E700'
}

.ms-Icon--Globe::before {
    content: '\E774'
}

.ms-Icon--Globe2::before {
    content: '\F49A'
}

.ms-Icon--GlobeFavorite::before {
    content: '\EF53'
}

.ms-Icon--Golf::before {
    content: '\EB1F'
}

.ms-Icon--GoogleDriveLogo::before {
    content: '\EE0B'
}

.ms-Icon--GoogleDriveLogoBottomBlue::before {
    content: '\F375'
}

.ms-Icon--GoogleDriveLogoLeftGreen::before {
    content: '\F374'
}

.ms-Icon--GoogleDriveLogoRightYellow::before {
    content: '\F376'
}

.ms-Icon--GotoToday::before {
    content: '\E8D1'
}

.ms-Icon--GridViewLarge::before {
    content: '\F234'
}

.ms-Icon--GridViewMedium::before {
    content: '\F233'
}

.ms-Icon--GridViewSmall::before {
    content: '\F232'
}

.ms-Icon--GripperBarHorizontal::before {
    content: '\E76F'
}

.ms-Icon--GripperBarVertical::before {
    content: '\E784'
}

.ms-Icon--GripperTool::before {
    content: '\E75E'
}

.ms-Icon--Group::before {
    content: '\E902'
}

.ms-Icon--GroupedAscending::before {
    content: '\EE67'
}

.ms-Icon--GroupedDescending::before {
    content: '\EE66'
}

.ms-Icon--GroupedList::before {
    content: '\EF74'
}

.ms-Icon--Guitar::before {
    content: '\F49B'
}

.ms-Icon--HailDay::before {
    content: '\EA00'
}

.ms-Icon--HailNight::before {
    content: '\EA13'
}

.ms-Icon--HalfAlpha::before {
    content: '\E97E'
}

.ms-Icon--Handwriting::before {
    content: '\E929'
}

.ms-Icon--HardDriveGroup::before {
    content: '\F18F'
}

.ms-Icon--Header1::before {
    content: '\EA19'
}

.ms-Icon--Header2::before {
    content: '\EF36'
}

.ms-Icon--Header3::before {
    content: '\EF37'
}

.ms-Icon--Header4::before {
    content: '\EF38'
}

.ms-Icon--Headset::before {
    content: '\E95B'
}

.ms-Icon--HeadsetSolid::before {
    content: '\F348'
}

.ms-Icon--Health::before {
    content: '\E95E'
}

.ms-Icon--HealthSolid::before {
    content: '\F33F'
}

.ms-Icon--Heart::before {
    content: '\EB51'
}

.ms-Icon--HeartFill::before {
    content: '\EB52'
}

.ms-Icon--Help::before {
    content: '\E897'
}

.ms-Icon--HelpMirrored::before {
    content: '\EA51'
}

.ms-Icon--Hide::before {
    content: '\ED1A'
}

.ms-Icon--Hide2::before {
    content: '\EF89'
}

.ms-Icon--Highlight::before {
    content: '\E7E6'
}

.ms-Icon--HighlightMappedShapes::before {
    content: '\F2A1'
}

.ms-Icon--History::before {
    content: '\E81C'
}

.ms-Icon--Home::before {
    content: '\E80F'
}

.ms-Icon--HomeSolid::before {
    content: '\EA8A'
}

.ms-Icon--Hospital::before {
    content: '\E91D'
}

.ms-Icon--Hotel::before {
    content: '\E824'
}

.ms-Icon--IconSetsFlag::before {
    content: '\F2A4'
}

.ms-Icon--IDBadge::before {
    content: '\F427'
}

.ms-Icon--ImageCrosshair::before {
    content: '\F2C9'
}

.ms-Icon--ImageDiff::before {
    content: '\F30B'
}

.ms-Icon--ImagePixel::before {
    content: '\F30C'
}

.ms-Icon--Import::before {
    content: '\E8B5'
}

.ms-Icon--Important::before {
    content: '\E8C9'
}

.ms-Icon--ImportMirrored::before {
    content: '\EA52'
}

.ms-Icon--Inbox::before {
    content: '\F41C'
}

.ms-Icon--InboxCheck::before {
    content: '\EF64'
}

.ms-Icon--IncidentTriangle::before {
    content: '\E814'
}

.ms-Icon--IncreaseIndentLegacy::before {
    content: '\E291'
}

.ms-Icon--Info::before {
    content: '\E946'
}

.ms-Icon--Info2::before {
    content: '\EA1F'
}

.ms-Icon--InfoSolid::before {
    content: '\F167'
}

.ms-Icon--InsertTextBox::before {
    content: '\EC7D'
}

.ms-Icon--InstallToDrive::before {
    content: '\F28D'
}

.ms-Icon--InternetSharing::before {
    content: '\E704'
}

.ms-Icon--iOSAppStoreLogo::before {
    content: '\EF8A'
}

.ms-Icon--IRMForward::before {
    content: '\F41F'
}

.ms-Icon--IRMForwardMirrored::before {
    content: '\F420'
}

.ms-Icon--IRMReply::before {
    content: '\F41D'
}

.ms-Icon--IRMReplyMirrored::before {
    content: '\F41E'
}

.ms-Icon--IssueSolid::before {
    content: '\F448'
}

.ms-Icon--IssueTracking::before {
    content: '\EEC0'
}

.ms-Icon--IssueTrackingMirrored::before {
    content: '\EEC1'
}

.ms-Icon--Italic::before {
    content: '\E8DB'
}

.ms-Icon--JavaScriptLanguage::before {
    content: '\F2EE'
}

.ms-Icon--JoinOnlineMeeting::before {
    content: '\ED8F'
}

.ms-Icon--JS::before {
    content: '\EBF0'
}

.ms-Icon--KaizalaLogo::before {
    content: '\F492'
}

.ms-Icon--Label::before {
    content: '\E932'
}

.ms-Icon--LadybugSolid::before {
    content: '\F44A'
}

.ms-Icon--LandscapeOrientation::before {
    content: '\EF6B'
}

.ms-Icon--LaptopSelected::before {
    content: '\EC76'
}

.ms-Icon--LargeGrid::before {
    content: '\EECB'
}

.ms-Icon--Library::before {
    content: '\E8F1'
}

.ms-Icon--Lifesaver::before {
    content: '\EF62'
}

.ms-Icon--LifesaverLock::before {
    content: '\EF63'
}

.ms-Icon--Light::before {
    content: '\E793'
}

.ms-Icon--Lightbulb::before {
    content: '\EA80'
}

.ms-Icon--LightningBolt::before {
    content: '\E945'
}

.ms-Icon--Like::before {
    content: '\E8E1'
}

.ms-Icon--LikeSolid::before {
    content: '\F3BF'
}

.ms-Icon--LineChart::before {
    content: '\E9E6'
}

.ms-Icon--Link::before {
    content: '\E71B'
}

.ms-Icon--LinkedInLogo::before {
    content: '\F20A'
}

.ms-Icon--List::before {
    content: '\EA37'
}

.ms-Icon--ListMirrored::before {
    content: '\EA55'
}

.ms-Icon--LocaleLanguage::before {
    content: '\F2B7'
}

.ms-Icon--Location::before {
    content: '\E81D'
}

.ms-Icon--LocationCircle::before {
    content: '\E80E'
}

.ms-Icon--LocationDot::before {
    content: '\E827'
}

.ms-Icon--LocationFill::before {
    content: '\E920'
}

.ms-Icon--LocationOutline::before {
    content: '\F2D0'
}

.ms-Icon--Lock::before {
    content: '\E72E'
}

.ms-Icon--LockSolid::before {
    content: '\E9A2'
}

.ms-Icon--LogRemove::before {
    content: '\F316'
}

.ms-Icon--LowerBrightness::before {
    content: '\EC8A'
}

.ms-Icon--LyncLogo::before {
    content: '\ED79'
}

.ms-Icon--Mail::before {
    content: '\E715'
}

.ms-Icon--MailAlert::before {
    content: '\ED80'
}

.ms-Icon--MailCheck::before {
    content: '\ED81'
}

.ms-Icon--MailFill::before {
    content: '\E8A8'
}

.ms-Icon--MailForward::before {
    content: '\E89C'
}

.ms-Icon--MailForwardMirrored::before {
    content: '\EA56'
}

.ms-Icon--MailLowImportance::before {
    content: '\ED82'
}

.ms-Icon--MailPause::before {
    content: '\ED83'
}

.ms-Icon--MailReminder::before {
    content: '\F418'
}

.ms-Icon--MailRepeat::before {
    content: '\ED84'
}

.ms-Icon--MailReply::before {
    content: '\E8CA'
}

.ms-Icon--MailReplyAll::before {
    content: '\E8C2'
}

.ms-Icon--MailReplyAllMirrored::before {
    content: '\EA58'
}

.ms-Icon--MailReplyMirrored::before {
    content: '\EA57'
}

.ms-Icon--MailSolid::before {
    content: '\F343'
}

.ms-Icon--MailTentative::before {
    content: '\F416'
}

.ms-Icon--MailTentativeMirrored::before {
    content: '\F417'
}

.ms-Icon--MailUndelivered::before {
    content: '\F415'
}

.ms-Icon--MapDirections::before {
    content: '\E816'
}

.ms-Icon--MapPin::before {
    content: '\E707'
}

.ms-Icon--MarkDownLanguage::before {
    content: '\F2FB'
}

.ms-Icon--Market::before {
    content: '\EAFC'
}

.ms-Icon--MarketDown::before {
    content: '\EF42'
}

.ms-Icon--Medical::before {
    content: '\EAD4'
}

.ms-Icon--Megaphone::before {
    content: '\E789'
}

.ms-Icon--MegaphoneSolid::before {
    content: '\F332'
}

.ms-Icon--Memo::before {
    content: '\E77C'
}

.ms-Icon--Merge::before {
    content: '\E7D5'
}

.ms-Icon--MergeDuplicate::before {
    content: '\F29A'
}

.ms-Icon--Message::before {
    content: '\E8BD'
}

.ms-Icon--MessageFill::before {
    content: '\EC70'
}

.ms-Icon--MicOff::before {
    content: '\EC54'
}

.ms-Icon--Microphone::before {
    content: '\E720'
}

.ms-Icon--MicrosoftFlowLogo::before {
    content: '\F4B1'
}

.ms-Icon--MicrosoftStaffhubLogo::before {
    content: '\F130'
}

.ms-Icon--MiniLink::before {
    content: '\E732'
}

.ms-Icon--MobileSelected::before {
    content: '\EC75'
}

.ms-Icon--Money::before {
    content: '\EAFD'
}

.ms-Icon--More::before {
    content: '\E712'
}

.ms-Icon--MoreSports::before {
    content: '\EB22'
}

.ms-Icon--MoreVertical::before {
    content: '\F2BC'
}

.ms-Icon--Move::before {
    content: '\E7C2'
}

.ms-Icon--MoveToFolder::before {
    content: '\E8DE'
}

.ms-Icon--MSNLogo::before {
    content: '\EB6C'
}

.ms-Icon--MSNVideos::before {
    content: '\EB1C'
}

.ms-Icon--MSNVideosSolid::before {
    content: '\F2DA'
}

.ms-Icon--MTMLogo::before {
    content: '\F44B'
}

.ms-Icon--MultiSelect::before {
    content: '\E762'
}

.ms-Icon--MultiSelectMirrored::before {
    content: '\EA98'
}

.ms-Icon--MusicInCollection::before {
    content: '\E940'
}

.ms-Icon--MusicInCollectionFill::before {
    content: '\EA36'
}

.ms-Icon--MusicNote::before {
    content: '\EC4F'
}

.ms-Icon--MyMoviesTV::before {
    content: '\EE6C'
}

.ms-Icon--Nav2DMapView::before {
    content: '\E800'
}

.ms-Icon--NavigateBack::before {
    content: '\F2DD'
}

.ms-Icon--NavigateBackMirrored::before {
    content: '\F2DE'
}

.ms-Icon--NavigateExternalInline::before {
    content: '\F35F'
}

.ms-Icon--NavigateForward::before {
    content: '\F2DF'
}

.ms-Icon--NavigateForwardMirrored::before {
    content: '\F2E0'
}

.ms-Icon--NetworkTower::before {
    content: '\EC05'
}

.ms-Icon--NewAnalyticsQuery::before {
    content: '\F1E0'
}

.ms-Icon--NewFolder::before {
    content: '\E8F4'
}

.ms-Icon--News::before {
    content: '\E900'
}

.ms-Icon--NewTeamProject::before {
    content: '\F2B2'
}

.ms-Icon--Next::before {
    content: '\E893'
}

.ms-Icon--NonprofitLogo32::before {
    content: '\F495'
}

.ms-Icon--NoteForward::before {
    content: '\ED99'
}

.ms-Icon--NotePinned::before {
    content: '\ED9A'
}

.ms-Icon--NoteReply::before {
    content: '\ED98'
}

.ms-Icon--NotExecuted::before {
    content: '\F440'
}

.ms-Icon--NotImpactedSolid::before {
    content: '\F441'
}

.ms-Icon--NugetLogo::before {
    content: '\F44C'
}

.ms-Icon--NumberedList::before {
    content: '\EA1C'
}

.ms-Icon--NumberField::before {
    content: '\EDC4'
}

.ms-Icon--OfficeAddinsLogo::before {
    content: '\EEC7'
}

.ms-Icon--OfficeAssistantLogo::before {
    content: '\EDCE'
}

.ms-Icon--OfficeFormsLogo::before {
    content: '\F434'
}

.ms-Icon--OfficeFormsLogo16::before {
    content: '\F436'
}

.ms-Icon--OfficeFormsLogo24::before {
    content: '\F43B'
}

.ms-Icon--OfficeFormsLogoFill::before {
    content: '\F435'
}

.ms-Icon--OfficeFormsLogoFill16::before {
    content: '\F437'
}

.ms-Icon--OfficeFormsLogoFill24::before {
    content: '\F43C'
}

.ms-Icon--OfficeFormsLogoInverse::before {
    content: '\EF86'
}

.ms-Icon--OfficeFormsLogoInverse16::before {
    content: '\F433'
}

.ms-Icon--OfficeFormsLogoInverse24::before {
    content: '\F43A'
}

.ms-Icon--OfficeLogo::before {
    content: '\EB6E'
}

.ms-Icon--OfficeStoreLogo::before {
    content: '\EDCF'
}

.ms-Icon--OfficeVideoLogo::before {
    content: '\F282'
}

.ms-Icon--OfficeVideoLogoFill::before {
    content: '\F283'
}

.ms-Icon--OfficeVideoLogoInverse::before {
    content: '\ED7A'
}

.ms-Icon--OfflineOneDriveParachute::before {
    content: '\EEC8'
}

.ms-Icon--OfflineOneDriveParachuteDisabled::before {
    content: '\EEC9'
}

.ms-Icon--OfflineStorageSolid::before {
    content: '\F34E'
}

.ms-Icon--OneDrive::before {
    content: '\E941'
}

.ms-Icon--OneDriveAdd::before {
    content: '\EF32'
}

.ms-Icon--OneNoteEduLogoInverse::before {
    content: '\EDD0'
}

.ms-Icon--OneNoteLogo::before {
    content: '\F1E7'
}

.ms-Icon--OneNoteLogo16::before {
    content: '\F39A'
}

.ms-Icon--OneNoteLogoFill::before {
    content: '\F1E8'
}

.ms-Icon--OneNoteLogoFill16::before {
    content: '\F39B'
}

.ms-Icon--OneNoteLogoInverse::before {
    content: '\EC0D'
}

.ms-Icon--OneNoteLogoInverse16::before {
    content: '\F399'
}

.ms-Icon--OpenFile::before {
    content: '\E8E5'
}

.ms-Icon--OpenFolderHorizontal::before {
    content: '\ED25'
}

.ms-Icon--OpenInNewWindow::before {
    content: '\E8A7'
}

.ms-Icon--OpenPane::before {
    content: '\E8A0'
}

.ms-Icon--OpenPaneMirrored::before {
    content: '\EA5B'
}

.ms-Icon--OpenSource::before {
    content: '\EBC2'
}

.ms-Icon--Org::before {
    content: '\ECA6'
}

.ms-Icon--OutlookLogo::before {
    content: '\F1E9'
}

.ms-Icon--OutlookLogo16::before {
    content: '\F39D'
}

.ms-Icon--OutlookLogoFill::before {
    content: '\F1EA'
}

.ms-Icon--OutlookLogoFill16::before {
    content: '\F39E'
}

.ms-Icon--OutlookLogoInverse::before {
    content: '\EB6D'
}

.ms-Icon--OutlookLogoInverse16::before {
    content: '\F39C'
}

.ms-Icon--OutOfOffice::before {
    content: '\ED34'
}

.ms-Icon--Package::before {
    content: '\E7B8'
}

.ms-Icon--Packages::before {
    content: '\F318'
}

.ms-Icon--Page::before {
    content: '\E7C3'
}

.ms-Icon--PageAdd::before {
    content: '\EA1A'
}

.ms-Icon--PageCheckedin::before {
    content: '\F104'
}

.ms-Icon--PageCheckedOut::before {
    content: '\F02C'
}

.ms-Icon--PageEdit::before {
    content: '\EFB6'
}

.ms-Icon--PageLeft::before {
    content: '\E760'
}

.ms-Icon--PageListMirroredSolid::before {
    content: '\F33B'
}

.ms-Icon--PageListSolid::before {
    content: '\F33A'
}

.ms-Icon--PageLock::before {
    content: '\F43F'
}

.ms-Icon--PageRight::before {
    content: '\E761'
}

.ms-Icon--PageSolid::before {
    content: '\E729'
}

.ms-Icon--PanoIndicator::before {
    content: '\E7B0'
}

.ms-Icon--Parachute::before {
    content: '\F351'
}

.ms-Icon--ParachuteSolid::before {
    content: '\F352'
}

.ms-Icon--Parameter::before {
    content: '\F306'
}

.ms-Icon--ParatureLogo::before {
    content: '\ED7B'
}

.ms-Icon--ParkingLocation::before {
    content: '\E811'
}

.ms-Icon--ParkingLocationMirrored::before {
    content: '\EA5E'
}

.ms-Icon--ParkingMirroredSolid::before {
    content: '\F34B'
}

.ms-Icon--ParkingSolid::before {
    content: '\F34A'
}

.ms-Icon--PartlyCloudyDay::before {
    content: '\E9C0'
}

.ms-Icon--PartlyCloudyNight::before {
    content: '\E9C1'
}

.ms-Icon--PartyLeader::before {
    content: '\ECA7'
}

.ms-Icon--Paste::before {
    content: '\E77F'
}

.ms-Icon--Pause::before {
    content: '\E769'
}

.ms-Icon--PaymentCard::before {
    content: '\E8C7'
}

.ms-Icon--PC1::before {
    content: '\E977'
}

.ms-Icon--PDF::before {
    content: '\EA90'
}

.ms-Icon--PencilReply::before {
    content: '\EF7B'
}

.ms-Icon--People::before {
    content: '\E716'
}

.ms-Icon--PeopleAdd::before {
    content: '\EA15'
}

.ms-Icon--PeopleAlert::before {
    content: '\ED93'
}

.ms-Icon--PeopleBlock::before {
    content: '\ED91'
}

.ms-Icon--PeoplePause::before {
    content: '\ED94'
}

.ms-Icon--PeopleRepeat::before {
    content: '\ED92'
}

.ms-Icon--Permissions::before {
    content: '\E8D7'
}

.ms-Icon--PermissionsSolid::before {
    content: '\F349'
}

.ms-Icon--Personalize::before {
    content: '\E771'
}

.ms-Icon--Phone::before {
    content: '\E717'
}

.ms-Icon--Photo2::before {
    content: '\EB9F'
}

.ms-Icon--Photo2Add::before {
    content: '\ECAB'
}

.ms-Icon--Photo2Remove::before {
    content: '\ECAC'
}

.ms-Icon--PhotoCollection::before {
    content: '\E7AA'
}

.ms-Icon--Picture::before {
    content: '\E8B9'
}

.ms-Icon--PictureLibrary::before {
    content: '\EEC2'
}

.ms-Icon--PieDouble::before {
    content: '\EB04'
}

.ms-Icon--PieSingle::before {
    content: '\EB05'
}

.ms-Icon--Pill::before {
    content: '\EACB'
}

.ms-Icon--Pin::before {
    content: '\E718'
}

.ms-Icon--Pinned::before {
    content: '\E840'
}

.ms-Icon--PinnedFill::before {
    content: '\E842'
}

.ms-Icon--PivotChart::before {
    content: '\F24C'
}

.ms-Icon--PlannerLogo::before {
    content: '\EDD1'
}

.ms-Icon--PlanView::before {
    content: '\F360'
}

.ms-Icon--Play::before {
    content: '\E768'
}

.ms-Icon--PlayerSettings::before {
    content: '\EF58'
}

.ms-Icon--PlayResume::before {
    content: '\F2C6'
}

.ms-Icon--Plug::before {
    content: '\F300'
}

.ms-Icon--PlugConnected::before {
    content: '\F302'
}

.ms-Icon--PlugDisconnected::before {
    content: '\F303'
}

.ms-Icon--PlugSolid::before {
    content: '\F301'
}

.ms-Icon--POI::before {
    content: '\ECAF'
}

.ms-Icon--POISolid::before {
    content: '\F2D1'
}

.ms-Icon--PostUpdate::before {
    content: '\E8F3'
}

.ms-Icon--PowerApps::before {
    content: '\EDD2'
}

.ms-Icon--PowerApps2Logo::before {
    content: '\F092'
}

.ms-Icon--PowerAppsLogo::before {
    content: '\F091'
}

.ms-Icon--PowerBILogo::before {
    content: '\EA1E'
}

.ms-Icon--PowerPointDocument::before {
    content: '\EF72'
}

.ms-Icon--PowerPointLogo::before {
    content: '\F1EB'
}

.ms-Icon--PowerPointLogo16::before {
    content: '\F394'
}

.ms-Icon--PowerPointLogoFill::before {
    content: '\F1EC'
}

.ms-Icon--PowerPointLogoFill16::before {
    content: '\F395'
}

.ms-Icon--PowerPointLogoInverse::before {
    content: '\EC2A'
}

.ms-Icon--PowerPointLogoInverse16::before {
    content: '\F393'
}

.ms-Icon--Precipitation::before {
    content: '\E9CF'
}

.ms-Icon--PresenceChickletVideo::before {
    content: '\E979'
}

.ms-Icon--Preview::before {
    content: '\E8FF'
}

.ms-Icon--PreviewLink::before {
    content: '\E8A1'
}

.ms-Icon--Previous::before {
    content: '\E892'
}

.ms-Icon--PrimaryCalendar::before {
    content: '\F4AE'
}

.ms-Icon--Print::before {
    content: '\E749'
}

.ms-Icon--PrintfaxPrinterFile::before {
    content: '\E956'
}

.ms-Icon--Processing::before {
    content: '\E9F5'
}

.ms-Icon--ProcessMetaTask::before {
    content: '\F290'
}

.ms-Icon--Product::before {
    content: '\ECDC'
}

.ms-Icon--ProFootball::before {
    content: '\EB27'
}

.ms-Icon--ProgressLoopInner::before {
    content: '\ECDE'
}

.ms-Icon--ProgressLoopOuter::before {
    content: '\ECDF'
}

.ms-Icon--ProgressRingDots::before {
    content: '\F16A'
}

.ms-Icon--ProHockey::before {
    content: '\EB28'
}

.ms-Icon--ProjectCollection::before {
    content: '\F363'
}

.ms-Icon--ProjectLogo16::before {
    content: '\F480'
}

.ms-Icon--ProjectLogo32::before {
    content: '\F47E'
}

.ms-Icon--ProjectLogoFill16::before {
    content: '\F481'
}

.ms-Icon--ProjectLogoFill32::before {
    content: '\F47F'
}

.ms-Icon--ProjectLogoInverse::before {
    content: '\EDD4'
}

.ms-Icon--ProtectedDocument::before {
    content: '\E8A6'
}

.ms-Icon--ProtectionCenterLogo32::before {
    content: '\F494'
}

.ms-Icon--ProtectRestrict::before {
    content: '\F22A'
}

.ms-Icon--PublicCalendar::before {
    content: '\EF6D'
}

.ms-Icon--PublicContactCard::before {
    content: '\EF6E'
}

.ms-Icon--PublicEmail::before {
    content: '\EF6F'
}

.ms-Icon--PublicFolder::before {
    content: '\EF70'
}

.ms-Icon--PublisherLogo::before {
    content: '\F1ED'
}

.ms-Icon--PublisherLogo16::before {
    content: '\F3A0'
}

.ms-Icon--PublisherLogoFill::before {
    content: '\F1EE'
}

.ms-Icon--PublisherLogoFill16::before {
    content: '\F3A1'
}

.ms-Icon--PublisherLogoInverse16::before {
    content: '\F39F'
}

.ms-Icon--Puzzle::before {
    content: '\EA86'
}

.ms-Icon--PY::before {
    content: '\F2F9'
}

.ms-Icon--PythonLanguage::before {
    content: '\F2F8'
}

.ms-Icon--QueryList::before {
    content: '\F2B8'
}

.ms-Icon--Questionnaire::before {
    content: '\EE19'
}

.ms-Icon--QuestionnaireMirrored::before {
    content: '\EE4B'
}

.ms-Icon--QuickNote::before {
    content: '\E70B'
}

.ms-Icon--QuickNoteSolid::before {
    content: '\F338'
}

.ms-Icon--RadioBtnOff::before {
    content: '\ECCA'
}

.ms-Icon--RadioBtnOn::before {
    content: '\ECCB'
}

.ms-Icon--RadioBullet::before {
    content: '\E915'
}

.ms-Icon--Rain::before {
    content: '\E9C4'
}

.ms-Icon--RainShowersDay::before {
    content: '\E9C3'
}

.ms-Icon--RainShowersNight::before {
    content: '\EA0F'
}

.ms-Icon--RainSnow::before {
    content: '\E9C7'
}

.ms-Icon--RawSource::before {
    content: '\F299'
}

.ms-Icon--Read::before {
    content: '\E8C3'
}

.ms-Icon--ReadingMode::before {
    content: '\E736'
}

.ms-Icon--ReadingModeSolid::before {
    content: '\F33D'
}

.ms-Icon--ReceiptCheck::before {
    content: '\EF5B'
}

.ms-Icon--ReceiptForward::before {
    content: '\EF59'
}

.ms-Icon--ReceiptReply::before {
    content: '\EF5A'
}

.ms-Icon--ReceiptTentative::before {
    content: '\F41A'
}

.ms-Icon--ReceiptTentativeMirrored::before {
    content: '\F41B'
}

.ms-Icon--ReceiptUndelivered::before {
    content: '\F419'
}

.ms-Icon--Recent::before {
    content: '\E823'
}

.ms-Icon--Record2::before {
    content: '\EA3F'
}

.ms-Icon--RecurringEvent::before {
    content: '\EF5D'
}

.ms-Icon--RecurringTask::before {
    content: '\EDB2'
}

.ms-Icon--RecycleBin::before {
    content: '\EF87'
}

.ms-Icon--Redeploy::before {
    content: '\F29E'
}

.ms-Icon--RedEye::before {
    content: '\E7B3'
}

.ms-Icon--Redo::before {
    content: '\E7A6'
}

.ms-Icon--Refresh::before {
    content: '\E72C'
}

.ms-Icon--ReminderGroup::before {
    content: '\EBF8'
}

.ms-Icon--ReminderPerson::before {
    content: '\EBF7'
}

.ms-Icon--Remove::before {
    content: '\E738'
}

.ms-Icon--RemoveEvent::before {
    content: '\ED8A'
}

.ms-Icon--RemoveFilter::before {
    content: '\EB08'
}

.ms-Icon--RemoveLink::before {
    content: '\ED90'
}

.ms-Icon--RemoveOccurrence::before {
    content: '\ED9B'
}

.ms-Icon--Rename::before {
    content: '\E8AC'
}

.ms-Icon--ReopenPages::before {
    content: '\ED50'
}

.ms-Icon--Repair::before {
    content: '\E90F'
}

.ms-Icon--Reply::before {
    content: '\E97A'
}

.ms-Icon--ReplyAll::before {
    content: '\EE0A'
}

.ms-Icon--ReplyAllAlt::before {
    content: '\EF5F'
}

.ms-Icon--ReplyAllMirrored::before {
    content: '\EE36'
}

.ms-Icon--ReplyAlt::before {
    content: '\EF5E'
}

.ms-Icon--ReplyMirrored::before {
    content: '\EE35'
}

.ms-Icon--Repo::before {
    content: '\F2CB'
}

.ms-Icon--ReportLibrary::before {
    content: '\EEBB'
}

.ms-Icon--ReportLibraryMirrored::before {
    content: '\EEBC'
}

.ms-Icon--RepoSolid::before {
    content: '\F2CC'
}

.ms-Icon--ReturnToSession::before {
    content: '\ED24'
}

.ms-Icon--ReviewRequestMirroredSolid::before {
    content: '\F357'
}

.ms-Icon--ReviewRequestSolid::before {
    content: '\F356'
}

.ms-Icon--ReviewResponseSolid::before {
    content: '\F358'
}

.ms-Icon--ReviewSolid::before {
    content: '\F355'
}

.ms-Icon--RevToggleKey::before {
    content: '\E845'
}

.ms-Icon--Rewind::before {
    content: '\EB9E'
}

.ms-Icon--Ribbon::before {
    content: '\E9D1'
}

.ms-Icon--RibbonSolid::before {
    content: '\F345'
}

.ms-Icon--RightDoubleQuote::before {
    content: '\E9B1'
}

.ms-Icon--Ringer::before {
    content: '\EA8F'
}

.ms-Icon--RingerOff::before {
    content: '\F2C5'
}

.ms-Icon--Robot::before {
    content: '\E99A'
}

.ms-Icon--Rocket::before {
    content: '\F3B3'
}

.ms-Icon--Room::before {
    content: '\ED9F'
}

.ms-Icon--Rotate::before {
    content: '\E7AD'
}

.ms-Icon--RowsChild::before {
    content: '\F29C'
}

.ms-Icon--RowsGroup::before {
    content: '\F29B'
}

.ms-Icon--Rugby::before {
    content: '\EB2D'
}

.ms-Icon--Running::before {
    content: '\EADA'
}

.ms-Icon--Sad::before {
    content: '\E757'
}

.ms-Icon--SadSolid::before {
    content: '\F33E'
}

.ms-Icon--Save::before {
    content: '\E74E'
}

.ms-Icon--SaveAll::before {
    content: '\F203'
}

.ms-Icon--SaveAndClose::before {
    content: '\F038'
}

.ms-Icon--SaveAs::before {
    content: '\E792'
}

.ms-Icon--Savings::before {
    content: '\EB0B'
}

.ms-Icon--ScheduleEventAction::before {
    content: '\F1EF'
}

.ms-Icon--ScopeTemplate::before {
    content: '\F2B0'
}

.ms-Icon--Script::before {
    content: '\F03A'
}

.ms-Icon--ScrollUpDown::before {
    content: '\EC8F'
}

.ms-Icon--Search::before {
    content: '\E721'
}

.ms-Icon--SearchAndApps::before {
    content: '\E773'
}

.ms-Icon--SearchCalendar::before {
    content: '\F4AF'
}

.ms-Icon--SearchIssue::before {
    content: '\F09A'
}

.ms-Icon--SearchIssueMirrored::before {
    content: '\F09B'
}

.ms-Icon--Section::before {
    content: '\EC0C'
}

.ms-Icon--Sections::before {
    content: '\EF76'
}

.ms-Icon--SecurityGroup::before {
    content: '\ED85'
}

.ms-Icon--Send::before {
    content: '\E724'
}

.ms-Icon--SendMirrored::before {
    content: '\EA63'
}

.ms-Icon--Separator::before {
    content: '\F35E'
}

.ms-Icon--Server::before {
    content: '\F201'
}

.ms-Icon--ServerEnviroment::before {
    content: '\F29F'
}

.ms-Icon--ServerProcesses::before {
    content: '\F1FE'
}

.ms-Icon--SetAction::before {
    content: '\F071'
}

.ms-Icon--Settings::before {
    content: '\E713'
}

.ms-Icon--Share::before {
    content: '\E72D'
}

.ms-Icon--ShareiOS::before {
    content: '\EF79'
}

.ms-Icon--SharepointLogo::before {
    content: '\F27E'
}

.ms-Icon--SharepointLogoFill::before {
    content: '\F27F'
}

.ms-Icon--SharepointLogoInverse::before {
    content: '\ED18'
}

.ms-Icon--Shield::before {
    content: '\EA18'
}

.ms-Icon--ShieldSolid::before {
    content: '\F340'
}

.ms-Icon--Shop::before {
    content: '\E719'
}

.ms-Icon--ShoppingCart::before {
    content: '\E7BF'
}

.ms-Icon--ShoppingCartSolid::before {
    content: '\F342'
}

.ms-Icon--ShopServer::before {
    content: '\F2B6'
}

.ms-Icon--ShowResults::before {
    content: '\E8BC'
}

.ms-Icon--ShowResultsMirrored::before {
    content: '\EA65'
}

.ms-Icon--SidePanel::before {
    content: '\EF52'
}

.ms-Icon--SidePanelMirrored::before {
    content: '\F221'
}

.ms-Icon--SignOut::before {
    content: '\F3B1'
}

.ms-Icon--SingleBookmark::before {
    content: '\EDFF'
}

.ms-Icon--SingleColumn::before {
    content: '\F1D3'
}

.ms-Icon--SingleColumnEdit::before {
    content: '\F321'
}

.ms-Icon--SIPMove::before {
    content: '\E759'
}

.ms-Icon--SkiResorts::before {
    content: '\EB45'
}

.ms-Icon--SkypeCheck::before {
    content: '\EF80'
}

.ms-Icon--SkypeCircleCheck::before {
    content: '\EF7D'
}

.ms-Icon--SkypeCircleClock::before {
    content: '\EF7E'
}

.ms-Icon--SkypeCircleMinus::before {
    content: '\EF7F'
}

.ms-Icon--SkypeClock::before {
    content: '\EF81'
}

.ms-Icon--SkypeForBusinessLogo::before {
    content: '\F0FC'
}

.ms-Icon--SkypeForBusinessLogo16::before {
    content: '\F40F'
}

.ms-Icon--SkypeForBusinessLogoFill::before {
    content: '\F27D'
}

.ms-Icon--SkypeForBusinessLogoFill16::before {
    content: '\F410'
}

.ms-Icon--SkypeLogo::before {
    content: '\EB6F'
}

.ms-Icon--SkypeLogo16::before {
    content: '\F40E'
}

.ms-Icon--SkypeMessage::before {
    content: '\EF83'
}

.ms-Icon--SkypeMinus::before {
    content: '\EF82'
}

.ms-Icon--SliderThumb::before {
    content: '\EC13'
}

.ms-Icon--Snooze::before {
    content: '\F4BD'
}

.ms-Icon--Snow::before {
    content: '\E9C8'
}

.ms-Icon--Snowflake::before {
    content: '\EB46'
}

.ms-Icon--SnowShowerDay::before {
    content: '\E9FD'
}

.ms-Icon--SnowShowerNight::before {
    content: '\EA11'
}

.ms-Icon--Soccer::before {
    content: '\EB21'
}

.ms-Icon--SocialListeningLogo::before {
    content: '\ED7C'
}

.ms-Icon--Sort::before {
    content: '\E8CB'
}

.ms-Icon--SortDown::before {
    content: '\EE69'
}

.ms-Icon--SortLines::before {
    content: '\E9D0'
}

.ms-Icon--SortUp::before {
    content: '\EE68'
}

.ms-Icon--Spacer::before {
    content: '\F40D'
}

.ms-Icon--Speakers::before {
    content: '\E7F5'
}

.ms-Icon--SpeedHigh::before {
    content: '\EC4A'
}

.ms-Icon--Split::before {
    content: '\EDBC'
}

.ms-Icon--Sprint::before {
    content: '\F3B0'
}

.ms-Icon--Squalls::before {
    content: '\E9CC'
}

.ms-Icon--StackedBarChart::before {
    content: '\F24D'
}

.ms-Icon--StackedLineChart::before {
    content: '\F24E'
}

.ms-Icon--StackIndicator::before {
    content: '\E7FF'
}

.ms-Icon--StaffNotebookLogo16::before {
    content: '\F48E'
}

.ms-Icon--StaffNotebookLogo32::before {
    content: '\F48C'
}

.ms-Icon--StaffNotebookLogoFill16::before {
    content: '\F48F'
}

.ms-Icon--StaffNotebookLogoFill32::before {
    content: '\F48D'
}

.ms-Icon--StaffNotebookLogoInverted16::before {
    content: '\F491'
}

.ms-Icon--StaffNotebookLogoInverted32::before {
    content: '\F490'
}

.ms-Icon--Starburst::before {
    content: '\EF78'
}

.ms-Icon--StarburstSolid::before {
    content: '\F33C'
}

.ms-Icon--StatusCircleCheckmark::before {
    content: '\F13E'
}

.ms-Icon--StatusCircleInner::before {
    content: '\F137'
}

.ms-Icon--StatusCircleOuter::before {
    content: '\F136'
}

.ms-Icon--StatusErrorFull::before {
    content: '\EB90'
}

.ms-Icon--StatusTriangle::before {
    content: '\EA82'
}

.ms-Icon--Step::before {
    content: '\F241'
}

.ms-Icon--StepInsert::before {
    content: '\F242'
}

.ms-Icon--StepShared::before {
    content: '\F243'
}

.ms-Icon--StepSharedAdd::before {
    content: '\F244'
}

.ms-Icon--StepSharedInsert::before {
    content: '\F245'
}

.ms-Icon--StockDown::before {
    content: '\EB0F'
}

.ms-Icon--StockUp::before {
    content: '\EB11'
}

.ms-Icon--Stop::before {
    content: '\E71A'
}

.ms-Icon--StopSolid::before {
    content: '\EE95'
}

.ms-Icon--Stopwatch::before {
    content: '\E916'
}

.ms-Icon--StoreLogo::before {
    content: '\EA96'
}

.ms-Icon--StoreLogoMed::before {
    content: '\EA04'
}

.ms-Icon--Storyboard::before {
    content: '\F308'
}

.ms-Icon--Streaming::before {
    content: '\E93E'
}

.ms-Icon--StreamingOff::before {
    content: '\F2BB'
}

.ms-Icon--StreamLogo::before {
    content: '\F329'
}

.ms-Icon--Strikethrough::before {
    content: '\EDE0'
}

.ms-Icon--Subscribe::before {
    content: '\EDA1'
}

.ms-Icon--Subscript::before {
    content: '\EDDF'
}

.ms-Icon--Suitcase::before {
    content: '\EDD3'
}

.ms-Icon--SunAdd::before {
    content: '\EF69'
}

.ms-Icon--Sunny::before {
    content: '\E9BD'
}

.ms-Icon--SunQuestionMark::before {
    content: '\EF6A'
}

.ms-Icon--Superscript::before {
    content: '\EDDE'
}

.ms-Icon--SwayLogo16::before {
    content: '\F484'
}

.ms-Icon--SwayLogo32::before {
    content: '\F482'
}

.ms-Icon--SwayLogoFill16::before {
    content: '\F485'
}

.ms-Icon--SwayLogoFill32::before {
    content: '\F483'
}

.ms-Icon--SwayLogoInverse::before {
    content: '\ED29'
}

.ms-Icon--Switch::before {
    content: '\E8AB'
}

.ms-Icon--SwitcherStartEnd::before {
    content: '\E810'
}

.ms-Icon--Sync::before {
    content: '\E895'
}

.ms-Icon--SyncFolder::before {
    content: '\E8F7'
}

.ms-Icon--SyncOccurence::before {
    content: '\F4A3'
}

.ms-Icon--SyncToPC::before {
    content: '\EE6E'
}

.ms-Icon--System::before {
    content: '\E770'
}

.ms-Icon--Tab::before {
    content: '\E7E9'
}

.ms-Icon--Table::before {
    content: '\ED86'
}

.ms-Icon--Tablet::before {
    content: '\E70A'
}

.ms-Icon--TabletSelected::before {
    content: '\EC74'
}

.ms-Icon--Tag::before {
    content: '\E8EC'
}

.ms-Icon--Taskboard::before {
    content: '\F1C2'
}

.ms-Icon--TaskGroup::before {
    content: '\F2AE'
}

.ms-Icon--TaskGroupMirrored::before {
    content: '\F2AF'
}

.ms-Icon--TaskLogo::before {
    content: '\F493'
}

.ms-Icon--TaskManager::before {
    content: '\EDB7'
}

.ms-Icon--TaskManagerMirrored::before {
    content: '\EDB8'
}

.ms-Icon--TaskSolid::before {
    content: '\F333'
}

.ms-Icon--Taxi::before {
    content: '\F4A1'
}

.ms-Icon--TeamFavorite::before {
    content: '\F2AD'
}

.ms-Icon--TeamsLogo::before {
    content: '\F27B'
}

.ms-Icon--TeamsLogoFill::before {
    content: '\F27C'
}

.ms-Icon--TeamsLogoInverse::before {
    content: '\F27A'
}

.ms-Icon--Teamwork::before {
    content: '\EA12'
}

.ms-Icon--Teeth::before {
    content: '\F4A0'
}

.ms-Icon--TemporaryUser::before {
    content: '\EE58'
}

.ms-Icon--Tennis::before {
    content: '\EB33'
}

.ms-Icon--TestAutoSolid::before {
    content: '\F3A8'
}

.ms-Icon--TestBeaker::before {
    content: '\F3A5'
}

.ms-Icon--TestBeakerSolid::before {
    content: '\F3A6'
}

.ms-Icon--TestCase::before {
    content: '\F3AF'
}

.ms-Icon--TestExploreSolid::before {
    content: '\F3A7'
}

.ms-Icon--TestImpactSolid::before {
    content: '\F3AA'
}

.ms-Icon--TestParameter::before {
    content: '\F3AD'
}

.ms-Icon--TestPlan::before {
    content: '\F3AB'
}

.ms-Icon--TestStep::before {
    content: '\F3AC'
}

.ms-Icon--TestSuite::before {
    content: '\F3AE'
}

.ms-Icon--TestUserSolid::before {
    content: '\F3A9'
}

.ms-Icon--TextBox::before {
    content: '\EDC2'
}

.ms-Icon--TextCallout::before {
    content: '\F2A2'
}

.ms-Icon--TextDocument::before {
    content: '\F029'
}

.ms-Icon--TextField::before {
    content: '\EDC3'
}

.ms-Icon--TFVCLogo::before {
    content: '\F44D'
}

.ms-Icon--ThisPC::before {
    content: '\EC4E'
}

.ms-Icon--ThumbnailView::before {
    content: '\E7B6'
}

.ms-Icon--ThumbnailViewMirrored::before {
    content: '\EA67'
}

.ms-Icon--Thunderstorms::before {
    content: '\E9C6'
}

.ms-Icon--Ticket::before {
    content: '\EB54'
}

.ms-Icon--Tiles::before {
    content: '\ECA5'
}

.ms-Icon--Tiles2::before {
    content: '\EF7C'
}

.ms-Icon--Timeline::before {
    content: '\ED9C'
}

.ms-Icon--TimelineDelivery::before {
    content: '\F2AB'
}

.ms-Icon--TimelineMatrixView::before {
    content: '\F361'
}

.ms-Icon--TimelineProgress::before {
    content: '\F2AA'
}

.ms-Icon--Timer::before {
    content: '\E91E'
}

.ms-Icon--ToDoLogoBottom::before {
    content: '\F4B3'
}

.ms-Icon--ToDoLogoInverse::before {
    content: '\F4BC'
}

.ms-Icon--ToDoLogoTop::before {
    content: '\F4B4'
}

.ms-Icon--ToggleBorder::before {
    content: '\EC12'
}

.ms-Icon--ToggleFilled::before {
    content: '\EC11'
}

.ms-Icon--ToggleThumb::before {
    content: '\EC14'
}

.ms-Icon--Touch::before {
    content: '\E815'
}

.ms-Icon--TouchPointer::before {
    content: '\E7C9'
}

.ms-Icon--Train::before {
    content: '\E7C0'
}

.ms-Icon--TrainSolid::before {
    content: '\EB4D'
}

.ms-Icon--TransferCall::before {
    content: '\ED95'
}

.ms-Icon--TriangleDown12::before {
    content: '\EED1'
}

.ms-Icon--TriangleLeft12::before {
    content: '\EED2'
}

.ms-Icon--TriangleRight12::before {
    content: '\EED3'
}

.ms-Icon--TriangleSolid::before {
    content: '\EA08'
}

.ms-Icon--TriangleSolidDown12::before {
    content: '\EECD'
}

.ms-Icon--TriangleSolidLeft12::before {
    content: '\EECE'
}

.ms-Icon--TriangleSolidRight12::before {
    content: '\EECF'
}

.ms-Icon--TriangleSolidUp12::before {
    content: '\EECC'
}

.ms-Icon--TriangleUp12::before {
    content: '\EED0'
}

.ms-Icon--TriggerApproval::before {
    content: '\F3B2'
}

.ms-Icon--TriggerAuto::before {
    content: '\F24A'
}

.ms-Icon--TriggerUser::before {
    content: '\F24B'
}

.ms-Icon--TripleColumn::before {
    content: '\F1D5'
}

.ms-Icon--TripleColumnEdit::before {
    content: '\F323'
}

.ms-Icon--Trophy::before {
    content: '\ED3F'
}

.ms-Icon--Trophy2Solid::before {
    content: '\F337'
}

.ms-Icon--TurnRight::before {
    content: '\E7DB'
}

.ms-Icon--TVMonitor::before {
    content: '\E7F4'
}

.ms-Icon--TVMonitorSelected::before {
    content: '\EC77'
}

.ms-Icon--TypeScriptLanguage::before {
    content: '\F2F7'
}

.ms-Icon--Umbrella::before {
    content: '\EC04'
}

.ms-Icon--Underline::before {
    content: '\E8DC'
}

.ms-Icon--Undo::before {
    content: '\E7A7'
}

.ms-Icon--Uneditable::before {
    content: '\ED1D'
}

.ms-Icon--UneditableMirrored::before {
    content: '\F4B9'
}

.ms-Icon--UneditableSolid12::before {
    content: '\F4B7'
}

.ms-Icon--UneditableSolidMirrored12::before {
    content: '\F4B8'
}

.ms-Icon--Unfavorite::before {
    content: '\E8D9'
}

.ms-Icon--Unknown::before {
    content: '\E9CE'
}

.ms-Icon--UnknownCall::before {
    content: '\ED97'
}

.ms-Icon--UnknownMirroredSolid::before {
    content: '\F2E2'
}

.ms-Icon--UnknownSolid::before {
    content: '\F2E1'
}

.ms-Icon--Unlock::before {
    content: '\E785'
}

.ms-Icon--UnlockSolid::before {
    content: '\F304'
}

.ms-Icon--Unpin::before {
    content: '\E77A'
}

.ms-Icon--Unsubscribe::before {
    content: '\EDA0'
}

.ms-Icon--UnsyncFolder::before {
    content: '\E8F6'
}

.ms-Icon--UnsyncOccurence::before {
    content: '\F4A4'
}

.ms-Icon--Up::before {
    content: '\E74A'
}

.ms-Icon--Upload::before {
    content: '\E898'
}

.ms-Icon--UserFollowed::before {
    content: '\F25C'
}

.ms-Icon--UserPause::before {
    content: '\F2BA'
}

.ms-Icon--UserSync::before {
    content: '\F2B9'
}

.ms-Icon--Vacation::before {
    content: '\F49F'
}

.ms-Icon--Variable::before {
    content: '\F305'
}

.ms-Icon--VariableGroup::before {
    content: '\F31B'
}

.ms-Icon--VB::before {
    content: '\F2F2'
}

.ms-Icon--VennDiagram::before {
    content: '\F273'
}

.ms-Icon--Video::before {
    content: '\E714'
}

.ms-Icon--VideoOff::before {
    content: '\F4B0'
}

.ms-Icon--VideoSolid::before {
    content: '\EA0C'
}

.ms-Icon--View::before {
    content: '\E890'
}

.ms-Icon--ViewAll::before {
    content: '\E8A9'
}

.ms-Icon--ViewAll2::before {
    content: '\EF56'
}

.ms-Icon--ViewDashboard::before {
    content: '\F246'
}

.ms-Icon--ViewList::before {
    content: '\F247'
}

.ms-Icon--ViewListGroup::before {
    content: '\F248'
}

.ms-Icon--ViewListTree::before {
    content: '\F249'
}

.ms-Icon--VisioDiagram::before {
    content: '\F2A0'
}

.ms-Icon--VisioDocument::before {
    content: '\F2A9'
}

.ms-Icon--VisioLogo::before {
    content: '\F2A7'
}

.ms-Icon--VisioLogo16::before {
    content: '\F3A3'
}

.ms-Icon--VisioLogoFill::before {
    content: '\F2A8'
}

.ms-Icon--VisioLogoFill16::before {
    content: '\F3A4'
}

.ms-Icon--VisioLogoInverse::before {
    content: '\ED7D'
}

.ms-Icon--VisioLogoInverse16::before {
    content: '\F3A2'
}

.ms-Icon--VisualBasicLanguage::before {
    content: '\F2F1'
}

.ms-Icon--VisualStudioLogo::before {
    content: '\EC22'
}

.ms-Icon--VoicemailForward::before {
    content: '\ED87'
}

.ms-Icon--VoicemailIRM::before {
    content: '\F421'
}

.ms-Icon--VoicemailReply::before {
    content: '\ED88'
}

.ms-Icon--Volume0::before {
    content: '\E992'
}

.ms-Icon--Volume1::before {
    content: '\E993'
}

.ms-Icon--Volume2::before {
    content: '\E994'
}

.ms-Icon--Volume3::before {
    content: '\E995'
}

.ms-Icon--VolumeDisabled::before {
    content: '\EA85'
}

.ms-Icon--VSTSAltLogo1::before {
    content: '\F382'
}

.ms-Icon--VSTSAltLogo2::before {
    content: '\F383'
}

.ms-Icon--VSTSLogo::before {
    content: '\F381'
}

.ms-Icon--Waffle::before {
    content: '\ED89'
}

.ms-Icon--Warning::before {
    content: '\E7BA'
}

.ms-Icon--Website::before {
    content: '\EB41'
}

.ms-Icon--Weights::before {
    content: '\EADB'
}

.ms-Icon--WifiEthernet::before {
    content: '\EE77'
}

.ms-Icon--WindDirection::before {
    content: '\EBE6'
}

.ms-Icon--WindowsLogo::before {
    content: '\E782'
}

.ms-Icon--Wines::before {
    content: '\EABF'
}

.ms-Icon--WipePhone::before {
    content: '\ED8D'
}

.ms-Icon--WordDocument::before {
    content: '\EF71'
}

.ms-Icon--WordLogo::before {
    content: '\F1E3'
}

.ms-Icon--WordLogo16::before {
    content: '\F391'
}

.ms-Icon--WordLogoFill::before {
    content: '\F1E4'
}

.ms-Icon--WordLogoFill16::before {
    content: '\F392'
}

.ms-Icon--WordLogoInverse::before {
    content: '\EC29'
}

.ms-Icon--WordLogoInverse16::before {
    content: '\F390'
}

.ms-Icon--Work::before {
    content: '\E821'
}

.ms-Icon--WorkFlow::before {
    content: '\EA01'
}

.ms-Icon--WorkItem::before {
    content: '\F314'
}

.ms-Icon--WorkItemBar::before {
    content: '\F35C'
}

.ms-Icon--WorkItemBarSolid::before {
    content: '\F35D'
}

.ms-Icon--WorkItemBug::before {
    content: '\F315'
}

.ms-Icon--World::before {
    content: '\E909'
}

.ms-Icon--WorldClock::before {
    content: '\E918'
}

.ms-Icon--YammerLogo::before {
    content: '\ED19'
}

.ms-Icon--ZipFolder::before {
    content: '\F012'
}

.ms-Icon--Zoom::before {
    content: '\E71E'
}

.ms-Icon--ZoomIn::before {
    content: '\E8A3'
}

.ms-Icon--ZoomOut::before {
    content: '\E71F'
}