.icon-container-layout {
  border: 0;
  width: auto;
  display: block;
  float: left;
  min-width: 0;
  min-height: 0;
  font-size: em(20px);
  height: 48px;
  width: 48px;
  text-align: center;
  background-color: transparent; }
  .icon-container-layout span {
    margin-left: 10px;
    position: relative;
    top: -3px;
    /* fix for improper positioning */ }

.icon {
  font-family: "SegoeUI MDL2 Assets";
  font-weight: normal;
  font-style: normal; }
  .icon.icon-ScrollChevronDownLegacy:before {
    content: "\E011" !important; }
  .icon.icon-close:before {
    content: "\E711" !important; }
  .icon.icon-right:before {
    content: "\E0AD" !important; }
  .icon.icon-left:before {
    content: "\E0A6" !important; }
  .icon.icon-up:before {
    content: "\E74A" !important; }
  .icon.icon-down:before {
    content: "\E74B" !important; }
  .icon.icon-blockedLegacy:before {
    content: "\E1F6" !important; }
  .icon.icon-filter:before {
    content: "\E16E" !important; }

.icon-layout {
  font-family: "SegoeUI MDL2 Assets";
  font-weight: normal;
  font-style: normal;
  position: relative;
  top: 1px;
  display: inline-block;
  line-height: 48px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*---------------Skill search pahe icons--------------------*/ }
  .icon-layout.icon-notification:before {
    content: "\E91C"; }
  .icon-layout.icon-add:before {
    content: "\E710"; }
  .icon-layout.icon-calendar:before {
    content: "\E787"; }
  .icon-layout.icon-back:before {
    content: "\E112"; }
  .icon-layout.icon-close:before {
    content: "\E10A"; }
  .icon-layout.icon-hamburger:before {
    content: "\E700"; }
  .icon-layout.icon-search:before {
    content: "\E094"; }
  .icon-layout.icon-user:before {
    content: "\E2AF"; }
  .icon-layout.icon-settings:before {
    content: "\E713"; }
  .icon-layout.icon-chevron-up:before {
    content: "\E09C"; }
  .icon-layout.icon-chevron-down:before {
    content: "\E09D"; }
  .icon-layout.icon-chevron-right:before {
    content: "\E00F"; }
  .icon-layout.icon-star:before {
    content: "\E1CE"; }
  .icon-layout.icon-star-filled:before {
    content: "\E1CF"; }
  .icon-layout.icon-basket:before {
    content: "\E821"; }
  .icon-layout.icon-pipe:before {
    content: "\E17B"; }
  .icon-layout.icon-accept:before {
    content: "\E001"; }
  .icon-layout.icon-pipe-health:before {
    content: "\E17A"; }
  .icon-layout.icon-bulb:before {
    content: "\E82F"; }
  .icon-layout.icon-alert:before {
    content: "\E1DE"; }
  .icon-layout.icon-eye:before {
    content: "\E052"; }
  .icon-layout.icon-square:before {
    content: "\E25E"; }
  .icon-layout.icon-circle:before {
    content: "\EC14"; }
  .icon-layout.icon-home:before {
    content: "\E10F"; }
  .icon-layout.icon-apps:before {
    content: "\E74C"; }
  .icon-layout.icon-pointer:before {
    content: "\E1E3"; }
  .icon-layout.icon-next:before {
    content: "\E0AB"; }
  .icon-layout.icon-home2:before {
    content: "\E80F"; }
  .icon-layout.icon-devices:before {
    content: "\E70C"; }
  .icon-layout.icon-clock:before {
    content: "\E823"; }
  .icon-layout.icon-collapse:before {
    content: "\E92C"; }
  .icon-layout.icon-book:before {
    content: "\E82D"; }
  .icon-layout.icon-calender:before {
    content: "\E163"; }
  .icon-layout.icon-resize:before {
    content: "\E73F"; }
  .icon-layout.icon-favoriteStar:before {
    content: "\E734"; }
  .icon-layout.icon-emoji2:before {
    content: "\E76E"; }
  .icon-layout.icon-delete:before {
    content: "\E74D"; }
  .icon-layout.icon-fullscreen:before {
    content: "\E740"; }
  .icon-layout.icon-glyph-733:before {
    content: "\EA6C"; }
  .icon-layout.icon-world-wire:before {
    content: "\E12B"; }
  .icon-layout.icon-glyph-533:before {
    content: "\E8AB"; }
  .icon-layout.icon-glyph-378:before {
    content: "\E7EE"; }
  .icon-layout.icon-other-user:before {
    content: "\E7EE"; }
  .icon-layout.icon-check-outline-check:before {
    content: "\E0A2"; }
  .icon-layout.icon-plus:before {
    content: "\E948"; }
  .icon-layout.icon-glyph-81e:before {
    content: "\E81E"; }
  .icon-layout.icon-glyph-7c4:before {
    content: "\E7C4"; }
  .icon-layout.icon-contact-info:before {
    content: "\E779"; }
  .icon-layout.icon-theme:before {
    content: "\E771"; }
  .icon-layout.icon-users:before {
    content: "\E125"; }
  .icon-layout.icon-glyph-957:before {
    content: "\E957"; }
  .icon-layout.icon-glyph-1a6:before {
    content: "\E1A6"; }
  .icon-layout.icon-glyph-91f:before {
    content: "\E91F"; }
  .icon-layout.icon-tick:before {
    content: "\E73E"; }
  .icon-layout.icon-account-outline:before {
    content: "\E13D"; }
  .icon-layout.icon-edit:before {
    content: "\E70F"; }
  .icon-layout.icon-usersearch:before {
    content: "\E8CF"; }
  .icon-layout.icon-sucsess-alert:before {
    content: "\E930"; }
  .icon-layout.icon-info-alert:before {
    content: "\E946"; }
  .icon-layout.icon-security-alert:before {
    content: "\E1DE"; }
  .icon-layout.icon-warning:before {
    content: "\E7BA"; }
  .icon-layout.icon-stop:before {
    content: "\E71A"; }
  .icon-layout.icon-save:before {
    content: "\E74E"; }
  .icon-layout.icon-publish:before {
    content: "\E930"; }
  .icon-layout.icon-cancelpublish:before {
    content: "\EA39"; }
  .icon-layout.icon-arrowHTMLMirroredLegacy:before {
    content: "\E0AE"; }
  .icon-layout.icon-arrowHTMLLegacy:before {
    content: "\E0D5"; }
  .icon-layout.icon-account-plus:before {
    content: "\E1E2"; }
  .icon-layout.icon-add-friend:before {
    content: "\E8FA"; }
  .icon-layout.icon-glyph-8cc:before {
    content: "\E8CC"; }
  .icon-layout.icon-glyph-152:before {
    content: "\E152"; }
  .icon-layout.icon-transcribe:before {
    content: "\E929"; }
  .icon-layout.icon-caption:before {
    content: "\E8BA"; }
  .icon-layout.icon-details:before {
    content: "\E12A"; }
  .icon-layout.icon-accept2:before {
    content: "\E8FB"; }
  .icon-layout.icon-disconnect-drive:before {
    content: "\E8CD"; }
  .icon-layout.icon-lock:before {
    content: "\E1F6"; }
  .icon-layout.icon-refresh:before {
    content: "\E1CD"; }
  .icon-layout.icon-new-window:before {
    content: "\E2B4"; }
  .icon-layout.icon-forward:before {
    content: "\E72A"; }
  .icon-layout.icon-back2:before {
    content: "\E72B"; }
  .icon-layout.icon-glyph-411:before {
    content: "\E81C"; }
  .icon-layout.icon-folder-move:before {
    content: "\E8DE"; }
  .icon-layout.icon-info:before {
    content: "\E946"; }
  .icon-layout.icon-arrow-left:before {
    content: "\E0A6"; }
  .icon-layout.icon-arrow-right:before {
    content: "\E0AD"; }
  .icon-layout.icon-email-opened:before {
    content: "\E166"; }
  .icon-layout.icon-send:before {
    content: "\E122"; }
  .icon-layout.icon-compare:before {
    content: "\E11E"; }
  .icon-layout.icon-cancel:before {
    content: "\E711"; }
  .icon-layout.icon-up:before {
    content: "\E74A"; }
  .icon-layout.icon-down:before {
    content: "\E74A"; }
  .icon-layout.icon-arrow-down:before {
    content: "\E74B"; }
  .icon-layout.icon-repair:before {
    content: "\E90F"; }
  .icon-layout.icon-pagination-previous:before {
    content: "\E892"; }
  .icon-layout.icon-pagination-next:before {
    content: "\E893"; }
  .icon-layout.icon-assignback:before {
    content: "\E845"; }
  .icon-layout.icon-scrollchevron-left:before {
    content: "\E016"; }
  .icon-layout.icon-scrollchevron-right:before {
    content: "\E017"; }
  .icon-layout.icon-scrollchevron-up:before {
    content: "\E018"; }
  .icon-layout.icon-scrollchevron-down:before {
    content: "\E019"; }
  .icon-layout.icon-check:before {
    content: "\E739"; }
  .icon-layout.icon-uncheck:before {
    content: "\E73A"; }
  .icon-layout.icon-filter:before {
    content: "\E71C"; }
  .icon-layout.icon-error-icon:before {
    content: "\E7BA"; }
  .icon-layout.icon-rating-star:before {
    content: "\E1CE"; }
  .icon-layout.icon-solid-star:before {
    content: "\E1CF"; }
  .icon-layout.icon-flag-outline:before {
    content: "\EB50"; }
  .icon-layout.icon-add-plus:before {
    content: "\E710"; }
  .icon-layout.icon-utilization:before {
    content: "\E716"; }
  .icon-layout.icon-copy:before {
    content: "\E8C8"; }
  .icon-layout.icon-bench:before {
    content: "\E81C"; }
  .icon-layout.icon-timer:before {
    content: "\E916"; }
  .icon-layout.icon-flag:before {
    content: "\E7C1"; }
  .icon-layout.icon-availability:before {
    content: "\E930"; }
  .icon-layout.icon-calendar1:before {
    content: "\E787"; }
  .icon-layout.icon-lost-labour:before {
    content: "\E8F8"; }
  .icon-layout.icon-average-time:before {
    content: "\E823"; }
  .icon-layout.icon-variance:before {
    content: "\E2AE"; }
  .icon-layout.icon-checkbox:before {
    content: "\E739"; }
  .icon-layout.icon-CheckboxComposite:before {
    content: "\E73A"; }
  .icon-layout.icon-checkboxindeterminate:before {
    content: "\E73C"; }
  .icon-layout.icon-error:before {
    content: "?"; }
  .icon-layout.icon-error-alert:before {
    content: "\E7BA"; }
  .icon-layout.icon-warningalert:before {
    content: "\E1DE"; }
  .icon-layout.icon-infoalert:before {
    content: "\E946"; }
  .icon-layout.icon-success-alert:before {
    content: "\E930"; }
  .icon-layout.icon-show-note:before {
    content: "\E70B"; }
  .icon-layout.icon-assign-resource:before {
    content: "\E136"; }
  .icon-layout.icon-quick-assign:before {
    content: "\E1E2"; }
  .icon-layout.icon-gylph-255:before {
    content: "\E248"; }
  .icon-layout.icon-wrench:before {
    content: "\E15E"; }
  .icon-layout.icon-gylph-89:before {
    content: "\E14C"; }
  .icon-layout.icon-account-frame-outline:before {
    content: "\E156"; }
  .icon-layout.icon-gylph-336:before {
    content: "\E779"; }
  .icon-layout.icon-success:before {
    content: "\E930"; }
  .icon-layout.icon-fielderror:before {
    content: "\E7BA"; }
  .icon-layout.icon-cross:before {
    content: "\E947"; }
  .icon-layout.icon-droupdown-arrow:before {
    content: "\E70D"; }
  .icon-layout.icon-checkblack:before {
    content: "\E73D"; }
  .icon-layout.icon-help:before {
    content: "\E8C9"; }
  .icon-layout.icon-flipped {
    -moz-transform: scale(1, -1);
    -webkit-transform: scale(1, -1);
    -o-transform: scale(1, -1);
    -ms-transform: scale(1, -1);
    transform: scale(1, -1); }

.icon-requestor {
  font-family: "SegoeUI MDL2 Assets";
  font-weight: normal;
  font-style: normal;
  position: relative;
  top: 1px;
  display: inline-block;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
  .icon-requestor.icon-notification:before {
    content: "\E91C"; }
  .icon-requestor.icon-back:before {
    content: "\E112"; }
  .icon-requestor.icon-close:before {
    content: "\E10A"; }
  .icon-requestor.icon-hamburger:before {
    content: "\E700"; }
  .icon-requestor.icon-search:before {
    content: "\E094"; }
  .icon-requestor.icon-user:before {
    content: "\E2AF"; }
  .icon-requestor.icon-settings:before {
    content: "\E713"; }
  .icon-requestor.icon-chevron-up:before {
    content: "\E09C"; }
  .icon-requestor.icon-chevron-down:before {
    content: "\E09D"; }
  .icon-requestor.icon-chevron-right:before {
    content: "\E00F"; }
  .icon-requestor.icon-star:before {
    content: "\E1CE"; }
  .icon-requestor.icon-star-filled:before {
    content: "\E1CF"; }
  .icon-requestor.icon-basket:before {
    content: "\E821"; }
  .icon-requestor.icon-pipe:before {
    content: "\E17B"; }
  .icon-requestor.icon-accept:before {
    content: "\E001"; }
  .icon-requestor.icon-pipe-health:before {
    content: "\E17A"; }
  .icon-requestor.icon-bulb:before {
    content: "\E82F"; }
  .icon-requestor.icon-alert:before {
    content: "\E1DE"; }
  .icon-requestor.icon-eye:before {
    content: "\E052"; }
  .icon-requestor.icon-square:before {
    content: "\E25E"; }
  .icon-requestor.icon-circle:before {
    content: "\EC14"; }
  .icon-requestor.icon-home:before {
    content: "\E10F"; }
  .icon-requestor.icon-apps:before {
    content: "\E74C"; }
  .icon-requestor.icon-pointer:before {
    content: "\E1E3"; }
  .icon-requestor.icon-next:before {
    content: "\E0AB"; }
  .icon-requestor.icon-home2:before {
    content: "\E80F"; }
  .icon-requestor.icon-devices:before {
    content: "\E70C"; }
  .icon-requestor.icon-clock:before {
    content: "\E823"; }
  .icon-requestor.icon-collapse:before {
    content: "\E92C"; }
  .icon-requestor.icon-book:before {
    content: "\E82D"; }
  .icon-requestor.icon-calender:before {
    content: "\E163"; }
  .icon-requestor.icon-resize:before {
    content: "\E73F"; }
  .icon-requestor.icon-favoriteStar:before {
    content: "\E734"; }
  .icon-requestor.icon-emoji2:before {
    content: "\E76E"; }
  .icon-requestor.icon-delete:before {
    content: "\E74D"; }
  .icon-requestor.icon-fullscreen:before {
    content: "\E740"; }
  .icon-requestor.icon-glyph-733:before {
    content: "\EA6C"; }
  .icon-requestor.icon-world-wire:before {
    content: "\E12B"; }
  .icon-requestor.icon-glyph-533:before {
    content: "\E8AB"; }
  .icon-requestor.icon-glyph-378:before {
    content: "\E7EE"; }
  .icon-requestor.icon-other-user:before {
    content: "\E7EE"; }
  .icon-requestor.icon-check-outline-check:before {
    content: "\E0A2"; }
  .icon-requestor.icon-plus:before {
    content: "\E948"; }
  .icon-requestor.icon-glyph-81e:before {
    content: "\E81E"; }
  .icon-requestor.icon-glyph-7c4:before {
    content: "\E7C4"; }
  .icon-requestor.icon-contact-info:before {
    content: "\E779"; }
  .icon-requestor.icon-theme:before {
    content: "\E771"; }
  .icon-requestor.icon-users:before {
    content: "\E125"; }
  .icon-requestor.icon-glyph-957:before {
    content: "\E957"; }
  .icon-requestor.icon-glyph-1a6:before {
    content: "\E1A6"; }
  .icon-requestor.icon-glyph-91f:before {
    content: "\E91F"; }
  .icon-requestor.icon-tick:before {
    content: "\E73E"; }
  .icon-requestor.icon-account-outline:before {
    content: "\E13D"; }
  .icon-requestor.icon-edit:before {
    content: "\E104"; }
  .icon-requestor.icon-stop:before {
    content: "\E71A"; }
  .icon-requestor.icon-save:before {
    content: "\E74E"; }
  .icon-requestor.icon-publish:before {
    content: "\E930"; }
  .icon-requestor.icon-arrowHTMLMirroredLegacy:before {
    content: "\E0AE"; }
  .icon-requestor.icon-arrowHTMLLegacy:before {
    content: "\E0D5"; }
  .icon-requestor.icon-account-plus:before {
    content: "\E1E2"; }
  .icon-requestor.icon-add-friend:before {
    content: "\E8FA"; }
  .icon-requestor.icon-glyph-8cc:before {
    content: "\E8CC"; }
  .icon-requestor.icon-glyph-152:before {
    content: "\E152"; }
  .icon-requestor.icon-transcribe:before {
    content: "\E929"; }
  .icon-requestor.icon-caption:before {
    content: "\E8BA"; }
  .icon-requestor.icon-details:before {
    content: "\E12A"; }
  .icon-requestor.icon-accept2:before {
    content: "\E8FB"; }
  .icon-requestor.icon-disconnect-drive:before {
    content: "\E8CD"; }
  .icon-requestor.icon-lock:before {
    content: "\E1F6"; }
  .icon-requestor.icon-refresh:before {
    content: "\E1CD"; }
  .icon-requestor.icon-new-window:before {
    content: "\E2B4"; }
  .icon-requestor.icon-forward:before {
    content: "\E72A"; }
  .icon-requestor.icon-back2:before {
    content: "\E72B"; }
  .icon-requestor.icon-glyph-411:before {
    content: "\E81C"; }
  .icon-requestor.icon-folder-move:before {
    content: "\E8DE"; }
  .icon-requestor.icon-info:before {
    content: "\E946"; }
  .icon-requestor.icon-arrow-left:before {
    content: "\E0A6"; }
  .icon-requestor.icon-arrow-right:before {
    content: "\E0AD"; }
  .icon-requestor.icon-email-opened:before {
    content: "\E166"; }
  .icon-requestor.icon-send:before {
    content: "\E122"; }
  .icon-requestor.icon-compare:before {
    content: "\E11E"; }
  .icon-requestor.icon-downarrow:before {
    content: "\E019"; }
  .icon-requestor.icon-addplus:before {
    content: "\E710"; }
  .icon-requestor.icon-duplicate:before {
    content: "\E8C8"; }
  .icon-requestor.icon-remove:before {
    content: "\E711"; }
  .icon-requestor.icon-calender:before {
    content: "\E787"; }
  .icon-requestor.icon-edit:before {
    content: "\E70F"; }
  .icon-requestor.icon-search:before {
    content: "\E71E"; }
  .icon-requestor.icon-check:before {
    content: "\E739"; }
  .icon-requestor.icon-uncheck:before {
    content: "\E005"; }
  .icon-requestor.icon-transfer:before {
    content: "\E716"; }
  .icon-requestor.icon-suspend:before {
    content: "\E876"; }
  .icon-requestor.icon-cancel:before {
    content: "\E871"; }
  .icon-requestor.icon-droupdown:before {
    content: "\E0E4"; }
  .icon-requestor.icon-gylph-255:before {
    content: "\E248"; }
  .icon-requestor.icon-wrench:before {
    content: "\E15E"; }
  .icon-requestor.icon-gylph-89:before {
    content: "\E14C"; }
  .icon-requestor.icon-account-frame-outline:before {
    content: "\E156"; }
  .icon-requestor.icon-gylph-336:before {
    content: "\E779"; }
  .icon-requestor.icon-success:before {
    content: "\E930"; }
  .icon-requestor.icon-fielderror:before {
    content: "\E7BA"; }
  .icon-requestor.icon-cross:before {
    content: "\E947"; }
  .icon-requestor.icon-droupdown-arrow:before {
    content: "\E70D"; }
  .icon-requestor.icon-requestor.icon-calendar-week:before {
    content: "\E162"; }
  .icon-requestor.icon-notes:before {
    content: "\E130"; }

/*# sourceMappingURL=icon.css.map */
