/*Left navigation menu bar icons styles*/
.nav-icon:before {
    content: "\00a0";
}

.nav-icon {
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
    position: relative;
    display: inline-block;
    width: 1.2em;
    line-height: 1;
}

.nav-active-request {
    background: url(../../../../images/navicons/Active-request.png);
}

.nav-administrator-dashboard {
    background: url(../../../../images/navicons/Administrator-Dashboard.png);
}

.nav-bulk-operations {
    background-image: url(../../../../images/navicons/Bulk-Operations.png);
}

.nav-calender {
    background-image: url(../../../../images/navicons/Calendar.png);
}

.nav-functional-manager-dashboard {
    background-image: url(../../../../images/navicons/Functional-Manager-Dashboard.png);
}

.nav-incoming-request {
    background-image: url(../../../../images/navicons/Incoming-request.png);
}

.nav-manage-oofs {
    background-image: url(../../../../images/navicons/Manage-OOFs.png);
}

.nav-manage-event-type {
    background-image: url(../../../../images/navicons/Manage-Event-type.png);
}

.nav-manage-queues {
    background-image: url(../../images/navicons/Manage-Queues.png);
}

.nav-on-behalf {
    background-image: url(../../../../images/navicons/On-behalf-of.png);
}

.nav-resorce-manager-dashboard {
    background-image: url(../../../../images/navicons/Resource-Manager-Dashboard.png);
}

.nav-resource-request {
    background-image: url(../../../../images/navicons/Resource-Requests.png);
}

.nav-skill-assessment {
    background-image: url(../../../../images/navicons/Skill-Assessment.png);
}

.nav-team-review {
    background-image: url(../../../../images/navicons/Team-Review.png);
}

.nav-readiness-manager-single {
    background-image: url(../../../../images/navicons/Readiness-Manager-Single.png);
}

.nav-readiness-manager-multiple {
    background-image: url(../../../../images/navicons/Readiness-Manager-Multiple.png);
}



@media screen and (-ms-high-contrast: active) {

    .nav-icon {
        background-size: contain;
        background-position: 50%;
        background-repeat: no-repeat;
        position: relative;
        display: inline-block;
        width: 1.2em;
        line-height: 1;
    }

    .nav-active-request {
        background: url(../../../../images/navicons/Active-request.png);
    }

    .nav-administrator-dashboard {
        background: url(../../../../images/navicons/Administrator-Dashboard.png);
    }

    .nav-bulk-operations {
        background-image: url(../../../../images/navicons/Bulk-Operations.png);
    }

    .nav-calender {
        background-image: url(../../../../images/navicons/Calendar.png);
    }

    .nav-functional-manager-dashboard {
        background-image: url(../../../../images/navicons/Functional-Manager-Dashboard.png);
    }

    .nav-incoming-request {
        background-image: url(../../../../images/navicons/Incoming-request.png);
    }

    .nav-manage-oofs {
        background-image: url(../../../../images/navicons/Manage-OOFs.png);
    }

    .nav-manage-event-type {
        background-image: url(../../../../images/navicons/Manage-Event-type.png);
    }

    .nav-manage-queues {
        background-image: url(../../images/navicons/Manage-Queues.png);
    }

    .nav-on-behalf {
        background-image: url(../../../../images/navicons/On-behalf-of.png);
    }

    .nav-resorce-manager-dashboard {
        background-image: url(../../../../images/navicons/Resource-Manager-Dashboard.png);
    }

    .nav-resource-request {
        background-image: url(../../../../images/navicons/Resource-Requests.png);
    }

    .nav-skill-assessment {
        background-image: url(../../../../images/navicons/Skill-Assessment.png);
    }

    .nav-team-review {
        background-image: url(../../../../images/navicons/Team-Review.png);
    }

    .nav-readiness-manager-single {
        background-image: url(../../../../images/navicons/Readiness-Manager-Single.png);
    }

    .nav-readiness-manager-multiple {
        background-image: url(../../../../images/navicons/Readiness-Manager-Multiple.png);
    }
}


@media screen and (-ms-high-contrast: white-on-black) {
    .nav-icon {
        background-size: contain;
        background-position: 50%;
        background-repeat: no-repeat;
        position: relative;
        display: inline-block;
        width: 1.2em;
        line-height: 1;
    }

    .nav-active-request {
        background: url(../../../../images/navicons/Active-request.png);
    }

    .nav-administrator-dashboard {
        background: url(../../../../images/navicons/Administrator-Dashboard.png);
    }

    .nav-bulk-operations {
        background-image: url(../../../../images/navicons/Bulk-Operations.png);
    }

    .nav-calender {
        background-image: url(../../../../images/navicons/Calendar.png);
    }

    .nav-functional-manager-dashboard {
        background-image: url(../../../../images/navicons/Functional-Manager-Dashboard.png);
    }

    .nav-incoming-request {
        background-image: url(../../../../images/navicons/Incoming-request.png);
    }

    .nav-manage-oofs {
        background-image: url(../../../../images/navicons/Manage-OOFs.png);
    }

    .nav-manage-event-type {
        background-image: url(../../../../images/navicons/Manage-Event-type.png);
    }

    .nav-manage-queues {
        background-image: url(../../images/navicons/Manage-Queues.png);
    }

    .nav-on-behalf {
        background-image: url(../../../../images/navicons/On-behalf-of.png);
    }

    .nav-resorce-manager-dashboard {
        background-image: url(../../../../images/navicons/Resource-Manager-Dashboard.png);
    }

    .nav-resource-request {
        background-image: url(../../../../images/navicons/Resource-Requests.png);
    }

    .nav-skill-assessment {
        background-image: url(../../../../images/navicons/Skill-Assessment.png);
    }

    .nav-team-review {
        background-image: url(../../../../images/navicons/Team-Review.png);
    }

    .nav-readiness-manager-single {
        background-image: url(../../../../images/navicons/Readiness-Manager-Single.png);
    }

    .nav-readiness-manager-multiple {
        background-image: url(../../../../images/navicons/Readiness-Manager-Multiple.png);
    }
}

@media screen and (-ms-high-contrast: black-on-white) {

.nav-icon {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  width: 1.2em;
        line-height: 1;
    }

  .nav-active-request {
        background: url(../../../../images/navicons/wb/Active-request.png);
    }

  .nav-administrator-dashboard {
        background: url(../../../../images/navicons/wb/Administrator-Dashboard.png);
    }

  .nav-bulk-operations {
        background-image: url(../../../../images/navicons/wb/Bulk-Operations.png);
    }

  .nav-calender {
        background-image: url(../../../../images/navicons/wb/Calendar.png);
    }

   .nav-functional-manager-dashboard {
        background-image: url(../../../../images/navicons/wb/Functional-Manager-Dashboard.png);
    }

  .nav-incoming-request {
        background-image: url(../../../../images/navicons/wb/Incoming-request.png);
    }

  .nav-manage-oofs {
        background-image: url(../../../../images/navicons/wb/Manage-OOFs.png);
    }

   .nav-manage-event-type {
        background-image: url(../../../../images/navicons/wb/Manage-Event-type.png);
    }

  .nav-manage-queues {
    background-image: url(../../../../images/navicons/Manage-Queues.png); }
   .nav-on-behalf {
        background-image: url(../../../../images/navicons/wb/On-behalf-of.png);
    }

   .nav-resorce-manager-dashboard {
        background-image: url(../../../../images/navicons/wb/Resource-Manager-Dashboard.png);
    }

   .nav-resource-request {
        background-image: url(../../../../images/navicons/wb/Resource-Requests.png);
    }

  .nav-skill-assessment {
        background-image: url(../../../../images/navicons/wb/Skill-Assessment.png);
    }

  .nav-team-review {
        background-image: url(../../../../images/navicons/wb/Team-Review.png);
    }

  .nav-readiness-manager-single {
        background-image: url(../../../../images/navicons/wb/Readiness-Manager-Single.png);
    }

  .nav-readiness-manager-multiple {
        background-image: url(../../../../images/navicons/wb/Readiness-Manager-Multiple.png);
    }
}




























/*# sourceMappingURL=nav-icon.css.map */
